

const Header = ()=>{
return (
    <>
<div className="wrapper3">
  
  <div id="header" >  
      <div className="flex">
          { <h1><img src="/images/slices/musicimage.png" id="logo"  title="Music Notations" alt="Music Notations" /></h1> }
             <h1 id="logotxt">Carnatic Music Notations</h1>
          </div>
          {/* <div className="flex">
         <h3 id="logotxt">Carnatic Music Notations</h3>
  </div> */}
  </div>
  
  
  <div id="nav">
  <ul className="nav">
  <li className="top">  <a href="/">Find Song</a>
    </li> 
      <li className="top">  <a href="/AboutUs.html">About us</a>
     </li>
  
      <li className="top hidecontact"><a href="/ContactForm.html">Contact us</a></li>
  
  </ul>
  </div>
  
  
  {/* <div id="content">
  <div className="column">
              <img src="/images/thyagaraja.png" width="124" height="126" alt="" title="Sri Thyagaraja" />
          </div>
  
  
          <div className="column">
              <img src="/images/utility_thmb.png" alt="" />
              <h3><a href="/blog/announcements/">Announcements</a></h3>
  
              <p>Dates for the 2023 Festival are announced! Stay tuned for details on
  the competitions and Sustaining Sampradaya shortly.</p>
  
          </div>
  
          </div> */}
   </div>

   </>)
}

export default Header