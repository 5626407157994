import { useState, useMemo, useEffect, useRef } from 'react'
import { sortRows, filterRows, paginateRows } from './helpers'
import { Pagination } from './Pagination'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import FormLabel from 'react-bootstrap/FormLabel';
import ReactDOM from "react-dom/client";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";


export const Table = ({ columns, data }) => {
  const [activePage, setActivePage] = useState(1)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
  const rowsPerPage = 25
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [buttonText, setButtonText] = useState([]);
  const path = require('path');
  const [SelectedRow, setSelectedRow] = useState([]);
  const [contactDetails, setContactDetails] = useState();
  const [message, setMessage] = useState()
  const [bodytext, setBodytext] = useState()
  const filteredRows = useMemo(() => filterRows(data, filters), [data, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)
  const ref = useRef();
  const count = filteredRows.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const songlist = [];
  const typelist = [];
  const composerlist = [];
  const ragamList = [];
  var optionslist = [];
  const sortaccessors = ['Name', 'Ragam', 'Type', 'Composer'];

  const handleSearch = (e, accessor) => {
    setActivePage(1)
    try {
      if (e != null) {
        if (typeof e === 'string') {
          console.log(e, accessor);
          setFilters((prevFilters) => ({
            ...prevFilters,
            [accessor]: e,
          }))
        }
        else {
          console.log(e, accessor);
          if (e[0].value != null) {
            setFilters((prevFilters) => ({
              ...prevFilters,
              [accessor]: e[0].value,
            }))
          }
        }
      } else {
        setFilters((prevFilters) => {
          const updatedFilters = { ...prevFilters }
          delete updatedFilters[accessor]

          return updatedFilters
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    data.map(({ Name, index }) => {
      if (!songlist.includes(Name)) {
        songlist.push({ value: Name, label: Name });
      }
    });
    data.map(({ Ragam, index }) => {
      if (!ragamList.includes(Ragam)) {
        ragamList.push({ value: Ragam, label: Ragam });
      }
    });
    // data.map(({ Type , index }) => {
    //   if (!typelist.includes(Type)) {
    //     typelist.push({ value: Type, label: Type });
    //   }
    // });

    // data.map(({ Composer , index }) => {
    //   if (!composerlist.includes(Composer)) {
    //     composerlist.push({ value: Composer, label: Composer });
    //   }
    // });

  }, [])
  const handleSelectedRow = (content) => {
    setSelectedRow(content);
    setBodytext(`Please provide details of the Song ${content["Name"]} of Ragam  ${content["Ragam"]}`)
    setShowContactPopup(true);
  };
  const handleEmptyRequest = () => {
    setShowContactPopup(true);
    setBodytext(`Please provide the details of`);
  };



  const handleRequest = (content) => {

    setBodytext(content);
    console.log(bodytext);

  };
  const handleSort = (accessor) => {
    setActivePage(1)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
  }

  const clearAll = () => {
    setSort({ order: 'asc', orderBy: 'id' })
    setActivePage(1)
    setFilters({})
  }

  return (
    <>


      <div className="wrapper2">

      <div className='container row'>
        <table>
          <thead>{!showContactPopup &&
          <tr className='first sticky_col_head'>
              <th></th>
              {columns.map((column) => {

                optionslist = null
                if (column.accessor == "Name") { optionslist = songlist }
                if (column.accessor == "Ragam") { optionslist = ragamList }
                if (column.accessor == "Composer") { optionslist = composerlist }
                if (column.accessor == "Type") { optionslist = typelist }
                console.log(optionslist)

                if (sortaccessors.includes(column.accessor)) {

                  return (
                    <th >
                      <Typeahead
                        allowNew
                        options={optionslist}
                        placeholder={column.Label}
                        style={{
                          borderColor: 'blue',
                        }}
                        ref={ref}
                        id='basic-typeahead-single'
                        onChange={(e) => { handleSearch(e, column.accessor) }}
                        onInputChange={(e) => { handleSearch(e, column.accessor) }}

                      />

                    </th>

                  )
                }
                else {
                  <th key={column.accessor}>
                    <span>{column.Label}</span></th>
                }
              })}
            </tr>}
            <tr className="second " style={ {backgroundColor:"beige" } }>
              <th className='sticky_col_head'></th>
              {columns.map((column) => {
                const sortIcon = () => {
                  if (column.accessor === sort.orderBy) {
                    if (sort.order === 'asc') {
                      return '⬆️'
                    }
                    return '⬇️'
                  } else {
                    return '️↕️'
                  }
                }
                if (sortaccessors.includes(column.accessor)) {
                  return (

                    <th key={column.accessor}>
                    {column.Label}
                      <button className='contactButton' onClick={() => handleSort(column.accessor)}>{sortIcon()}</button>
                    </th>
                  )
                }
                else {
                  return (
                    <th key={column.accessor}>
                      <span>{column.Label}</span></th>)
                }
              })}

            </tr>
           
          </thead>
          <tbody>
            {calculatedRows.map((row) => {
              return (

                <tr key={row.id}>
                  <td className="sticky_col_body" key={row.id}>
                    <button style={{
                      padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '60px',
                    }}
                      onClick={() => {
                        handleSelectedRow(row)
                      }}

                    >
                      Contact Us
                    </button>{' '}
                  </td>

                  {columns.map((column) => {
                    if (column.format) {

                      return <td key={column.accessor}>{column.format(row[column.accessor])}</td>

                    }
                    return <td key={column.accessor}>{row[column.accessor]}</td>


                  })}
                </tr>
              )
            })}
          </tbody>
        </table></div>
         <div id="pagination" className='row' >
        {count > 0 ? (
          <Pagination 
            activePage={activePage}
            count={count}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        ) : (
          <p>No data found</p>
        )}
         <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
       </div>  
        <Modal
          dialogClassName='modal-60wh'
          show={showContactPopup}
          onHide={() => {
            setShowContactPopup(false);
          }}
        >

          <Modal.Header>
            <Modal.Title><h1>Contact US</h1></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='text'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                as='textarea'
                required
                placeholder='name'
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId='text'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                as='textarea'

                required
                placeholder='Email'
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    email: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId='text'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                as='textarea'

                required
                placeholder='Phone'
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    phone: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId='text'>
              <Form.Label>Request Details</Form.Label>
              <Form.Control
                as='textarea'
                required
                rows={5}
                defaultValue={bodytext}
                onChange={(e) =>
                  handleRequest(e.target.value)
                }

              />
            </Form.Group>
            <Form.Group controlId='text'>
              <Form.Label>Comments / Suggestions</Form.Label>
              <Form.Control
                as='textarea'

                required
                placeholder='Comments / Suggestions'
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    suggestions: e.target.value,
                  })
                }
              />
            </Form.Group>

            <button
              style={{
                padding: '4px',
                borderRadius: '30px',
                margin: '3px',
                width: '200px',
                height: '50px'

              }}
              type="submit"
              onClick={async () => {
                console.log(contactDetails)

                // const transporter = nodemailer.createTransport({
                //     host: 'smtp.gmail.com',
                //     service: 'gmail',
                //     auth: {
                //       user: 'carnaticmusiccompositions@gmail.com',
                //       pass: 'vdemanbvrjlawwfq'
                try {
                  axios.post('/api/sendmail/', {
                    to: 'carnaticmusiccompositions@gmail.com',
                    text: `Dear Admin , <br/>  Here is the request from <strong>${contactDetails.name} </strong> :  <br/> <strong> Request:  ${bodytext} </strong>
                     Suggestions : ${contactDetails.suggestions} <br />
                     Contact Details: <br /> Email : ${contactDetails.email} , Phone : ${contactDetails.phone} `,
                    subject: 'Song Requested'
                  })


                } catch (error) {
                  if (contactDetails == null) {
                    setMessage({
                      message:
                        'Contact details are not updated',
                      style: { backgroundColor: 'red' },
                    })
                    alert(
                      'Please update Contact details'
                    )
                    setShowContactPopup(true);
                  }
                  else {

                    setMessage({
                      message:
                        'Error Sending email to customer. Please contact Admin.',
                      style: { backgroundColor: 'red' },
                    })
                    alert(
                      'Error Sending email to customer. Try again. if it fails again, Please contact Admin.'
                    )
                  }
                }

                setMessage({
                  message: 'Email sent to customer!',

                  style: { backgroundColor: 'lightseagreen' },
                })
                setShowContactPopup(false)
                setContactDetails(null)
              }}

            >
              Send Request
            </button>

          </Modal.Body>
        </Modal>
        <div>
         
        </div>
      </div>
    </>
  )
}
