import logo from './logo.svg';
import './App.css';
import DataTableComponent  from './components/datatable.component'
import ContactForm from "./components/ContactForm";
import AboutUs from "./components/AboutUs";

import Header from './components/header';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015

import './styles/common.css';
import './styles/main.css';
import './styles/nav-mobile.css';
import './styles/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
return(
  
  <BrowserRouter>
    <Header />
   
      <Routes>    
       
          <Route path="/" element={<DataTableComponent />} />
          <Route path="/ContactForm.html" element={<ContactForm />}  />
          <Route path="/AboutUs.html" element={<AboutUs />} />

      </Routes>
    </BrowserRouter>);

  // return (
  //   <div className="App">
      
  //     <DataTableComponent />
  //   </div>
  // );
}

export default App;
