import React from 'react';
import nodemailer from 'nodemailer';
import { useTable, UseFiltersState , usef, useGlobalFilter ,useSortBy} from 'react-table'
// import  '../tablestyle.css';
import { useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import FormLabel from 'react-bootstrap/FormLabel';
import Pagination  from './Pagination';
import Form from 'react-bootstrap/Form';
import { Table } from './Table';





function DataTableComponent() {
  const data = [
          {
          "Serial #": "1",
          "Name": "Devi Meenalochani",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2",
          "Name": "Sri Saara Devarathae",
          "Type": "Varnam-Tana",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3",
          "Name": "Valachi yunnanaa",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4",
          "Name": "Manasu telisi",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5",
          "Name": "Neerajakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6",
          "Name": "Neeraja lochani",
          "Type": "Varnam-Tana",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "7",
          "Name": "Ninne Namminaa",
          "Type": "Varnam-Tana",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "8",
          "Name": "Paradevathae varadae",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "9",
          "Name": "Ninne Namminaa",
          "Type": "Varnam-Tana",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "10",
          "Name": "Kanjadalayathakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "11",
          "Name": "Jagadeesha Paatali pura",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "12",
          "Name": "Ninnukori",
          "Type": "Varnam-Tana",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "13",
          "Name": "Sarasiruhasana priyae",
          "Type": "Varnam-Tana",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "14",
          "Name": "Devi Dayaapari",
          "Type": "Varnam-Tana",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "15",
          "Name": "Yela nee daya raadhu",
          "Type": "Varnam-Tana",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "16",
          "Name": "Devi Gowri Manohari",
          "Type": "Varnam-Tana",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "17",
          "Name": "Needhu charana mulae",
          "Type": "Varnam-Tana",
          "Ragam": "Vijayanaagari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "18",
          "Name": "Yela nee daya raadhu",
          "Type": "Varnam-Tana",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "19",
          "Name": "Daya Jooda",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Tisra Jathi Ata thalam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "20",
          "Name": "Paarvathi priya kumaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "21",
          "Name": "Paalae Paripaalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha Chandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "22",
          "Name": "Saara Saakshi Chamundeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsavinodini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "23",
          "Name": "Govardhana gireesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naagavalli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "24",
          "Name": "Mahatripura Sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maddhima ravali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "25",
          "Name": "Raamam Bhajae Shyamalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "26",
          "Name": "Paahi Raamachandra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijaya Saarangam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "27",
          "Name": "Pankajanabha sahodari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Laavanya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "28",
          "Name": "Maduraakshari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowrilalithaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "29",
          "Name": "Niraamayae Nirmalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Iraavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "30",
          "Name": "Neelakantam upaasmahae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dheera Ranjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "31",
          "Name": "Chidaananda vilaasini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Chandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "32",
          "Name": "Bhajarae Sri Krishnam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kousthubam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "33",
          "Name": "Devasenapathim bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chithroopi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "34",
          "Name": "Maamava Sri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manoham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "35",
          "Name": "Sri Raajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "Rajarajeshwari Pancharatnam-1",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "36",
          "Name": "Sri Raajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "Rajarajeshwari Pancharatnam-3",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "37",
          "Name": "Sri Raajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "Rajarajeshwari Pancharatnam-2",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "38",
          "Name": "Sri Raajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "Rajarajeshwari Pancharatnam-4",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "39",
          "Name": "Sri Raajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Cuddalore M Subramaniam",
          "Group": "Rajarajeshwari Pancharatnam-5",
          "Language": "N/A",
          "Book Title": "Varnams, KeerthanamsApoorva Ragas, Rajarajeshwari Pancharatnam",
          "Book Author": "Cuddalore M Subramaniam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "40",
          "Name": "Shri Meenakshi maampahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "41",
          "Name": "Karunakara paratpara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "42",
          "Name": "Velae Kandan Thirukkaiyalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "43",
          "Name": "Dhaaraka Samhaaruda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "44",
          "Name": "Inyochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "45",
          "Name": "Vasantharudu poojitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "46",
          "Name": "Saadara mukha guha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "47",
          "Name": "Shiki vaahana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "48",
          "Name": "Needarshana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "49",
          "Name": "Samaya muraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "50",
          "Name": "Jagadambhika",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "51",
          "Name": "Sanchalamenduru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanta Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Kallidaikuruchi Sangeetha Kalanidhi Brahmasri Vedantha Bhagavathar Keerthanaigal",
          "Book Author": "H. Mahadevaiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "52",
          "Name": "Swami nee paada mula",
          "Type": "Varnam-Tana",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "53",
          "Name": "Sree Ramachandra naadhu",
          "Type": "Varnam-Tana",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "54",
          "Name": "Sarojaaksha Rama Ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "55",
          "Name": "Nenarunchi",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "56",
          "Name": "Ninnendho vaedi",
          "Type": "Varnam-Pada",
          "Ragam": "Devamanohari",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "57",
          "Name": "Sree Rajagopala",
          "Type": "Varnam-Pada",
          "Ragam": "Abhogi",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "58",
          "Name": "Raama Naamoraalincharaa",
          "Type": "Swarajathi",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "59",
          "Name": "Raamaa ravikula",
          "Type": "Swarajathi",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "60",
          "Name": "Raama Manavivinumaa",
          "Type": "Swarajathi",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "61",
          "Name": "Saami nenaruna",
          "Type": "Swarajathi",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "62",
          "Name": "Guru charanam bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "63",
          "Name": "Guruvara mahimala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "64",
          "Name": "Chedi pogae Oh Manasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "65",
          "Name": "Kannulaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "66",
          "Name": "Sri Raama Brahmamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "67",
          "Name": "Raama Bhakthi jesina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "68",
          "Name": "Ra Ra Vasudeva Vara kumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "69",
          "Name": "Kodanda Deeksha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "70",
          "Name": "Ninnuvinaaga Devvvaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "71",
          "Name": "Sri Raghu Raamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "72",
          "Name": "Ra Ra Dasaratha Raajakumara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "73",
          "Name": "Hari Hari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "74",
          "Name": "Paramapurusha Jagadeesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "75",
          "Name": "Thagaduraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "76",
          "Name": "Paravasa maayanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "77",
          "Name": "Vidavanuraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "78",
          "Name": "Naa Thappulanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "79",
          "Name": "Anandamaya maanavanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jyothiswaroopini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "80",
          "Name": "Manasaa Raamuni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "81",
          "Name": "Yamuni Paadalaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "82",
          "Name": "Raadha Ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "83",
          "Name": "Sabalamu maani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "84",
          "Name": "Naarada Gaana Vishaarada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "85",
          "Name": "Karuninchara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "86",
          "Name": "Bhajarae Manasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "87",
          "Name": "Kuchela Paripaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "88",
          "Name": "Dikulu maanparaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "89",
          "Name": "Vinaraadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "90",
          "Name": "Vadarasanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "91",
          "Name": "Yevaa ratha rinthu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "92",
          "Name": "Ra Ra Shri Raghu Veera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "93",
          "Name": "Neekae Daya ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "94",
          "Name": "Narayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "95",
          "Name": "Paalayamaam shree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "96",
          "Name": "Thathvamu theliyaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "97",
          "Name": "Prathyaksha mukha ra ra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "98",
          "Name": "Yemani Palukulu ra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "99",
          "Name": "Neramulennaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "100",
          "Name": "Paalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suvarnaangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "101",
          "Name": "Inthamarmivanuchu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "102",
          "Name": "Aparaadhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "103",
          "Name": "Gnaana swaroopamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "104",
          "Name": "Idhigadhayaanandhamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "105",
          "Name": "Raamachandra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "106",
          "Name": "Bhajarae Shree Raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "107",
          "Name": "Raakshasa kula mardana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "108",
          "Name": "Charananu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanaagari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "109",
          "Name": "Dhaariteliyaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "110",
          "Name": "Tirupathi venkataramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "Sowrashtram",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "111",
          "Name": "Balagopala krishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "112",
          "Name": "Khandavahaathmaja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "113",
          "Name": "Naama mulanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "114",
          "Name": "Dasaradha Nandana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "115",
          "Name": "Hari Hari nee narahari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "116",
          "Name": "Krishnaa yanusu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "117",
          "Name": "Karunaanidhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "118",
          "Name": "Sridhara vaikuntadhipa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "119",
          "Name": "Thillana-KedaraGowlai",
          "Type": "Thillana",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "120",
          "Name": "Neevae nannu brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "121",
          "Name": "Bhagaseya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rama Narayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Walajapet Venkatramana Bhagavathar Compositions & History",
          "Book Author": "Nagaraja Bhagavathar, Damodaran",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "122",
          "Name": "Sri Gananatha",
          "Type": "Geetham",
          "Ragam": "Malahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "123",
          "Name": "Kundagowra",
          "Type": "Geetham",
          "Ragam": "Malahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "124",
          "Name": "Keraya neeranu",
          "Type": "Geetham",
          "Ragam": "Malahari",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "125",
          "Name": "Padumanabha",
          "Type": "Geetham",
          "Ragam": "Malahari",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "126",
          "Name": "Vibhishana Paalaka",
          "Type": "Geetham",
          "Ragam": "Malahari",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "127",
          "Name": "Analekara",
          "Type": "Geetham",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "128",
          "Name": "Sri Gopala",
          "Type": "Geetham",
          "Ragam": "Naatai",
          "Thalam": "Dhruva ",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "129",
          "Name": "Duma kumaa",
          "Type": "Geetham",
          "Ragam": "Gowlai",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "130",
          "Name": "Devaki Devaki",
          "Type": "Lakshana Geetham",
          "Ragam": "Arabhi",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "131",
          "Name": "Jagadabhi nayaki",
          "Type": "Geetham",
          "Ragam": "Sree",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "132",
          "Name": "Shanka chakram",
          "Type": "Geetham",
          "Ragam": "ReethiGowlai",
          "Thalam": "Jhampa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "133",
          "Name": "Vidubimba",
          "Type": "Geetham",
          "Ragam": "Bhowli",
          "Thalam": "Dhruva ",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "134",
          "Name": "Arae Arae Naadhas",
          "Type": "Geetham",
          "Ragam": "Saranga naatai",
          "Thalam": "Dhruva Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "135",
          "Name": "Manmadha",
          "Type": "Geetham",
          "Ragam": "Maalavasri",
          "Thalam": "Dhruva",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "136",
          "Name": "Arae Arae Saarva",
          "Type": "Geetham",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Matya",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "137",
          "Name": "Rae Rae sudha",
          "Type": "Geetham",
          "Ragam": "Kalyani",
          "Thalam": "Dhruva",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "138",
          "Name": "Dhara Dhalitha champaka",
          "Type": "Geetham",
          "Ragam": "Shankarabharanam",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "139",
          "Name": "Arae dheena paarijaathu",
          "Type": "Geetham",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Dhruva Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "140",
          "Name": "Saami Ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "141",
          "Name": "Saara saakshi Ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "142",
          "Name": "Sri Naathaa",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "143",
          "Name": "Palumaru",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "144",
          "Name": "Janani",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "145",
          "Name": "Sami Daya juda",
          "Type": "Varnam-Tana",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "146",
          "Name": "Cheliya",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "147",
          "Name": "Shree Karambuga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "148",
          "Name": "Kori Kori",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "149",
          "Name": "Kalikedhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Purvi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "150",
          "Name": "Paramaathmuni maansa (Maansa Pooja)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "151",
          "Name": "Gopamelara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "152",
          "Name": "Kanikaramu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "153",
          "Name": "Naraayana nee naamamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "154",
          "Name": "Paramadayanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "155",
          "Name": "Kannadhantri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "156",
          "Name": "Jagadabhiraama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "157",
          "Name": "Nannu brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "158",
          "Name": "Maattaada radha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "159",
          "Name": "Neevae dikkani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Venkatesa Pancharatnam-4",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "160",
          "Name": "Paradevi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "161",
          "Name": "Koniyaatina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Kalahasthi Pancharatnam-1",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "162",
          "Name": "Sri Krishna Leela Tarangini (Tuned by Veena Kuppayyar)",
          "Type": "Tarangam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar, Veena Kuppaiyyar",
          "Group": "Krishna Leela Tarangini",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "163",
          "Name": "Gaana Lola Karunaala Vaala",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "164",
          "Name": "Intha sowkha seya",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "165",
          "Name": "Ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "166",
          "Name": "Vanajaaksha",
          "Type": "Varnam-Tana",
          "Ragam": "ReethiGowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "167",
          "Name": "Maguva ninne",
          "Type": "Varnam-Tana",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "168",
          "Name": "Vanajaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "169",
          "Name": "Karunimpa",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "170",
          "Name": "Chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "171",
          "Name": "Intha parakela",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "172",
          "Name": "Kaligi ninnae",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "173",
          "Name": "Paalinchu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "174",
          "Name": "Sri pathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "175",
          "Name": "Maapathi naamamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapijingala",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "176",
          "Name": "Paraakruda janula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "177",
          "Name": "Mosa pogavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "178",
          "Name": "Ituvanti samayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Jhampa",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "179",
          "Name": "Neranemiraa nera Jesina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "180",
          "Name": "Telisi teliya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "181",
          "Name": "Maaramanu nee varamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "182",
          "Name": "Nannu brova Raadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "183",
          "Name": "Chalamela Jesavura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desiya Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "184",
          "Name": "Brovanu Ra ra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "185",
          "Name": "Intha paaraamuga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "186",
          "Name": "Ninnu nera nammithi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "187",
          "Name": "Momu joopaka raadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "188",
          "Name": "Neranammidhiraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "189",
          "Name": "Paalimpavae nannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "190",
          "Name": "Kaththulae thani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaya naarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "191",
          "Name": "Elara naatho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "192",
          "Name": "Nannubrocheva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "193",
          "Name": "Mahima Teliya ",
          "Type": "Pallavi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "194",
          "Name": "Tripura Sundari Gowri",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "195",
          "Name": "Intha Chalamu",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "196",
          "Name": "Entho premato",
          "Type": "Varnam-Tana",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "197",
          "Name": "Sarasijaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "198",
          "Name": "Saami neepai",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "199",
          "Name": "Nenarunchi",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "200",
          "Name": "Ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "201",
          "Name": "Saraguna nannela",
          "Type": "Varnam-Tana",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "202",
          "Name": "Mithiraakshi neepai",
          "Type": "Varnam-Tana",
          "Ragam": "Mukhari",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "203",
          "Name": "Kamalakshi ",
          "Type": "Varnam-Tana",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "204",
          "Name": "Ee thanuvunu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desiya Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "205",
          "Name": "Pattapaadu saaladha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "206",
          "Name": "Manavyala kimpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "207",
          "Name": "Naamorala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "208",
          "Name": "Ninne nera nammithi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "209",
          "Name": "Talli naameedha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "210",
          "Name": "Nee sahaayamu leni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "211",
          "Name": "Jo Jo Jo Devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "212",
          "Name": "Intha parakela namma (on Mysore Chamundeshwari)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "213",
          "Name": "Nannu brova Raadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Kalahasthi Pancharatnam-2",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "214",
          "Name": "Birana Nannu brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Kalahasthi Pancharatnam-3",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "215",
          "Name": "Sama Gaana lola",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SaalagaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Kalahasthi Pancharatnam-4",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "216",
          "Name": "Sevindhamuraaramma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Kalahasthi Pancharatnam-5",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "217",
          "Name": "Baahumeeraganu naatho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Venkatesa Pancharatnam-5",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "218",
          "Name": "Mammu brochu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Venkatesa Pancharatnam-1",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "219",
          "Name": "Nannu brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Venkatesa Pancharatnam-2",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "220",
          "Name": "Sarojaakshani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "Sri Venkatesa Pancharatnam-3",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "221",
          "Name": "Taamasa mela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "222",
          "Name": "Ninnunera nammidhiraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "223",
          "Name": "Ituvanti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "224",
          "Name": "Sree Ramana padma nayana",
          "Type": "Swara Sahithya Ragamalika",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvottiyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "225",
          "Name": "Venugaana loluni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thyagaraja Swamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "226",
          "Name": "Premathone nundaga",
          "Type": "Pallavi",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampa",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "227",
          "Name": "A eka maina manasu thonu",
          "Type": "Pallavi",
          "Ragam": "Bhairavi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "228",
          "Name": "Nee divya paadamulaku (Mangalam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Kuppaiyyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veena Kuppaiyar Compositions (Pallavi Swara Kalpavalli) - Tiruvottiyur Tyagayyar",
          "Book Author": "Therazhundur V. Ranganathan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "229",
          "Name": "Sri Ganapathiyae Thunai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Vinayagar Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "230",
          "Name": "Siddhi arul sivasakthi baalakanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Vinayagar Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "231",
          "Name": "Gajaanana ganeshwaranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Vinayagar Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "232",
          "Name": "Vandenakarul thandhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Vinayagar Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "233",
          "Name": "Guruparanae ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sadguru Keerthanam",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "234",
          "Name": "Vaa Vaa kalai maathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Saraswathi Keerthanam",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "235",
          "Name": "Jeya Jeya (Thodayam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Jhampa",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "236",
          "Name": "Hara Hara Shanmuga Namo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "237",
          "Name": "Karunaakara Guhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "238",
          "Name": "Saravana Bhava Sivaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "239",
          "Name": "Kadaikan paarayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "240",
          "Name": "Shanmuganae Siva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "241",
          "Name": "Yaenakundriru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "242",
          "Name": "Vela charanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "243",
          "Name": "Indha thiru vadivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "244",
          "Name": "Kandha Vandarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "245",
          "Name": "Kannarak kandaenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "246",
          "Name": "Saravana Bhava soora",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "247",
          "Name": "Velayudhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "248",
          "Name": "Oraaru muganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "249",
          "Name": "Mangalam kumara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Subramanya Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "250",
          "Name": "Sankara Mahadeva",
          "Type": "Virutham",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "251",
          "Name": "Jaya Sankara (Thodayam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Jhampa",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "252",
          "Name": "Jagamellam (Manga sindhu)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mangala Kaushika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "253",
          "Name": "Mahadeva namo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "254",
          "Name": "Neelakanda nithyananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "255",
          "Name": "Karunaa saagara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "256",
          "Name": "Enna Vandhalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "257",
          "Name": "Paramaananda sundara sankara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "258",
          "Name": "Unnai thavira",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "259",
          "Name": "Unai nambinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "260",
          "Name": "Chandrakalaadharanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "261",
          "Name": "Innum irangatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "262",
          "Name": "Paarum Paarum Kadai kann",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "263",
          "Name": "Kaalakooda vishamunda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "264",
          "Name": "Sharanam shiva ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "265",
          "Name": "Manakkavalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "266",
          "Name": "Innum irakkamillaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "267",
          "Name": "Hara hara shanmbho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopaka Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "268",
          "Name": "Shankara nin karunai purivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "269",
          "Name": "Chandrasekaranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "270",
          "Name": "Mahaa prabho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "271",
          "Name": "Nambinae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "272",
          "Name": "Unmel baaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "273",
          "Name": "Chitham kalangaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "274",
          "Name": "Endraikku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "275",
          "Name": "Sambu krupai varumo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "276",
          "Name": "Unn paadamae gathi yenak keesaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "277",
          "Name": "Innamum endhan meedhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "278",
          "Name": "Dayavu seiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "279",
          "Name": "Unn pola deiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "280",
          "Name": "Enn neramum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "281",
          "Name": "Paramadayakara shankara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "282",
          "Name": "Sodhippadenna jnyaayamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "283",
          "Name": "Eeaswaranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "284",
          "Name": "Eesanae nin paada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naganandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "285",
          "Name": "Jeya Jeya kailasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "286",
          "Name": "Ini naan marakkenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "287",
          "Name": "Sivaanandam Brahmaanandam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "288",
          "Name": "Naadanai (with raga mudra)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "289",
          "Name": "Darisikka venum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "290",
          "Name": "Bhajanai seivai manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "291",
          "Name": "Malayadhae manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "292",
          "Name": "Dinamum ninai manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "293",
          "Name": "Sivanai ninai manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "294",
          "Name": "Sivanai ninaithu sthuthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "295",
          "Name": "Sankaranai dinam dinamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "296",
          "Name": "Eesanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "297",
          "Name": "Nambikkol nambikkol sivanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "298",
          "Name": "Manamae unnakuoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "299",
          "Name": "Sindhanai seivadheppodhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "300",
          "Name": "Theruvadheppo nenje",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "301",
          "Name": "Mosampogaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "302",
          "Name": "Devanai potri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "303",
          "Name": "Adi sivanarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "304",
          "Name": "Igaparam tharum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "305",
          "Name": "Siva naamam vegu kshemama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "306",
          "Name": "Yezhai nenjae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "307",
          "Name": "Periyor padam pani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "308",
          "Name": "Patrikondaar podhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "309",
          "Name": "Ekaantha nishtayil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "310",
          "Name": "Unnippaaradi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "311",
          "Name": "Yaaraanaalenna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "312",
          "Name": "Yethanai thaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "313",
          "Name": "Aankaaraveriyarkku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "314",
          "Name": "Kelviyilla",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "315",
          "Name": "Sevikkavenum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "316",
          "Name": "Darisikka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "317",
          "Name": "Orunaal orupozhudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "318",
          "Name": "Pannindhaar piravi noi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "319",
          "Name": "Paramadayakaranai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "320",
          "Name": "Aaruvaruvaar kooda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "321",
          "Name": "Ulagaththukkaaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "322",
          "Name": "Enna vidham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "323",
          "Name": "aagaadhaagaathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "324",
          "Name": "Nambaadhae Nambaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "325",
          "Name": "Aagumaagu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopaka Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "326",
          "Name": "Sivan varuaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "327",
          "Name": "pirattai kandu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "328",
          "Name": "Yaen indha padiyaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "329",
          "Name": "Marundhirukkudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "330",
          "Name": "Nan muthirukkudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "331",
          "Name": "Chithaththai sivanpaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "332",
          "Name": "Dinamum nee pani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Sivan Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "333",
          "Name": "Parama nanda natanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "334",
          "Name": "Ananda natam aaduvar thillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "335",
          "Name": "Kaanavenum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "336",
          "Name": "Darisanamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "337",
          "Name": "Aadum chidambaraeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "338",
          "Name": "Raajanai thozhuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "339",
          "Name": "Aadum paraman paadam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "340",
          "Name": "Aananda mandru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "341",
          "Name": "Kattai vittu pogum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopaka Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Nataraja Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "342",
          "Name": "Deivam neeyae nalla",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Mahavishnu Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "343",
          "Name": "Vandarul samayamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Mahavishnu Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "344",
          "Name": "Gopalakrishna dayanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Mahavishnu Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "345",
          "Name": "Srikanteshwaranai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Mahavishnu Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "346",
          "Name": "Paradevi thirupaarkadal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Mahalakshmi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "347",
          "Name": "Ambikai Gowri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "348",
          "Name": "Karunaakataakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "349",
          "Name": "Thaayae Gathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "350",
          "Name": "Andari Sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "351",
          "Name": "Ennidathir krupai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "352",
          "Name": "Aavidaiambiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "353",
          "Name": "Charanam Charanam Gomathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "354",
          "Name": "Ambikaiyae arulsei",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "355",
          "Name": "Ambikae unpaada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "356",
          "Name": "Varasugamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "357",
          "Name": "Karunaakari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "358",
          "Name": "Akilaloka janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "359",
          "Name": "Karunaipuri idhu tharunam meenakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "360",
          "Name": "Nithyakalyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "361",
          "Name": "Ambikaeunpadamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "362",
          "Name": "Karunai seivaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "363",
          "Name": "Yaenindha thaamasam Meenakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "364",
          "Name": "Arul puriya idhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Ata Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "365",
          "Name": "Chindhai irangu neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "366",
          "Name": "Nee dayavai aadharippai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "367",
          "Name": "Shakthi gowri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "368",
          "Name": "Rajarajeshwari giriraja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Devi Keerthanams",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "369",
          "Name": "Manonmanikkummi",
          "Type": "Kummi",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "Kummi paatu",
          "Language": "Tamil",
          "Book Title": "Bhajanai Keerthanangal",
          "Book Author": "Gnana skandar iyer",
          "Year of Publication": "1929",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "370",
          "Name": "Ashir Abhinandana Padya Maalika",
          "Type": "Sloka",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Shankaracharya of Govardanah Peetah",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "371",
          "Name": "Maha Ganapathim Vande",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "372",
          "Name": "Subramanyena Rakshitoham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "373",
          "Name": "Sadaa Shrayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chaamaram/Shanmugapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "374",
          "Name": "Abhayaambhaa Jagadambhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "375",
          "Name": "Aaryaam Abhayaambhaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "376",
          "Name": "Girijayaa Ajayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "377",
          "Name": "Abhayaambhikaayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "378",
          "Name": "Abhayaambikaayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "379",
          "Name": "Ambikaayaa Abhayaambikaayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "380",
          "Name": "Abhayaambhaayaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "381",
          "Name": "Daakshaayani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "382",
          "Name": "Abhayaambaa naayaka vara daayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "383",
          "Name": "Abhayaambhaa naayaka hari saayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "384",
          "Name": "Sri Abhayaambha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Abhayambha Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "385",
          "Name": "Kari Kalabha mukham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "386",
          "Name": "Kumaarasvaaminam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "387",
          "Name": "Sooryamoorthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Chatusra Jathi Dhruva Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "388",
          "Name": "Chandram Bhaja Maansa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "389",
          "Name": "Angaarakam Ashrayaamyaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "390",
          "Name": "Bhudamaashrayami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "391",
          "Name": "Bruhaspathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "392",
          "Name": "Shri Sukra Bhagavantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Khanda Jathi Ata thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "393",
          "Name": "Divaakara Tanujam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "394",
          "Name": "Samraamyaham sadaa raahum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RamaManohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "395",
          "Name": "Mahaasuram Kedu Maham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chaamaram/Shanmugapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Navagraha Kritis (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "396",
          "Name": "Raktha Ganapathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "397",
          "Name": "Gurumoorthae Bahukeerthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "398",
          "Name": "Thyaagaraajo viraajathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "399",
          "Name": "Thaayagaraaja Mahathva jaaroha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "400",
          "Name": "Thyaagaraaja Yoga Vaibhavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "401",
          "Name": "Thyaagesham Bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rudrapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "402",
          "Name": "Thyaagaraajam Bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "403",
          "Name": "Thyaagaraajam Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "404",
          "Name": "Thyaagaraajena Samrakshithoham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SaalagaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "405",
          "Name": "Thyaagaraajaaya Namasthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "406",
          "Name": "Thyaagaraajaa Dhanyam na jaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "407",
          "Name": "Sree Thyaagaraajasya Bhaktobhavaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rudrapriya",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "408",
          "Name": "Thyaagaraajae Kruthyaa Kruth",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "409",
          "Name": "Veera vasantha thyaagaraja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Veera Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "410",
          "Name": "Thyaagaraaja Paalayaashumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "411",
          "Name": "Anandeshwarena Samrakshithoham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Tiruvarur Kshetra Panchalinga Krithi",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "412",
          "Name": "Sideshwaraaya Namasthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Tiruvarur Kshetra Panchalinga Krithi",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "413",
          "Name": "Sadaachaleshwaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Tiruvarur Kshetra Panchalinga Krithi",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "414",
          "Name": "Haatakeshwara Samrakshamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Tiruvarur Kshetra Panchalinga Krithi",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "415",
          "Name": "Sree Valmeeka lingam Chinthayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Tiruvarur Kshetra Panchalinga Krithi",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "416",
          "Name": "Chintayamaam kanda moola",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Kanchipuram (Prithvi)",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "417",
          "Name": "Jambhupathae maampahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Thiruvanaikaval (Appu)",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "418",
          "Name": "Arunaachalanaatham smaraami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Thiruvanamalai (Tejo)",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "419",
          "Name": "Sree Kaalahastheesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Kalahasthi (Vayu)",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "420",
          "Name": "Ananda Natana prakaasham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Bhuloka Panchalinga Kshetra/Panchabhutha Kshetra Kriti-Chidambaram (Aakaasha)",
          "Language": "Sanskrit",
          "Book Title": "Shiva Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "421",
          "Name": "Sree Gananaatham Bhajare",
          "Type": "Kriti/Keerthanam",
          "Ragam": "EshaManohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "422",
          "Name": "Swaminaatha Paripaalayaashumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "423",
          "Name": "Sree Krishno Maam Rakshathu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nasamani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "424",
          "Name": "Sree Krishnam Bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Roopavathi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "425",
          "Name": "Vaasudevam Upaasmahae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MaalavaPanchamam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "426",
          "Name": "Narahari maashrayami sathatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaya Shuddha Maalavi",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "427",
          "Name": "Govardhana gireesham Smarami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "428",
          "Name": "Balakrishnam Bhaavayami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gopikavasantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "429",
          "Name": "Chetah Sri Balakrishnam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "430",
          "Name": "Neelaangam Harim Nithyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "431",
          "Name": "Rajagopalam Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "432",
          "Name": "Parimala Ranganatham Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "433",
          "Name": "Ramakrishnena Samrakshitoham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "434",
          "Name": "Gopalakrishnaya Namasthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "435",
          "Name": "Krishnananda mukunda murarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "436",
          "Name": "Rangapuravihara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BrindavanaSaranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "437",
          "Name": "Ananta balakrishnamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "EshaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "438",
          "Name": "Sree Venugopala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurinji",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "439",
          "Name": "Santhana Sowbhagya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "440",
          "Name": "Shanka Chakra Gada paanim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "441",
          "Name": "Govindaraajam Upasmahae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "442",
          "Name": "Sowndararajam Ashrayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BrindavanaSaranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "443",
          "Name": "Sree Sundararajam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kasi Ramakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "444",
          "Name": "Varadarajam Upasmahae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "445",
          "Name": "Maadhavo Maampaathu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "Dasavathara Ragamalika",
          "Language": "Sanskrit",
          "Book Title": "Sri Krishna Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "446",
          "Name": "Vinaayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thoyavegavaahini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "447",
          "Name": "Sowra senaesam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrasenam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "448",
          "Name": "Sree Raamachandro Rakshathu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "449",
          "Name": "Raamachandram Bhaavayami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "450",
          "Name": "Sree Raamam ravikulaapthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "451",
          "Name": "Kodandaraamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilaaravam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "452",
          "Name": "Kshithijaa Ramanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "453",
          "Name": "SanthaanaRaamaswaminam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindolaVasantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "454",
          "Name": "Raamachandrena",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maanji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "455",
          "Name": "Raamachandraaya Namasthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "456",
          "Name": "Raamachandraa Dhanyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "457",
          "Name": "Raamachandrasya Daasoham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhaamavathi/Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "458",
          "Name": "Raamae Bharatha paalitha Raajyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jyothi",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "459",
          "Name": "Maamavapattabhiraama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "460",
          "Name": "Maamava Raghuveera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maahuri",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "461",
          "Name": "Raama Raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Raamakali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "462",
          "Name": "Bhavanaathmajaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chalanaatai",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "463",
          "Name": "Raamachandra Bhaktham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keyahejjajji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "464",
          "Name": "Veera hanumathae namo namo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "465",
          "Name": "Raamajannardhana",
          "Type": "Nottuswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "466",
          "Name": "Aanjaneyam sadaa Bhaavayaami",
          "Type": "Nottuswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sri Rama Navavarnam (By Muthuswami Dikshitar)",
          "Book Author": "Anantakrishnayyar",
          "Year of Publication": "1957",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "467",
          "Name": "Sree Raajamaathangi",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "468",
          "Name": "Sree Mahaa",
          "Type": "Varnam-Tana",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "469",
          "Name": "Sree Mahishaasura",
          "Type": "Varnam-Tana",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "470",
          "Name": "Sree Mahaaganapathae",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "471",
          "Name": "Sree Sugahamsa gamanaa",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "472",
          "Name": "Mana mohanaa",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "473",
          "Name": "Ganapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "474",
          "Name": "Gam Ganapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "475",
          "Name": "Vaageeswari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "476",
          "Name": "Ambavaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "477",
          "Name": "Vaani Nithyakalyaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "478",
          "Name": "Sharanam Vijaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijaya Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "479",
          "Name": "Sree Mahishaasura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsagamani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "480",
          "Name": "Vaancha thonunaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KarnaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "481",
          "Name": "Meenalochani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "482",
          "Name": "Raajaraajeshwary",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "483",
          "Name": "Maanamukhaavalanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "484",
          "Name": "Ninnu nammi Naanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Mishra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "485",
          "Name": "Nee Paada mulanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NataBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "486",
          "Name": "Pashupathi priya sathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pasupathipriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "487",
          "Name": "Thappulanniyu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "488",
          "Name": "Amba Alankaari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Tisra Jathi Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "489",
          "Name": "Jeya niyaashrutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rathnangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "490",
          "Name": "Saarasadalanayaae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saaraamathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "491",
          "Name": "Samayamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BudhaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "492",
          "Name": "Ika nae thaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Guha Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "493",
          "Name": "Sharavana Bhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pasupathipriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "494",
          "Name": "Shree Saravana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sumana priya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "495",
          "Name": "Siki vaahana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadeepakam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "496",
          "Name": "Nee vaeyidu paraagu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "497",
          "Name": "Teliyakanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "498",
          "Name": "Valli Naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "499",
          "Name": "Manasukaraki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MeghaRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "500",
          "Name": "Ithi Manji samayamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "501",
          "Name": "Ennaalu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "502",
          "Name": "Saravana bhavaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali/Kamavardini",
          "Thalam": "Adi (Kanda Gathi)",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "503",
          "Name": "Ithivaela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilabhaashini",
          "Thalam": "Sankeerna Jathi Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "504",
          "Name": "Naenu jaesina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "505",
          "Name": "Ninnu Nera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "506",
          "Name": "Needu mahima",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "507",
          "Name": "Bhoopaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "508",
          "Name": "Neepaadhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "509",
          "Name": "Dheera sikhaamani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "510",
          "Name": "Thaarakanaama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "511",
          "Name": "Sharanaagatha vatsala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "512",
          "Name": "Sree Raghuvara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naayaki",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "513",
          "Name": "Sahajaguna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "514",
          "Name": "Pasupathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "515",
          "Name": "Kalilo hari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "516",
          "Name": "Sree Raama Kodandaraama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "517",
          "Name": "Dinamani vamsa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Tisra Jathi Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "518",
          "Name": "Bandamujaeyaraadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "519",
          "Name": "Thudhikaikuriya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Mishra chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "520",
          "Name": "Muruganukkoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "521",
          "Name": "Yaarukkum Adangaatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "522",
          "Name": "Moovaasaikonda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Keerthanangal - Part 1",
          "Book Author": "Fiddle Appavayyar",
          "Year of Publication": "1934",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "523",
          "Name": "Pidiathanuruvumai",
          "Type": "Thevaram",
          "Ragam": "Viyaazhakkurinji/Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "524",
          "Name": "Thodidaiya",
          "Type": "Thevaram",
          "Ragam": "Nattapaadai/Shuddha Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "525",
          "Name": "Unnaamulai",
          "Type": "Thevaram",
          "Ragam": "Nattapaadai/Shuddha Naatai",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "526",
          "Name": "Silaidhanai",
          "Type": "Thevaram",
          "Ragam": "Nattapaadai/Shuddha Naatai",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "527",
          "Name": "Madaiyil vaLai",
          "Type": "Thevaram",
          "Ragam": "Thakkaraagam/Khambhoji",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "528",
          "Name": "Bhogamaartha",
          "Type": "Thevaram",
          "Ragam": "Pazhanthakkaraagam/Arabhi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "529",
          "Name": "Vandaanga",
          "Type": "Thevaram",
          "Ragam": "Pazhanthakkaraagam/Arabhi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "530",
          "Name": "Katrangeri",
          "Type": "Thevaram",
          "Ragam": "Kurinji/Yadukula Khambhoji",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "531",
          "Name": "vambaarkundra",
          "Type": "Thevaram",
          "Ragam": "Kurinji/Yadukula Khambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "532",
          "Name": "Bandaththaal",
          "Type": "Thevaram",
          "Ragam": "Viyaazhakkurinji/Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "533",
          "Name": "Neerusaervathor",
          "Type": "Thevaram",
          "Ragam": "Megharaagakurinji/Neelaambari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "534",
          "Name": "Thondelaamalar",
          "Type": "Thevaram",
          "Ragam": "Indhalam/Nadanamakriya",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "535",
          "Name": "Mandiramaavathu",
          "Type": "Thevaram",
          "Ragam": "Gaandhaaram/Navaroj",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "536",
          "Name": "Vaeyurutholi bhangan",
          "Type": "Thevaram",
          "Ragam": "Piyandhaigaandhaaram/Navaroj",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "537",
          "Name": "Thondaranju",
          "Type": "Thevaram",
          "Ragam": "Sevvazhi/Yadukula Khambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "538",
          "Name": "Maaninaervizhi",
          "Type": "Thevaram",
          "Ragam": "Kolli/Navaroj",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "539",
          "Name": "Kaadhallagi",
          "Type": "Thevaram",
          "Ragam": "Kowsigam/Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "540",
          "Name": "Veedalaala",
          "Type": "Thevaram",
          "Ragam": "Kowsigam/Bhairavi",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "541",
          "Name": "Sangamaru",
          "Type": "Thevaram",
          "Ragam": "Saadhaari/Pantuvarali",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "542",
          "Name": "Marundhavai",
          "Type": "Thevaram",
          "Ragam": "Saadhaari/Pantuvarali",
          "Thalam": "Tisra Jathi Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "543",
          "Name": "Vedhavaelviyai",
          "Type": "Thevaram",
          "Ragam": "Pazhampanchuram/Shankarabharanam",
          "Thalam": "Mishra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "544",
          "Name": "Maadharmadappidi",
          "Type": "Thevaram",
          "Ragam": "Yaazhmoori/Atana",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "545",
          "Name": "Kootraayina",
          "Type": "Thevaram",
          "Ragam": "Kolli/Navaroj",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "546",
          "Name": "Maadarpiraikanni",
          "Type": "Thevaram",
          "Ragam": "Gaandhaaram/Navaroj",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "547",
          "Name": "Sonmaalai",
          "Type": "Thevaram",
          "Ragam": "Pazhanthakkaraagam/Arabhi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "548",
          "Name": "Saambalaipoosi",
          "Type": "Thevaram",
          "Ragam": "Bhairavi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "549",
          "Name": "Karuvaaikidandhu",
          "Type": "Thevaram",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "550",
          "Name": "Maasiveenaiyum",
          "Type": "Thevaram",
          "Ragam": "Poorvikalyani",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "551",
          "Name": "Chandiranai maagangai",
          "Type": "Thevaram",
          "Ragam": "HariKhambhoji",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "552",
          "Name": "Narpadhaththaar",
          "Type": "Thevaram",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "553",
          "Name": "Pithaapirai soodi",
          "Type": "Thevaram",
          "Ragam": "Indhalam/Nadanamakriya",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy naayanaar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "554",
          "Name": "Ponnaar meniyanae",
          "Type": "Thevaram",
          "Ragam": "Nattaraagam/Pantuvarali",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy naayanaar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "555",
          "Name": "Thillai vaazh andanar tham",
          "Type": "Thevaram",
          "Ragam": "KollikowvaaNam/Navaroj",
          "Thalam": "Tisra Jathi Roopakam",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy naayanaar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "556",
          "Name": "Vadivudai mazhuvaendhi",
          "Type": "Thevaram",
          "Ragam": "Puraneermai/Bhupaalam",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy naayanaar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "557",
          "Name": "Aadukindralai",
          "Type": "Thiruvaasagam",
          "Ragam": "Mohanam",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Maanikkavaasagar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "558",
          "Name": "Ammaiyae appaa",
          "Type": "Thiruvaasagam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Maanikkavaasagar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "559",
          "Name": "Kangalirandum",
          "Type": "Thiruvaasagam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Maanikkavaasagar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "560",
          "Name": "Vaiyamneeduga",
          "Type": "Periyapuranam",
          "Ragam": "Saveri",
          "Thalam": "Mishra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Sekkizhaar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "561",
          "Name": "MunduThamizhmaalai",
          "Type": "Thiruppugazh",
          "Ragam": "Navaroj",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinaathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "562",
          "Name": "Neiththasurikuzhal",
          "Type": "Thiruppugazh",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinaathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "563",
          "Name": "Pattupadaadha",
          "Type": "Thiruppugazh",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinaathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "564",
          "Name": "Paadhi Madhi nadhi",
          "Type": "Thiruppugazh",
          "Ragam": "Ragamalika",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinaathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "565",
          "Name": "Seeruyarndha",
          "Type": "Chindu",
          "Ragam": "Kharaharapriya",
          "Thalam": "Mishra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Madurai Govindasamy Iyer",
          "Group": "Thirugnaanasambandha Naayanaar Sindhu",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "566",
          "Name": "Thandayuneeraadi",
          "Type": "Chindu",
          "Ragam": "Kharaharapriya",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Madurai Govindasamy Iyer",
          "Group": "Thirugnaanasambandha Naayanaar Sindhu",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "567",
          "Name": "TheLLu shiva",
          "Type": "Chindu",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Madurai Govindasamy Iyer",
          "Group": "Thirugnaanasambandha Naayanaar Sindhu",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "568",
          "Name": "Aariru",
          "Type": "Kandapuranam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kachiappa Sivacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thevara Pann Swara Amaippu",
          "Book Author": "Appaswamy Oduvamoorthy",
          "Year of Publication": "1928",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "569",
          "Name": "Kandaen Kandaen Kandaen Seethayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "570",
          "Name": "Sami Raghu Raamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "571",
          "Name": "Varugiraano",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "572",
          "Name": "Ramaswami Konda Kolam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "573",
          "Name": "Vandaan Vandaan Bharathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "574",
          "Name": "Raaman Yezhundarulinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "575",
          "Name": "Ennayum vara sonnaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "576",
          "Name": "Aadi Devanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "577",
          "Name": "Charanam Charanam Enndraanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "578",
          "Name": "Avadaaram Seidhiduveerae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "579",
          "Name": "Unnadha mahimayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "580",
          "Name": "Iyanae un mahimayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Arunachala Kavi Rama Nataka Keerthanam",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "581",
          "Name": "Nee Kaela naayada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "582",
          "Name": "Paripaalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "583",
          "Name": "Sri Raghukula Nidhim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "584",
          "Name": "Parama Paavana Raamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "585",
          "Name": "Neranammiti",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanadaa",
          "Thalam": "Khanda Jathi Ata thalam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "586",
          "Name": "Saamaja vara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "587",
          "Name": "Saraguna paalimpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "588",
          "Name": "Nee Paadhamulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "589",
          "Name": "Sath Bhakthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "590",
          "Name": "Vega neevu",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "591",
          "Name": "Nirupamaana",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "592",
          "Name": "Sarasamulaa",
          "Type": "Javali",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "593",
          "Name": "Marulukonnadira",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "594",
          "Name": "Anudinamu",
          "Type": "Javali",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "595",
          "Name": "Thillana-Yadukula Khambhoji",
          "Type": "Thillana",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "596",
          "Name": "Thillana-Thodi",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "597",
          "Name": "Sree Venkatesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar (Tuned by Ariyakudi)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "598",
          "Name": "Thiruvutrilagu gangai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "599",
          "Name": "SeervaLar pasunthogai (Murugan thudhi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "600",
          "Name": "Thelluthamizhukku (Kazhugumalai nagar vaLam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "601",
          "Name": "Chenni kuLa nagar (Koyil vaLam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "602",
          "Name": "ponnulavu (Kazhugumalai vaLam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "603",
          "Name": "puLLikkalaabam (vaavi vaLam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "604",
          "Name": "Maragatha vikachitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "605",
          "Name": "pavanagiri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "606",
          "Name": "chendina maranagar vaazh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "607",
          "Name": "Paadhi raathiri (Surampokku Natrrayirangal)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "608",
          "Name": "nevamaayappani (Thalaivi yirangal)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "609",
          "Name": "Aarumugavadivelavanae (thalaivi oodal)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desiya Thodi (Bhairavi)",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "610",
          "Name": "KaNNayiram paDaiththa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "611",
          "Name": "Bhumi mechchida",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desika Mukhari",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "612",
          "Name": "Chandavarai vandhaguga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "613",
          "Name": "Vannaththinai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "614",
          "Name": "Chendhin maanagara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "615",
          "Name": "kannal soozh (vaavi vaLam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "616",
          "Name": "Chendhoor vaLar (Thalaiviyin oodaL)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "617",
          "Name": "PaaLaivaai kamugil (Thalaivi varundhudal)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "618",
          "Name": "EnnaDi naan paetra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "619",
          "Name": "ManjuniGar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "620",
          "Name": "Vangaara Maarbilani (Thirupugazh)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "621",
          "Name": "Pancharatna Thirupugazh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "622",
          "Name": "Isaindha soolam (Thirupugazh)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "623",
          "Name": "Karuvinaaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Subramanya Swami Peril Kaavadai Chindhu",
          "Book Author": "Chinnikulam Annamalai Reddiyar",
          "Year of Publication": "1955",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "624",
          "Name": "Vande Maataram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subramanya Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "625",
          "Name": "Jayamundu bhayamillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Subramanya Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "626",
          "Name": "Aadum chidambaramo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "627",
          "Name": "Eppo varuvaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "628",
          "Name": "Charanam Charanam Raghurama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Rama Nataka Keerthanam",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "629",
          "Name": "Indhaa vibheeshana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Rama Nataka Keerthanam",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "630",
          "Name": "Anta Raama Soundaryam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Rama Nataka Keerthanam",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "631",
          "Name": "Yaen Palli kondeerayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "Rama Nataka Keerthanam",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "632",
          "Name": "Yaar poi sollvaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Subbaraamaiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "633",
          "Name": "Muthukumarayyanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "634",
          "Name": "Athalasedanaaraada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Tisra Dhuruvam",
          "Praise of": "N/A",
          "Composer": "Arunagirinaathar",
          "Group": "Thirupugazh",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "635",
          "Name": "Unai dinam thozha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinaathar",
          "Group": "Thirupugazh",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "636",
          "Name": "Thillai vaazh andanar tham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy naayanaar",
          "Group": "Thevaram",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "637",
          "Name": "Siraiyaaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "Thevaram",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "638",
          "Name": "Neerusaervathor",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Thevaram",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "639",
          "Name": "Thondaranju",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "Thevaram",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "640",
          "Name": "Enneramum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthu thandavar",
          "Group": "Ninda Stuti",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "641",
          "Name": "Jaanaki Ramana Dasharatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaanamaamalai Jeeyar Swamigal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "642",
          "Name": "Dheema thathara",
          "Type": "Thillana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ariyakudi Ramanuja Iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "643",
          "Name": "Dasharatha Sutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vaanamaamalai Jeeyar Swamigal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "644",
          "Name": "Raamaayika",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "645",
          "Name": "Sadhguru swamiki Satileni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "On Tyagaraja swami",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "646",
          "Name": "Samaja Varada neeku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "647",
          "Name": "Pari paalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "648",
          "Name": "Neekelanaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "649",
          "Name": "Govinda katayaparamaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "650",
          "Name": "Vishekamhathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "651",
          "Name": "Pooraya mamakaamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "652",
          "Name": "Uyyalalukavvayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "653",
          "Name": "Intha Saukya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "654",
          "Name": "Naa moraala kimpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "655",
          "Name": "Vedalanu Kodanda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "656",
          "Name": "Kotinadulu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "657",
          "Name": "Manasuloni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "658",
          "Name": "PalukuKanDa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "659",
          "Name": "Patali Gathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "660",
          "Name": "Tanayuni Brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "661",
          "Name": "Thathva meruga tharama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "662",
          "Name": "Raamaa ninne nammi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "663",
          "Name": "Venkatesaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "664",
          "Name": "Vinayakuni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "665",
          "Name": "Entharo mahanubhavulu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "Ghana Raga Pancharatnam",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "666",
          "Name": "Pathitha pavanudaina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "667",
          "Name": "Parama Bhagavatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "668",
          "Name": "Bhagavatha Ramayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagarajar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "669",
          "Name": "Simhaadipathiyae Kadhinidhiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Suriyan",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "670",
          "Name": "Katakaadhipathiyae kulir madhiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Chandiran",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "671",
          "Name": "Sevvai endridum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Sevvaai",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "672",
          "Name": "Madhitharum Sudhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Budhan",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "673",
          "Name": "Vaanavar tham guruvae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Viyaazhan",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "674",
          "Name": "Vaan mazhayai pozhindhida",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Sukran",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "675",
          "Name": "Kaaka vaahananae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Shani",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "676",
          "Name": "Vallamai migundha raahuvae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Raahu",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "677",
          "Name": "Oodhidum pugazh saer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N S Chidambaram",
          "Group": "Navagraha Nallanjali-Kedhu",
          "Language": "Tamil",
          "Book Title": "Navagraha Nallanjali (Tamil s on Navagrahas)",
          "Book Author": "N. S. Chidambaram, S. Balasubramaniam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "678",
          "Name": "Anai mugathonae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "679",
          "Name": "Vallal annamalaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "680",
          "Name": "Annamalai annalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "681",
          "Name": "Ethisayum potrum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "682",
          "Name": "Kalaimagalae ennai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "683",
          "Name": "Muruganai nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Visharada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "684",
          "Name": "Unnaiyandri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "685",
          "Name": "Velanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "686",
          "Name": "Pannirukai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "687",
          "Name": "Ennai aandarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "688",
          "Name": "Aindhezhuthinai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "689",
          "Name": "Ellamsei",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "690",
          "Name": "Kadavul Arulai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "691",
          "Name": "Kadavulai maravathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thandavam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "692",
          "Name": "Iraivanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Narayani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "693",
          "Name": "Aandarulvai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "694",
          "Name": "Arulvai angayar kanniyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "695",
          "Name": "Arula vaendum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saaraamathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "696",
          "Name": "Anbukondennai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "697",
          "Name": "Adiyenai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "698",
          "Name": "Enai nee maravathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "699",
          "Name": "Varuvai Angayar kanniyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "700",
          "Name": "Maatchi migundha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "701",
          "Name": "Aaruyiraam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "702",
          "Name": "Thamizh Mozhiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "703",
          "Name": "Yazhin Inimai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "704",
          "Name": "Isaiyin ellayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "705",
          "Name": "Yezhisaiyaagiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "706",
          "Name": "Paadavaendumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanadam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "707",
          "Name": "Kutramillamalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "708",
          "Name": "Aakamumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "709",
          "Name": "Pugazhai thedi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "710",
          "Name": "Sinamadaiyaathae seeri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "711",
          "Name": "Vaazhthiduvom vaareer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "712",
          "Name": "Nal vaazhkaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "713",
          "Name": "Oruvaraiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaarmukhavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "714",
          "Name": "En thaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "715",
          "Name": "Naavukarasanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "716",
          "Name": "Tirupathi malaiyurai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "717",
          "Name": "Engum niraindha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "718",
          "Name": "Kalladhavaridam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "719",
          "Name": "Katraar vazhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasena",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "720",
          "Name": "Avaiyarindhu pesa vaendum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "VandanaDharini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "721",
          "Name": "Amaidhi adaindhal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "722",
          "Name": "Vanjam Nirai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Urmika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "723",
          "Name": "Unmai pesuvom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "724",
          "Name": "Aruluru vaanavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyanadayini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "725",
          "Name": "Osai oliellam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "726",
          "Name": "Innuruvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "727",
          "Name": "Pann porundhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "728",
          "Name": "Paadi maghindhiduvom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jeevanthika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "729",
          "Name": "Vaazhiya yezhisaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Tamizh Paamaalai",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "730",
          "Name": "Sri Mahaganapathae Dehi Shivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "731",
          "Name": "Saraswathi Devi Thava Charanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi Manohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "732",
          "Name": "Shri Sambashiva Sadhguro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suryakantham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "733",
          "Name": "Balasubramanyam Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "734",
          "Name": "Jaya Narayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "735",
          "Name": "Hemagirisha manonmani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "EshaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "736",
          "Name": "Nandikeshawaram Namamyaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "737",
          "Name": "Shivaya Namaha - Shiva Shiva Shambho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-1",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "738",
          "Name": "Maheshwaraya Namaha - Hara Maheshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-2",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "739",
          "Name": "Shambhavae Namaha - Paramapurusham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Padi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-3",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "740",
          "Name": "Pinaakinae namaha - Pinakinae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BrindavanaSaranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-4",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "741",
          "Name": "Sashishekaraya Namaha - Sashishekara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-5",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "742",
          "Name": "Vamadevaya Namaha - Vamadeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-6",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "743",
          "Name": "Viroopaakshaaya namaha - Sree Virupaakshaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-7",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "744",
          "Name": "Kaparthinae namaha - Krupa sagaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhava manohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-8",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "745",
          "Name": "Neelalohithaya Namaha - neelalohitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-9",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "746",
          "Name": "Sankaraya Namaha - Sankara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-10",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "747",
          "Name": "Shulappanayae namaha - Baalalochana soola",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-11",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "748",
          "Name": "Katvaanginae namaha - Kaalatheetha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-12",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "749",
          "Name": "Vishwavallabhaya namaha - Vishwavallabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-13",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "750",
          "Name": "Sibhivishtaya namaha - Sibhivishtam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-14",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "751",
          "Name": "Ambikanaathaaya namaha - Ambika natham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-15",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "752",
          "Name": "Sree Kantaya namaha - Sree Kanta dhayanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SaalagaBhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-16",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "753",
          "Name": "Bhakthavatsalaya namaha - bhakthavatsalabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-17",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "754",
          "Name": "Bhavaaya namaha - Sree Bhavam chinmayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-18",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "755",
          "Name": "Sarvaaya namaha - Sarva swaroopam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-19",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "756",
          "Name": "Trilokeshaya namaha - Sree trilokesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-20",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "757",
          "Name": "Chithikantaya namaha - Hara hara Chithikantaa shiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-21",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "758",
          "Name": "Shivapriyaya namaha - Shivaapriya jagatheesa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-22",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "759",
          "Name": "Ugraaya namaha - Pashupathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-23",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "760",
          "Name": "Kapalinae namaha - kapalinam vandhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-24",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "761",
          "Name": "Kaamaarayae namaha - Kaamaarim bhajamanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-25",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "762",
          "Name": "Andhakaasurasoothanaaya namaha - Anthakaasura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-26",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "763",
          "Name": "Gangaadharaaya namaha - Gangadhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-27",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "764",
          "Name": "Lalaataakshaaya namaha - lalaataaksha namosthute",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-28",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "765",
          "Name": "Kaalakaalaaya namaha - Kaalakaalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-29",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "766",
          "Name": "Krupaanidhayae namaha - Krupaanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanadam",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-30",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "767",
          "Name": "Bheemaaya namaha - Bheemam bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-31",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "768",
          "Name": "Parasuhastaaya namaha - Parasuhastaaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-32",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "769",
          "Name": "Mrugapaanayae namaha - Mrugapaanae mruthyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-33",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "770",
          "Name": "Jataadharaaya namaha - Sree Jataadharaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sindhu mandaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-34",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "771",
          "Name": "Kailasavaasinae namaha - Kailasavaasam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sindhu ramakriyaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-35",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "772",
          "Name": "Kavasinae namaha - Devamkavasinam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bangala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-36",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "773",
          "Name": "Katoraaya namaha - Sambhum katoram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-37",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "774",
          "Name": "Tripuraandhakaya namaha - tripuranthaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-38",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "775",
          "Name": "Vrushaangaaya namaha - Vrushaangam bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-39",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "776",
          "Name": "Vrushabhaaroodaaya namaha - Shivam Vrushabhaaroodam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanakalyani",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-40",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "777",
          "Name": "BhasmondhooLithavigrahaaya namaha - Jayajaya Bhasmo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Seemandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-41",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "778",
          "Name": "Saamapriyaaya namaha - Saamapriya Shivashankara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-42",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "779",
          "Name": "Swaramayaaya namaha - Swaramayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-43",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "780",
          "Name": "Trayee moorthayae namaha - Sree Trayee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayanarayani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-44",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "781",
          "Name": "Aneeshwaraaya namaha - Aneeshwaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-45",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "782",
          "Name": "Sarvagnaaya namaha - Sarvagnyaasaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ravichandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-46",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "783",
          "Name": "Paramaathmanae namaha - Paramaathmanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamsaBrahmari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-47",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "784",
          "Name": "Somasooryaagnilochanaaya namaha - Somasoorya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-48",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "785",
          "Name": "Haveeshae namaha - Devaaya havishae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gundakkriya",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-49",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "786",
          "Name": "Yagnamayaaya namaha - Yagnamayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-50",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "787",
          "Name": "Somaaya namaha - Somasundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-51",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "788",
          "Name": "Panchavakthraaya namaha - Panchavakthrashrayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadeepakam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-52",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "789",
          "Name": "Sadashivaaya namaha - Sadashivam sadashrayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsagamani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-53",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "790",
          "Name": "Vishweshwaraya namaha - Sree Vishweshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-54",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "791",
          "Name": "Veerabhadraaya namaha - Sree Veerabhadram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-55",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "792",
          "Name": "Gananaathaaya namaha - Gananaatham bhajae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Panchamam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-56",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "793",
          "Name": "Prajaapathayae namaha - Paripoorna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-57",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "794",
          "Name": "Hiranyaraethasae namaha - Namo hiranya thejo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mangalakaishiki",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-58",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "795",
          "Name": "Durdharshaaya namaha - Anandha roopam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-59",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "796",
          "Name": "Gireeshaaya namaha - Mahaabala gireesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-60",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "797",
          "Name": "Gireeshaaya namha - Gireesham mahesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-61",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "798",
          "Name": "Anaghaaya namaha - Anagham Akilaanda naayakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-62",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "799",
          "Name": "Bhujangabhushanaya namaha - Bhujanga bhushana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ChenjuKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-63",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "800",
          "Name": "Bhargaaya namaha - Bhargam pashupathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jingala",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-64",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "801",
          "Name": "Girithanvinae namaha - Girithanvinam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokiladhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-65",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "802",
          "Name": "Giripriyaaya namaha - Giripriyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kathanakuthuhalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-66",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "803",
          "Name": "Kruthivaasasae namaha - Kruthivaasasae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-67",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "804",
          "Name": "Puraaraadhayae namaha - Puraaradhayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-68",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "805",
          "Name": "Bhagavathae namaha - Bhagavathae rudraaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-69",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "806",
          "Name": "Brahmadhaadhipaaya namaha - Brahmadhaadhipam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-70",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "807",
          "Name": "Mrutyunjayaaya namaha - Hara Mrutyunjaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-71",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "808",
          "Name": "Sookshmathanavae namaha - Shankaram Sookshmathanum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindolaVasantham",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-72",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "809",
          "Name": "Jagatvyaapinae namaha - Sarva jagatvyaapinae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manoramaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-73",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "810",
          "Name": "Jagatguravae namaha - Jagatguro dheena",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gopikavasantham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-74",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "811",
          "Name": "Vyomakeshaaya namha - Vyomakesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-75",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "812",
          "Name": "Mahaasena jankaaya namaha - Shiva Mahaasena",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-76",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "813",
          "Name": "Chaaruvikramaaya namaha - Chaaruvikrama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-77",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "814",
          "Name": "Rudraaya namaha - Rudram mahesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-78",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "815",
          "Name": "Bhuthapathayae namaha - Bhoothapathayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-79",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "816",
          "Name": "SthaaNavae namaha - SthaaNumbhajaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KarnaRanjani",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-80",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "817",
          "Name": "Ahirputhniyaaya namaha - Purushaaya ahir",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-81",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "818",
          "Name": "Digambaraaya namaha - Digambaram shivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-82",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "819",
          "Name": "Ashtamoorthayae namaha - Ashtamoorthim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-83",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "820",
          "Name": "Anaekaathmanae namaha - Anakaathmanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanaagari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-84",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "821",
          "Name": "Saadhvikaaya namaha - Saadhvikam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-85",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "822",
          "Name": "Shuddhavigrahaaya namaha - Paapanaasanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-86",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "823",
          "Name": "Shaashvathaaya namaha - Maanasa Shaashvatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-87",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "824",
          "Name": "Kandaparasavae namaha - Kandaparasavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-88",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "825",
          "Name": "Ajaaya namaha - Ajam haram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-89",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "826",
          "Name": "Paasavimochakaaya namaha - Bhava paapa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalatha",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-90",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "827",
          "Name": "Mrudaaya namaha - Mrutyunjayam mrudam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-91",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "828",
          "Name": "Pashupathayae namaha - Pasupathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saindhavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-92",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "829",
          "Name": "Devaaya namaha - Devam Charanyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-93",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "830",
          "Name": "Mahaadevaaya namaha - Mahaadevam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Narayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-94",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "831",
          "Name": "Avyayaaya namaha - Bhajaamyaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-95",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "832",
          "Name": "Harayae namaha - Shivamharim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-96",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "833",
          "Name": "Purushathanthapithae namaha - Purushadantha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-97",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "834",
          "Name": "Avyakraaya namaha - Ugraaya Avyakraaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-98",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "835",
          "Name": "Dakshaathvaraharaya namaha - Dakshaath",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-99",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "836",
          "Name": "Haraaya namaha - hara haraa maha deva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-100",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "837",
          "Name": "Bhaganethrapithae namaha - Baalalochanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-101",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "838",
          "Name": "Avyakthaaya namaha - Athi sundaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-102",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "839",
          "Name": "Sahasraakshaaya namaha - Sahasraaksham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naayaki",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-103",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "840",
          "Name": "Sahasrapaathae namaha - Sahasrapaadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-104",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "841",
          "Name": "Abhavarga pradaaya namaha - Abhavarga pradham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pasupathipriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-105",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "842",
          "Name": "Anantaaya namaha - Anataaya sarvaaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-106",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "843",
          "Name": "Taarakaaya namaha - Taarakam Shivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-107",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "844",
          "Name": "Parameshwaraya namaha - Sree Parameshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-108",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "845",
          "Name": "Mangalam - Mangalam Bhavathuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Shivarchanai/Shivashtothra Keerthanai-Mangalam",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "846",
          "Name": "Suryan - Aadhithyam Devaadi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Navagraha Keerthanai-1",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "847",
          "Name": "Chandran - Chandram Bhajae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Navagraha Keerthanai-2",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "848",
          "Name": "Sevvaai - Angaarakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Navagraha Keerthanai-3",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "849",
          "Name": "Bhuthan - Rohinae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Navagraha Keerthanai-4",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "850",
          "Name": "Bruhaspathi - Vaachasthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Navagraha Keerthanai-5",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "851",
          "Name": "Sukran - Sree Bhaargavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Navagraha Keerthanai-6",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "852",
          "Name": "Saneeshwaran - Chaayaadevi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "Navagraha Keerthanai-7",
          "Language": "Sanskrit",
          "Book Title": "Muthiah Bhagavthar Sahithyangal (Siva ashtothra, Navagraha Keerthanaigal)",
          "Book Author": "Harikesanallur M. Vaidhyalingam",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "853",
          "Name": "Bharathadesa mendru ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subramanya Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "854",
          "Name": "Ninnuvinaaga mari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Ekam",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "855",
          "Name": "Ninne Namminaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "856",
          "Name": "Talli ninnu nera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "857",
          "Name": "O jagadambaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "858",
          "Name": "Brova vamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "859",
          "Name": "Yema ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "860",
          "Name": "Biranavara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "861",
          "Name": "Kaamakshi nee padayuga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "862",
          "Name": "Kamakshi Amba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Sudesamitran-Ariyakudi Ramanuja Iyengar",
          "Book Author": "Ariyakudi Ramanuja Iyengar",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "863",
          "Name": "Kanakaanga kaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kanakangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-1",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "864",
          "Name": "Tharunam Idhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rathnangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-2",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "865",
          "Name": "Maa Madhura Saa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ganamurti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-3",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "866",
          "Name": "Daasa nesa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vanaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-4",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "867",
          "Name": "Nija Bhakthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-5",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "868",
          "Name": "Vaa Velavaa Vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tanarupi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-6",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "869",
          "Name": "Vandharul nee dhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Senavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-7",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "870",
          "Name": "Kali theera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-8",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "871",
          "Name": "Karunaikkadalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-9",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "872",
          "Name": "Irangaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natakapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-10",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "873",
          "Name": "Sukha Vaazhvadai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-11",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "874",
          "Name": "Naalaagudhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Roopavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-12",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "875",
          "Name": "Naadha nilai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gayakapriya",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-13",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "876",
          "Name": "Nambinaen Ayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-14",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "877",
          "Name": "Naan enn seivaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-15",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "878",
          "Name": "Kaana kan kodi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-16",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "879",
          "Name": "Kanjam Konjam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suryakantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-17",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "880",
          "Name": "AaLalaagaadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hatakambheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-18",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "881",
          "Name": "Varam thaarum saami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jhankaradhwani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-19",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "882",
          "Name": "Amboruha Paadamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NataBhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-20",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "883",
          "Name": "Velavaa vaa vinai theera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-21",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "884",
          "Name": "Kanpaaraiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-22",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "885",
          "Name": "Paaraai arul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-23",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "886",
          "Name": "Singaara kumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varunapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-24",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "887",
          "Name": "Maalaakinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MaaraRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-25",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "888",
          "Name": "Needhaan Appa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-26",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "889",
          "Name": "Malayaadhae manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-27",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "890",
          "Name": "Neeyae Gathi Endhaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-28",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "891",
          "Name": "Enai Aalaiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-29",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "892",
          "Name": "Naayaen unnaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naganandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-30",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "893",
          "Name": "Shambu sadaa Shiva sutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yagapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-31",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "894",
          "Name": "Kalangaadhae manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavardhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-32",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "895",
          "Name": "Ninai manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gangeyabhushani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-33",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "896",
          "Name": "Naadhaanu santhaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vagadeeshwari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-34",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "897",
          "Name": "Paaraamugam thaenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Soolini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-35",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "898",
          "Name": "Yaedhaiyaa gathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chalanaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-36",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "899",
          "Name": "Vaarana muga vaa thunai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "900",
          "Name": "Aiyanae AatkoL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "901",
          "Name": "Varavaenum vaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "902",
          "Name": "Arulvaai Sree meenalochani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "903",
          "Name": "Aimpon anaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "904",
          "Name": "Ini Nammakoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "On Tyagaraja swami",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "905",
          "Name": "Ranjitha kavi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "On Kavi Kunjara Bharathi",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 1",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1932",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "906",
          "Name": "Paripaahi Ganadhipa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "907",
          "Name": "Paripaalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "908",
          "Name": "Parvathi naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "909",
          "Name": "Rama Rama Guna Seema",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "910",
          "Name": "Paramapurusha Jagadeesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "911",
          "Name": "Vandae Sadaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "912",
          "Name": "Vande Deva Deva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "913",
          "Name": "Sarasa savadana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "914",
          "Name": "Sarasijanabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "915",
          "Name": "Sri Ramachandra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "916",
          "Name": "Jagadeesha Sada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nattakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "917",
          "Name": "Mamava sada janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "918",
          "Name": "Mamava Padmanabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "919",
          "Name": "Padmanabha Pahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "920",
          "Name": "Sarasa sama mrudu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "921",
          "Name": "Jalajanaabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "922",
          "Name": "Pahi Jagajjanani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "923",
          "Name": "Sathatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "924",
          "Name": "Deva Deva Jagadeeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "925",
          "Name": "Pari paahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "926",
          "Name": "Bhaavayae Sree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "927",
          "Name": "Vandae Sadaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "928",
          "Name": "Gopalam Sevaeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "929",
          "Name": "Mohanamayi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "930",
          "Name": "Mamava Karunaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "931",
          "Name": "Pahi Jagajjanani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "932",
          "Name": "Mamava sada varadae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nattakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "933",
          "Name": "Chinthayami thae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "934",
          "Name": "Krupaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "935",
          "Name": "Viharamanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "936",
          "Name": "Kosalendra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "937",
          "Name": "Rama Rama Pahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "938",
          "Name": "Gaangeya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "939",
          "Name": "Paahi Sada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "940",
          "Name": "Jaya Jaya Padmanabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "941",
          "Name": "Saamodham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "942",
          "Name": "Paalaya Raghu Naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "943",
          "Name": "Viharamanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "944",
          "Name": "Mamava Jagadeeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "945",
          "Name": "Mamava Shrutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "946",
          "Name": "Paahi Padmanabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "947",
          "Name": "Sarojanabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "948",
          "Name": "Bhavayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalathika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "949",
          "Name": "Paalaya Madhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "950",
          "Name": "Rama Rama Pahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "951",
          "Name": "Pankajaaksha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "952",
          "Name": "Paahi Daaksha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "953",
          "Name": "Smarajanaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "954",
          "Name": "Kalayae Sree Kamalanayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "955",
          "Name": "Anjaneya Raghurama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "956",
          "Name": "Samajendra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "957",
          "Name": "Paripaahi maamayi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "958",
          "Name": "Paahi maamaneesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saindhavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "959",
          "Name": "Pannagendra shayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "960",
          "Name": "Saamodham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "961",
          "Name": "Paahi maam Sri Padmanabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "962",
          "Name": "Jaya Jaya Padmanabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "963",
          "Name": "Paramapurusham Hrudaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalithapanchamam",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "964",
          "Name": "Reena Madha nutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "965",
          "Name": "Sadharamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "966",
          "Name": "Jagadheesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "967",
          "Name": "Deva Deva maam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "968",
          "Name": "Jagadeesha Sree Ramana maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "969",
          "Name": "Mohanam thava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "970",
          "Name": "Bhogeendra saayinam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "971",
          "Name": "Sevae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "972",
          "Name": "Smara maanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "973",
          "Name": "Paahi daaksha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "974",
          "Name": "Pannagashayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "975",
          "Name": "Saarasaaksha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "976",
          "Name": "Sri Kumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "977",
          "Name": "Nanda sutha thava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurinji",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "978",
          "Name": "Santhatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "979",
          "Name": "Vimalakamala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "980",
          "Name": "Jaya Jaya Raghurama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "981",
          "Name": "Bhakthaparaayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "982",
          "Name": "Raghukula thilaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "983",
          "Name": "KaLakanti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "984",
          "Name": "Paahisreepathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "985",
          "Name": "Kaaranam vinaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "986",
          "Name": "Saadharamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "987",
          "Name": "Devi Jagaj janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-1",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "988",
          "Name": "Paahi maam Sri vageeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-2",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "989",
          "Name": "Devi Paavanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-3",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "990",
          "Name": "Bharathi maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-4",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "991",
          "Name": "Janani Mamavameya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-5",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "992",
          "Name": "Saroruhaasana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-6",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "993",
          "Name": "Janani Paahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-7",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "994",
          "Name": "Paahi janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-8",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "995",
          "Name": "Paahi parvathanandini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navarathiri Krithi-9",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "996",
          "Name": "Ramachandra paahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "997",
          "Name": "Paramananda natanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "998",
          "Name": "Jaya Jaya Jagadeesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "999",
          "Name": "Shankara Sree Giri naatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1000",
          "Name": "Kaantha thava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Manipravalam",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1001",
          "Name": "Alarsara paritapam (Jalajabandhu)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Manipravalam",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1002",
          "Name": "TheLiviyalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Manipravalam",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1003",
          "Name": "ValaputhaaLa",
          "Type": "Javali",
          "Ragam": "Atana",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1004",
          "Name": "Idusaahasamu",
          "Type": "Javali",
          "Ragam": "Saindhavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1005",
          "Name": "Pannagendra shayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1006",
          "Name": "Mangalam-Bhujagashaayino",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Swathi Thirunal Keerthanamala",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1954",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1007",
          "Name": "Gananathanae Gunabhodhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1008",
          "Name": "Pazham nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1009",
          "Name": "Kundruva vel vaangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1010",
          "Name": "Kaalakaalan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1011",
          "Name": "Muruga muruga endral",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1012",
          "Name": "Konjik Konji",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1013",
          "Name": "VeNNai unnumen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1014",
          "Name": "Thayae tripura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1015",
          "Name": "Ethanai tharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1016",
          "Name": "Veledutha kaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1017",
          "Name": "Aaruyir",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1018",
          "Name": "Kazhanigal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1019",
          "Name": "Sathya sevaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhogavasantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1020",
          "Name": "Thaayinai KaaNaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1021",
          "Name": "Indha varamarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1022",
          "Name": "Engae thedugirai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1023",
          "Name": "KaruthilenaaLum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1024",
          "Name": "Unaiyandri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1025",
          "Name": "Pazhani malaithanil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1026",
          "Name": "Gurupara guhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1027",
          "Name": "Yedhaedho enniya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1028",
          "Name": "Appa unaimaravaenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1029",
          "Name": "Enmanam polae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1030",
          "Name": "Saradhiyaai varuvaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "VandanaDharini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1031",
          "Name": "Iniyaedhu kavalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1032",
          "Name": "Muruga enadharuyirae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1033",
          "Name": "Dheem Dheem Dheem",
          "Type": "Thillana",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1034",
          "Name": "Indha veedu undran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1035",
          "Name": "Kalaiyinbhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1036",
          "Name": "Pizhai ninaindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1037",
          "Name": "Shanthamillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1038",
          "Name": "Aadum perumaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1039",
          "Name": "Kai koduppayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1040",
          "Name": "Manamirangaadhadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1041",
          "Name": "Endro nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1042",
          "Name": "Kailaayamgirithanail",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Jathi Matya thalam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1043",
          "Name": "Nammkoru kurai yaedhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1044",
          "Name": "Enna Punniyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desiya Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1045",
          "Name": "Appan irukkum bodhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1046",
          "Name": "Endraenai naadiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vilasini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1047",
          "Name": "Samagana priyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1048",
          "Name": "Yaaro avan yaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1049",
          "Name": "Paarellam Arulaalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1050",
          "Name": "Arangaa aravaNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1051",
          "Name": "Kaanbhathendro naam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pilu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1052",
          "Name": "Sahiyae inni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1053",
          "Name": "Urangiduvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1054",
          "Name": "Kannan Kuzhalosai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sindhubhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1055",
          "Name": "Kandaen kanavinilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1056",
          "Name": "Thanjamendru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Isai Manjari (Periyasamy Thooran)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1057",
          "Name": "Gana naatha",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1058",
          "Name": "Ninne Nammi",
          "Type": "Varnam-Tana",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1059",
          "Name": "Saarasadala",
          "Type": "Swarajathi",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1060",
          "Name": "SreeMahaGanapathae (Thripadam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Tripadam",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1061",
          "Name": "Vidhya dhaarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Tripadam",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1062",
          "Name": "Sree Kamalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Haripriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Tripadam",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1063",
          "Name": "Sivanukkilaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1064",
          "Name": "Mayil vaahana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1065",
          "Name": "Kaavaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1066",
          "Name": "Kaarthikeyanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1067",
          "Name": "Kandaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1068",
          "Name": "Yaenaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1069",
          "Name": "Unnai ninaindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1070",
          "Name": "Vadivel",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rasavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1071",
          "Name": "Sharavana bhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malkauns",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1072",
          "Name": "Anjael Anjael",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1073",
          "Name": "Gaandhamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desiya Thodi",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1074",
          "Name": "Vaa Vaa Vel Murugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desiya Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1075",
          "Name": "Ullathinidhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sindhubhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1076",
          "Name": "Sirparanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1077",
          "Name": "Sivaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1078",
          "Name": "Amba meenalochani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1079",
          "Name": "Maathaa sadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1080",
          "Name": "Amba meenakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1081",
          "Name": "Paramapaavani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sindhu Gowri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1082",
          "Name": "Thaayae Tharunam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1083",
          "Name": "Maamava harae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1084",
          "Name": "Madhusoodhana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasanana",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1085",
          "Name": "Kaarmughil vaNNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1086",
          "Name": "Devaadi devar pugazh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Odappattu",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1087",
          "Name": "Bhaaratha janani",
          "Type": "Notes",
          "Ragam": "Shankarabharanam",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Bharat Bhajan",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1088",
          "Name": "Smarasa paavana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhimplas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Bharat Bhajan",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1089",
          "Name": "Jayathi Jayathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Bharat Bhajan",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1090",
          "Name": "Shantha Ahimsa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi (Hindusthani)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Mahatma Gandhi Sankeerthanam",
          "Language": "Sanskrit",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1091",
          "Name": "Bhagavaan mudatre ulagu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Tirukural Madura Keerthanai",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1092",
          "Name": "Neri nindraar needu vaazhwar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram T R Vishwanatha Shastry",
          "Group": "Tirukural Madura Keerthanai",
          "Language": "Tamil",
          "Book Title": "Vishwa Madura Keerthanai",
          "Book Author": "Mayuram T R Vishwanatha Shastry",
          "Year of Publication": "1983",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1093",
          "Name": "Mayaatheetha swaroopini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1094",
          "Name": "Thodi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Thodi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1095",
          "Name": "Shankarabharanam Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1096",
          "Name": "Atana Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Atana",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1097",
          "Name": "Bhairavi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Bhairavi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1098",
          "Name": "Kalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1099",
          "Name": "Thodi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1100",
          "Name": "Khamas Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Khamas",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1101",
          "Name": "Ragamalika Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Ragamalika",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1102",
          "Name": "Shankarabharanam Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1103",
          "Name": "Saveri Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Saveri",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1104",
          "Name": "Chakravaham Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Chakravaham",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1105",
          "Name": "Kalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1106",
          "Name": "Vasantha Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Vasantha",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1107",
          "Name": "Hemavathi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Hemavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1108",
          "Name": "Poorvikalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi (Sankeerna nadai)",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1109",
          "Name": "Khambhoji Shabdam (Sree karaa Sugunaa)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1110",
          "Name": "Khambhoji Shabdam (Sadayudaanae)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1111",
          "Name": "Khambhoji Shabdam (Sami ninne)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1112",
          "Name": "Khambhoji Shabdam (Sarasijaakshulu)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1113",
          "Name": "Khambhoji Shabdam (Niratha munanee)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1114",
          "Name": "Khambhoji Shabdam (Gokulaambhudhi)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1115",
          "Name": "Khambhoji Shabdam (VeNyudaa)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1116",
          "Name": "Khambhoji Shabdam (Saaryagu naera)",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1117",
          "Name": "Dhanyasi Swarajathi (Gopala Lola)",
          "Type": "Swarajathi",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1118",
          "Name": "Atana Swarajathi (Saami Nee ram)",
          "Type": "Swarajathi",
          "Ragam": "Atana",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1119",
          "Name": "Thodi Swarajathi (Samiki jaalamae)",
          "Type": "Swarajathi",
          "Ragam": "Thodi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1120",
          "Name": "YadukulaKhambhoji Swarajathi (Sarojaakshiro)",
          "Type": "Swarajathi",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1121",
          "Name": "Chakravaham Swarathi (Sadayaayika)",
          "Type": "Swarajathi",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1122",
          "Name": "Huseni Swarajathi (Yaemandhi yaanaraa)",
          "Type": "Swarajathi",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1123",
          "Name": "Sahana Swarajathi (Naetiki Dhayavachchaena)",
          "Type": "Swarajathi",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1124",
          "Name": "Huseni Swarajathi (Endha maayakkaariyo)",
          "Type": "Swarajathi",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1125",
          "Name": "Chakravaham Swarathi (Saami Mael)",
          "Type": "Swarajathi",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1126",
          "Name": "Khambhoji Swarajathi (Kaana Aavalaanaen)",
          "Type": "Swarajathi",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1127",
          "Name": "Manavikai",
          "Type": "N/A",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1128",
          "Name": "Adi mohamkondaen",
          "Type": "N/A",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1129",
          "Name": "Samini rammanavae",
          "Type": "N/A",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1130",
          "Name": "Saamiyai Azhaithu vaadi",
          "Type": "N/A",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1131",
          "Name": "Saami rammanavae",
          "Type": "N/A",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1132",
          "Name": "Sarasaa ninu",
          "Type": "N/A",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1133",
          "Name": "Sarasaa ninai maravael",
          "Type": "N/A",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1134",
          "Name": "Nee Saati Thora",
          "Type": "N/A",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1135",
          "Name": "Mohamaana",
          "Type": "N/A",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1136",
          "Name": "Sahiyae Indha Vaelayil",
          "Type": "N/A",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1137",
          "Name": "Samiyai azhaithu vaadi",
          "Type": "N/A",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1138",
          "Name": "Mohalaahiri",
          "Type": "N/A",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1139",
          "Name": "Saami kaa naa dho",
          "Type": "N/A",
          "Ragam": "Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1140",
          "Name": "Saami naapai",
          "Type": "N/A",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1141",
          "Name": "Sarasa Shikaamani",
          "Type": "N/A",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1142",
          "Name": "Saami naepalu kaami",
          "Type": "N/A",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1143",
          "Name": "Thaanikae thagu",
          "Type": "N/A",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1144",
          "Name": "Thaani saati podi",
          "Type": "N/A",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1145",
          "Name": "Saami unnaiyae",
          "Type": "N/A",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1146",
          "Name": "Saro jaakshiro",
          "Type": "N/A",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1147",
          "Name": "Saami ninne",
          "Type": "N/A",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1148",
          "Name": "Saami nee vaeyani",
          "Type": "N/A",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1149",
          "Name": "Salamu jaesithae",
          "Type": "N/A",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1150",
          "Name": "Yae Maguva bhodinchara",
          "Type": "N/A",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1151",
          "Name": "Sami ninne kori",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1152",
          "Name": "Sarasooda nee vaeyani",
          "Type": "N/A",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1153",
          "Name": "Intha kopamelara",
          "Type": "N/A",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1154",
          "Name": "Chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1155",
          "Name": "Sarasija naabha",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1156",
          "Name": "Madhana bilahari",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "Chatur Raga sloka malika",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1157",
          "Name": "Saami ninnu vaegamae",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "Twele Raga ragamallika",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1158",
          "Name": "Pannagaa theercha",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1159",
          "Name": "Kaar viriyum",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "Desiya Ashta raga maalika",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1160",
          "Name": "Thodi Surapadam (Daani sarisamaanee)",
          "Type": "Surapadam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1161",
          "Name": "ValaputhaaLa",
          "Type": "Padam",
          "Ragam": "Atana",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1162",
          "Name": "Thaani pothana",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1163",
          "Name": "Idu saahasa",
          "Type": "Javali",
          "Ragam": "Saindhavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1164",
          "Name": "Thodi Thillana",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1165",
          "Name": "Thodi Thillana",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1166",
          "Name": "Kaanadaa Thillana",
          "Type": "Thillana",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1167",
          "Name": "Kapi Thillana",
          "Type": "Thillana",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1168",
          "Name": "Bilahari Thillana",
          "Type": "Thillana",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1169",
          "Name": "Shankarabharanam Thillana",
          "Type": "Thillana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1170",
          "Name": "Atana Thillana",
          "Type": "Thillana",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1171",
          "Name": "Behag Thillana",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1172",
          "Name": "Kalyani Thillana",
          "Type": "Thillana",
          "Ragam": "Kalyani",
          "Thalam": "Sankeerna laghu deshadi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1173",
          "Name": "Hamsanandi Thillana",
          "Type": "Thillana",
          "Ragam": "Hamsanandi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1174",
          "Name": "Mandari Thillana",
          "Type": "Thillana",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1175",
          "Name": "Begada Thillana",
          "Type": "Thillana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tanjai Naalvar Isai Karuvulam (Ponniah Mani Malai) - The Dance Compositions of the Tanjore Quartet",
          "Book Author": "Tanjore Quartet",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1176",
          "Name": "Vinayagar Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Nattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Panchamurthy Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1177",
          "Name": "Murugan Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Gowlai",
          "Thalam": "Chatusra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Panchamurthy Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1178",
          "Name": "Sambhandar Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Arabhi",
          "Thalam": "Sarva Laghu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Panchamurthy Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1179",
          "Name": "Chandekeshwarar Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Sree",
          "Thalam": "Chatusra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Panchamurthy Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1180",
          "Name": "Natarajar Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Varali",
          "Thalam": "Sarva Laghu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Panchamurthy Kavuthuvam",
          "Language": "Sanskrit",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1181",
          "Name": "Brahma sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1182",
          "Name": "Indra Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Gurjari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1183",
          "Name": "Agni Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Nattai",
          "Thalam": "Chatusra Jhampai",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1184",
          "Name": "Yama Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Desakshi",
          "Thalam": "Chatusra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1185",
          "Name": "Niruthi Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Kuntalam",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1186",
          "Name": "Varuna Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Varali",
          "Thalam": "Chatusra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1187",
          "Name": "Vayu Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Makuta Ramagiri",
          "Thalam": "Chatusra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1188",
          "Name": "Kubera Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Maalavasri",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1189",
          "Name": "Eesaanya Sandhi Kavuthuvam",
          "Type": "Kavuthuvam",
          "Ragam": "Malahari",
          "Thalam": "Khanda Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Nava Sandhi Kavuthuvam",
          "Language": "Tamil",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1190",
          "Name": "Geetham raerae Sree raa",
          "Type": "Geetham",
          "Ragam": "Arabhi",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1191",
          "Name": "Soolathi Narayana",
          "Type": "Sulathi",
          "Ragam": "Mohanam",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1192",
          "Name": "Prabandham Chandasow",
          "Type": "Prabandam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Chatusra Matyam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1193",
          "Name": "Kaivaara Prabandham Thothika",
          "Type": "Prabandam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Chatusra Matyam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1194",
          "Name": "Nattai Taayam",
          "Type": "Taayam",
          "Ragam": "Nattai",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1195",
          "Name": "Gowlai Taayam",
          "Type": "Taayam",
          "Ragam": "Gowlai",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1196",
          "Name": "Varali Taayam",
          "Type": "Taayam",
          "Ragam": "Varali",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1197",
          "Name": "Varali Taayam",
          "Type": "Taayam",
          "Ragam": "Varali",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1198",
          "Name": "Gowlai Taayam",
          "Type": "Taayam",
          "Ragam": "Gowlai",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1199",
          "Name": "Bowli Taayam",
          "Type": "Taayam",
          "Ragam": "Bhowli",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Adi Bharatha Kala Manjari",
          "Book Author": "K. P. Kitappa, K. P. Sivanandam",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1200",
          "Name": "Kanakangi",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1201",
          "Name": "Vanajakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1202",
          "Name": "Panthamela naasaami",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1203",
          "Name": "Nee vamti saami",
          "Type": "Varnam-Tana",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1204",
          "Name": "Saamikara",
          "Type": "Varnam-Tana",
          "Ragam": "Nattakurinji",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1205",
          "Name": "Kanakangi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1206",
          "Name": "Naameetha dhayanumchi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1207",
          "Name": "Soothathi ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1208",
          "Name": "Valachivachi",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1209",
          "Name": "Saami naapai",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1210",
          "Name": "Chalamu jesethi",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1211",
          "Name": "Chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1212",
          "Name": "Sarasija nabha ",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1213",
          "Name": "Vanajaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1214",
          "Name": "Samukhamu",
          "Type": "Varnam-Tana",
          "Ragam": "Nattai",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1215",
          "Name": "Intha chalamu seya",
          "Type": "Varnam-Tana",
          "Ragam": "Surutti",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1216",
          "Name": "Karunaa karuda vanu",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Tisra Jathi Ata thalam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1217",
          "Name": "Manasu telisi",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1218",
          "Name": "Saranga nayanaro",
          "Type": "Varnam-Tana",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1219",
          "Name": "Saarasaakshiro",
          "Type": "Varnam-Tana",
          "Ragam": "Nattakurinji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1220",
          "Name": "Maayaatheetha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1221",
          "Name": "Sree Guruguha murti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PinnaShadjam/Dhenuka",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1222",
          "Name": "Sree karambu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Sanskrit",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1223",
          "Name": "Saarasaakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shailadesaakshi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1224",
          "Name": "Paramapavani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1225",
          "Name": "Neethu paadhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1226",
          "Name": "Sree Rajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1227",
          "Name": "Satileni guruguha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1228",
          "Name": "Saaregu nee paadamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Guru Stuti-Navarathna mala",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1229",
          "Name": "Dheenarakshaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1230",
          "Name": "Ranganaathudae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1231",
          "Name": "Sabhalasiththu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1232",
          "Name": "Bruhadeeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1233",
          "Name": "Ninnupaasi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1234",
          "Name": "Kaamithaphaladayaki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1235",
          "Name": "Parama Kalyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1236",
          "Name": "Adi Parameshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1237",
          "Name": "Bhakta paalana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1238",
          "Name": "Sree Mahadevyni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1239",
          "Name": "Ninnusaareku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1240",
          "Name": "Mahadeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1241",
          "Name": "Bruhadamba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1242",
          "Name": "Ambayaninae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1243",
          "Name": "Nee Paadhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1244",
          "Name": "Amba Neelambari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1245",
          "Name": "Nee Sari Saati",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1246",
          "Name": "Mangalapradhapulichu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "On Madurai Meenakshi Amman",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1247",
          "Name": "Krupajoodavamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi amman",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1248",
          "Name": "Kaliyugamuna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Nagapatnam Nilaayatakshi",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1249",
          "Name": "Amba Sowrambha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "On Mysore Chamundeshwari",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1250",
          "Name": "Karunaseyavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1251",
          "Name": "Gandhuka nagaramuna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1252",
          "Name": "Tharunam eedhamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1253",
          "Name": "Enna Punniyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shiva nama yogi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1254",
          "Name": "Perungoil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1255",
          "Name": "Ippadi En manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Thiruveezhi malai swaminatha Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1256",
          "Name": "Jagadeeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvarur Ramaswamy Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1257",
          "Name": "Ekkalathilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvarur Ramaswamy Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1258",
          "Name": "Idhu nalla samayamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tiruvarur Ramaswamy Pillai",
          "Group": "Chatur Bhasha Malika (Tamil/Kannada/Hindi/Sanskrit)",
          "Language": "NA",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1259",
          "Name": "Kalyani kaluyath katha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal Lyrics/Vadivelu (Tanjore Quartet) Music",
          "Group": "Chatur Raga Malika",
          "Language": "Sanskrit",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1260",
          "Name": "Saanamtham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal Lyrics/Vadivelu (Tanjore Quartet) Music",
          "Group": "Chatur Raga Malika",
          "Language": "Sanskrit",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1261",
          "Name": "Gowri Naayaka",
          "Type": "Thillana",
          "Ragam": "Kaanadaa",
          "Thalam": "Simhanandanam",
          "Praise of": "N/A",
          "Composer": "Maha Vaidyanatha Iyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1262",
          "Name": "Mandari Thillana",
          "Type": "Thillana",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1263",
          "Name": "Begada Thillana",
          "Type": "Thillana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1264",
          "Name": "Kapi Thillana",
          "Type": "Thillana",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1265",
          "Name": "Bilahari Thillana",
          "Type": "Thillana",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1266",
          "Name": "Atana Thillana",
          "Type": "Thillana",
          "Ragam": "Atana",
          "Thalam": "Deshadi",
          "Praise of": "On ThiruvetkaLam ambal",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1267",
          "Name": "Behag Thillana",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1268",
          "Name": "Thodi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Thodi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1269",
          "Name": "Shankarabharanam Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1270",
          "Name": "Atana Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Atana",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1271",
          "Name": "Bhairavi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Bhairavi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1272",
          "Name": "Kalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1273",
          "Name": "Thodi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1274",
          "Name": "Khamas Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Khamas",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1275",
          "Name": "Ragamalika Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Ragamalika",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1276",
          "Name": "Shankarabharanam Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1277",
          "Name": "Saveri Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Saveri",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1278",
          "Name": "Chakravaham Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Chakravaham",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1279",
          "Name": "Kalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1280",
          "Name": "Vasantha Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Vasantha",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1281",
          "Name": "Hemavathi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Hemavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1282",
          "Name": "Poorvikalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1283",
          "Name": "Sree Karasuku",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1284",
          "Name": "Sadayudaanae",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1285",
          "Name": "Sami ninne",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1286",
          "Name": "Sarasi Jaakshi",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1287",
          "Name": "Niradha munani",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1288",
          "Name": "Gokulaambhudhi",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1289",
          "Name": "VeNyudaa",
          "Type": "Shabdam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1290",
          "Name": "Gopalaa",
          "Type": "Swarajathi",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1291",
          "Name": "Sami nirammanavae",
          "Type": "Swarajathi",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1292",
          "Name": "Saamiki",
          "Type": "Swarajathi",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1293",
          "Name": "Sarojaakshi",
          "Type": "Swarajathi",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1294",
          "Name": "Sadayaa Ika",
          "Type": "Swarajathi",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1295",
          "Name": "Yaemanthayaanaraa",
          "Type": "Swarajathi",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1296",
          "Name": "Nae tiki",
          "Type": "Swarajathi",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1297",
          "Name": "Saami mael",
          "Type": "Swarajathi",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1298",
          "Name": "Kaana Aavalaanaen",
          "Type": "Swarajathi",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1299",
          "Name": "Manavigai",
          "Type": "Varnam-Pada",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1300",
          "Name": "Sami neerammanavae",
          "Type": "Varnam-Pada",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1301",
          "Name": "Sami neerammanavae",
          "Type": "Varnam-Pada",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1302",
          "Name": "Sarasaa ninu",
          "Type": "Varnam-Pada",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1303",
          "Name": "Saaminaa kaatho",
          "Type": "Varnam-Pada",
          "Ragam": "Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1304",
          "Name": "Saami naapai",
          "Type": "Varnam-Pada",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1305",
          "Name": "Nee Saati thora",
          "Type": "Varnam-Pada",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1306",
          "Name": "Sarasa Shikaamani",
          "Type": "Varnam-Pada",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1307",
          "Name": "Saami naepalu",
          "Type": "Varnam-Pada",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1308",
          "Name": "Thaani kae",
          "Type": "Varnam-Pada",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1309",
          "Name": "Thaa nee saati",
          "Type": "Varnam-Pada",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1310",
          "Name": "Saarojaakshi",
          "Type": "Varnam-Pada",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1311",
          "Name": "Saami ninne",
          "Type": "Varnam-Pada",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1312",
          "Name": "Saami neevaeyana",
          "Type": "Varnam-Pada",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1313",
          "Name": "Chalamu jaesithae",
          "Type": "Varnam-Pada",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1314",
          "Name": "Yaemakuva Bhodinchae",
          "Type": "Varnam-Pada",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1315",
          "Name": "Sami ninne",
          "Type": "Varnam-Pada",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "Navaratna Malika",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1316",
          "Name": "Mohamaana",
          "Type": "Varnam-Pada",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1317",
          "Name": "Sakhiyae",
          "Type": "Varnam-Pada",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1318",
          "Name": "Saamiyai",
          "Type": "Varnam-Pada",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1319",
          "Name": "Adimoham",
          "Type": "Varnam-Pada",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1320",
          "Name": "Mohalaahiri",
          "Type": "Varnam-Pada",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1321",
          "Name": "Sarasuda",
          "Type": "Varnam-Pada",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1322",
          "Name": "Inthakopa",
          "Type": "Varnam-Pada",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1323",
          "Name": "Chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1324",
          "Name": "Sarasija",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1325",
          "Name": "Madana Bilahari",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "Chatur raga sloka malikai",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1326",
          "Name": "Samininu",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "Ragamalika with 12 ragas",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1327",
          "Name": "Pannagaa theercha",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1328",
          "Name": "Kaarviriyum",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "Desiya ashta Ragamalika",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1329",
          "Name": "Kalyanikalu",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Chatur Raga Sloka malikai",
          "Language": "Sanskrit",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1330",
          "Name": "Saa nam tham",
          "Type": "N/A",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Chatur Raga Sloka malikai",
          "Language": "Sanskrit",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1331",
          "Name": "Daa ni sari",
          "Type": "Swarapadam",
          "Ragam": "Thodi",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1332",
          "Name": "Thani Bhodana",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1333",
          "Name": "Yaemovalapaaya",
          "Type": "Javali",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1334",
          "Name": "Indhu Nila",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1335",
          "Name": "Elara naapai",
          "Type": "Javali",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1336",
          "Name": "Mutta vaththu",
          "Type": "Javali",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1337",
          "Name": "Mosajaesae",
          "Type": "Javali",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1338",
          "Name": "Thodi Thillana (Thaani Dhiru Dhiru)",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1339",
          "Name": "Thodi Thillana (Tha Thaani Thillana)",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1340",
          "Name": "Kaanadaa Thillana",
          "Type": "Thillana",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1341",
          "Name": "Kapi Thillana",
          "Type": "Thillana",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1342",
          "Name": "Bilahari Thillana",
          "Type": "Thillana",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1343",
          "Name": "Shankarabharanam Thillana",
          "Type": "Thillana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1344",
          "Name": "Atana Thillana",
          "Type": "Thillana",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1345",
          "Name": "Behag Thillana",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1346",
          "Name": "Hamsanandi Thillana",
          "Type": "Thillana",
          "Ragam": "Hamsanandi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "The Dance Compositions of The Tanjore Quartette/Ponniah Mani Maalai",
          "Book Author": "Sivanandam",
          "Year of Publication": "1992",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1347",
          "Name": "35 Alankarams (7 * 5 = 35)",
          "Type": "N/A",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "NA",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1348",
          "Name": "Gananaatha - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1349",
          "Name": "Vaa Saravananae - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1350",
          "Name": "Umai Padhiyidam - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "Bhairavi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1351",
          "Name": "Vaamathi dhara - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1352",
          "Name": "Thirumagal - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Matya thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1353",
          "Name": "Enaiyaal - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1354",
          "Name": "Naanmalariniluraivaai - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1355",
          "Name": "Kamalamalarinil - Sanchari Geetham",
          "Type": "Geetham",
          "Ragam": "Dhanyasi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1356",
          "Name": "Sooryabhagavaanai",
          "Type": "Geetham",
          "Ragam": "Sowrashtram",
          "Thalam": "Chatusra Jathi Dhruva Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Vaara Geetham (Days of the week Geetham)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1357",
          "Name": "Valarilam madhiyavanae",
          "Type": "Geetham",
          "Ragam": "Asaveri",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Vaara Geetham (Days of the week Geetham)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1358",
          "Name": "Poovulagathipa puvitharumaganae",
          "Type": "Geetham",
          "Ragam": "Surutti",
          "Thalam": "Khanda jathi Rooopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Vaara Geetham (Days of the week Geetham)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1359",
          "Name": "Madhitharu maganae",
          "Type": "Geetham",
          "Ragam": "Naatakurinji",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Vaara Geetham (Days of the week Geetham)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1360",
          "Name": "Guru Mahaan",
          "Type": "Geetham",
          "Ragam": "Atana",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Vaara Geetham (Days of the week Geetham)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1361",
          "Name": "Paarkavanae",
          "Type": "Geetham",
          "Ragam": "Paras",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Vaara Geetham (Days of the week Geetham)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1362",
          "Name": "Chanibhagavaan",
          "Type": "Geetham",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Khanda Jathi Matya thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Vaara Geetham (Days of the week Geetham)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1363",
          "Name": "Paraaparan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Naalvar Thudhi",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1364",
          "Name": "Saalach chirandha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ulagiyal (Kalvi)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1365",
          "Name": "Olukamadhuvae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ulagiyal (Ozhukkam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1366",
          "Name": "Koodiyae Vaazhvadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ulagiyal (Otrumai)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1367",
          "Name": "Vaanamudanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ulagiyal (Iyarkai)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1368",
          "Name": "Paarinil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ulagiyal (Iyarkai)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1369",
          "Name": "Ulagamadhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Khanda Jathi Matya thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ulagiyal (Vivasayam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1370",
          "Name": "Mayamalawa",
          "Type": "Geetham",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1371",
          "Name": "Aarosaiyinil",
          "Type": "Geetham",
          "Ragam": "Saveri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1372",
          "Name": "Kaelvikkiniya",
          "Type": "Geetham",
          "Ragam": "Sree",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1373",
          "Name": "Mukhari ragam",
          "Type": "Geetham",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1374",
          "Name": "Harikhambhodhi",
          "Type": "Geetham",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1375",
          "Name": "Paarinil yevarum",
          "Type": "Geetham",
          "Ragam": "Mohanam",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1376",
          "Name": "Arabhi pannin",
          "Type": "Geetham",
          "Ragam": "Arabhi",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1377",
          "Name": "Bilahari raaga",
          "Type": "Geetham",
          "Ragam": "Bilahari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Ragalakshna Geetham",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1378",
          "Name": "Chakravaham Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Chakravaham",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1379",
          "Name": "Kalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1380",
          "Name": "Thodi Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1381",
          "Name": "Shankarabharanam Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1382",
          "Name": "Poorvikalyani Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi (Sankeerna nadai)",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "NA",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1383",
          "Name": "Thaamadhamaeno",
          "Type": "Swarajathi",
          "Ragam": "Bilahari",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1384",
          "Name": "Nee Arulayo",
          "Type": "Swarajathi",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1385",
          "Name": "MaadhevaKaruNa",
          "Type": "Swarajathi",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1386",
          "Name": "Kaana Aavalaanaen",
          "Type": "Swarajathi",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1387",
          "Name": "Kadhiravan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Pancha Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Pancha Thala Sulathi",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1388",
          "Name": "Daamodhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Pancha Jathi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Aindhu Jaathi Sulathi (Pancha Jathi Sulathi)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1389",
          "Name": "Thaayae Nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Trikaala TriThala Sulathi",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1390",
          "Name": "Tha dhiku thaam",
          "Type": "Prabandam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Kaivaara Prabandam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1391",
          "Name": "Kobham",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1392",
          "Name": "Sogamaanaen",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1393",
          "Name": "Naadhaa naavalar",
          "Type": "Varnam-Tana",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1394",
          "Name": "Paavaai",
          "Type": "Varnam-Tana",
          "Ragam": "Varali",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1395",
          "Name": "Thogaimaamayil",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1396",
          "Name": "Endha naeramum",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1397",
          "Name": "Varuvaaro",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1398",
          "Name": "Saamiyidam",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1399",
          "Name": "Mohamaana",
          "Type": "Varnam-Pada",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1400",
          "Name": "Saamiyai Azhaithu vaadi",
          "Type": "Varnam-Pada",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1401",
          "Name": "Thiruvarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1402",
          "Name": "Sangath thamizh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1403",
          "Name": "Perungoil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1404",
          "Name": "Thandhai thaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1405",
          "Name": "Paadiyaadak KaaNbom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1406",
          "Name": "Enaa Seiguvaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Velavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1407",
          "Name": "Jagadeesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1408",
          "Name": "Yaen padhaithaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1409",
          "Name": "Piravaavaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1410",
          "Name": "Vaa Vaa Endrunnai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1411",
          "Name": "AarugaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1412",
          "Name": "MaalaisooDiyavar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1413",
          "Name": "KaaNamanoharamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1414",
          "Name": "Marandhuvidudalundho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1415",
          "Name": "Kaalam Idhuvallavo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1416",
          "Name": "Thaerseluthuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1417",
          "Name": "Yedho Theriyavillayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1418",
          "Name": "Paalai nilam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1419",
          "Name": "NeeLkadalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1420",
          "Name": "Pookamazhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "AindhiNai padhangaL",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1421",
          "Name": "Kaarviriyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Desiya Ashta Ragamalika",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1422",
          "Name": "Ninaindhodi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Sapta Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "Sapta Thala Ragamalika",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1423",
          "Name": "Bilahari Thillana",
          "Type": "Thillana",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1424",
          "Name": "Atana Thillana",
          "Type": "Thillana",
          "Ragam": "Atana",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1425",
          "Name": "Behag Thillana",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1426",
          "Name": "Shankarabharanam",
          "Type": "Thillana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 18 (Tamizh Isai Karuvulam - Tanjore Quartet)",
          "Book Author": "Ramanathan Chettiyar",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1427",
          "Name": "Anjudhal Ozhithae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1428",
          "Name": "Anjaelendrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1429",
          "Name": "Andhaya kaalam vandhidum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1430",
          "Name": "Ardhanaareershwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1431",
          "Name": "Anbu seiyum vaazhkaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1432",
          "Name": "Anbu madhandhanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "On Bharath matha",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1433",
          "Name": "Annai Sharadhamani devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1434",
          "Name": "Aaridam murai Solluvaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1435",
          "Name": "Idhayam padaithadhai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1436",
          "Name": "Indha oru udhaviyaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1437",
          "Name": "Indha yaakai veeN ponaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1438",
          "Name": "Ivan perum paaviyendrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1439",
          "Name": "Iraivaa nin karuNaiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1440",
          "Name": "Inbam Nalamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1441",
          "Name": "Indrae Arul puriveer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1442",
          "Name": "Innamum avar manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1443",
          "Name": "Innum yeththanai kaalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1444",
          "Name": "Ulagariyaadhendru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1445",
          "Name": "Ulladhaikkondae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1446",
          "Name": "Unnaiyandri vaeroru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1447",
          "Name": "Unnaamam Un paechu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1448",
          "Name": "ENNidum podhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1449",
          "Name": "Yethanai dharam sonnalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1450",
          "Name": "Ethanai piravigal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1451",
          "Name": "Yenakkoru vazhiyumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1452",
          "Name": "En manam polae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1453",
          "Name": "Yaezhai ivan allavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1454",
          "Name": "Aingaranillamal yaarun thunai illai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1455",
          "Name": "Orudharam Dharisithaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1456",
          "Name": "Karunaiyin avadharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1457",
          "Name": "Kazhanigal Soozh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1458",
          "Name": "Kaalanai veezhthiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1459",
          "Name": "KaaLi karuneeli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1460",
          "Name": "Kundruva vel vaangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1461",
          "Name": "Sakala kala naayaki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1462",
          "Name": "Saththi vadivel paraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1463",
          "Name": "Sirithu purameriththa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1464",
          "Name": "Senthil Vadivelanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1465",
          "Name": "Senthil Valar Guhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1466",
          "Name": "Chaepidu Vidhdhai sagamum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1467",
          "Name": "Sollivaa sakhiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1468",
          "Name": "Sodhanai seidhadhu podhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1469",
          "Name": "Thillayil aadum thiru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1470",
          "Name": "ThoNi midhakkavittu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1471",
          "Name": "Nammakoru kurai yaedhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1472",
          "Name": "Nandru thuyilkoLveer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1473",
          "Name": "NaatkaLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1474",
          "Name": "Thedi Thedi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1475",
          "Name": "Bhavakkadal thaaNdida",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1476",
          "Name": "Pazhani nindra paraman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1477",
          "Name": "Pazhanip padhi sendraal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1478",
          "Name": "Pazhani malaithanil vaazhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1479",
          "Name": "Paarellam Arulaalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1480",
          "Name": "Pithaa Unmel",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1481",
          "Name": "Pizhai ninaithu nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1482",
          "Name": "Bhuddhan thuravukkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1483",
          "Name": "PonkaravaNindha Naadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1484",
          "Name": "Pongu thaamarai pootha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1485",
          "Name": "Ponaal pogiraar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1486",
          "Name": "Manadhu kalangaadhae nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1487",
          "Name": "Manamirangaadhadhu yaeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1488",
          "Name": "Muruganadi saerndhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1489",
          "Name": "Muruganadi manamuruga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1490",
          "Name": "Muruganendru varuvaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1491",
          "Name": "Muruganai yenai aanda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1492",
          "Name": "Muruganai koovi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1493",
          "Name": "Murugaa thanjam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1494",
          "Name": "Vaazhvinil Thunbam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1495",
          "Name": "VeeNN pazhi sumatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1496",
          "Name": "VeeN vaadham Vaendhaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1497",
          "Name": "Veeravael velan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1498",
          "Name": "Vaenugaana amudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1499",
          "Name": "Vaelavaa surakula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1500",
          "Name": "Vel muruganai ninaivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1501",
          "Name": "Sree Ramakrishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1502",
          "Name": "Kazhanigal Soozh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1503",
          "Name": "Aingaranillamal yaarun thunai illai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1504",
          "Name": "Pazhani malaithanil vaazhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1505",
          "Name": "Sakala kala naayaki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1506",
          "Name": "Pongu thaamarai pootha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1507",
          "Name": "Pithaa Unmel",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1508",
          "Name": "Yaezhai ivan allavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1509",
          "Name": "Anjaelendrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1510",
          "Name": "Veeravael velan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1511",
          "Name": "Senthil Valar Guhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1512",
          "Name": "ENNidum podhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1513",
          "Name": "Vaazhvinil Thunbam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1514",
          "Name": "Kundruva vel vaangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1515",
          "Name": "Thillayil aadum thiru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1516",
          "Name": "Indha yaakai veeN ponaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1517",
          "Name": "Manadhu kalangaadhae nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1518",
          "Name": "Vaelavaa surakula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1519",
          "Name": "Unnaamam Un paechu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1520",
          "Name": "Senthil Vadivelanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1521",
          "Name": "Ethanai piravigal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1522",
          "Name": "Paarellam Arulaalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1523",
          "Name": "Muruganadi manamuruga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1524",
          "Name": "Yenakkoru vazhiyumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1525",
          "Name": "Sodhanai seidhadhu podhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1526",
          "Name": "Manamirangaadhadhu yaeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1527",
          "Name": "Iraivaa nin karuNaiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1528",
          "Name": "Inbam Nalamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1529",
          "Name": "Ulladhaikkondae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1530",
          "Name": "NaatkaLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1531",
          "Name": "Karunaiyin avadharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1532",
          "Name": "Muruganadi saerndhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1533",
          "Name": "Pazhani nindra paraman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1534",
          "Name": "Nammakoru kurai yaedhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1535",
          "Name": "Ardhanaareershwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1536",
          "Name": "Bhavakkadal thaaNdida",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1537",
          "Name": "Indrae Arul puriveer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1538",
          "Name": "Kaalanai veezhthiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1539",
          "Name": "Andhaya kaalam vandhidum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1540",
          "Name": "Sirithu purameriththa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1541",
          "Name": "Sree Ramakrishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1542",
          "Name": "Ivan perum paaviyendrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1543",
          "Name": "Chaepidu Vidhdhai sagamum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1544",
          "Name": "Pazhanip padhi sendraal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1545",
          "Name": "Yethanai dharam sonnalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1546",
          "Name": "Nandru thuyilkoLveer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1547",
          "Name": "Ulagariyaadhendru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1548",
          "Name": "En manam polae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1549",
          "Name": "Muruganai yenai aanda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1550",
          "Name": "KaaLi karuneeli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1551",
          "Name": "ThoNi midhakkavittu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1552",
          "Name": "Murugaa thanjam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1553",
          "Name": "Pizhai ninaithu nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1554",
          "Name": "Orudharam Dharisithaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1555",
          "Name": "Vel muruganai ninaivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1556",
          "Name": "VeeN vaadham Vaendhaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1557",
          "Name": "PonkaravaNindha Naadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1558",
          "Name": "Unnaiyandri vaeroru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1559",
          "Name": "Anbu madhandhanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "On Bharath matha",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1560",
          "Name": "Saththi vadivel paraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1561",
          "Name": "Anbu seiyum vaazhkaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1562",
          "Name": "Anjudhal Ozhithae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1563",
          "Name": "Bhuddhan thuravukkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1564",
          "Name": "Annai Sharadhamani devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1565",
          "Name": "Aaridam murai Solluvaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1566",
          "Name": "Idhayam padaithadhai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1567",
          "Name": "Innum yeththanai kaalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1568",
          "Name": "Innamum avar manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1569",
          "Name": "Sollivaa sakhiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1570",
          "Name": "Thedi Thedi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1571",
          "Name": "Muruganai koovi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1572",
          "Name": "Vaenugaana amudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1573",
          "Name": "Muruganendru varuvaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1574",
          "Name": "Indha oru udhaviyaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1575",
          "Name": "Ponaal pogiraar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1576",
          "Name": "VeeNN pazhi sumatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 7 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1943",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1577",
          "Name": "Bhakthi minsaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1578",
          "Name": "Chidambaramaena ninaivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bangala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1579",
          "Name": "Gnanappaal arulvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saaraamathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1580",
          "Name": "Mona nisiyinilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1581",
          "Name": "Aruviyaippolum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/Tiger Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1582",
          "Name": "Mohana valai veesinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1583",
          "Name": "Ellam Sivan Seyal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1584",
          "Name": "Kottam adangi chumma iruppayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1585",
          "Name": "Saadhanam Seiyya vaareerae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1586",
          "Name": "Anbirundhalae podhum aandavanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1587",
          "Name": "Paramesanai paarvathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1588",
          "Name": "Unakku samaanamaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1589",
          "Name": "Anjalaamo Nenjae endrum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1590",
          "Name": "Abhayakaraththai paartha podhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1591",
          "Name": "Anandamae paramaanandhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jujahuli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1592",
          "Name": "KaNNaalae unnai naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1593",
          "Name": "Kavalaip padhaadhae manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1594",
          "Name": "Pudhuyugam varavaendum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1595",
          "Name": "Varuvaano vanakkuyilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1596",
          "Name": "Ananda natana maadinaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gopikathilakam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1597",
          "Name": "Illai enbaan yaaradaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1598",
          "Name": "Sharadaa deviyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1599",
          "Name": "Thaayae sivakaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1600",
          "Name": "Podhum Podhum Podhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1601",
          "Name": "Nambithaan irukindraen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1602",
          "Name": "Aaseervaadham tharuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1603",
          "Name": "Yethanai inbangal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1604",
          "Name": "Shakthiyai vanangiduvom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1605",
          "Name": "Alaiyaamal paarumaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1606",
          "Name": "Veera urudhi varuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1607",
          "Name": "Govindaraaja perumaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1608",
          "Name": "Seevapadagai ootum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/VS Gomathi Shankaraiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1609",
          "Name": "Koyilulagellam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1610",
          "Name": "KaNNarakk kaNdu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1611",
          "Name": "Annaamalai appanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1612",
          "Name": "Vande Maatram Vande Maatram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1613",
          "Name": "Endhaai vaazhgavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1614",
          "Name": "Thaayae Charanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1615",
          "Name": "Paripoorna Sudhandhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1616",
          "Name": "Eppodhu varuvaayo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1617",
          "Name": "Vazhikaati nadhathinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/LR Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1618",
          "Name": "Nataraja Charanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1619",
          "Name": "Shanthamaaga unn sannidhiyil irundhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1620",
          "Name": "Yezhundhiru Kalyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1621",
          "Name": "Appa unn ponnadi thurappaeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1622",
          "Name": "Devaseevanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1623",
          "Name": "Idhayakkadhavai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1624",
          "Name": "Unayae kaadhalithaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1625",
          "Name": "Bhaaramellam Vaithaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1626",
          "Name": "Poorana gnaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1627",
          "Name": "Eppodhu Viduthalaiyo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1628",
          "Name": "Paramanai sindhai seivom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1629",
          "Name": "Iravum pagalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1630",
          "Name": "Maanida Janmam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1631",
          "Name": "Thillai natarajanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1632",
          "Name": "PaNNuDan paNiyuDan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1633",
          "Name": "Mangalamaai vaazhga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1634",
          "Name": "Ammaa Unnaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1635",
          "Name": "Anbu varam tharuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1636",
          "Name": "Gurunaathan avanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1637",
          "Name": "Kaividaadhae Duraiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1638",
          "Name": "Pazhaniappan thunaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1639",
          "Name": "Yaaro Vandhennai aasai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Bangala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1640",
          "Name": "OriDam Thaaraayo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1641",
          "Name": "Unnai TheLindhiduvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Narayanaswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1642",
          "Name": "Ennai kaividuvaayo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1643",
          "Name": "Kaathirukkindraen En ayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1644",
          "Name": "Kanavilaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1645",
          "Name": "Dheena Dhaya paranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1646",
          "Name": "En Mugam paar Aiyanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1647",
          "Name": "Aththaa AruLilayo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1648",
          "Name": "Eppadi Paadinaroo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Devagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/KS Sivavadivelu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 11 (Shuddananda Bharati)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1959",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1649",
          "Name": "Sivanai ninaindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1650",
          "Name": "Unmel Bhaaram Vaithu unnaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1651",
          "Name": "Oraaru muganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1652",
          "Name": "Sivanai ninai manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1653",
          "Name": "PizhaigaL ellam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1654",
          "Name": "Chidambaramae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1655",
          "Name": "Ichchanmamidhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1656",
          "Name": "Sivan varuvaar thunai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1657",
          "Name": "Kandean kali theerndhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1658",
          "Name": "Manamae unakkoru marundhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1659",
          "Name": "Sugamaa iru manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1660",
          "Name": "Seikadan Seigavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1661",
          "Name": "Appanum ammayum nee allavo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1662",
          "Name": "Ondrae Uyar samayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1663",
          "Name": "Thondu seidhalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1664",
          "Name": "Neeyandri ennuLam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1665",
          "Name": "Kavalai yedharku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1666",
          "Name": "Anbu seidhalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1667",
          "Name": "PoruLae perugida",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1668",
          "Name": "Unaiyandri matrondu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1669",
          "Name": "Idhuvo KaruNai en meedhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1670",
          "Name": "Yaaraedhu seiyinum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1671",
          "Name": "Shakthi yaenakkun",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1672",
          "Name": "Bhakthi seivadhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1673",
          "Name": "Vaazhvu enbhadhu enna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1674",
          "Name": "Idhu endru vazhi kaatugavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1675",
          "Name": "Arul surandhu arul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1676",
          "Name": "Kaividalaamo Kaathu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1677",
          "Name": "Maiyal Kondaen",
          "Type": "Padam",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1678",
          "Name": "PaLLi yezhundharul vaai",
          "Type": "Padam",
          "Ragam": "Bhupalam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Trivandram Lakshmanapillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1679",
          "Name": "Sharanaa gathi endru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1680",
          "Name": "Thiruvaadhirai ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1681",
          "Name": "Aadiya paadhathai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1682",
          "Name": "Yaedhukku indha sadalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1683",
          "Name": "Sivakaama Sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1684",
          "Name": "Maravaamal eppadiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1685",
          "Name": "Innamum sandheha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1686",
          "Name": "Yaarukku thaan theriyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1687",
          "Name": "Yaarukku ponnambalan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1688",
          "Name": "Sabhapathiku veru deivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1689",
          "Name": "VeeNaai kaalam kazhikaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/SS Krishnaiyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1690",
          "Name": "Paar Paar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/SS Krishnaiyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1691",
          "Name": "Vandu en Aanduarulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/SS Krishnaiyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1692",
          "Name": "Shuddha Nirvikara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/SS Krishnaiyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1693",
          "Name": "Paramaatma jyothiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/SS Krishnaiyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1694",
          "Name": "KaaN bhaenaa Kanmaniyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/SS Krishnaiyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1695",
          "Name": "Anubhavikaa agalaadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1696",
          "Name": "Aadhaaram vaeraedhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1697",
          "Name": "Arunilai perambala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1698",
          "Name": "Undran ullam ariyaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Achuta Dasar/Mayuram TR Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1699",
          "Name": "Chitham uvandhuarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1700",
          "Name": "Tharunam Tharunam Dayai seivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1701",
          "Name": "Iyanae Uyar Meiyanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1702",
          "Name": "Chitham Irangi nal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1703",
          "Name": "Thanjam neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1704",
          "Name": "Tharunam Karunai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1705",
          "Name": "Nithyaanandhathai naadu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1706",
          "Name": "KaruNaa kara murthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1707",
          "Name": "Yedho Sodhanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1708",
          "Name": "Manamae Nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1709",
          "Name": "Yenai Aadharippaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1710",
          "Name": "Innam thamadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1711",
          "Name": "Gnana maargam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1712",
          "Name": "Udhavi seiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1713",
          "Name": "Naanae unnai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1714",
          "Name": "Nidham Nidham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1715",
          "Name": "Parabrahmamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1716",
          "Name": "Karunaa Sagara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1717",
          "Name": "paNamae unnaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1718",
          "Name": "Devaadhi Devaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/Alathur Venkatesa Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1719",
          "Name": "Andha raama Sowndharyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavirayar",
          "Group": "Ramanataka Keerthanaigal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1720",
          "Name": "AarendraNNaamalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavirayar",
          "Group": "Ramanataka Keerthanaigal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1721",
          "Name": "MahathuvamuLLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavirayar",
          "Group": "Ramanataka Keerthanaigal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1722",
          "Name": "PiZhai porukka vaeNum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavirayar",
          "Group": "Ramanataka Keerthanaigal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1723",
          "Name": "Paaraai peNNae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavirayar",
          "Group": "Ramanataka Keerthanaigal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 3 (Nilakanta Sivan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1724",
          "Name": "Siddhi Vinayakanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "KaviKunjaraBharathi/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1725",
          "Name": "Nichayam Idhudhaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavaManohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1726",
          "Name": "Pathini polavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Samkrandhanapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1727",
          "Name": "Aiyaa yenai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1728",
          "Name": "Innam paaraamugamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1729",
          "Name": "Iniyaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sivanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1730",
          "Name": "Eppadiyum paramae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1731",
          "Name": "Oho kaalamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1732",
          "Name": "Karunaakara paramporulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1733",
          "Name": "Yaedhu paramugam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1734",
          "Name": "Jaalam seivadhedho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1735",
          "Name": "Eppo kaanbhaeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rathinambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Achutadasar/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1736",
          "Name": "Maraimudi porulaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Achutadasar/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1737",
          "Name": "Thiru uLach seiyal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Achutadasar/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1738",
          "Name": "Thoodhu nee solli vaaraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirikoodaraasappakaviraayar/MM Dandapani Desikar",
          "Group": "Kutrala Kuravanji",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1739",
          "Name": "Sugavazhithanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1740",
          "Name": "Putham puthu vasantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shuddhananda Bharathi/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1741",
          "Name": "Vaazhthavaendumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1742",
          "Name": "Arasae perumai annamalaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1743",
          "Name": "Vaazhiya Senthamizh naadu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "On Tamilnadu",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1744",
          "Name": "Veeraththaayar Vaazhndanadu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "On Tamilnadu",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1745",
          "Name": "Thamizhae Thamizhargal selvam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhaathri",
          "Thalam": "Adi",
          "Praise of": "On Tamil Language",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1746",
          "Name": "Maathae nee thoodhu selladi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1747",
          "Name": "Pazhanthamizh veeran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1748",
          "Name": "Ennakoru varam tharuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1749",
          "Name": "Parangiri perumaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Visharada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1750",
          "Name": "Ini nalamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rathipathipriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ouvai Duraisamy Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1751",
          "Name": "Vennilaavum vaanum polae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasena",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Bharathidasan/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1752",
          "Name": "Oviyam Varaindhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Alankarapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Bharathidasan/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1753",
          "Name": "Aduvome Pallu paduvome Thamizh aatchiyil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarangatharangini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Bharathidasan/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1754",
          "Name": "Naaraayananai thudhippai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Komalangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Raghava Ramanuja Dasar/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1755",
          "Name": "PaamaNam kamazhavaendum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalithamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerkuppai Rama Subramanya Chettiar/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1756",
          "Name": "KadavuLai maravathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thandavam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1757",
          "Name": "Naavukkarasanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1758",
          "Name": "Aakamumae tharum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1759",
          "Name": "En thaayae En annai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1760",
          "Name": "Thamizh mozhiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "On Tamil Language",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1761",
          "Name": "Aindhezhuthinai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1762",
          "Name": "Thaayai nee maravaathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "On Mothers",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1763",
          "Name": "Yaadhum engal oorae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1764",
          "Name": "Arul purivaai paramporulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanaagari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1765",
          "Name": "Engae thedukindraai iraivanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1766",
          "Name": "Ethanai paerthunburuvor",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelamani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1767",
          "Name": "Perumai KoLVaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "On Tamilnadu",
          "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1768",
          "Name": "ALLi undidalaam vaareer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "On Tamil",
          "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1769",
          "Name": "VaravaeNum vadivelanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagavalli",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1770",
          "Name": "Unnai Vaendinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1771",
          "Name": "Inbakkanaa ondru kandaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pudukkotai Krishnamurthy/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1772",
          "Name": "Aadum kalabha mayil paagan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Udumalaipettai Narayanakavi/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1773",
          "Name": "Ellorum inbura kaNN kaaNa mangalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Udumalaipettai Narayanakavi/MM Dandapani Desikar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 17 (Various composers)",
          "Book Author": "T N Sivasubramanya Pillai",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1774",
          "Name": "Sri Ganapathy",
          "Type": "Geetham",
          "Ragam": "GambiraNattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1775",
          "Name": "Aadiya paadhanai",
          "Type": "Geetham",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1776",
          "Name": "Vaanavar potridum",
          "Type": "Geetham",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1777",
          "Name": "Malai thanil ulava",
          "Type": "Geetham",
          "Ragam": "Saveri",
          "Thalam": "Tisra Jathi Roopakam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1778",
          "Name": "Vedha naavil",
          "Type": "Geetham",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1779",
          "Name": "ThiruvuLa seyal yedhuvo",
          "Type": "Geetham",
          "Ragam": "Hindolam",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1780",
          "Name": "KalaivaaNi thaayae",
          "Type": "Geetham",
          "Ragam": "Saraswathi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1781",
          "Name": "TharuNa midhae",
          "Type": "Geetham",
          "Ragam": "Mohanam",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1782",
          "Name": "Ganapathi endrae Karudhida",
          "Type": "Geetham",
          "Ragam": "Kalyani",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1783",
          "Name": "Arul tharuvar Engal",
          "Type": "Geetham",
          "Ragam": "Malayamarutham",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1784",
          "Name": "Anaithu uyirkum",
          "Type": "Geetham",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1785",
          "Name": "Kandadhum kaetadhum",
          "Type": "Geetham",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1786",
          "Name": "Ayan yenai padaithan",
          "Type": "Geetham",
          "Ragam": "Poorvikalyani",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1787",
          "Name": "Poraamaiyai Kondeer",
          "Type": "Geetham",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1788",
          "Name": "Adimai Vaazhvai agatruvaayae",
          "Type": "Geetham",
          "Ragam": "Varali",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1789",
          "Name": "Ara neri vazhuvaa",
          "Type": "Geetham",
          "Ragam": "Vachaspathi",
          "Thalam": "Tisra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1790",
          "Name": "Mariyaadhai theriyaadha",
          "Type": "Geetham",
          "Ragam": "Huseni",
          "Thalam": "Sankeerna Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1791",
          "Name": "VaLamigum nannaadu",
          "Type": "Geetham",
          "Ragam": "KedaraGowlai",
          "Thalam": "Sankeerna Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1792",
          "Name": "Padhiyae deivam endru odhiya",
          "Type": "Geetham",
          "Ragam": "Saveri",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1793",
          "Name": "Anavaradhamum",
          "Type": "Geetham",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1794",
          "Name": "Paraman unakku",
          "Type": "Geetham",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1795",
          "Name": "Ananda Thaandava moorthy",
          "Type": "Geetham",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1796",
          "Name": "Aativaikum Bhommaiyinaal",
          "Type": "Geetham",
          "Ragam": "Gowlai",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1797",
          "Name": "Arivudaiyor",
          "Type": "Geetham",
          "Ragam": "GambiraNattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1798",
          "Name": "Yedhuvum sadhamalla",
          "Type": "Geetham",
          "Ragam": "Abhogi",
          "Thalam": "Tisra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1799",
          "Name": "Bhakthi vahithavar",
          "Type": "Geetham",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1800",
          "Name": "Paramae piravaa varamae tharumae",
          "Type": "Geetham",
          "Ragam": "Devamanohari",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1801",
          "Name": "Aram seya virumbu",
          "Type": "Geetham",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1802",
          "Name": "Om Ganapathi",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1803",
          "Name": "Vaa neelaambaa",
          "Type": "Varnam-Tana",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1804",
          "Name": "Athi roopamaana ivar yaar sakhiyae",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1805",
          "Name": "Arivaenadi",
          "Type": "Varnam-Tana",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1806",
          "Name": "Arunachalam thanail urai",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1807",
          "Name": "Venthamarai selvi",
          "Type": "Varnam-Tana",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1808",
          "Name": "Ivar yaaradi sakhiyae",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1809",
          "Name": "Chaeti nee poi",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1810",
          "Name": "Kalaivaani kamalachani",
          "Type": "Varnam-Tana",
          "Ragam": "Abhogi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1811",
          "Name": "Pazhamudhir solai",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1812",
          "Name": "Maaranai vellum",
          "Type": "Varnam-Tana",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1813",
          "Name": "Tharuvaar dhayavirundhaal",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1814",
          "Name": "Thanga avarku ingu",
          "Type": "Varnam-Tana",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1815",
          "Name": "Kaarum Kavalai",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1816",
          "Name": "Yaadhuseivaen",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1817",
          "Name": "ParimaLa solai thanilae",
          "Type": "Varnam-Tana",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1818",
          "Name": "TharuNam Idhuvae",
          "Type": "Varnam-Tana",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1819",
          "Name": "Kanindhodi vandhaen",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1820",
          "Name": "Ananda Thandava",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1821",
          "Name": "Vadivela Vandharul",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Tisra Jathi Ata thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1822",
          "Name": "Om Ganapathy unai dinam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1823",
          "Name": "Varamarulavaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1824",
          "Name": "Emmadhathorukum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1825",
          "Name": "Amba Jagadamba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1826",
          "Name": "Yaagamum Yogamum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1827",
          "Name": "KaruNaakaran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1828",
          "Name": "Parashakthi padam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1829",
          "Name": "Naadum Adiyaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1830",
          "Name": "Manamae nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1831",
          "Name": "Geetham Sangeetham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1832",
          "Name": "Arindhavar yaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1833",
          "Name": "Vanjaga seikai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1834",
          "Name": "Shivaloka naadhanai dinamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1835",
          "Name": "KaNNa nin manam kaniyadho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1836",
          "Name": "Jaathi madhangalaedhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1837",
          "Name": "Pathithan naan yaen paarinil pirandhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1838",
          "Name": "Saravana Bhava nama om",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1839",
          "Name": "Vaadhum soodhum vedhanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1840",
          "Name": "Ulagir pirandhu uzhandraen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1841",
          "Name": "Andhi pozhudinil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1842",
          "Name": "Thaayinum dhayai udaiyaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1843",
          "Name": "Paandiya naayakan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1844",
          "Name": "Paridhana pazhakamundo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1845",
          "Name": "Maha Deso mandala thalaivaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1846",
          "Name": "Thanaiyan yenadhu vinai agala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1847",
          "Name": "Andavan ananda thandavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1848",
          "Name": "Maanidap piraviyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1849",
          "Name": "Sagameedhinilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1850",
          "Name": "Malaimaevum kumaresanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1851",
          "Name": "Thaamadhamaeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1852",
          "Name": "Kaapadhu unn kadan andro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1853",
          "Name": "Ninadhadimaiyaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1854",
          "Name": "Unnai andri deivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1855",
          "Name": "Theruvinil Odi Siruvarudan aadi",
          "Type": "Padam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1856",
          "Name": "Subhadina manadhaalae",
          "Type": "Padam",
          "Ragam": "NataBhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1857",
          "Name": "Paandiya naayakanthaandi thozhi",
          "Type": "Padam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1858",
          "Name": "Naadhanai azhaithu vaadi",
          "Type": "Padam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1859",
          "Name": "Andravar thandha inbathai",
          "Type": "Padam",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 21 (CS Nataraja Sundara Pillai)",
          "Book Author": "CS Nataraja Pillai",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1860",
          "Name": "Agaramumaahi",
          "Type": "Thirupugazh",
          "Ragam": "HariKhambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1861",
          "Name": "Adala saedanaaraada",
          "Type": "Thirupugazh",
          "Ragam": "Manirangu",
          "Thalam": "Tisra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1862",
          "Name": "Adirumkazhal",
          "Type": "Thirupugazh",
          "Ragam": "Revagupti",
          "Thalam": "Tisra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1863",
          "Name": "Abhahaara nindhai",
          "Type": "Thirupugazh",
          "Ragam": "Chakravaham",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1864",
          "Name": "Aruna manimaevu",
          "Type": "Thirupugazh",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Somadhi Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1865",
          "Name": "Arundhi vaazhvodu",
          "Type": "Thirupugazh",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1866",
          "Name": "Allivizhiyaalum",
          "Type": "Thirupugazh",
          "Ragam": "Thodi",
          "Thalam": "Drithiya thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1867",
          "Name": "Adimagamaayi",
          "Type": "Thirupugazh",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1868",
          "Name": "Aaramanivaarai",
          "Type": "Thirupugazh",
          "Ragam": "Atana",
          "Thalam": "Chachathputam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1869",
          "Name": "Arumugam",
          "Type": "Thirupugazh",
          "Ragam": "Revagupti",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1870",
          "Name": "Imraajani",
          "Type": "Thirupugazh",
          "Ragam": "Malayamarutham",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1871",
          "Name": "Iyalisai",
          "Type": "Thirupugazh",
          "Ragam": "Huseni",
          "Thalam": "Vishama thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1872",
          "Name": "Iruppavar",
          "Type": "Thirupugazh",
          "Ragam": "Vasantha",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1873",
          "Name": "Iruvar mayalo",
          "Type": "Thirupugazh",
          "Ragam": "Deepakam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1874",
          "Name": "Ulaga pasupaasa",
          "Type": "Thirupugazh",
          "Ragam": "Begada",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1875",
          "Name": "Iyangaranai",
          "Type": "Thirupugazh",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1876",
          "Name": "Aindhu bhuthamum",
          "Type": "Thirupugazh",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1877",
          "Name": "Orupadhu mirupadham",
          "Type": "Thirupugazh",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1878",
          "Name": "Oru pozhudhu MirucharaNa",
          "Type": "Thirupugazh",
          "Ragam": "Chakravaham",
          "Thalam": "Sankeerna Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1879",
          "Name": "Kaadhi modhi vaadhaadu",
          "Type": "Thirupugazh",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1880",
          "Name": "Kaavippoovai",
          "Type": "Thirupugazh",
          "Ragam": "Hindolam",
          "Thalam": "Khanda jathi Rooopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1881",
          "Name": "Kudathai thagarthu",
          "Type": "Thirupugazh",
          "Ragam": "Pushpalathika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1882",
          "Name": "Kumara gurupara",
          "Type": "Thirupugazh",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1883",
          "Name": "Kumara gurupara Muruga",
          "Type": "Thirupugazh",
          "Ragam": "Kharaharapriya",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1884",
          "Name": "Koorvel",
          "Type": "Thirupugazh",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1885",
          "Name": "Kombanaiyaar",
          "Type": "Thirupugazh",
          "Ragam": "Kharaharapriya",
          "Thalam": "Sankeerna Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1886",
          "Name": "Saandhudanae",
          "Type": "Thirupugazh",
          "Ragam": "YamunaKalyani",
          "Thalam": "Mishra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1887",
          "Name": "Siva Gnana",
          "Type": "Thirupugazh",
          "Ragam": "Sama",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1888",
          "Name": "Sivanaar manamkuLira",
          "Type": "Thirupugazh",
          "Ragam": "Hamsanadam",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1889",
          "Name": "Seer sirakku maeni",
          "Type": "Thirupugazh",
          "Ragam": "Nalinakanthi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1890",
          "Name": "Tharayin maanuda",
          "Type": "Thirupugazh",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1891",
          "Name": "Thirunila maruvikkaalin",
          "Type": "Thirupugazh",
          "Ragam": "Hamsanandi",
          "Thalam": "Chala Matyam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1892",
          "Name": "ThuiLLumadha",
          "Type": "Thirupugazh",
          "Ragam": "Hamsanandi",
          "Thalam": "Khanda jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1893",
          "Name": "Theruvinil nadavaa",
          "Type": "Thirupugazh",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1894",
          "Name": "Naasarthang",
          "Type": "Thirupugazh",
          "Ragam": "Bilahari",
          "Thalam": "Sankeerna Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1895",
          "Name": "Naalumaindhuvaasal",
          "Type": "Thirupugazh",
          "Ragam": "Thodi",
          "Thalam": "Tisram",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1896",
          "Name": "Niraamaya",
          "Type": "Thirupugazh",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1897",
          "Name": "Nilavinilae",
          "Type": "Thirupugazh",
          "Ragam": "Kaanadaa",
          "Thalam": "Tisra Jathi Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1898",
          "Name": "Niraimadhi",
          "Type": "Thirupugazh",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1899",
          "Name": "Ninaithadhethanai",
          "Type": "Thirupugazh",
          "Ragam": "Abhogi",
          "Thalam": "Khanda jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1900",
          "Name": "Neethaan",
          "Type": "Thirupugazh",
          "Ragam": "HamirKalyani",
          "Thalam": "Tisra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1901",
          "Name": "Pagaliravinil",
          "Type": "Thirupugazh",
          "Ragam": "Pantuvarali",
          "Thalam": "Antarakreedaa",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1902",
          "Name": "Padar puviyin",
          "Type": "Thirupugazh",
          "Ragam": "Neelambari",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1903",
          "Name": "Parimala kalabha",
          "Type": "Thirupugazh",
          "Ragam": "Naatakurinji",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1904",
          "Name": "Paniyin Vindhuli",
          "Type": "Thirupugazh",
          "Ragam": "HariKhambhoji",
          "Thalam": "Khanda Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1905",
          "Name": "Paalaai noolaai",
          "Type": "Thirupugazh",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1906",
          "Name": "Mundu Thamizh Maalai",
          "Type": "Thirupugazh",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1907",
          "Name": "Variyaar karungaN",
          "Type": "Thirupugazh",
          "Ragam": "Kamalaapthapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1908",
          "Name": "Varuga veetenum",
          "Type": "Thirupugazh",
          "Ragam": "Thodi",
          "Thalam": "Rangathyodham",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1909",
          "Name": "Vindhadhinoori",
          "Type": "Thirupugazh",
          "Ragam": "Kaanadaa",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Kanchipuram Naina Pillai aka Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1910",
          "Name": "Ambuli Neerai",
          "Type": "Thirupugazh",
          "Ragam": "Gowlai",
          "Thalam": "MaGanamatyam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1911",
          "Name": "Avasiyamun",
          "Type": "Thirupugazh",
          "Ragam": "Abhogi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1912",
          "Name": "Aramilaavathi",
          "Type": "Thirupugazh",
          "Ragam": "Swarnamalli",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1913",
          "Name": "Atraikiraithaedi",
          "Type": "Thirupugazh",
          "Ragam": "Saranga",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1914",
          "Name": "AathaalikaLpuri",
          "Type": "Thirupugazh",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1915",
          "Name": "Aarumaaru",
          "Type": "Thirupugazh",
          "Ragam": "Hamsanadam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1916",
          "Name": "Iravupagal",
          "Type": "Thirupugazh",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Tisra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1917",
          "Name": "Iruvinayin",
          "Type": "Thirupugazh",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1918",
          "Name": "Iravaamar",
          "Type": "Thirupugazh",
          "Ragam": "Thodi",
          "Thalam": "Tisram",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1919",
          "Name": "Umbartharu",
          "Type": "Thirupugazh",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1920",
          "Name": "Yedhirillaadha",
          "Type": "Thirupugazh",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Tisra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1921",
          "Name": "Kaththuriyakaru",
          "Type": "Thirupugazh",
          "Ragam": "Saveri",
          "Thalam": "Nichangaleela",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1922",
          "Name": "Kaanaadha Dhoora",
          "Type": "Thirupugazh",
          "Ragam": "Jaalakesari",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1923",
          "Name": "Kaanonaadhadhu",
          "Type": "Thirupugazh",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1924",
          "Name": "Kaamiyathazhundhi",
          "Type": "Thirupugazh",
          "Ragam": "Mukhari",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1925",
          "Name": "Kaalanida",
          "Type": "Thirupugazh",
          "Ragam": "Hindolam",
          "Thalam": "Tisra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1926",
          "Name": "Guhanae Guruparanae",
          "Type": "Thirupugazh",
          "Ragam": "Keeravani",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1927",
          "Name": "Guhayil Navanaadharun",
          "Type": "Thirupugazh",
          "Ragam": "KedaraGowlai",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1928",
          "Name": "Kudi Vaazhkai",
          "Type": "Thirupugazh",
          "Ragam": "Kokilathvani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1929",
          "Name": "Kuyilondru Mozhi",
          "Type": "Thirupugazh",
          "Ragam": "Amrithavahini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1930",
          "Name": "Kuyilomozhi",
          "Type": "Thirupugazh",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1931",
          "Name": "Kodiyana",
          "Type": "Thirupugazh",
          "Ragam": "Kalyani",
          "Thalam": "Simhaleela",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1932",
          "Name": "Kolaimadha",
          "Type": "Thirupugazh",
          "Ragam": "Dhanyasi",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1933",
          "Name": "Chadhuratharai",
          "Type": "Thirupugazh",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1934",
          "Name": "Santhatham",
          "Type": "Thirupugazh",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1935",
          "Name": "Sigaramarundha",
          "Type": "Thirupugazh",
          "Ragam": "Bilahari",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1936",
          "Name": "Sindhutreluzh",
          "Type": "Thirupugazh",
          "Ragam": "Sowrashtram",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1937",
          "Name": "Seethamadhiya",
          "Type": "Thirupugazh",
          "Ragam": "Bhairavi",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1938",
          "Name": "SeethaLavaari",
          "Type": "Thirupugazh",
          "Ragam": "Varali",
          "Thalam": "Khanda Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1939",
          "Name": "Seerulaaviya",
          "Type": "Thirupugazh",
          "Ragam": "Vasantha",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1940",
          "Name": "SruthimaraigaLirunaalu",
          "Type": "Thirupugazh",
          "Ragam": "Janaranjani",
          "Thalam": "Tisra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1941",
          "Name": "SaemakkomaLa",
          "Type": "Thirupugazh",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1942",
          "Name": "Gnyaalamengum",
          "Type": "Thirupugazh",
          "Ragam": "ReethiGowlai",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1943",
          "Name": "Thandha pasithanai",
          "Type": "Thirupugazh",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1944",
          "Name": "Thamizhodhiya",
          "Type": "Thirupugazh",
          "Ragam": "Kannadaa",
          "Thalam": "Tisra Jathi Dhuruva Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1945",
          "Name": "Timiramaamana",
          "Type": "Thirupugazh",
          "Ragam": "Dhanyasi",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1946",
          "Name": "Thimiravudhadhi",
          "Type": "Thirupugazh",
          "Ragam": "Kolakalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1947",
          "Name": "Thol yelumbu",
          "Type": "Thirupugazh",
          "Ragam": "ShivaKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1948",
          "Name": "Nachchu aravamendru",
          "Type": "Thirupugazh",
          "Ragam": "Karnataka Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1949",
          "Name": "Nilayaaporulai",
          "Type": "Thirupugazh",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1950",
          "Name": "Pathiyaal naanunnai",
          "Type": "Thirupugazh",
          "Ragam": "Begada",
          "Thalam": "Khanda jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1951",
          "Name": "Paalo thaeno",
          "Type": "Thirupugazh",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1952",
          "Name": "Bhumiadanir",
          "Type": "Thirupugazh",
          "Ragam": "Garudadhwani",
          "Thalam": "Tisra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1953",
          "Name": "Pothilirundhu",
          "Type": "Thirupugazh",
          "Ragam": "Bhavapriya",
          "Thalam": "AnangaThaalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1954",
          "Name": "Madhiyaal vithaganaagi",
          "Type": "Thirupugazh",
          "Ragam": "Chakravaham",
          "Thalam": "Chatusra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1955",
          "Name": "Marukkulaaviya",
          "Type": "Thirupugazh",
          "Ragam": "Kuntalavarali",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1956",
          "Name": "Muthaitharu",
          "Type": "Thirupugazh",
          "Ragam": "Hamsadhwani",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1957",
          "Name": "Mogu mogena",
          "Type": "Thirupugazh",
          "Ragam": "Kusuma Vichithra",
          "Thalam": "Khanda chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1958",
          "Name": "Vasanamiga",
          "Type": "Thirupugazh",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1959",
          "Name": "VanjamkoN",
          "Type": "Thirupugazh",
          "Ragam": "Bilahari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1960",
          "Name": "Varada maNi nee",
          "Type": "Thirupugazh",
          "Ragam": "Devamanohari",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar/Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 19 (Thirupugazh)",
          "Book Author": "Kanchipuram Naina Pillai, Chitoor Subramanya Pillai, P Vaidhyalingam Pillai",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1961",
          "Name": "Kunjara Mukhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Kanda Purana Churukka paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1962",
          "Name": "Velanae Arul anukoolanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Kanda Purana Churukka paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1963",
          "Name": "Kuzhandhai aagi irundhal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1964",
          "Name": "Himavaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1965",
          "Name": "Kavasi pola vadaindhar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1966",
          "Name": "Indha poruLgaLukku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1967",
          "Name": "Himachalath thinuk",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1968",
          "Name": "Paanik grahanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1969",
          "Name": "Avadhariththaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1970",
          "Name": "ThiruviLaiyaadal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1971",
          "Name": "Baala leelai seidhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1972",
          "Name": "Vedhanai sirai seidhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1973",
          "Name": "Vidai Koduththaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1974",
          "Name": "Theril yaerinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1975",
          "Name": "Chendhuril vandhirundthaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1976",
          "Name": "Avadharith thaarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1977",
          "Name": "Poosanai Seidhaarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1978",
          "Name": "Siva poosanai seidhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1979",
          "Name": "Aanai muganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1980",
          "Name": "Leelaigal purindhitaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1981",
          "Name": "Devar siraiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1982",
          "Name": "Baalano vandhennai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1983",
          "Name": "Avanae Baalan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1984",
          "Name": "Unn Karuthu edhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1985",
          "Name": "Unn Paadha Pathiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1986",
          "Name": "Indhiraaniyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1987",
          "Name": "AruL puri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1988",
          "Name": "ThirumaNa kolam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1989",
          "Name": "KalyaNa mantapam thanail",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1990",
          "Name": "Singaadhana thirundhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1991",
          "Name": "AavaanO paramporuL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1992",
          "Name": "IgazhchiyO pugazhchiyO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1993",
          "Name": "moham niraindhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1994",
          "Name": "avadharithaalaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1995",
          "Name": "Thirumagal valarndhalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1996",
          "Name": "thiNai punadhndhanil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1997",
          "Name": "Ennakkirangi aruLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1998",
          "Name": "AruL seivadhan thiru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "1999",
          "Name": "KaNgaLaarakkanDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2000",
          "Name": "paaNikgrahaNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2001",
          "Name": "Kayamugan neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2002",
          "Name": "Kandanai nee vandanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntalavarali",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2003",
          "Name": "Charanam charanam Aiyanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2004",
          "Name": "Un padhathai nambinaenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2005",
          "Name": "Sharada deviyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2006",
          "Name": "Indiran PaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2007",
          "Name": "Guruparanai yendha naalil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2008",
          "Name": "Seevigaiyin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2009",
          "Name": "Thirukkurundha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2010",
          "Name": "Upadesithu AruLinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2011",
          "Name": "Namakkor",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2012",
          "Name": "Dhandanam seidharae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2013",
          "Name": "AruLaadhadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2014",
          "Name": "Vandhiyum varundhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2015",
          "Name": "KooliyaaLaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2016",
          "Name": "Enna vadhisayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2017",
          "Name": "Adithaanae paandiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2018",
          "Name": "Thazhumbhu pattadhuvae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2019",
          "Name": "Manna nee kaeLaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2020",
          "Name": "Uththamanaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2021",
          "Name": "Sundaresaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2022",
          "Name": "Thillai chidambara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2023",
          "Name": "Aananda Paravasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2024",
          "Name": "Vaadhavoorarum yezhundharulinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2025",
          "Name": "Naayakar aruL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vaiyacheri Ramaswamy Iyer/Kalidas Neelakanta Iyer/VS Gomathi Shankara Iyer",
          "Group": "Manikkavasagar Charithra Thamizh Isai Paadalgal",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 16 (Vaiyacheri Ramaswami Iyer)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1948",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2026",
          "Name": "Murugan ThuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2027",
          "Name": "Ini indha vaazhvil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2028",
          "Name": "Kaiyurai koNdu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2029",
          "Name": "Ekambara nadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2030",
          "Name": "vaEzha muganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2031",
          "Name": "Thaayinaik kaaNaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2032",
          "Name": "Yedhedho eNNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2033",
          "Name": "Anji Anjiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2034",
          "Name": "Jaya Sree Ramakrishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2035",
          "Name": "Kai Koduppaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2036",
          "Name": "Murugaa Un",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2037",
          "Name": "Yaen Padaiththaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadabrahmam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2038",
          "Name": "Thiruvae Undharul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2039",
          "Name": "Oru Naazhi Vaazhvaeninum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2040",
          "Name": "KaariruL serindhadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Raviswaroopini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2041",
          "Name": "Vidhai polavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2042",
          "Name": "Ambalathil aadum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2043",
          "Name": "Pirar sevaiyaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2044",
          "Name": "Idhuvon nee karuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malahari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2045",
          "Name": "Hari inba naamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2046",
          "Name": "Vetri aruLum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2047",
          "Name": "Mandira vadivanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2048",
          "Name": "Kadhir vaelavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2049",
          "Name": "Kalai inbamaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2050",
          "Name": "vazhikaatum bhavasalathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2051",
          "Name": "Neeyae ThuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2052",
          "Name": "Hari madhavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2053",
          "Name": "yezhai azhudha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2054",
          "Name": "Iraivan uraikovil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2055",
          "Name": "Amudhooru natramizh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2056",
          "Name": "Neengaadhendranai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2057",
          "Name": "Varuvaai endrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2058",
          "Name": "Aaroo nee yena ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2059",
          "Name": "Aiyaa naanundran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2060",
          "Name": "Azhindhidum inbathil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2061",
          "Name": "Kooriyondru kooriduvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2062",
          "Name": "Somanai aNipera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2063",
          "Name": "Indha vaedam kanDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2064",
          "Name": "Evvuyirkum anbu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavinodini",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2065",
          "Name": "PaambaNi piththanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2066",
          "Name": "Gnaana paNdithaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janasammodhini",
          "Thalam": "Mishra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2067",
          "Name": "Bhuvana Mohana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalathika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2068",
          "Name": "Ennai un adiyanaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "AmrithaDhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2069",
          "Name": "Unnai ninaindhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2070",
          "Name": "UnnaruL Illamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2071",
          "Name": "Sumaiperidhaendrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2072",
          "Name": "Sirumalarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2073",
          "Name": "Natana maadinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2074",
          "Name": "Eththanai piravigal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thatilathika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2075",
          "Name": "Annai vaaNiyin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Seelaangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2076",
          "Name": "Oruvarum Ariyaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saamandhadheeparam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2077",
          "Name": "Enthan padaginilor",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2078",
          "Name": "Ellam tharuvan bhayamillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2079",
          "Name": "Darisanam tharuvaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2080",
          "Name": "UnnaruLillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanaagari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2081",
          "Name": "Unmai indrarindhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Bangala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2082",
          "Name": "Vael vaangu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2083",
          "Name": "Anjael endru arulvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Swarnadeepakam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2084",
          "Name": "ThiruvuLLak karuthaenna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2085",
          "Name": "Sinamae Serunganalaagumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2086",
          "Name": "Veera vaazhvae vaazha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2087",
          "Name": "TharuNam idhu thaanayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2088",
          "Name": "Saevadi malar vaada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2089",
          "Name": "Endru nin thaer varum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vilasini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2090",
          "Name": "Sevadi malar vaada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2091",
          "Name": "VaeNNai thiruDi uNDa",
          "Type": "Padam",
          "Ragam": "Bhavapriya",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2092",
          "Name": "Indha vaeLaiyil",
          "Type": "Padam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2093",
          "Name": "Thinai maeyum",
          "Type": "Padam",
          "Ragam": "Mandari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2094",
          "Name": "Innamum umadhu nenjam",
          "Type": "Padam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2095",
          "Name": "Varuvaar Varuvaar",
          "Type": "Padam",
          "Ragam": "Kanaka Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2096",
          "Name": "Seriyirut kaanil",
          "Type": "Padam",
          "Ragam": "Bhagya sabaree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2097",
          "Name": "Kandhanai kaaNaamal",
          "Type": "Padam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2098",
          "Name": "Indha veedundran",
          "Type": "Padam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2099",
          "Name": "Vaazhiya Ulagam vaazhiya karuNai",
          "Type": "Padam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2100",
          "Name": "Mangalamae Shubha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "Mangalam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2101",
          "Name": "Aanai maamugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2102",
          "Name": "NaaDorum vazhipaduvomae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2103",
          "Name": "Sindhai seidhiduveer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MaargaHindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2104",
          "Name": "Nee ennai aandarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2105",
          "Name": "Jothiyae oLi sudar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2106",
          "Name": "Maraa malar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2107",
          "Name": "Kaaraikaar paeyaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "On Karaikal Ammaiyaar",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2108",
          "Name": "Thiruvaamoor pugazhanaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2109",
          "Name": "ThoNdar seer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Agni Kobam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2110",
          "Name": "VeNNai nallur vandhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2111",
          "Name": "aruNandhi sivappae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2112",
          "Name": "marai gnaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Piramodhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2113",
          "Name": "chaNdesar padam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2114",
          "Name": "Vithaganaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NataBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2115",
          "Name": "Naavalooril",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2116",
          "Name": "Thiruvadi dinam thozhuvom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2117",
          "Name": "Muthamizh mannavanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sunadavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2118",
          "Name": "aruL seidhidaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2119",
          "Name": "Nenjamae naadorum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vajragandhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2120",
          "Name": "mandra vaaNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2121",
          "Name": "yaeno kavalai unakku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2122",
          "Name": "Anjaadhae nenjae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Narayanadesatchi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2123",
          "Name": "Naanaenum oru poruL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2124",
          "Name": "Thunbam varum pozhuDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chitramani",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2125",
          "Name": "Aaindhoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ambhojini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2126",
          "Name": "pulangaLai adakka vaeNdum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NeelaMani",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2127",
          "Name": "Isai vaaNarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ThomaraDhaariNi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2128",
          "Name": "Kumara naayakaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2129",
          "Name": "Vandhu kaathidaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2130",
          "Name": "Unnaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2131",
          "Name": "Devi endra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2132",
          "Name": "Koodal maanagar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2133",
          "Name": "Kaar VaNNanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2134",
          "Name": "kalaimagaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2135",
          "Name": "Vazhi padal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Divyabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2136",
          "Name": "Tharaadhala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2137",
          "Name": "ThirukkuraLai ariyaadhaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2138",
          "Name": "Maanilamael maanidaraam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2139",
          "Name": "Oruvar poruLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PusangiNi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2140",
          "Name": "Aimperum paavangaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surabhushani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2141",
          "Name": "Cherukkoru podhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2142",
          "Name": "Nambinorkku nanmai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2143",
          "Name": "Mangaiyor",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2144",
          "Name": "Iravum pagalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagachudamani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2145",
          "Name": "UllaM KoLLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2146",
          "Name": "Vaedhanai endru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2147",
          "Name": "thiNai punadhndhanil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharaardhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2148",
          "Name": "Uyir naayagar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2149",
          "Name": "MaamalarthaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2150",
          "Name": "Poruththavar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2151",
          "Name": "Kobathai adaki aaLvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suryakantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2152",
          "Name": "kaLvaraayinar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ChaamandhachaaLavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2153",
          "Name": "Aasaiyai uraikkindraen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharaardhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2154",
          "Name": "Thamizhanukku yaen indhu pithu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2155",
          "Name": "Ilamai thaNNeer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Omkari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2156",
          "Name": "Thaayae karuNai purivAi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Savithri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2157",
          "Name": "kiLiyae unai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2158",
          "Name": "Inbam viLaivadhaengae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desovadhi",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2159",
          "Name": "Variyavar thamai arindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhuvanaranjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2160",
          "Name": "Soodhai mana ninaiyeer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2161",
          "Name": "Thoodhu sendru vaaraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Raviswaroopini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2162",
          "Name": "kaNNaena vaazhudhal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2163",
          "Name": "Kayavarai yaen padaiththai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaratnabhushani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2164",
          "Name": "Mozhiyinai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhavalakamchi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2165",
          "Name": "EndranuLLam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2166",
          "Name": "Vanjithu oruvarai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2167",
          "Name": "PoruL padaiththal mattum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2168",
          "Name": "Thamizh payiluveer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gothiraari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2169",
          "Name": "KadavuLai nambungal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuvalayaabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2170",
          "Name": "Patratra vaazha vanDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2171",
          "Name": "chaNpayil varubhavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaarmukhavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2172",
          "Name": "Otrumai mannugavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadagaththodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2173",
          "Name": "Sri Ganapathy unai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2174",
          "Name": "Surusuruppodu uzhaippom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chayavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2175",
          "Name": "VaanorgaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V Ramanathan Chettiyar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 13 (Devakottai V Ramanathan Chettiyar)",
          "Book Author": "N Swaminatha Pillai",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2176",
          "Name": "KaDavuLae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "JeyaNarayani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2177",
          "Name": "KalaivaaNiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Desi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2178",
          "Name": "Vandhen neramum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsabrahmari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2179",
          "Name": "ENNinaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2180",
          "Name": "Kaa Vaa Guhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2181",
          "Name": "Paadhukaathu ennai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2182",
          "Name": "Edhu vaeNdum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SaalagaBhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2183",
          "Name": "KaruNai Sei",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2184",
          "Name": "Unnakoru kurai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2185",
          "Name": "Vaa Vaa Raghavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2186",
          "Name": "En Seivomaena",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2187",
          "Name": "Unnakadi maiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2188",
          "Name": "Manamae kanavilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2189",
          "Name": "Aaraadhara unai allaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2190",
          "Name": "Naayae nuiyuum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanadam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2191",
          "Name": "Paerinba naayakanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2192",
          "Name": "Veedu Kidaikkum vazhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2193",
          "Name": "Ennakaar thuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MaargaHindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2194",
          "Name": "Thaarakam unai alladhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2195",
          "Name": "Innam manamillayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2196",
          "Name": "Inimael madavaarai ninaiyaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2197",
          "Name": "Murugaa krupaakaraa",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2198",
          "Name": "Saatchaath kaari",
          "Type": "Javali",
          "Ragam": "Gurupriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2199",
          "Name": "Shankaranae haranae",
          "Type": "Javali",
          "Ragam": "Lalithamanohari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2200",
          "Name": "Naadhaa karuNai seiguvaai",
          "Type": "Javali",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2201",
          "Name": "Kanda naathaa nee thaa",
          "Type": "Javali",
          "Ragam": "Saaraamathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2202",
          "Name": "Devi unaiyae",
          "Type": "Javali",
          "Ragam": "Sunadavinodini",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2203",
          "Name": "Samaya mudhavu",
          "Type": "Javali",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2204",
          "Name": "Vaelaayudhaa varadhaa",
          "Type": "Javali",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2205",
          "Name": "Manamae kavalayai",
          "Type": "Javali",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2206",
          "Name": "KaruNaakara DhidavaaraNa",
          "Type": "Javali",
          "Ragam": "Sree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2207",
          "Name": "Yaedhinamum manaththai",
          "Type": "Javali",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2208",
          "Name": "Ishtathodinamae manamae",
          "Type": "Javali",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2209",
          "Name": "Kandanaathan padam",
          "Type": "Javali",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2210",
          "Name": "Pugazhmaan magaL",
          "Type": "Javali",
          "Ragam": "Kapi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2211",
          "Name": "Vaelanai aNaindhaal",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2212",
          "Name": "Innum konjanaaL paaraDi",
          "Type": "Javali",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2213",
          "Name": "Enn naeramum en kaNgaL",
          "Type": "Javali",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2214",
          "Name": "Sammadhamaa vaelarae",
          "Type": "Javali",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2215",
          "Name": "Aasai aanaenae sahiyae",
          "Type": "Javali",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2216",
          "Name": "Adhi kobamaa aahaadhamaa",
          "Type": "Javali",
          "Ragam": "Shuddha Seemandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2217",
          "Name": "Enna seiguvaen ini",
          "Type": "Javali",
          "Ragam": "Darbar",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2218",
          "Name": "Vaadhae Seiyyalaagumaa",
          "Type": "Javali",
          "Ragam": "Mandari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2219",
          "Name": "Sollak kaeLammaa",
          "Type": "Javali",
          "Ragam": "RasaLi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2220",
          "Name": "IdhunaaL varai naan kooDi",
          "Type": "Javali",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2221",
          "Name": "Nayamaagavae irundhadhae",
          "Type": "Javali",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2222",
          "Name": "Avalae Nalla Mangaiyo",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2223",
          "Name": "Chumma perumaikkaaga",
          "Type": "Javali",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2224",
          "Name": "Maanae adhiga maiyalaanaenae",
          "Type": "Javali",
          "Ragam": "Rudrapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2225",
          "Name": "Varuvaano vaaraano",
          "Type": "Javali",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2226",
          "Name": "Konjam kaelamma",
          "Type": "Javali",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2227",
          "Name": "Anaeka thuthiyam",
          "Type": "Javali",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2228",
          "Name": "Ippo thazhaithu vaa",
          "Type": "Javali",
          "Ragam": "Punnagavarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2229",
          "Name": "Soramada vaarudanae",
          "Type": "Javali",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2230",
          "Name": "Sarikaamabhaavanai",
          "Type": "Surapadam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2231",
          "Name": "Jaadai paesakoodumo",
          "Type": "Surapadam",
          "Ragam": "MeghaRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2232",
          "Name": "Maalaanaen aNaivaai",
          "Type": "Surapadam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2233",
          "Name": "Paarthaennadhaan seivadhaam",
          "Type": "Surapadam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2234",
          "Name": "Kobam aagaadhamma",
          "Type": "Surapadam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2235",
          "Name": "Naanindha maiyal sagiyaenae",
          "Type": "Surapadam",
          "Ragam": "Namanarayani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2236",
          "Name": "Andha minnalae",
          "Type": "Surapadam",
          "Ragam": "PoornakaLanidhi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2237",
          "Name": "KaaNaenae sahiyae",
          "Type": "Surapadam",
          "Ragam": "Kalyanavasantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2238",
          "Name": "Ithanai aathiramaen",
          "Type": "Surapadam",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2239",
          "Name": "Kutramirundhaal",
          "Type": "Surapadam",
          "Ragam": "Bhushavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2240",
          "Name": "Aniyaayamaa nyaayamaa",
          "Type": "Surapadam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2241",
          "Name": "Ippodhae varacholladi",
          "Type": "Surapadam",
          "Ragam": "KannadaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 10 (Muthusamy Kaviyar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2242",
          "Name": "Thillai Chidambaramae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KapiNarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2243",
          "Name": "Dherisitha paerai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GandharvaManohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2244",
          "Name": "Deiveega thalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thirumoorthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2245",
          "Name": "Endha thalathaiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2246",
          "Name": "EnnaaLum Vaasamaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Seekandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2247",
          "Name": "pala mandhirathirukum Bhala mandiram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadamaaruvam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2248",
          "Name": "Parandhanai adaindhida",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gamanashrama",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2249",
          "Name": "Orukaal sivachidambaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2250",
          "Name": "Sivachidambaram endru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PinnaShadjam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2251",
          "Name": "Innamum oruthalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsavinodini",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2252",
          "Name": "Kaalai thooki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2253",
          "Name": "Unmugam paartha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Niranjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2254",
          "Name": "Yedhukkudhavi indha kaayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhogavasantham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2255",
          "Name": "Enna Dunivaai naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilanandi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2256",
          "Name": "VeeDum ambalam aagi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vilasini",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2257",
          "Name": "Enn pizhaippu undan pizhaippu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Velaveli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2258",
          "Name": "Ummaipol aaTai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BhanukeervaaNi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2259",
          "Name": "Innam vayiram unn nenjilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RathnaGandhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2260",
          "Name": "Enn naeramum kaalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2261",
          "Name": "Idhaivida gudhithu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MaLavaPanchamam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2262",
          "Name": "Ambalathu aadal nadippu enbhadhai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kowmaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2263",
          "Name": "Yaedhuku ithanai modi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamsaKhambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2264",
          "Name": "PaarungaL vandhu paarungaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasukari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2265",
          "Name": "Endha kaariyathuku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2266",
          "Name": "Yaen Indha paraku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiChandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthuPillai/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2267",
          "Name": "AruL Seivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2268",
          "Name": "Ennai aaLaiyya pannagaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KapiNarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2269",
          "Name": "paLLi yezhundharulveer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2270",
          "Name": "Bhuloka Kayilasam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2271",
          "Name": "Vandhaa dharitharul Seivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2272",
          "Name": "Varuvaai varuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2273",
          "Name": "Ellam therindhirundhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2274",
          "Name": "Dhayai seidhu neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2275",
          "Name": "Pathi seimanamae nidham nidhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2276",
          "Name": "VaaVaa VaaVaa Devaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2277",
          "Name": "Thaa Thaa Thaa Punidhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2278",
          "Name": "Raama Naama Dhyaanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2279",
          "Name": "Varuvaai dhayaaLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2280",
          "Name": "Saamiyai nee kootivaa",
          "Type": "Surasadhi",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2281",
          "Name": "Maalaanaen minnaethoodhu",
          "Type": "Surasadhi",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2282",
          "Name": "Seela samuga dhayaLa",
          "Type": "Surasadhi",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2283",
          "Name": "Paavaiyar thilaga maanavaL",
          "Type": "Surasadhi",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "SevarkuLam kandasamy pulavar/Muthuveerappa kavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2284",
          "Name": "Aanai Maamuganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2285",
          "Name": "Ulagil kaN kaNda deivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2286",
          "Name": "Ellam valla mona muzhumudhalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nattakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2287",
          "Name": "Sendhir padhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2288",
          "Name": "Sundaravai vaelan padamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2289",
          "Name": "Kanda naathanai kaaNalaam ippodhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2290",
          "Name": "Paadham nambinaen ayyanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2291",
          "Name": "Velaayudhanai maelaam maignaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2292",
          "Name": "Chitham iranga villaiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2293",
          "Name": "GuNakkundrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2294",
          "Name": "Devae suraloka sikhamaninay",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2295",
          "Name": "Seeralai vaayurai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V MuthuveeraKavirayar/TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 9 (Marimutha Pillai)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2296",
          "Name": "Murugan ThuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2297",
          "Name": "Ini indha vaazhvil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2298",
          "Name": "Kaiyurai koNdu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2299",
          "Name": "Ekambara nadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2300",
          "Name": "vaEzha muganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2301",
          "Name": "Thaayinaik kaaNaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2302",
          "Name": "Yedhedho eNNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2303",
          "Name": "Anji Anjiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2304",
          "Name": "Jaya Sree Ramakrishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2305",
          "Name": "Kai Koduppaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2306",
          "Name": "Murugaa Un",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2307",
          "Name": "Yaen Padaiththaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadabrahmam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2308",
          "Name": "Thiruvae Undharul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2309",
          "Name": "Oru Naazhi Vaazhvaeninum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2310",
          "Name": "KaariruL serindhadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Raviswaroopini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2311",
          "Name": "Vidhai polavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2312",
          "Name": "Ambalathil aadum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2313",
          "Name": "Pirar sevaiyaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2314",
          "Name": "Idhuvon nee karuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malahari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2315",
          "Name": "Hari inba naamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2316",
          "Name": "Vetri aruLum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2317",
          "Name": "Mandira vadivanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2318",
          "Name": "Kadhir vaelavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2319",
          "Name": "Kalai inbamaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2320",
          "Name": "vazhikaatum bhavasalathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2321",
          "Name": "Neeyae ThuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2322",
          "Name": "Hari madhavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2323",
          "Name": "yezhai azhudha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2324",
          "Name": "Iraivan uraikovil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2325",
          "Name": "Amudhooru natramizh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2326",
          "Name": "Neengaadhendranai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2327",
          "Name": "Varuvaai endrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2328",
          "Name": "Aaroo nee yena ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2329",
          "Name": "Aiyaa naanundran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2330",
          "Name": "Azhindhidum inbathil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2331",
          "Name": "Kooriyondru kooriduvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2332",
          "Name": "Somanai aNipera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2333",
          "Name": "Indha vaedam kanDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2334",
          "Name": "Evvuyirkum anbu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavinodini",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2335",
          "Name": "PaambaNi piththanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2336",
          "Name": "Gnaana paNdithaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janasammodhini",
          "Thalam": "Mishra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2337",
          "Name": "Bhuvana Mohana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalathika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2338",
          "Name": "Ennai un adiyanaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "AmrithaDhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2339",
          "Name": "Unnai ninaindhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2340",
          "Name": "UnnaruL Illamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2341",
          "Name": "Sumaiperidhaendrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2342",
          "Name": "Sirumalarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2343",
          "Name": "Natana maadinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2344",
          "Name": "Eththanai piravigal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thatilathika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2345",
          "Name": "Annai vaaNiyin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Seelaangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2346",
          "Name": "Oruvarum Ariyaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saamandhadheeparam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2347",
          "Name": "Enthan padaginilor",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2348",
          "Name": "Ellam tharuvan bhayamillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2349",
          "Name": "Darisanam tharuvaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2350",
          "Name": "UnnaruLillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanaagari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2351",
          "Name": "Unmai indrarindhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Bangala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2352",
          "Name": "Vael vaangu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2353",
          "Name": "Anjael endru arulvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Swarnadeepakam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2354",
          "Name": "ThiruvuLLak karuthaenna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2355",
          "Name": "Sinamae Serunganalaagumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2356",
          "Name": "Veera vaazhvae vaazha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2357",
          "Name": "TharuNam idhu thaanayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2358",
          "Name": "Saevadi malar vaada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2359",
          "Name": "Endru nin thaer varum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vilasini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2360",
          "Name": "Sevadi malar vaada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2361",
          "Name": "VaeNNai thiruDi uNDa",
          "Type": "Padam",
          "Ragam": "Bhavapriya",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2362",
          "Name": "Indha vaeLaiyil",
          "Type": "Padam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2363",
          "Name": "Thinai maeyum",
          "Type": "Padam",
          "Ragam": "Mandari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2364",
          "Name": "Innamum umadhu nenjam",
          "Type": "Padam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2365",
          "Name": "Varuvaar Varuvaar",
          "Type": "Padam",
          "Ragam": "Kanaka Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2366",
          "Name": "Seriyirut kaanil",
          "Type": "Padam",
          "Ragam": "Bhagya sabaree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2367",
          "Name": "Kandhanai kaaNaamal",
          "Type": "Padam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2368",
          "Name": "Indha veedundran",
          "Type": "Padam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2369",
          "Name": "Vaazhiya Ulagam vaazhiya karuNai",
          "Type": "Padam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2370",
          "Name": "Mangalamae Shubha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/N Sivaramakrishna Bhagavathar/VS Gomathi Shankarayyar",
          "Group": "Mangalam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 15 (Periyasamy Turan)",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2371",
          "Name": "Bhuloka Kayilasagiri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2372",
          "Name": "Sevika vaendum ayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2373",
          "Name": "Innam oru thalam innam oru kovil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2374",
          "Name": "Theertham mudhal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2375",
          "Name": "Chirsabhai thanilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2376",
          "Name": "Natanam kanDa podhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KamalaManohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2377",
          "Name": "Eesanae koDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2378",
          "Name": "Ambara Chidambara Sadhanandhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2379",
          "Name": "Nee dhayavaai ennai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2380",
          "Name": "Unai nambinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2381",
          "Name": "Ennai yenakku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2382",
          "Name": "Unn paadhamae thuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2383",
          "Name": "Santhatham unn paadhasevai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2384",
          "Name": "Siththar innam Undo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2385",
          "Name": "KaaNaamal Irundhaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2386",
          "Name": "kaNdavar viNdilarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2387",
          "Name": "Paadha dharisanam seiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasena",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2388",
          "Name": "AaDinadheppadiyo natanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2389",
          "Name": "AaDikkonDaar andha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2390",
          "Name": "Shambho Shankara Mahadeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2391",
          "Name": "AaDiya paadhaa iruvar aadiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2392",
          "Name": "Dherisitha aLavil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2393",
          "Name": "Maaya viththai seigiraanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2394",
          "Name": "Aatu kaalai thaarum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suvarnaangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2395",
          "Name": "Saami dharisanam kanDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushavali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2396",
          "Name": "AmbalavaaNar neerae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2397",
          "Name": "Aaraar aasai padaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2398",
          "Name": "Naanae muzhudhum nambinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2399",
          "Name": "Aiyanae natanam aadiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NataBhairavi",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2400",
          "Name": "Aadiya vaedikkai paareer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2401",
          "Name": "Nadipavanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shadvidhamaargini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2402",
          "Name": "Niruthanjseithaarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavahini",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2403",
          "Name": "Ananda thaandavam aadinar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2404",
          "Name": "KaaNaamal veeNilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2405",
          "Name": "Derisanam seivaenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2406",
          "Name": "kaNdapin kaNkuLirndhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2407",
          "Name": "Arumarundhoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2408",
          "Name": "Endru sendru KaaNbaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2409",
          "Name": "KaaNa mukthi paeralaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Namanarayani",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2410",
          "Name": "HaraHara ShivaShiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2411",
          "Name": "Yaarukku theriyum ayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natakapriya",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2412",
          "Name": "Sundara kunjitha paadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2413",
          "Name": "Thookiya paadhathin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2414",
          "Name": "Sivandha paadhathai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2415",
          "Name": "Avanae devar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanaagari",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2416",
          "Name": "Maravaadhiru nenjamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2417",
          "Name": "Sivanai maravaadhae ninaimanamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Seelaangi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2418",
          "Name": "Paesaadhae nenjamae paesaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suryakantham",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2419",
          "Name": "Chidambaramae ninai manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2420",
          "Name": "Derisanamae ninai manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2421",
          "Name": "Thothiram seimanamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2422",
          "Name": "Nirtham iduiththiru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2423",
          "Name": "Thaedi thiriyaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2424",
          "Name": "KaNdaayo manamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2425",
          "Name": "Sivachidambaramaedherisikka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2426",
          "Name": "Kaettu pogaadhae nenjae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2427",
          "Name": "Ini sumakka mudiyaadhu dhegam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2428",
          "Name": "Innam piravaamalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2429",
          "Name": "KalandhukoL vaenthillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2430",
          "Name": "Maanikkavaasagar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 4 (Muthuthandavar)",
          "Book Author": "Muthuthandavar",
          "Year of Publication": "1967",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2431",
          "Name": "Paadhamae Thunai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2432",
          "Name": "Thavamaevum thenpuli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2433",
          "Name": "Piravaadha varam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2434",
          "Name": "Irakkam varamal pona",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2435",
          "Name": "Thillai Thillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2436",
          "Name": "Thillai thalamaendru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2437",
          "Name": "Paeyaandi thanaik kanDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2438",
          "Name": "KaNdaen kali theerndhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2439",
          "Name": "Indha prathaapamum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2440",
          "Name": "Kanakasabhapathikku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2441",
          "Name": "Siva Chidambaramendru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2442",
          "Name": "Naanondrum ariyaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2443",
          "Name": "Kanaka sabhai thiru natanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2444",
          "Name": "Pichaikkaara vaesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2445",
          "Name": "Engae thaedi pidithaayadi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2446",
          "Name": "Guruvai paNindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2447",
          "Name": "Engal gurunaatharudaiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2448",
          "Name": "Piravaadha mukthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2449",
          "Name": "Eppo tholaiyum indha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2450",
          "Name": "Mosam vandhadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2451",
          "Name": "Chitham Chidambaraththae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2452",
          "Name": "Unadhu thiruvadi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2453",
          "Name": "ThaanDava dharisanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2454",
          "Name": "En naeramum undhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2455",
          "Name": "Thaedi alaigiraayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2456",
          "Name": "Kaividalaagaadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2457",
          "Name": "Aadiyapaadhaa iruvargal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2458",
          "Name": "Maaya vaazhkaiyai nambaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2459",
          "Name": "Aadiya paadhamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2460",
          "Name": "Thandhaalum tharattum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2461",
          "Name": "Dheham ippadiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2462",
          "Name": "Manadhu adanguvathaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2463",
          "Name": "Periyorae yaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2464",
          "Name": "Sharanaa gathi endru nambivandean",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2465",
          "Name": "Kanaka sabesan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2466",
          "Name": "Aadina thaandavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2467",
          "Name": "kaNNaalorudharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2468",
          "Name": "Arpudha natanam aadinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2469",
          "Name": "Araharasiva sankara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2470",
          "Name": "Thiruvadi charanamendru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2471",
          "Name": "Yedho theriyaamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2472",
          "Name": "Enna saami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2473",
          "Name": "Satrum achamillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/VS Gomathi Shankara Iyer/KS Narayana swamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2474",
          "Name": "Devaa jaganaathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2475",
          "Name": "Kirubai paaramma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2476",
          "Name": "Thandai thaai irundhaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2477",
          "Name": "Sivaloka enngira",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2478",
          "Name": "Inimaelaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2479",
          "Name": "Thaathai thaathai endru aadum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2480",
          "Name": "Ponnambala vaaNanadi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2481",
          "Name": "Orudhara maagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2482",
          "Name": "Dinam dinam nataraasar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2483",
          "Name": "Pagalaavadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2484",
          "Name": "Aanandha kadalaatum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2485",
          "Name": "kaNNalae kaNdaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/Chidambaram Rajarathna deekshitar/TN Sivasubramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2486",
          "Name": "Eppo varuvaaro endhan kalitheera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2487",
          "Name": "Natanamaadinaar iyer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2488",
          "Name": "Chidambaram endru orutharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2489",
          "Name": "Thillai chidambaram endrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2490",
          "Name": "Varuvaaro varam tharuvaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2491",
          "Name": "Aanandha koothaadinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2492",
          "Name": "Idhunalla samayamaiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2493",
          "Name": "Paarka Paarka Thigatumaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2494",
          "Name": "Thaa thai endraadruvaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2495",
          "Name": "Natanam adinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2496",
          "Name": "Kunchitha paadhathai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2497",
          "Name": "Dherisika vaenum chidambarathai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2498",
          "Name": "Paarthu pizhaiyungaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2499",
          "Name": "Innamum orudharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2500",
          "Name": "Pathi paNNi kondirunthal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2501",
          "Name": "ThonDarai KaaNNgilamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2502",
          "Name": "ThaLLu vaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2503",
          "Name": "Aadiya paadhathai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2504",
          "Name": "Indha sadhalam vandhavaaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2505",
          "Name": "Kanaka sabhapathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2506",
          "Name": "Arivudaiyor paNiNdhethum thillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2507",
          "Name": "Manadhae unakk",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2508",
          "Name": "Natana sabesa nadhi punai eesaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2509",
          "Name": "Darisanam kaNdaarku marujanmam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2510",
          "Name": "Podhum podhumaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2511",
          "Name": "Neesanaai pirandhalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2512",
          "Name": "Aadum Chidambaramo Ayyan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2513",
          "Name": "Thillai ambalaththaanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2514",
          "Name": "Shambho Gangadhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "EshaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2515",
          "Name": "Sopanam kanDukonDean",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2516",
          "Name": "Innam varak kaaNaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2517",
          "Name": "Aadiya paadham darisanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2518",
          "Name": "Thaa Thaa Thaa Nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2519",
          "Name": "Thenpuliyur",
          "Type": "KaNNi",
          "Ragam": "Pantuvarali",
          "Thalam": "Tisra Gathi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2520",
          "Name": "KaaNaamal irukka laagaadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi/VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 6 (Gopalakrishna Bharathi)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1944",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2521",
          "Name": "Vinayaka Charanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Paayiram)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2522",
          "Name": "Yaen Palli kondeerayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Paayiram)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2523",
          "Name": "Raja Raajarku raajan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2524",
          "Name": "PiLLai illadha bhaagyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2525",
          "Name": "Parabrahma swaroopamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2526",
          "Name": "Raamanai tharuvaai jaga rakshaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2527",
          "Name": "Raaman yaedhukku idharko",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2528",
          "Name": "Mosam varadhaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2529",
          "Name": "Yaaro ivar yaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2530",
          "Name": "Annai jaanaki vandhalae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Balakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2531",
          "Name": "Indha manu thara vaENum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2532",
          "Name": "Raamanukku mannan mudi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2533",
          "Name": "En bhaagyamae bhaagyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2534",
          "Name": "Naanae LakshmaNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2535",
          "Name": "Ithanai kobamum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2536",
          "Name": "Eppadi manam thuNindhadho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2537",
          "Name": "Ingae irungaaNum darisanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2538",
          "Name": "saErum gadhiyaal saEruvaEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Ayodhya kandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2539",
          "Name": "Arivaar yaar unnai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2540",
          "Name": "Irundhanae ramachandran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2541",
          "Name": "KaaNavaENum latcham kaNNgaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2542",
          "Name": "yaarendru raaghavanai eNNineer amma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2543",
          "Name": "enna seivaen seethai naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2544",
          "Name": "Raamaa nee inda kobam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Aaranyakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2545",
          "Name": "yaenindha madhikuriththaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2546",
          "Name": "Saami raghuraama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2547",
          "Name": "Aadi devanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2548",
          "Name": "Arasu seivadhenna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2549",
          "Name": "Varugiraano vaaraano",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Kishkindhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2550",
          "Name": "En kanavai keLaaiamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Sundarakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2551",
          "Name": "Agni bhagavaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Sundarakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2552",
          "Name": "Choodaamani kanDapodhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Sundarakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2553",
          "Name": "Indha vibeeshanan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2554",
          "Name": "CharanaM charaNam endraanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2555",
          "Name": "Raghavanae charaNam raghuraamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2556",
          "Name": "Indhaa vibeeshaNaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2557",
          "Name": "SonnaaL sree raaman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2558",
          "Name": "kaeLadaa raavaNanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2559",
          "Name": "Engae vaithirundhaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2560",
          "Name": "Indha yosanai yaen ayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2561",
          "Name": "yaen vandhaai thambhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2562",
          "Name": "en vaarthai thanai kaeL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2563",
          "Name": "Ayyanae unnmahimaiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2564",
          "Name": "Unadhu mahimai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2565",
          "Name": "yaedhukku varundhukiraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2566",
          "Name": "yeaLundhaaLae poongodhai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2567",
          "Name": "Vandhaan Vandhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2568",
          "Name": "Raaman yeazhundharuLinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2569",
          "Name": "Singaaram seidhaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2570",
          "Name": "Kaatchi yudanae raaman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "Ramanataka Keerthanaigal (Yuddhakandam)",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 8 (Arunachala Kavirayar)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1945",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2571",
          "Name": "Giriraja sutha thanayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BangaLa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2572",
          "Name": "Ela nee daya (Balakanakamaya)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2573",
          "Name": "Gurulekha Etuvanti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2574",
          "Name": "Maanamu laedha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2575",
          "Name": "Nagumomu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2576",
          "Name": "Dharini telusu konti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2577",
          "Name": "Mokshamu galadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saaraamathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2578",
          "Name": "Ksheerasagara shayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2579",
          "Name": "Manasu nil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2580",
          "Name": "Raminchu vaa raevaruraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Subhoshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2581",
          "Name": "RaRaa maa yinti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2582",
          "Name": "Eduta nilachi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2583",
          "Name": "Entha ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2584",
          "Name": "Jagadhanandakaraka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "Ghana Raga Pancharatnam",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2585",
          "Name": "Duduku gala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "Ghana Raga Pancharatnam",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2586",
          "Name": "Sadhinchanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "Ghana Raga Pancharatnam",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2587",
          "Name": "Kanakanaruchira",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "Ghana Raga Pancharatnam",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2588",
          "Name": "Entharo Mahanubhavulu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "Ghana Raga Pancharatnam",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2589",
          "Name": "Rajuvedala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2590",
          "Name": "Rama nannu brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2591",
          "Name": "Kaligiyuntae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2592",
          "Name": "Ganamurthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ganamurti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2593",
          "Name": "Rama nee samana mevaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2594",
          "Name": "Bhagayanayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chandrajyothi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2595",
          "Name": "Ramabhi rama ramaneeya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2596",
          "Name": "Nadhoupasana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2597",
          "Name": "Dunmaaraga saraadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2598",
          "Name": "Tera tyaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2599",
          "Name": "Nidhi chala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2600",
          "Name": "Rama katha sudha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2601",
          "Name": "Anuragamulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2602",
          "Name": "Meevalla guNadhosha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2603",
          "Name": "Paramaathmudu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vagadeeshwari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2604",
          "Name": "Brochaevarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2605",
          "Name": "Brovabarama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2606",
          "Name": "Ninnuvana naama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2607",
          "Name": "Vidajaaladura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2608",
          "Name": "Enthavde konduraghava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2609",
          "Name": "Orajupuju saethi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KannadaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2610",
          "Name": "Naenendhu vedhuku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2611",
          "Name": "Raamaa nee yaeda premarahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dileepakam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2612",
          "Name": "Geethaarthamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2613",
          "Name": "Raga sudha rasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2614",
          "Name": "Santhamu lekha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2615",
          "Name": "Rama Ramana raa raa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2616",
          "Name": "Telisi Rama chinthanatho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2617",
          "Name": "Nee naama roopamulaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Thyagaraja Swamy Krithis with meaning",
          "Book Author": "Kumbakonam Jayammal (Maha Vaidhyanatha Iyer music lineage)",
          "Year of Publication": "1995",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2618",
          "Name": "Thodudaiya (Nattapaadai)",
          "Type": "Thevaram",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thirubrahmapuram",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2619",
          "Name": "Kootraayina (Kolli)",
          "Type": "Thevaram",
          "Ragam": "Navaroj",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirunavukkarasar",
          "Group": "Kshetram-Thiruvadhigaiveerataanam",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2620",
          "Name": "Pithapirai soodi (IndhaLam)",
          "Type": "Thevaram",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy nayanar",
          "Group": "Kshetram-Thiruvennainaloor",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2621",
          "Name": "Vaeyuru thoLi bangan (PiyandhaiGandharam)",
          "Type": "Thevaram",
          "Ragam": "Navaroj",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2622",
          "Name": "Enna puNNiyam (Nattaraagam)",
          "Type": "Thevaram",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruvalanchuzhi",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2623",
          "Name": "Bandhaththaal (Viyazhakkurinji)",
          "Type": "Thevaram",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thirukkazhumalam",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2624",
          "Name": "Idarinum thaLarinum (Gandharapanchamam)",
          "Type": "Thevaram",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruvadudurai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2625",
          "Name": "Poovaar malark kondaDiyaar",
          "Type": "Thevaram",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruvannamalai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2626",
          "Name": "Poothaer thaayana (Pazhanthakkaragam)",
          "Type": "Thevaram",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruvoththoor",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2627",
          "Name": "Aadinaai naruneiyodu (GandharaPanchamam)",
          "Type": "Thevaram",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Koil",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2628",
          "Name": "Aala neezhalugandha (Pazhampanchuram)",
          "Type": "Thevaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruvaalavaai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2629",
          "Name": "Nandrudaiyaanai (Kurinji)",
          "Type": "Thevaram",
          "Ragam": "HariKhambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruchirappalli",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2630",
          "Name": "Neerusaervathor (Megharagakurinji)",
          "Type": "Thevaram",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruparaaithurai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2631",
          "Name": "Kaadhalaagi kasindhu (Kowsikam)",
          "Type": "Thevaram",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2632",
          "Name": "Utrumai searvadhu (Pazhampanchuram)",
          "Type": "Thevaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thirubrahmapuram",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2633",
          "Name": "Veedalaala vaayilaai (Kowsikam)",
          "Type": "Thevaram",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruvalavaai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2634",
          "Name": "Iththanai yaamaatrai (Nattaraagam)",
          "Type": "Thevaram",
          "Ragam": "Pantuvarali",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy nayanar",
          "Group": "Kshetram-ThirugurukaavoorveLLadai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2635",
          "Name": "Parasu paaNiyar (Pazhampanchuram)",
          "Type": "Thevaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshtram-Thiruppallavanneswaram",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2636",
          "Name": "yaerisaiyum vaDavaalin (Megharagakurinji)",
          "Type": "Thevaram",
          "Ragam": "Neelambari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruveezhimizhalai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2637",
          "Name": "Mangaiyar karasi vaLavarkon (Puraneermai)",
          "Type": "Thevaram",
          "Ragam": "Bhupalam",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thiruvalavaai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2638",
          "Name": "ThonDaranju kaLiru (Sevvazhi)",
          "Type": "Thevaram",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-Thirukkedaram",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2639",
          "Name": "Paththimaiyum aDimaiyum (Pazhampanchuram)",
          "Type": "Thevaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy nayanar",
          "Group": "Kshetram-Thiruvaroor",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2640",
          "Name": "meeLaa Adimai (Chendhuruthi)",
          "Type": "Thevaram",
          "Ragam": "Madhyamavathi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy nayanar",
          "Group": "Kshetram - Tiruvaroor",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2641",
          "Name": "kaloor perumaNam (andhALikkurinjin)",
          "Type": "Thevaram",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirugnanasambandar",
          "Group": "Kshetram-NaloorperumaNam",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2642",
          "Name": "Thaanaenai munpaDaiththaan (Panchamam)",
          "Type": "Thevaram",
          "Ragam": "Ahiri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sundaramoorthy nayanar",
          "Group": "Kshetram-ThirunoDiththaanmalai",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2643",
          "Name": "Vaikal poonkazhivaai (nattaragam)",
          "Type": "DivyaPrabandam-Thiruvaimozhi",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Nammazhvaar",
          "Group": "Kshetram-ThiruvaNvaNdoor",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2644",
          "Name": "seelamilla siriyanaelum (Pazhamthakkaragam)",
          "Type": "DivyaPrabandam-Thiruvaimozhi",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Nammazhvaar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2645",
          "Name": "vaNDuNU narumala (GandharaPanchamam)",
          "Type": "DivyaPrabandam-PeriyaThirumozhi",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirumangaiazhwar",
          "Group": "Kshetram-ThiruviNNagaram",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2646",
          "Name": "Poonilaaya vaindhumaai (Kowshikam)",
          "Type": "DivyaPrabandam-Thiruchchandaviruththam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirumazhisaipiraan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2647",
          "Name": "Maayakkoothaa (Pazhampanchuram)",
          "Type": "DivyaPrabandam-Thiruvaimozhi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nammazhvaar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2648",
          "Name": "Maanaei nokku nalleer (Panchamam)",
          "Type": "N/A",
          "Ragam": "Ahiri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Nammazhvaar",
          "Group": "Kshetram-Thiruvallavaazh",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2649",
          "Name": "Thiruththaai sempothae (Pazhanthakkaragam)",
          "Type": "DivyaPrabandam-Thirumangaiazhwar",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirumangaiazhwar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2650",
          "Name": "Ooraellam thunji (puraneermai)",
          "Type": "DivyaPrabandam-Thiruvaimozhi",
          "Ragam": "Bhowli",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Nammazhvaar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2651",
          "Name": "Kongalarandha (Kolli)",
          "Type": "DivyaPrabandam-PeriyaThirumozhi",
          "Ragam": "Navaroj",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Thirumangaiazhwar",
          "Group": "Kshetram-Thiruvenkatam",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2652",
          "Name": "Maanikkam katti (Megharagakurinji)",
          "Type": "DivyaPrabandam-PeriyazhwarThirumozhi",
          "Ragam": "Neelambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyazhvar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2653",
          "Name": "Thamaru mamaru",
          "Type": "Thirupugazh",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar",
          "Group": "Kshetram-Pazhani",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2654",
          "Name": "ParimaLa kaLabha",
          "Type": "Thirupugazh",
          "Ragam": "Khambhoji",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar",
          "Group": "Kshetram-Thiruchendur",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2655",
          "Name": "Kaadhi modhi vaadhaadu",
          "Type": "Thirupugazh",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2656",
          "Name": "koDaana maerumalai",
          "Type": "Thirupugazh",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2657",
          "Name": "vaasiththu kaaNo",
          "Type": "Thirupugazh",
          "Ragam": "Manirangu",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Arunagirinathar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tevaram, Naalaayira Divya Prabandham, Thiruppugazh",
          "Book Author": "MM Dandapani Desikar",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2658",
          "Name": "Maha Ganapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2659",
          "Name": "Gaanamutham thaa nee nidham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saalaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-37",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2660",
          "Name": "Kanaka mayura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jalaarnavam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-38",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2661",
          "Name": "Anaadha Rakshaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jhalavarali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-39",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2662",
          "Name": "Saami idhae nala samayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaneetham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-40",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2663",
          "Name": "Anjaadhae nenjamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paavani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-41",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2664",
          "Name": "Sadaananda Bhakthi thandhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Raghupriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-42",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2665",
          "Name": "Viraivaagavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gavambhodhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-43",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2666",
          "Name": "Senthiruvaelan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-44",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2667",
          "Name": "Vaelanae Vaerae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-45",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2668",
          "Name": "Antharanga bhakthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shadvidhamaargini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-46",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2669",
          "Name": "Ikaparasukha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suvarnaangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-47",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2670",
          "Name": "Appa murukaiya nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Divyamani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-48",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2671",
          "Name": "KaaruNya kandha gaanamudham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhavalambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-49",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2672",
          "Name": "Yaen manamae unakkindha bhayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Namanarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-50",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2673",
          "Name": "Kaa murugaiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-51",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2674",
          "Name": "Sami sadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-52",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2675",
          "Name": "Ikamae sukham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gamanashrama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-53",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2676",
          "Name": "Paramaanandha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vishvambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-54",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2677",
          "Name": "Kandhaa karpaga tharuvae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShyamaLanghi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-55",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2678",
          "Name": "Sukhamae Sukham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-56",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2679",
          "Name": "Unnai allaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-57",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2680",
          "Name": "Manadhae maravadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-58",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2681",
          "Name": "Kandha Bhaktha chinthamani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-59",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2682",
          "Name": "Mohanakara muthukumara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neethimathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-60",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2683",
          "Name": "Nadhasukham tharu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kanthamani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-61",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2684",
          "Name": "Gananya Desika",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-62",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2685",
          "Name": "Kaikooda VaeNumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-63",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2686",
          "Name": "Ikhapara sukha dhaayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-64",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2687",
          "Name": "Sadhaanandhamae tharum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-65",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2688",
          "Name": "Samagana lola",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chithrambari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-66",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2689",
          "Name": "Velumayilumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sucharithra",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-67",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2690",
          "Name": "Gaanaamudha paanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jyothiswaroopini",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-68",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2691",
          "Name": "Sukhakarasoma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhathuvardhani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-69",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2692",
          "Name": "Thandharul Ayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nasikabhushani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-70",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2693",
          "Name": "Kaa Guhaa Shanmukhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kosalam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-71",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2694",
          "Name": "AruL seiya vaENum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rasikapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-72",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2695",
          "Name": "Dhanthi maamukha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2696",
          "Name": "Vaa Vaa Shiki vaahanaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2697",
          "Name": "Mona gnana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2698",
          "Name": "Vaelavaa vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2699",
          "Name": "Maravaa thiru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2700",
          "Name": "Tharuvar nin aruL thiruvae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2701",
          "Name": "Kanaka vaela karuNaalavaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2702",
          "Name": "Vaa Sharavana bhavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2703",
          "Name": "Vaelaiyya dhayavillaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2704",
          "Name": "Krupaipaaraiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2705",
          "Name": "Sami idhae samayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2706",
          "Name": "Thaa Thaa nin paadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2707",
          "Name": "Maanae mohanm sahiyaenae",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2708",
          "Name": "VaravaeNum saminaatha",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2709",
          "Name": "Sami neeyae gathi",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2710",
          "Name": "Appanae Kabali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "on Mylapore Kabaleeswarar",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2711",
          "Name": "Eesan kanaka sabaesan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "on Chidambaram Sabanathar",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2712",
          "Name": "Shambho Shankara Sadashivaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "On Lord Shiva",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2713",
          "Name": "Rama ravikula somaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Lord Rama",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2714",
          "Name": "Sree Venugopala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Jhampai",
          "Praise of": "On Lord Venugopala",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2715",
          "Name": "Sami Deekshitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Roopakam",
          "Praise of": "on Muthuswamy Dikshitar",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2716",
          "Name": "Yaedhum ariyaenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2717",
          "Name": "Naadha thathva vinodha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2718",
          "Name": "Ulam ariyaadhaa Oyaadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2719",
          "Name": "AruL Paaraiyya",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2720",
          "Name": "MangaLam mayil vaahana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2721",
          "Name": "Geethaa amudhamae thaa sadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2722",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-23",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2723",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Varunapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-24",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2724",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "MaaraRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-25",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2725",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-26",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2726",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Sarasangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-27",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2727",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-28",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2728",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Naganandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-30",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2729",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Yagapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-31",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2730",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Ragavardhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-32",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2731",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Gangeyabhushani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-33",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2732",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Vagadeeshwari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-34",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2733",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Soolini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-35",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2734",
          "Name": "Chittaiswaram for Melakartha composition",
          "Type": "N/A",
          "Ragam": "Chalanaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "Melakartha Ragam-36",
          "Language": "Tamil",
          "Book Title": "Kanda Gana Amudam - Part 2",
          "Book Author": "Koteeswara Iyer",
          "Year of Publication": "1938",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2735",
          "Name": "Sama Natakam",
          "Type": "Drama",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Karnataka Sangetham - Part 1",
          "Book Author": "SR Kuppuswamy",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2736",
          "Name": "aruL purivAi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shuddhanandha Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Karnataka Sangetham - Part 1",
          "Book Author": "SR Kuppuswamy",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2737",
          "Name": "Niththam unnai vaeNDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "SR Kuppusamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Karnataka Sangetham - Part 2",
          "Book Author": "SR Kuppuswamy",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2738",
          "Name": "Indha sowkya mani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja swamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Karnataka Sangetham - Part 3",
          "Book Author": "SR Kuppuswamy",
          "Year of Publication": "1946",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2739",
          "Name": "Deva Deva Kalayamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2740",
          "Name": "Saahasigatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2741",
          "Name": "Gopa nandana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2742",
          "Name": "Bhavayae saarasanaabham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2743",
          "Name": "paalaya maamayi bho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2744",
          "Name": "Saarasaayatha lochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2745",
          "Name": "Raama paripaalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2746",
          "Name": "Padmanabha paalithaebha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2747",
          "Name": "Narasimha maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2748",
          "Name": "Paahi maamayi mukunda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhaari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2749",
          "Name": "Sree Padmanabha kalayithum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2750",
          "Name": "Sourae vidhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Sembada",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2751",
          "Name": "Kama janaka ripu gaNa madha hara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2752",
          "Name": "Sarasa sama mukha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2753",
          "Name": "Kaladhaa nava naashanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2754",
          "Name": "Padasanadhi muni jana manasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2755",
          "Name": "Saevae Sree kaantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MohanaKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2756",
          "Name": "Smara hari paadaaravindam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2757",
          "Name": "karuNakara madhava mamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2758",
          "Name": "Mandhara Dhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2759",
          "Name": "Smara sada manasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2760",
          "Name": "Raasa vilaasa lola",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2761",
          "Name": "Kanjanabha dhayayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2762",
          "Name": "Pankaja lochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2763",
          "Name": "Dhayaayaami Sree raghu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2764",
          "Name": "Gopalaka pahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2765",
          "Name": "Bhava Dheeyakathaabhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Shravanam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2766",
          "Name": "Thavaka naamaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Keerthanam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2767",
          "Name": "Sathatham samsmaraaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Smaranam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2768",
          "Name": "Pankajaksha thava saevaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Padasevanam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2769",
          "Name": "Aaraadhayaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Archanam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2770",
          "Name": "Vandae Deva Deva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Vandanam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2771",
          "Name": "Paramapursha nannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Dasyam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2772",
          "Name": "Bhavathi vishvaaso",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Sakhyam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2773",
          "Name": "Deva Deva kalpayaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Navaratna malika keerthanam (Atmanivedhanam)",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2774",
          "Name": "Kamalajaasya (Mohanam, Bilahari, Dhanyasi, Saranga, Madhyamavathi, Atana, Naatakurinji, Darbar, AnandaBhairavi, Sowrashtram)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Dasavathara Ragamalika",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2775",
          "Name": "Bhavayami Raghuramam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Ramayana keerthanam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2776",
          "Name": "Saa nandam (KamalaManohari, Hamsadhwani, Revagupti, Tarangini)",
          "Type": "Padyam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Chatur Ragamalika",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2777",
          "Name": "KalyaaNi kaluyath gathaa (Kalyani, Mohanam, Saranga, Shankarabharanam)",
          "Type": "Padyam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Chatur Ragamalika",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2778",
          "Name": "Raajeevaaksha bharo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2779",
          "Name": "Saliyae kunjana mo dhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BrindavanaSaranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Hindusthani Krithi",
          "Language": "Hindi",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2780",
          "Name": "Daevana kae pathi indra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa (Hindusthani)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Hindusthani Krithi",
          "Language": "Hindi",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2781",
          "Name": "Ramachandra prabhu ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi (Hindusthani)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Hindusthani Krithi",
          "Language": "Hindi",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2782",
          "Name": "Vishweshwara Darshana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi (Hindusthani)",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Hindusthani Krithi",
          "Language": "Hindi",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2783",
          "Name": "Soosa ganga bhasma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanashree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Hindusthani Krithi",
          "Language": "Hindi",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2784",
          "Name": "Dwiratha vadhana naakum",
          "Type": "Slokam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2785",
          "Name": "Pankaja naabho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2786",
          "Name": "Lokaabhi raama thanuvaam",
          "Type": "Slokam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2787",
          "Name": "Pancha saayaka (Utsavam 1)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2788",
          "Name": "Pankajaakshanaam (Utsavam 2)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2789",
          "Name": "Kanaka mayamaayeedum (Utsavam 3)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2790",
          "Name": "Andolika vaahanae (Utsavam 4)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2791",
          "Name": "Syaanandhu raeshvaran (Utsavam 5)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2792",
          "Name": "Indiraapathi (Utsavam 6)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2793",
          "Name": "Shibhi kayilaezhundhu (Utsavam 7)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MangalaKaishiki",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2794",
          "Name": "Neelapuri kuzhalaazho (Utsavam 8)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2795",
          "Name": "Sharadhinthi samamukha (Utsavam 9)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2796",
          "Name": "Naagashayananaam (Utsavam 10)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2797",
          "Name": "Sarasijanaabhanin (Samaapanam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "Utsava Prabandam",
          "Language": "Sanskrit",
          "Book Title": "Maharaja Swathi Thirunal Kritis - Part 1",
          "Book Author": "V. V. Sundararajan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2798",
          "Name": "Ninnukori",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2799",
          "Name": "Nera nammithi",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanadaa",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2800",
          "Name": "Vanajaakshaa",
          "Type": "Varnam-Tana",
          "Ragam": "Varali",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2801",
          "Name": "Sami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Narayani",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2802",
          "Name": "Thaani kori kaenu",
          "Type": "Varnam-Pada",
          "Ragam": "Vasantha",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2803",
          "Name": "Saamaja varadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2804",
          "Name": "Parama paavana raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2805",
          "Name": "Saraguna paalimpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2806",
          "Name": "Nee kaelanaayada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2807",
          "Name": "Nee paadhamulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2808",
          "Name": "Sath bhakthiyu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2809",
          "Name": "Sree raghukula nithim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2810",
          "Name": "Anudhinamunu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2811",
          "Name": "Sree venkatesam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2812",
          "Name": "Paarthasaarathi naanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2813",
          "Name": "Paripaalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2814",
          "Name": "Sathguru swamiki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2815",
          "Name": "Samayamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2816",
          "Name": "Ninnukolisi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2817",
          "Name": "Rama Ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2818",
          "Name": "Parula seva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NataBhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2819",
          "Name": "Nijamukha raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2820",
          "Name": "Raghunatha nannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SuraRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2821",
          "Name": "KaruNadhO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2822",
          "Name": "Vaega neevu",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2823",
          "Name": "Nirupamaana",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2824",
          "Name": "Marulukonnadhira",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2825",
          "Name": "Sarasamu",
          "Type": "Javali",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2826",
          "Name": "Paras Thillana",
          "Type": "Thillana",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2827",
          "Name": "Poornachandrika Thillana",
          "Type": "Thillana",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2828",
          "Name": "Thodi Thillana",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2829",
          "Name": "Yadukula Khambhoji Thillana",
          "Type": "Thillana",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2830",
          "Name": "Kaanada Thillana",
          "Type": "Thillana",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2831",
          "Name": "Kapi Thillana",
          "Type": "Thillana",
          "Ragam": "Kapi",
          "Thalam": "Lakshmeesa Thalam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2832",
          "Name": "Sami nee pai (Sarasangi, Saveri, Navaroj, Lalitha, Varali, Arabhi, Khamas, Bhupalam, Sree)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramnad (Poochi) Srinivasa Iyengar",
          "Group": "Navaratna Malika",
          "Language": "N/A",
          "Book Title": "Ramnad (Poochi) Srinivasa Iyengar's compositions",
          "Book Author": "N C Parthasarathy, Dwaraka Parthasarathy",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2833",
          "Name": "Koothaadum Ganapathiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2834",
          "Name": "Karpagambikae kanakangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kanakangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-1",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2835",
          "Name": "Ithanai azhago amma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rathnangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-2",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2836",
          "Name": "VeNu gaana moorthy",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ganamurti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-3",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2837",
          "Name": "punnai vanaspathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vanaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-4",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2838",
          "Name": "manamirangavillaiyaa manavati",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-5",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2839",
          "Name": "Taana roopi neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tanarupi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-6",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2840",
          "Name": "Sokkanudan porudhum senavathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Senavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-7",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2841",
          "Name": "parindhOdi vandhaen karpagamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-8",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2842",
          "Name": "Kaama Dhaenu karpagamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-9",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2843",
          "Name": "Ambala koothanin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natakapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-10",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2844",
          "Name": "Maangkuyil koovidum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-11",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2845",
          "Name": "Adhi roopavathi neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Roopavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-12",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2846",
          "Name": "Gaana gaayakapriya karpagamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gayakapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-13",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2847",
          "Name": "vakuLabharanam aNi vadivaambikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-14",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2848",
          "Name": "Thaayaana karpagamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-15",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2849",
          "Name": "marandhuyir vaazhvaeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-16",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2850",
          "Name": "kodaakodi sooryakaantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suryakantham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-17",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2851",
          "Name": "Poompattu ponpaavaadai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hatakambheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-18",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2852",
          "Name": "Singaara bhavani vanDhaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jhankaradhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-19",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2853",
          "Name": "Aananda natanam aadum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NataBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-20",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2854",
          "Name": "thanjam aDaindhaen thaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-21",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2855",
          "Name": "arahara mahaadevan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-22",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2856",
          "Name": "MangaLa gowri manohari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-23",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2857",
          "Name": "tharuNamidhuvae Arul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varunapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-24",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2858",
          "Name": "Maararanjani neeyumallavo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MaraRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-25",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2859",
          "Name": "yaarumilaen charukesi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-26",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2860",
          "Name": "Sarasam puriyum sarasangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-27",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2861",
          "Name": "karpoorahaarathiyin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-28",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2862",
          "Name": "Thirumayilai vaLarum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-29",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2863",
          "Name": "paagaai manam urugi paadinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naganandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-30",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2864",
          "Name": "Paadha pankaja malar nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yagapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-31",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2865",
          "Name": "Innisai poo raagam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavardhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-32",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2866",
          "Name": "Kandhanukku vael koduththa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gangeyabhushani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-33",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2867",
          "Name": "vaakaruLvaai vaagadheeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vagadeeshwari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-34",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2868",
          "Name": "Thiru mayilai vaLarum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Soolini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-35",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2869",
          "Name": "parvathaa chalanaaDan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chalanaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-36",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2870",
          "Name": "arumbu malar yaeduthu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Salagam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-37",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2871",
          "Name": "karuNai kadalae abiraami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jhalarnavam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-38",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2872",
          "Name": "maayaajhaalavarali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jhalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-39",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2873",
          "Name": "veNNai pol manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaneetham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-40",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2874",
          "Name": "Natanam aadum navarasa pavani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pavani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-41",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2875",
          "Name": "sooriyan thudhiththidum maariyamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Raghupriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-42",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2876",
          "Name": "Shantha nayakiyae charaNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gavambhodhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-43",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2877",
          "Name": "saambhavapriya manohaariNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-44",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2878",
          "Name": "Dheenabandhu neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-45",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2879",
          "Name": "thathuvamaanavaLae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shadvidhamaargini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-46",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2880",
          "Name": "swarnamayamaana swarnaanghi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suvarnaangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-47",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2881",
          "Name": "thirumayilai kabaali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Divyamani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-48",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2882",
          "Name": "thaayae dhavalaambari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhavalambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-49",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2883",
          "Name": "maamayilaapuri pooraNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Namanarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-50",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2884",
          "Name": "kamavardhini karpagambikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-51",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2885",
          "Name": "Sethuramapriya madhu karpagamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-52",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2886",
          "Name": "ennai kaakamanashrama maeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gamanashrama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-53",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2887",
          "Name": "akilaanda kodi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vishvambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-54",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2888",
          "Name": "maamayilai shyamaLaanghi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShyamaLanghi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-55",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2889",
          "Name": "kandanukku vel koduththa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-56",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2890",
          "Name": "simhendramadhyama vaahiNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-57",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2891",
          "Name": "imayamalaippadhi arasan pudhalviyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-58",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2892",
          "Name": "Dharma samvardhini Dharmavathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-59",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2893",
          "Name": "neethimathi maa mayilai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neethimathi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-60",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2894",
          "Name": "Kaanthaamani hariNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kanthamani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-61",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2895",
          "Name": "Nandhi Rishapriya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-62",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2896",
          "Name": "Kabaaliyai thaLuvum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-63",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2897",
          "Name": "mayilapuri vachaspathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-64",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2898",
          "Name": "mayilai kabali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-65",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2899",
          "Name": "Chithira poompaavadai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chithrambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-66",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2900",
          "Name": "Sundari karpaga sucharithra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sucharithra",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-67",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2901",
          "Name": "AruL JyothiswaroopiNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jyothiswaroopini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-68",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2902",
          "Name": "maadhu karpaga thadhuvardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thadhuvardhini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-69",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2903",
          "Name": "Muthu mookuthi alankari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nasikabhushani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-70",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2904",
          "Name": "Kosalam aanDiDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kosalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-71",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2905",
          "Name": "Gaana rasikapriya maana karpagamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rasikapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VeeraMani Iyer",
          "Group": "72 melakartha krithis on Karpagambal-72",
          "Language": "Tamil",
          "Book Title": "Mylapore (Thirumayilai) Karpagambal Krithis - 72 Melakartha",
          "Book Author": "VeeramaniAiyar (yazhpanam)",
          "Year of Publication": "2000",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2906",
          "Name": "varuvaar varuvaar endraen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2907",
          "Name": "thaiyalae unai ninaindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2908",
          "Name": "YaengaaNum varavara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2909",
          "Name": "ellaam arivaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2910",
          "Name": "Eththai kaNdu nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2911",
          "Name": "Eththanai sonnalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2912",
          "Name": "Padhari varugudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2913",
          "Name": "Ivan yaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Azhagar Kuravanji",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2914",
          "Name": "Manadhariyaamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2915",
          "Name": "Nadamaadi thirindha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Papavinasa Mudaliar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2916",
          "Name": "Thiruvotriyoor",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2917",
          "Name": "Ennaeramum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Marimuthu Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2918",
          "Name": "Thayae Yashoda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2919",
          "Name": "YaarPoi solluvaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2920",
          "Name": "Varuvaar azhaithuvaadi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ramalinga Swamigal",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2921",
          "Name": "Inimael varukkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2922",
          "Name": "Vaelavarae umaiththedi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2923",
          "Name": "Mugaththai kaatiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Papavinasa Mudaliar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2924",
          "Name": "Paeyaandi thanaik kanDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2925",
          "Name": "Ini enna paechchu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2926",
          "Name": "Ennida nadathaikkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2927",
          "Name": "Varuvaaraanaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2928",
          "Name": "Kaalaith thooki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "MarimuthaPillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2929",
          "Name": "Innamum orudharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2930",
          "Name": "Yaedhukkithanai moDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "MarimuthaPillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2931",
          "Name": "Thaanae varugiraaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2932",
          "Name": "Un Adhrishtam swami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2933",
          "Name": "Natanam adinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2934",
          "Name": "Adhuvum solluvaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2935",
          "Name": "Theruvil vaaraano",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2936",
          "Name": "Sevikka vaeNdum aiyyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2937",
          "Name": "Adi kondar andha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi (Khanda Gathi)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2938",
          "Name": "Arumarundhoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2939",
          "Name": "Yaen Palli kondeerayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "ArunachalaKavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2940",
          "Name": "Sundara kunjitha paadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2941",
          "Name": "Varuvaai varam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2942",
          "Name": "Maayavidhai seigiranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2943",
          "Name": "Ennai aaLaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2944",
          "Name": "KaaNaamal Irundhaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2945",
          "Name": "Sabhapathiku vaeru deivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2946",
          "Name": "Ambara Chidambara parampara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi (Khanda Gathi)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2947",
          "Name": "Innum paaraamugam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2948",
          "Name": "Chidambaram Arahara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2949",
          "Name": "Saami yezhai naanaiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2950",
          "Name": "Bhalamandirathirkkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "MarimuthaPillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2951",
          "Name": "AaDinadheppadiyo natanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2952",
          "Name": "Sivachidambaramendru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "MarimuthaPillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2953",
          "Name": "Ninaippadheppodhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Vedanayagam Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2954",
          "Name": "KaaNaamal veeNilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Muthuthandavar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2955",
          "Name": "Arut Jyothi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Ramalinga Swamigal",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2956",
          "Name": "Ennathaan sonnaaradi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Madhurakavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2957",
          "Name": "Kaasirundhal Ingae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2958",
          "Name": "Naetru raathiripona",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2959",
          "Name": "Unnaithoodhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Madhurakavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2960",
          "Name": "Idhai vida innumvaerae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Subbaramayyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2961",
          "Name": "Thathai mozhiyaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2962",
          "Name": "Innum en mael",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2963",
          "Name": "Ella ArumaigaLum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2964",
          "Name": "Nidhiraiyil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2965",
          "Name": "Yaarukkaagilum bhayamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Madhurakavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Keerthanangal",
          "Book Author": "Various",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2966",
          "Name": "swamin gajamukha vibho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "On Ganesha",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2967",
          "Name": "Swami mayuragiri vadivela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2968",
          "Name": "AruL Paaraiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Vishnu",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2969",
          "Name": "Arul seiyya vaeNum vaaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "On Saraswathi",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2970",
          "Name": "Vaada raajan kumaranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "On Hanuman",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2971",
          "Name": "KaTiyakaaran vandhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2972",
          "Name": "Kolamigum senghaN",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2973",
          "Name": "thirubhavani vandha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2974",
          "Name": "mandara dhara maadhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2975",
          "Name": "sengaNmaalazhagar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2976",
          "Name": "maruthodayu mizhaingar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2977",
          "Name": "Mohana maamayil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2978",
          "Name": "mohanavalli pandadhithanal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2979",
          "Name": "MangaLam saer solai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2980",
          "Name": "Ivan yaaro ariyaenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2981",
          "Name": "Madhiyai mayakku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2982",
          "Name": "MagaLennamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2983",
          "Name": "Sandhanak kuzhambheduthani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2984",
          "Name": "Mathae yangae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2985",
          "Name": "moha minjuthaenna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2986",
          "Name": "Mangaiyae nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2987",
          "Name": "Mohanavalli minnaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2988",
          "Name": "aNaiya vaarum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2989",
          "Name": "saEdasayanam seidhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2990",
          "Name": "Paavaiyae saami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedara Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2991",
          "Name": "kadalae endranai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2992",
          "Name": "van koDumai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2993",
          "Name": "madhana unnakkaenindha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2994",
          "Name": "malaik kuravanji",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2995",
          "Name": "Aavin kandrai madimisai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Tisra Nadai",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2996",
          "Name": "Engal saariyondrae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2997",
          "Name": "theertha mahimayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2998",
          "Name": "Kaarulaaviya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "2999",
          "Name": "ammaiyenkurith thiramai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3000",
          "Name": "Thanikkuri yuraikkirae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3001",
          "Name": "paar mezhughi kolam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Ata thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3002",
          "Name": "Ankuliya mitta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3003",
          "Name": "vaalaiarul thiru maganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3004",
          "Name": "mukkiyamaayi kuriyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Eka thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3005",
          "Name": "pootaganj seikiraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3006",
          "Name": "Vandhu seruvaar maanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3007",
          "Name": "thapip poda manmadhaaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Ata thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3008",
          "Name": "MaruthuLabha azhagar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3009",
          "Name": "solaimalai singanaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3010",
          "Name": "Thiru maalazhagar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3011",
          "Name": "Ambinai thoduthu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3012",
          "Name": "paravaigal varugudhaiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3013",
          "Name": "kuruginam pidikkavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3014",
          "Name": "paesaathadaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Ata thalam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3015",
          "Name": "mangaiyar panai kaatum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3016",
          "Name": "parandhodip poyinavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3017",
          "Name": "azhaithu vaadaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3018",
          "Name": "thaedich chendraanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3019",
          "Name": "Singhi enganam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3020",
          "Name": "Chekka sivandha nirathaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nondhi sindhu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3021",
          "Name": "thirumaal azhagarukku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kavi Kunjara Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Azhagar Kuravanji",
          "Book Author": "N. Kotteswara Iyer",
          "Year of Publication": "1963",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3022",
          "Name": "Sricha puthraya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kanakangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3023",
          "Name": "Sri gurum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rathnangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3024",
          "Name": "Paahi jagadeeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ganamurti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3025",
          "Name": "Eeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vanaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3026",
          "Name": "Sree Hanumantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3027",
          "Name": "Sree Raamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tanarupi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3028",
          "Name": "Jaalamela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Senavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3029",
          "Name": "Tyagaraja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3030",
          "Name": "Raavaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3031",
          "Name": "Paripaalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natakapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3032",
          "Name": "Vaadha mela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3033",
          "Name": "Paalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Roopavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3034",
          "Name": "Sree Mahavishnum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gayakapriya",
          "Thalam": "Khanda jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3035",
          "Name": "Kumaaruni valaenu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3036",
          "Name": "Shyamalambikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3037",
          "Name": "Girija Pathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3038",
          "Name": "Karuninchara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suryakantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3039",
          "Name": "Rakshaasumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hatakambheri",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3040",
          "Name": "Jashakedhana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jhankaradhwani",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3041",
          "Name": "Nalina nayani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NataBhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3042",
          "Name": "Sree Dakshinaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3043",
          "Name": "Parameshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3044",
          "Name": "Smararaechitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3045",
          "Name": "Samaashrayaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varunapriya",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3046",
          "Name": "Ramaapathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MaraRanjani",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3047",
          "Name": "Paradeveem",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3048",
          "Name": "Vandeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3049",
          "Name": "Smara maanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3050",
          "Name": "Sree Subramanyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3051",
          "Name": "Daakshaayani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naganandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3052",
          "Name": "Yashodeyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yagapriya",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3053",
          "Name": "Gaanasudhaarasamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavardhini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3054",
          "Name": "Paalayashumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gangeyabhushani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3055",
          "Name": "Pranamaamyaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vagadeeshwari",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3056",
          "Name": "Paalayaashumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Soolini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3057",
          "Name": "Nakaathmaja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chalanaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3058",
          "Name": "Vaarana vadhanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Salagam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3059",
          "Name": "Maheshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jalaarnavam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3060",
          "Name": "Maadhava dhayayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3061",
          "Name": "Himaathmajae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaneetham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3062",
          "Name": "Jayadanadasaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pavani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3063",
          "Name": "Kathilaka vamsa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Raghupriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3064",
          "Name": "Vinadhisae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gavambhodhi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3065",
          "Name": "Maadhava maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3066",
          "Name": "Karunanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3067",
          "Name": "Himavatheem",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shadvidhamaargini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3068",
          "Name": "Sree Raghupathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Suvarnaangi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3069",
          "Name": "Nowmithaavakeena",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Divyamani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3070",
          "Name": "Sree Vaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhavalambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3071",
          "Name": "Mahadeva paahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Namanarayani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3072",
          "Name": "Charanam thava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kamavardhini/Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3073",
          "Name": "Mahadevam anisham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3074",
          "Name": "Enni maarulu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gamanashrama",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3075",
          "Name": "Bho Shambho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vishvambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3076",
          "Name": "Shyamalangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShyamaLanghi",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3077",
          "Name": "Sada thava paadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3078",
          "Name": "Maamava maayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3079",
          "Name": "Tharunamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3080",
          "Name": "Vasamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3081",
          "Name": "Smaranam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neethimathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3082",
          "Name": "Bhuvaneshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kanthamani",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3083",
          "Name": "Nandeesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3084",
          "Name": "Taamralochani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3085",
          "Name": "Nudhindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3086",
          "Name": "Gathi neevae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3087",
          "Name": "Sree Raama Naumi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chithrambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3088",
          "Name": "Chinthayami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sucharithra",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3089",
          "Name": "Sree Gayathreem",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jyothiswaroopini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3090",
          "Name": "Mahesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhathuvardhani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3091",
          "Name": "Ambikaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nasikabhushani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3092",
          "Name": "O Manasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kosalam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3093",
          "Name": "Pavana thanaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rasikapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "72 melakartha ragam s",
          "Language": "N/A",
          "Book Title": "Janaka Raga Kruthi Manjari",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1952",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3094",
          "Name": "Saagara Sadhguna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3095",
          "Name": "Mahaadeva sutam aham pranamami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3096",
          "Name": "Aayakalaikkellam arasiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3097",
          "Name": "Sree Dakshinamurthim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3098",
          "Name": "Nee daya raadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3099",
          "Name": "Hari nee smaranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3100",
          "Name": "Daakshaayani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naganandhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3101",
          "Name": "Amba maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Ranjani, Niranjani, Janaranjani)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3102",
          "Name": "Kaavavae Kanya kumari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Chapu",
          "Praise of": "On Goddess Kanyakumari",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3103",
          "Name": "Thillana-Brindavani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Brindavani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3104",
          "Name": "Saraguna kaavu",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3105",
          "Name": "Siddhi naayakaena",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3106",
          "Name": "Kuruni smarimpumo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3107",
          "Name": "Yelae paalimpa jaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3108",
          "Name": "Akila deva nutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3109",
          "Name": "Vaegamae kaava raavaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3110",
          "Name": "Varuga Varuga maamayilaeriyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3111",
          "Name": "Bruhadeeshwara Mahadeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3112",
          "Name": "Pavana thanaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rasikapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3113",
          "Name": "Thillana-Hindolam",
          "Type": "Thillana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3114",
          "Name": "Aa baala gopaalamu",
          "Type": "Varnam-Tana",
          "Ragam": "Amrithavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3115",
          "Name": "Pirai aniyum perumaan maindhanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3116",
          "Name": "Gaana sudhaa rasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3117",
          "Name": "Vasamaa nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3118",
          "Name": "Shankarabharana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3119",
          "Name": "Nandeesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3120",
          "Name": "Thora vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3121",
          "Name": "Thaamralochani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3122",
          "Name": "Gaana maalinchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyanavasantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3123",
          "Name": "Thillana-Chakravaham",
          "Type": "Thillana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3124",
          "Name": "Ninnu nera nammithi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3125",
          "Name": "Mahaneeya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sumukham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3126",
          "Name": "Mahaneeya Madhoo moorthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mahathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3127",
          "Name": "Sadaa thava paada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3128",
          "Name": "Kamaladalaaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3129",
          "Name": "Endha naeramum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3130",
          "Name": "Aayar kula seyaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3131",
          "Name": "Gaana rasikae jagaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3132",
          "Name": "Bangaru murali shringara ravali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3133",
          "Name": "Thillana-Dwijavanthi",
          "Type": "Thillana",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3134",
          "Name": "Omkaara pranava",
          "Type": "Varnam-Tana",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3135",
          "Name": "Paahiprabho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3136",
          "Name": "Saama Gaana Saarva bhowma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Roopakam",
          "Praise of": "On Tyagaraja Swami",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3137",
          "Name": "Kannanaenum podhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3138",
          "Name": "Charanam Thava Charanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3139",
          "Name": "Gathi neevae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3140",
          "Name": "Sree Kaarunya roopini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3141",
          "Name": "Mannavan ennai pirindhae",
          "Type": "Padam",
          "Ragam": "Neelambari",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3142",
          "Name": "Endrum thunai neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3143",
          "Name": "Mangalam Himathanayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Dr M Balamurali Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Murali Ravali",
          "Book Author": "Dr. M. Balamurali krishna",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3144",
          "Name": "Needaya radha neerajakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3145",
          "Name": "Karimukha varada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "On Ganesha",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3146",
          "Name": "Saraswathi Namousthuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Roopakam",
          "Praise of": "On Saraswathi",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3147",
          "Name": "Nee Paadhamae Gathi Nalina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Roopakam",
          "Praise of": "On Goddess Kanthimathi",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3148",
          "Name": "Niradhamukha ninne sharanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3149",
          "Name": "Nee charanaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3150",
          "Name": "Sadaa paalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3151",
          "Name": "Paada Bhakana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3152",
          "Name": "Paaraamukha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3153",
          "Name": "Ninnuvinaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3154",
          "Name": "Sivaananda Kaamavardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3155",
          "Name": "Maravanunae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3156",
          "Name": "Nee allal inni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3157",
          "Name": "Naagabhaya vara ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3158",
          "Name": "Shankara mahadeva manohari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "On Goddess Parvathi",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3159",
          "Name": "Maarakoti sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3160",
          "Name": "Neesamaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3161",
          "Name": "Nee daya galkunae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3162",
          "Name": "Ranjani niranjani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3163",
          "Name": "Porumaiyai izhandhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "On Goddess Parvathi-Bhuvaneshwari",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3164",
          "Name": "Kamalaasani Sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3165",
          "Name": "Taamasa miga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3166",
          "Name": "Gathiverevaramma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3167",
          "Name": "Thillana-Hamsanandi",
          "Type": "Thillana",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Gaana Bhaskara Manimaalai",
          "Book Author": "G. N. Balasubramaniam",
          "Year of Publication": "1956",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3168",
          "Name": "Namaami Sriman mahadevendra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chandrachuda",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
          "Book Author": "N. Channakesaviah",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3169",
          "Name": "Sri Ramana Vijitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
          "Book Author": "N. Channakesaviah",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3170",
          "Name": "Thappaga Nannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
          "Book Author": "N. Channakesaviah",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3171",
          "Name": "Sthri veshamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
          "Book Author": "N. Channakesaviah",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3172",
          "Name": "Saagara kanyaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
          "Book Author": "N. Channakesaviah",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3173",
          "Name": "Sri Raghukula jalathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
          "Book Author": "N. Channakesaviah",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3174",
          "Name": "Kamalakaantha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Some rare and unpublished keerthanas of Mysore Sadashiva Rao",
          "Book Author": "N. Channakesaviah",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3175",
          "Name": "Devadideva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Roopakam",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3176",
          "Name": "Na Tarama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3177",
          "Name": "Pannagasayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3178",
          "Name": "Endu Dachu-konnavu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3179",
          "Name": "Nami Sree Sathyavijaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "On Sri Sathya Vijayatirthar",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3180",
          "Name": "Samrajya Daya Kesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Ekambreshwarar",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3181",
          "Name": "Vachamagocha-rudani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "On Triplicane Parthasarathy perumal",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3182",
          "Name": "Ninnu nera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3183",
          "Name": "Ninnu vinaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3184",
          "Name": "Bhajanaseyavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Roopakam",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3185",
          "Name": "Vanajaksha ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNattai",
          "Thalam": "Adi",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3186",
          "Name": "Nikepudu daya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3187",
          "Name": "Narasimhudu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KamalaManohari",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "On Narasimha",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3188",
          "Name": "Saketha Nagara Natha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "On Rama",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3189",
          "Name": "Sri Parthasarathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "On Triplicane Parthasarathy perumal",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3190",
          "Name": "Paramaatbuthamaina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "On Sriram Ranganathar",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3191",
          "Name": "Sree Raamabhi raama",
          "Type": "Swarajathi",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "s of Mysore Sadashiva Rao",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "1947",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3192",
          "Name": "Thillana-Poorvikalyani",
          "Type": "Thillana",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3193",
          "Name": "Kanugoni",
          "Type": "N/A",
          "Ragam": "Kalyani",
          "Thalam": "N/A",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3194",
          "Name": "Kamalaakaanta susvanta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3195",
          "Name": "Peddadevudani ninnu nammitira",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3196",
          "Name": "Emaguva Bodhinchera",
          "Type": "Varnam-Pada",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "On Krishnarajendra Wodeyar",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3197",
          "Name": "Rajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3198",
          "Name": "Sri Ramana neeve gatiyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoornaShadjam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3199",
          "Name": "Dorekanu nedu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhari",
          "Thalam": "Adi",
          "Praise of": "On Lord Krishna",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3200",
          "Name": "Sri Kamakoti Peetasthithe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3201",
          "Name": "Manamuleda nayada neeku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "On Lord Krishna",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3202",
          "Name": "Tyagaraja swami vedalina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "N/A",
          "Praise of": "On Tyagaraja Swami",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3203",
          "Name": "Sri Lakshmiramana paramaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Sriram Ranganathar",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3204",
          "Name": "Ramuni nammina janulaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3205",
          "Name": "Rama ninnu nammina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3206",
          "Name": "Sri Subramanya Lokasharanya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "On Palani Subramanyar",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3207",
          "Name": "Rama kathaa saagarameede",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3208",
          "Name": "Samayamidae nannu brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3209",
          "Name": "Mrokkeda nee padamulaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3210",
          "Name": "Mangalam Sri Ramanukku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3211",
          "Name": "Bhujallarae pundarikakshinupai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3212",
          "Name": "Dorikenu needu srikrishnudu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3213",
          "Name": "Emani varnintunamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3214",
          "Name": "Maha Ganapathy",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Sankeerna Chapu",
          "Praise of": "On Ganesha",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3215",
          "Name": "Maal marugan Murugan",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3216",
          "Name": "Natajanapaalini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "On Parvathi",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3217",
          "Name": "Pada sarojamula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "On Parvathi",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3218",
          "Name": "Sharavana Bhava guha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3219",
          "Name": "Porutharulvaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "On Bhoomaadevi",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3220",
          "Name": "Paripoorana Chandra vadhani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "On Kamakshi",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3221",
          "Name": "Muruga thirumaal marugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "On Muruga",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3222",
          "Name": "Manasaara MadhiyaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saaraamathi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3223",
          "Name": "Ezhiludai hamsanadam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanadam",
          "Thalam": "Adi",
          "Praise of": "On Raga Hamsanadam",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3224",
          "Name": "Geetha Vaadya natana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natakapriya",
          "Thalam": "Adi",
          "Praise of": "On Devi",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3225",
          "Name": "Mahadeva Shiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revathi",
          "Thalam": "Adi",
          "Praise of": "On Shiva",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3226",
          "Name": "Ranjani Maalaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Ranjani, SriRanjani, Megharanjani, Janaranjani)",
          "Thalam": "Adi",
          "Praise of": "On Devi",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3227",
          "Name": "Raama naamamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "On Rama",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3228",
          "Name": "Mandhirkugandhadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sindhubhairavi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3229",
          "Name": "Manamalar aLiththu",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3230",
          "Name": "Thillana-AnandaBhairavi",
          "Type": "Thillana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Shiva",
          "Composer": "Tanjavur V Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Tanjavur V Shankara Iyer",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3231",
          "Name": "Shree Ganeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "On Ganesha",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - Ganesha Sthuthi",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3232",
          "Name": "Vaanchhasi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - Dhyana Sthuthi",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3233",
          "Name": "Santatam aham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desakshi",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 1st avaranam (trailokya mohana chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3234",
          "Name": "Bhajasva shree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 2nd avaranam (sarvaashaa paripooraka chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3235",
          "Name": "Sarva jeeva dayaakari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 3rd avaranam (Sarvasamkshobhana chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3236",
          "Name": "Yogayogeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 4th avaranam (sarva sowbhaagya daayaka chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3237",
          "Name": "neela lohita",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Khanda jathi Dhuruva Thalam",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 5th avaranam (sarvaartha saadhaka chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3238",
          "Name": "sadaanandamayi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Sankeerna Jathi Matya Thalam",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 6th avaranam (sarva rakshaakara chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3239",
          "Name": "sakala loka naayikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 7th avaranam (sarva rogahara chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3240",
          "Name": "sankari sree rajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 8th avaranam (sarva siddhi pradaayaka chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3241",
          "Name": "natajana kalpavalli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - 9th avaranam (sarva aanandamaya chakram)",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3242",
          "Name": "haladharaanujam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "On Kanchipuram Kamakshi",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Kamakshi Navavarna - Phala Sthuthi",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3243",
          "Name": "Bhajanaamruta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Sapta Rathna Kruthi - 1",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3244",
          "Name": "aganita mahimaadbhuta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Sapta Rathna Kruthi - 2",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3245",
          "Name": "maadhava hrudhi kelini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Sapta Rathna Kruthi - 3",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3246",
          "Name": "baala sarasa muralee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Sapta Rathna Kruthi - 4",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3247",
          "Name": "jataadhara shankara deva deva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Sapta Rathna Kruthi - 5",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3248",
          "Name": "aalaavadhennaalo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Sapta Rathna Kruthi - 6",
          "Language": "Tamil",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3249",
          "Name": "sundaranandakumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "OothukaduVenkataKavi",
          "Group": "Sapta Rathna Kruthi - 7",
          "Language": "N/A",
          "Book Title": "OVK's Kamakshi Navavarnam and Sapta Rathna Kritis",
          "Book Author": "Chitravina N Ravikiran",
          "Year of Publication": "2007",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3250",
          "Name": "Vela tenparangirilolaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3251",
          "Name": "Moolaadhara devaaya namasthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3252",
          "Name": "Eththanai naaL aagum un",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gandharvamanohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3253",
          "Name": "Kalangaadhiru nenjamae nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3254",
          "Name": "Nandagopa baalanae nata jana paripaalanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3255",
          "Name": "Tunaiyagum engaL kula deyva ganapathy",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3256",
          "Name": "Iniyaagilum undan inaiyadi malarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3257",
          "Name": "Aalavaayamar meenamma adimai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manohari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3258",
          "Name": "Taamadam tagaadhayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3259",
          "Name": "Aadhi mudalvaa amararkadhibaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3260",
          "Name": "Thirumurugaa yena uraiththidu dhinamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3261",
          "Name": "Poovilurai vedan puyamsaer vaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sharadabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3262",
          "Name": "Saarasa dala nayana shrikrishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3263",
          "Name": "Paavanan neeyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Uttari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3264",
          "Name": "pranava mudalvanae vinaayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Lalgudi V R Gopala Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3265",
          "Name": "pugalvaay neeallaal puvithanil",
          "Type": "Varnam-Tana",
          "Ragam": "Amrithavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3266",
          "Name": "Thirumaal marugaa un thirunaamam",
          "Type": "Varnam-Tana",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3267",
          "Name": "Jalajaaksha nee paadhamae",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3268",
          "Name": "Raamanai raghu naadanai nidam",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3269",
          "Name": "Entho premathonu",
          "Type": "Varnam-Tana",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3270",
          "Name": "Arunodayamae anbin",
          "Type": "Varnam-Tana",
          "Ragam": "Bowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3271",
          "Name": "Devi un paadamae paNindom",
          "Type": "Varnam-Tana",
          "Ragam": "Devagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3272",
          "Name": "Parama karunaa saagara",
          "Type": "Varnam-Tana",
          "Ragam": "Garudadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3273",
          "Name": "Innum taamadameno dayapariyae",
          "Type": "Varnam-Tana",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3274",
          "Name": "Engum nirai deivamae",
          "Type": "Varnam-Tana",
          "Ragam": "Hamsavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3275",
          "Name": "Kannanai manivannanai",
          "Type": "Varnam-Tana",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3276",
          "Name": "Unnaiandri veraar ulaginil",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3277",
          "Name": "Inta Tamasa",
          "Type": "Varnam-Tana",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3278",
          "Name": "Nivegani verevarunnaaru",
          "Type": "Varnam-Tana",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3279",
          "Name": "Vallabhai nayaka vandanam",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3280",
          "Name": "Karunai puriya vaenum kamakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3281",
          "Name": "Nivae gatiyani niratamu",
          "Type": "Varnam-Tana",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3282",
          "Name": "Tharunam en thaye",
          "Type": "Varnam-Tana",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3283",
          "Name": "Parivudan Bhakthargal",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3284",
          "Name": "Naada swaroopini un paadam panivom",
          "Type": "Varnam-Tana",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3285",
          "Name": "Chalamu seya",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3286",
          "Name": "Nambum anbarkkarulum",
          "Type": "Varnam-Tana",
          "Ragam": "Varamu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3287",
          "Name": "Innam en manam",
          "Type": "Varnam-Pada",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3288",
          "Name": "Chendhil nagar mevum",
          "Type": "Varnam-Pada",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3289",
          "Name": "Angayarkkanni anandam - Navarasa Varnam",
          "Type": "Varnam-Pada",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "Navarasa varnam",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3290",
          "Name": "Devar munivar thozhum",
          "Type": "Varnam-Pada",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3291",
          "Name": "Sree jagadeeshwari durga maata",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahir Bhairav",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3292",
          "Name": "Nee dayai seiyyavidil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3293",
          "Name": "Vinakayunnadela sada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3294",
          "Name": "Thenmadurai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsaroopini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3295",
          "Name": "Kandan seyal andro yaavum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3296",
          "Name": "Tharizhaippadhi en iyalbandro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragavardhini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3297",
          "Name": "Unnaiandru veror pugalum undo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saaraamathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3298",
          "Name": "Kumaara guruguham bhaavayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3299",
          "Name": "Thillana-AnandaBhairavi",
          "Type": "Thillana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3300",
          "Name": "Thillana-Behag",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3301",
          "Name": "Thillana-Bhageshri",
          "Type": "Thillana",
          "Ragam": "Bhageshri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3302",
          "Name": "Thillana-Bhimplas",
          "Type": "Thillana",
          "Ragam": "Bhimplas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3303",
          "Name": "Thillana-Bindumalini",
          "Type": "Thillana",
          "Ragam": "Bindumalini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3304",
          "Name": "Thillana-Brindavani",
          "Type": "Thillana",
          "Ragam": "Brindavani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3305",
          "Name": "Thillana-Chenchurutti",
          "Type": "Thillana",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3306",
          "Name": "Thillana-DarbariKaanada",
          "Type": "Thillana",
          "Ragam": "DarbariKaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3307",
          "Name": "Thillana-Desh",
          "Type": "Thillana",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3308",
          "Name": "Thillana-Durga",
          "Type": "Thillana",
          "Ragam": "Durga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3309",
          "Name": "Thillana-Dwijavanthi",
          "Type": "Thillana",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3310",
          "Name": "Thillana-Hameer Kalyani",
          "Type": "Thillana",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3311",
          "Name": "Thillana-Hamsanandi",
          "Type": "Thillana",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3312",
          "Name": "Thillana-Kathanakuthuhalam",
          "Type": "Thillana",
          "Ragam": "Kathanakuthuhalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3313",
          "Name": "Thillana-Kalyanavasantham",
          "Type": "Thillana",
          "Ragam": "Kalyanavasantham",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3314",
          "Name": "Thillana-Khamas",
          "Type": "Thillana",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3315",
          "Name": "Thillana-Kaanadaa",
          "Type": "Thillana",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3316",
          "Name": "Thillana-Kapi",
          "Type": "Thillana",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3317",
          "Name": "Thillana-Karnaranjani",
          "Type": "Thillana",
          "Ragam": "KarnaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3318",
          "Name": "Thillana-Kuntalavarali",
          "Type": "Thillana",
          "Ragam": "Kuntalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3319",
          "Name": "Thillana-Madhuvanthi",
          "Type": "Thillana",
          "Ragam": "Madhuvanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3320",
          "Name": "Thillana-Mishra Maand",
          "Type": "Thillana",
          "Ragam": "Mishra Maand",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3321",
          "Name": "Thillana-Mishra Sivaranjani",
          "Type": "Thillana",
          "Ragam": "Mishra Sivaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3322",
          "Name": "Thillana-Mohanakalyani",
          "Type": "Thillana",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3323",
          "Name": "Thillana-Nagaswarali",
          "Type": "Thillana",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3324",
          "Name": "Thillana-Nalinakanthi",
          "Type": "Thillana",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3325",
          "Name": "Thillana-Pahadi",
          "Type": "Thillana",
          "Ragam": "Pahadi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3326",
          "Name": "Thillana-Priyadarshini",
          "Type": "Thillana",
          "Ragam": "Priyadarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3327",
          "Name": "Thillana-Rageshri",
          "Type": "Thillana",
          "Ragam": "Rageshri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3328",
          "Name": "Thillana-Revathi",
          "Type": "Thillana",
          "Ragam": "Revathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3329",
          "Name": "Thillana-Sindhu Bhairavi",
          "Type": "Thillana",
          "Ragam": "Sindhubhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3330",
          "Name": "Thillana-Thilang",
          "Type": "Thillana",
          "Ragam": "Thilang",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3331",
          "Name": "Thillana-Vasantha",
          "Type": "Thillana",
          "Ragam": "Vasantha",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3332",
          "Name": "Thillana-Vaasanthi",
          "Type": "Thillana",
          "Ragam": "Vaasanthi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3333",
          "Name": "Thillana-YamunaKalyani",
          "Type": "Thillana",
          "Ragam": "YamunaKalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3334",
          "Name": "Jathiswaram-Behag",
          "Type": "Jathiswaram",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3335",
          "Name": "Pushpanjali-Gambhiranaatai",
          "Type": "Pushpanjali",
          "Ragam": "GambiraNattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3336",
          "Name": "Jathiswaram-Rasikapriya",
          "Type": "Jathiswaram",
          "Ragam": "Rasikapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3337",
          "Name": "Jathiswaram-Sindhubhairavi",
          "Type": "Jathiswaram",
          "Ragam": "Sindhubhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Lalgudi's Creations - Lalgudi 90 Edition",
          "Book Author": "Lalgudi G Jayaraman",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3338",
          "Name": "Nee paadhamae Gathi yani",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pazhani R Subramanya Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Pazhani R Subramanya Bhagavathar",
          "Book Author": "K Nagaratnammal",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3339",
          "Name": "Swami naapai dayayunchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pazhani R Subramanya Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Pazhani R Subramanya Bhagavathar",
          "Book Author": "K Nagaratnammal",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3340",
          "Name": "Bruhaddeshwara prana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pazhani R Subramanya Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Pazhani R Subramanya Bhagavathar",
          "Book Author": "K Nagaratnammal",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3341",
          "Name": "Sree Subramanya Shrutha jana paala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pazhani R Subramanya Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Pazhani R Subramanya Bhagavathar",
          "Book Author": "K Nagaratnammal",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3342",
          "Name": "Sthala nammidhira shankari thanaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pazhani R Subramanya Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Pazhani R Subramanya Bhagavathar",
          "Book Author": "K Nagaratnammal",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3343",
          "Name": "paalimpa vamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Pazhani R Subramanya Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Pazhani R Subramanya Bhagavathar",
          "Book Author": "K Nagaratnammal",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3344",
          "Name": "Thillana-Vasantha",
          "Type": "Thillana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pazhani R Subramanya Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Pazhani R Subramanya Bhagavathar",
          "Book Author": "K Nagaratnammal",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3345",
          "Name": "Apadhoorugu",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3346",
          "Name": "Adhineepai",
          "Type": "Javali",
          "Ragam": "YamanKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3347",
          "Name": "Apudumanasu",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3348",
          "Name": "Idhineegu",
          "Type": "Javali",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3349",
          "Name": "Ilaakuna",
          "Type": "Javali",
          "Ragam": "Darbar",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3350",
          "Name": "Oorivaaraemi",
          "Type": "Javali",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3351",
          "Name": "Enthadikulukae",
          "Type": "Javali",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3352",
          "Name": "Yaemanthunae",
          "Type": "Javali",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3353",
          "Name": "Yaemimaayamu",
          "Type": "Javali",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3354",
          "Name": "yaeraathaku nataraa",
          "Type": "Javali",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3355",
          "Name": "Yaelaraadaayanae",
          "Type": "Javali",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3356",
          "Name": "Kommaro",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3357",
          "Name": "Chelinae netlu",
          "Type": "Javali",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3358",
          "Name": "Thaarumaaru",
          "Type": "Javali",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3359",
          "Name": "Telisae vaka lella",
          "Type": "Javali",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3360",
          "Name": "naareemani",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3361",
          "Name": "Praanasakutidu",
          "Type": "Javali",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3362",
          "Name": "Palimiyaela",
          "Type": "Javali",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3363",
          "Name": "Paripovalara",
          "Type": "Javali",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3364",
          "Name": "Mariyaada theliyakanae",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3365",
          "Name": "Maarubaari thaalalaenura",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3366",
          "Name": "Smara Sunda",
          "Type": "Javali",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3367",
          "Name": "Samayamidae",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3368",
          "Name": "Vaani pondu",
          "Type": "Javali",
          "Ragam": "Kaanadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3369",
          "Name": "Vaththaninne",
          "Type": "Javali",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3370",
          "Name": "Vagalaadi",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tirupati Narayanaswamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3371",
          "Name": "Merakaadhu",
          "Type": "Javali",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tirupati Narayanaswamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3372",
          "Name": "Marubaari",
          "Type": "Javali",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3373",
          "Name": "Muttavaththura",
          "Type": "Javali",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3374",
          "Name": "Modi jese",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thirupanandhaal Pattabhirammaya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Javalis",
          "Book Author": "T. Brinda",
          "Year of Publication": "1960",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3375",
          "Name": "Paahi Paahi bala ganapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3376",
          "Name": "Engae yolitheereesanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3377",
          "Name": "Karunai thandhenai aalamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3378",
          "Name": "Maamayura meedhil yaeri vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3379",
          "Name": "Karunai purindhu ratchiamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3380",
          "Name": "Ambaa krupai thandhu ratchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karanataka Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3381",
          "Name": "Gathi undan iru thaalamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3382",
          "Name": "Otriyoorappa ninai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3383",
          "Name": "Indha nara jananam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3384",
          "Name": "Poomael valarum annaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3385",
          "Name": "Thanjam Thanjamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3386",
          "Name": "Kanmaniyae solladi",
          "Type": "Padam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3387",
          "Name": "Naanae naevinaen",
          "Type": "Padam",
          "Ragam": "Sindhubhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3388",
          "Name": "Sri vidarbhanagar vaasini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "Rukmini Kalyanam",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3389",
          "Name": "Jaathiyai keduppaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "Iyarpagai naayanaar Charithram",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3390",
          "Name": "Madhura Ranjitha",
          "Type": "Kummi",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Madurai Meenakshi",
          "Composer": "Mazhavai Chidambhara Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Mazhavai Chidambara Bhaarathi Paadalgal",
          "Book Author": "Dr. P. C. Sitaaraamayar",
          "Year of Publication": "1964",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "3391",
          "Name": "Akhilanda Kti Brahmaanda",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda jathi ata thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3392",
          "Name": "Vaaranaasyam",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3393",
          "Name": "Sree Raamachandra",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3394",
          "Name": "Enduku",
          "Type": "Varnam-Tana",
          "Ragam": "Ramapriya",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3395",
          "Name": "Bhajarae Maanasa",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3396",
          "Name": "Gati Neevani",
          "Type": "Varnam-Tana",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3397",
          "Name": "Ninne nammitinayya",
          "Type": "Varnam-Tana",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3398",
          "Name": "Ninnu Koriyunnanuraa",
          "Type": "Varnam-Tana",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3399",
          "Name": "Dayaledaa",
          "Type": "Varnam-Tana",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3400",
          "Name": "Evarunnaaruraa",
          "Type": "Varnam-Tana",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3401",
          "Name": "Palumaaru",
          "Type": "Varnam-Pada",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3402",
          "Name": "Evarunnaaru",
          "Type": "Varnam-Tana",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3403",
          "Name": "Chalamela Jessevuraa",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3404",
          "Name": "Nive Rakshakudani",
          "Type": "Varnam-Tana",
          "Ragam": "Kathanakuthuhalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3405",
          "Name": "Yadukula Jalanidhi",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3406",
          "Name": "Vanajaaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3407",
          "Name": "Vandenishamaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3408",
          "Name": "Pranamaamyaham shree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3409",
          "Name": "Panamata Srimahaganapathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3410",
          "Name": "Lambodaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3411",
          "Name": "Sri Saraswathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3412",
          "Name": "Maamavathu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3413",
          "Name": "Namaousthute Devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3414",
          "Name": "Pranamaamyaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3415",
          "Name": "Bhajamaanasa Saraswathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3416",
          "Name": "Shaarade Paahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3417",
          "Name": "Gurukrupaaleka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalatha",
          "Thalam": "Tisra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3418",
          "Name": "Sree Purandara Guruvaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi Manohari",
          "Thalam": "Mishra Jathi Triputa thalam",
          "Praise of": "On Purandaradasar",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3419",
          "Name": "Srimadaadi Tyagaraja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "On Tyagaraja Swami",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3420",
          "Name": "Raaghavendra Gurumaanatosmi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3421",
          "Name": "Guru Raaghavendram Anisham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3422",
          "Name": "Namaami Vidyaaratnaakara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3423",
          "Name": "Sree Keshavamaam paalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3424",
          "Name": "Naaraayanam namata",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3425",
          "Name": "Bhaja maadhavam anisham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3426",
          "Name": "Maamavaashu Govindaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3427",
          "Name": "Satatam Srivishnum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3428",
          "Name": "Mahaasudhanamaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3429",
          "Name": "Trivikramam aham bhaje",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3430",
          "Name": "Vaamana manisham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naayaki",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3431",
          "Name": "Sreedhara paahi dayaakara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3432",
          "Name": "Paripaahimaam sree Hrushikesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3433",
          "Name": "Paalayaashu Padmanaabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3434",
          "Name": "Daamodaramanisham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3435",
          "Name": "Sankarshana maam paalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3436",
          "Name": "Vaasudevamanisham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanadaa",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3437",
          "Name": "Pranamaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3438",
          "Name": "Aniruddhamaashrayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Tisra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3439",
          "Name": "Purushottama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3440",
          "Name": "Bhajaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3441",
          "Name": "Manasaa Vachasaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3442",
          "Name": "Bhaavayaachutam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3443",
          "Name": "Janaardanam Samaashrayeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3444",
          "Name": "Upendramaashrayaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3445",
          "Name": "Harae Paripaahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3446",
          "Name": "Paahi Krishna Vaasudeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3447",
          "Name": "Baalam gopaalamakhila",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3448",
          "Name": "Naaraayana namousthute",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3449",
          "Name": "Enta Nirdaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3450",
          "Name": "Raamaaramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3451",
          "Name": "Sree Vaasudeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Tisra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3452",
          "Name": "Inta paraakelanayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3453",
          "Name": "Ninne Sharanantinayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3454",
          "Name": "Sree Vaasudeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhari",
          "Thalam": "Tisra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3455",
          "Name": "BhajaMaanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3456",
          "Name": "Gokulanilaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3457",
          "Name": "Devakitanaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalatha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3458",
          "Name": "Govindam Bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3459",
          "Name": "Bhajarae Gopalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3460",
          "Name": "Jaya Jaya Maadhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayantasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3461",
          "Name": "Mama Hrudayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ReethiGowlai",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3462",
          "Name": "Namaamyaham Sree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3463",
          "Name": "Entani nee varnintuno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3464",
          "Name": "Nee Paadamulanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naayaki",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3465",
          "Name": "Indiraa Ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3466",
          "Name": "Neeve Gatiyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3467",
          "Name": "Paripaahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3468",
          "Name": "Paalita Bhuvana patitapaavana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3469",
          "Name": "Naa chai vidavakuraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3470",
          "Name": "Naa moralanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3471",
          "Name": "Idi Samayamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natakapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3472",
          "Name": "Shankari Ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3473",
          "Name": "Devi Ramae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3474",
          "Name": "Sree Chamundeshwari Paalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3475",
          "Name": "Brovavamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Tisra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3476",
          "Name": "Sri Hari Vallabhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Udayaravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3477",
          "Name": "Kalayae Mama Hrudayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HindusthaniKapi",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3478",
          "Name": "Varalakshmi Namousthuthe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3479",
          "Name": "Sree Mahaalakshmeem",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3480",
          "Name": "Kuru mae kushalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3481",
          "Name": "Devi Kamalaalayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3482",
          "Name": "Paahi maam Ksheerasaagara tanayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3483",
          "Name": "Shreeramaadevi maamavatu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3484",
          "Name": "Maamava mrudujaaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3485",
          "Name": "Shailasuthae Shiva sahithae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3486",
          "Name": "Shivae Paahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3487",
          "Name": "Karunisou Thaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3488",
          "Name": "Maanamuto",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3489",
          "Name": "Raamaabhiraama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3490",
          "Name": "Chintayeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3491",
          "Name": "Devaadideva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sunadavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3492",
          "Name": "Gaanasudhaarasa Paanamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3493",
          "Name": "Kalinarulaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3494",
          "Name": "Pari paahi maam paravaasudeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3495",
          "Name": "Harini Bhajinche",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3496",
          "Name": "Sigguledu naakinchukaina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3497",
          "Name": "Nee paadamulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3498",
          "Name": "Palukavademiraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3499",
          "Name": "Brovavayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3500",
          "Name": "Bhaavayeham Raghuveeram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3501",
          "Name": "Paripaahi maam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3502",
          "Name": "Karuninchi naanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3503",
          "Name": "Brochevarevarura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3504",
          "Name": "Krupatonu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3505",
          "Name": "Raamae Vasatu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3506",
          "Name": "Dayaledemi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3507",
          "Name": "Dayato nannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3508",
          "Name": "Intaparaangmukhamela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3509",
          "Name": "Nera nammithi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3510",
          "Name": "Sree Raamachandram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jhalavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3511",
          "Name": "Neekenduku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3512",
          "Name": "Raaraa en pilachite",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3513",
          "Name": "Bhajarae Maanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3514",
          "Name": "Paripaahi Raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3515",
          "Name": "Smara bhumi sutaadhi patim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3516",
          "Name": "Ninne nammitinayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3517",
          "Name": "Idi Niku Nyaayamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gamanashrama",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3518",
          "Name": "Dasharathanandana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawagowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3519",
          "Name": "Ninnu Nammiti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3520",
          "Name": "Neramemi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3521",
          "Name": "Smara Raamachandram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3522",
          "Name": "Nannu Brochutakevarunnaaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3523",
          "Name": "Dayaleka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3524",
          "Name": "Marachitivemo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "PoorviKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3525",
          "Name": "Raaraa raajeva lochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3526",
          "Name": "Mahaatmule",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3527",
          "Name": "Evaranivedanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3528",
          "Name": "Bhajanseyarada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3529",
          "Name": "Raama nee dayarada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3530",
          "Name": "Paraakelanayyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3531",
          "Name": "Paripaahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3532",
          "Name": "Jaanaki Manoharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3533",
          "Name": "Karuninchi naanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NavarasaKannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3534",
          "Name": "Nannu Brovarada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3535",
          "Name": "Karuninchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bangala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3536",
          "Name": "Marimari vachchuna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3537",
          "Name": "Karunapayonidhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3538",
          "Name": "Paripaahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3539",
          "Name": "Parulanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3540",
          "Name": "Varamulosagi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3541",
          "Name": "Neekela Dayaraadu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kathanakuthuhalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3542",
          "Name": "Raamam namaami satatam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3543",
          "Name": "Taramugaduraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3544",
          "Name": "Neekabhimaanamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokiladhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3545",
          "Name": "Daasharathae Paahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3546",
          "Name": "Nimishamaina Shri Raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3547",
          "Name": "Nee Daya Etula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3548",
          "Name": "Sree Ranganaatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3549",
          "Name": "Brovaraada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varunapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3550",
          "Name": "Bhajarae Re",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanakalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3551",
          "Name": "Paahimaam Parameshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3552",
          "Name": "Bhu kailaasapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3553",
          "Name": "Paalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3554",
          "Name": "Panataartiharamaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Senchurutti",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3555",
          "Name": "Shambho Shankara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3556",
          "Name": "Girijaa Ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3557",
          "Name": "Pranamaamyaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Mishra Jathi Triputa thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3558",
          "Name": "Thillana-ShuddhaSaalavi",
          "Type": "Thillana",
          "Ragam": "ShuddhaSaalavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3559",
          "Name": "Thillana-Mohanakalyani",
          "Type": "Thillana",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3560",
          "Name": "Thillana-Kannadaa",
          "Type": "Thillana",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3561",
          "Name": "Thillana-Charukesi",
          "Type": "Thillana",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3562",
          "Name": "Thillana-Khamas",
          "Type": "Thillana",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3563",
          "Name": "Thillana-Poornachandrika",
          "Type": "Thillana",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3564",
          "Name": "Thillana-Kaanadaa",
          "Type": "Thillana",
          "Ragam": "Kaanadaa",
          "Thalam": "Tisra Jathi Roopaka Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3565",
          "Name": "Thillana-Surutti",
          "Type": "Thillana",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3566",
          "Name": "Thillana-Sree",
          "Type": "Thillana",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3567",
          "Name": "Thillana-Kalyani",
          "Type": "Thillana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3568",
          "Name": "Nee pilachite",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3569",
          "Name": "Era naa saami",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3570",
          "Name": "Premato",
          "Type": "Javali",
          "Ragam": "Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3571",
          "Name": "Ninnentagaa",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3572",
          "Name": "Ninnubaasi",
          "Type": "Javali",
          "Ragam": "Senchurutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3573",
          "Name": "Ninnubaasi",
          "Type": "Javali",
          "Ragam": "Yadukula Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Vasudeva Kirtana Manjari",
          "Book Author": "Mysore Vasudevacharyar",
          "Year of Publication": "2015",
          "Book Print Language": "English"
      },
      {
          "Serial #": "3574",
          "Name": "Garudadhwani",
          "Type": "Jathiswaram",
          "Ragam": "Garudadhwani",
          "Thalam": "Chatusra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Neyveli Santhanagopalan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3575",
          "Name": "Hamsadhwani",
          "Type": "Jathiswaram",
          "Ragam": "Hamsadhwani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3576",
          "Name": "Hemavathi",
          "Type": "Jathiswaram",
          "Ragam": "Hemavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore quartet",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3577",
          "Name": "Hindolam",
          "Type": "Jathiswaram",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "KGSMA Students",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3578",
          "Name": "Hindolam",
          "Type": "Jathiswaram",
          "Ragam": "Hindolam",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Unknown Author",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3579",
          "Name": "Kalyani",
          "Type": "Jathiswaram",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3580",
          "Name": "Kalyani",
          "Type": "Jathiswaram",
          "Ragam": "Kalyani",
          "Thalam": "Thisra Ekam",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3581",
          "Name": "Naatakurinji",
          "Type": "Jathiswaram",
          "Ragam": "Naatakurinji",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "KGSMA Students",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3582",
          "Name": "Rasikapriya",
          "Type": "Jathiswaram",
          "Ragam": "Rasikapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3583",
          "Name": "Revathi",
          "Type": "Jathiswaram",
          "Ragam": "Revathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "KGSMA Students",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3584",
          "Name": "Charumathi Upachaaramu",
          "Type": "Javali",
          "Ragam": "Kaanada",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Pattabhiramiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3585",
          "Name": "Cheli Nenetlu (Javali)",
          "Type": "Javali",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Chinnaiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3586",
          "Name": "Elaradayanae",
          "Type": "Javali",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnaiyaa/Dharmapuri Subbaraya iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3587",
          "Name": "Kopametula",
          "Type": "Javali",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Chinnaiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3588",
          "Name": "Maaru baari Oorvalene",
          "Type": "Javali",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbaraaya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3589",
          "Name": "Maarubaari",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbaraaya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3590",
          "Name": "Marulukonnadira",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3591",
          "Name": "Mutu Vadura",
          "Type": "Javali",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnaiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3592",
          "Name": "Paripovalera",
          "Type": "Javali",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Pattabhiramiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3593",
          "Name": "Parulanna mata (Javali)",
          "Type": "Javali",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3594",
          "Name": "Smara Sundaraanguni",
          "Type": "Javali",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3595",
          "Name": "Vagaladi Bhodanalaku (Javali)",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tirupathi Narayanaswamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3596",
          "Name": "Vanipondu",
          "Type": "Javali",
          "Ragam": "Kaanada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbaraaya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3597",
          "Name": "Aaduvome Pallu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3598",
          "Name": "AaLaavadhennaLo (6th Sapta Rathna Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3599",
          "Name": "Abhimaanamen nadugal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3600",
          "Name": "Abhishtavaradha Sree Maha Ganapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3601",
          "Name": "Adigo Badradri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Bhadrachala Ramadasar/Tumu Narasimhadasa",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3602",
          "Name": "Adiseshan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3603",
          "Name": "Aganita Mahimaatbudha (2nd Sapta Rathna Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3604",
          "Name": "Ainkaranai (Thirupugazh)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Arunagirinaathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3605",
          "Name": "Akilandeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3606",
          "Name": "Akshaya Linga Vibho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3607",
          "Name": "Alaipayuthey",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3608",
          "Name": "Amba Neelambari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3609",
          "Name": "Amba Neelayathakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3610",
          "Name": "Amba Paradevathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rudrapriya",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Krishnaswami Ayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3611",
          "Name": "Amma Dharmasamvardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3612",
          "Name": "Amma Raavamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3613",
          "Name": "Amudhamooru - Tirupugazh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinadar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3614",
          "Name": "Ananda Nata aaduvar thillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Neelakanta Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3615",
          "Name": "Ananda Natana prakasham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3616",
          "Name": "Anandaamruthakarshini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amruthavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3617",
          "Name": "Aneeshvaram Ambikeshvaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3618",
          "Name": "Anjaneya Raghurama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3619",
          "Name": "Annapoornae Visalakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3620",
          "Name": "Anumanai Anudinam ninai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Madhuvanthi / Bhageshri / SindhuBhairavi / Revathi)",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Guru Surajananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3621",
          "Name": "Anuragamule",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswati",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3622",
          "Name": "Aparadhamulan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3623",
          "Name": "Apparama Bhakthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3624",
          "Name": "Arabhimaanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tarangampadi Panchanadayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3625",
          "Name": "Aradhayami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3626",
          "Name": "avan seyalanri Or aNUvum asaiyAdAgaiyAl",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3627",
          "Name": "Baala sarasa (4th Sapta Rathna Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3628",
          "Name": "Baalaambikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manoranjani",
          "Thalam": "Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3629",
          "Name": "Balagopalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3630",
          "Name": "Balakanakamaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3631",
          "Name": "Balasubramanyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3632",
          "Name": "Bhagyada Lakshmi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3633",
          "Name": "Bhairavi Shatkam (Arulsindhum Bhairavi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Bhairavi flavours)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr. S. Ghatam Karthick",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3634",
          "Name": "Bhajanaamruta (1st Sapta Rathna Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3635",
          "Name": "Bhajanaseyaradha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3636",
          "Name": "Bhajare Bodhendram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Jayendra Saraswathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3637",
          "Name": "Bhajare Manasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3638",
          "Name": "Bhajasva shree (2nd avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3639",
          "Name": "Bharatha Desha Hithaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swami Dayananda Saraswathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3640",
          "Name": "Bharo Guru Raghavendra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Mishra Gathi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3641",
          "Name": "Bhavamulona",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3642",
          "Name": "Bhavayami Raghuramam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3643",
          "Name": "Bho Shambho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revathi",
          "Thalam": "Eka",
          "Praise of": "N/A",
          "Composer": "Swami Dayananda Saraswathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3644",
          "Name": "Bhogeendrasayinam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3645",
          "Name": "Bhuveneshwarya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3646",
          "Name": "Biraanavara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3647",
          "Name": "Brihadeeshwaro Rakshathu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ganasamavarali",
          "Thalam": "Thisra Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3648",
          "Name": "Brocheva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3649",
          "Name": "Brochevarevare",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3650",
          "Name": "Brovabarama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3651",
          "Name": "Bruhadamba Madamba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhaanumathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3652",
          "Name": "Bruhadambikayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3653",
          "Name": "Chalarae Rama (Sompaina)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3654",
          "Name": "Chandram Bhajamanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3655",
          "Name": "Chandran Oliyil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Eka (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3656",
          "Name": "Chandrasekara Chandrasekahara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yaman Kalyani",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Ganesh-Kumaresh",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3657",
          "Name": "Chani Thodi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3658",
          "Name": "Chede Budhimaanuraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3659",
          "Name": "Chenthil Aandavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3660",
          "Name": "Chera raava demira",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3661",
          "Name": "Chesinadella",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3662",
          "Name": "Chetah Sri Balakrishnam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Thisra Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3663",
          "Name": "Chidambara Nataraja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tanukirti",
          "Thalam": "Mishra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3664",
          "Name": "Chinnanchiru kiliyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Chatusra Jathi Eka Thalam-Tisra Nadai",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3665",
          "Name": "Chintayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3666",
          "Name": "Chinthayami Jagadambha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3667",
          "Name": "Chitham Irangadha daenayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3668",
          "Name": "Choodarae chelulara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3669",
          "Name": "Cleveland Aradhanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Neyveli Santhanagopalan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3670",
          "Name": "Dakshinamoorthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jhumpai",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3671",
          "Name": "Danda muppatenura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3672",
          "Name": "Dasana Madiko",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3673",
          "Name": "Deva Deva Jagadeeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3674",
          "Name": "Deva Deva kalayamidae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3675",
          "Name": "Devi Brova samayamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chintamani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3676",
          "Name": "Devi Neeye Thunai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3677",
          "Name": "Devi Paavanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3678",
          "Name": "Devi Sri Tulasamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3679",
          "Name": "Dhadhathi Sambatham (Dhaivatham)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shri Ganapathi Sacchidananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3680",
          "Name": "Dharmasamvardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3681",
          "Name": "Dina Jana Vana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3682",
          "Name": "Dinamani vamsha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3683",
          "Name": "Dolayam Chala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3684",
          "Name": "Dora kuna (karaku bangaru)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3685",
          "Name": "Dudukugala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3686",
          "Name": "Durga Devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3687",
          "Name": "Durga Devi Samrakshamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3688",
          "Name": "Edutanilachithe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3689",
          "Name": "Ee vasudha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3690",
          "Name": "Eesha Pahimaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3691",
          "Name": "Ehi Annapoorne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3692",
          "Name": "Ekkaalathilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvaarur Ramaswamy Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3693",
          "Name": "Ekkalathilum maravenae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3694",
          "Name": "Ela Raadhaayanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnaiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3695",
          "Name": "Elara Krishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3696",
          "Name": "Emani Veginthune",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3697",
          "Name": "Enadhu Ullamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kalki Krishnamurthi/SV Venkatraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3698",
          "Name": "Enatinomu Phalamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3699",
          "Name": "Endu dhagi naado",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3700",
          "Name": "Enduku Peddhala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3701",
          "Name": "Ennaganu Ramabhajana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Bhadrachala Ramadasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3702",
          "Name": "Ennakkun irupadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Kalyani, Bhairavi, KedaraGowlai, Thodi, Mohanam, Dhanyasi, Sahana, Shanmukhapriya, Madhyamavathi)",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3703",
          "Name": "Enta Muddu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bindu Malini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3704",
          "Name": "Entha Ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3705",
          "Name": "Entha rani thana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3706",
          "Name": "Enthanerchina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3707",
          "Name": "Entharo Mahanubhavulu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3708",
          "Name": "Enthavedukondu Raghava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3709",
          "Name": "Eppo Varuvaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3710",
          "Name": "Etavunnara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3711",
          "Name": "Eti Janmaamithi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3712",
          "Name": "Gaganagatha (Gandharam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shri Ganapathi Sacchidananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3713",
          "Name": "Gajavadana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kumara Ettendra",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3714",
          "Name": "Ganamoorthe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ganamurthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3715",
          "Name": "Gandhamu Puyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3716",
          "Name": "Ganga Sthuthi (Devi Sureshwari)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Adi Shankaracharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3717",
          "Name": "Garuda Gamana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3718",
          "Name": "Giriraja Sutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bangaala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3719",
          "Name": "Gnanamosagaradha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3720",
          "Name": "Gopa nandana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushaavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3721",
          "Name": "Gopalaka Pahimam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revagupti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3722",
          "Name": "Gopi Ninna Maga ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi (Thisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3723",
          "Name": "Gopika Manoharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3724",
          "Name": "Govindanukku aatpatta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Folk Tune",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3725",
          "Name": "Grahabalamemi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3726",
          "Name": "Guruguhaya Bhakthaanugrahaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3727",
          "Name": "Gurulekha Etuvanti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowri Manohari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3728",
          "Name": "Guruvayoor Ennoro Kshetram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Tisra Gathi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3729",
          "Name": "Guruvina Gulamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3730",
          "Name": "Haladharanujam (Navavarana phala stuthi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3731",
          "Name": "Hanumantha Devanamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3732",
          "Name": "Hariharaputram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Khanda Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3733",
          "Name": "Hecharikagarara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Khanda Ekam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3734",
          "Name": "Himagiri Thanayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3735",
          "Name": "Hiranmayeem Lakshmeem",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3736",
          "Name": "Idi Nyaayamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maalavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3737",
          "Name": "Indrupol Endrum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswati",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3738",
          "Name": "Innudaya Bharathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyana Vasantham",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3739",
          "Name": "Jagadambike",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3740",
          "Name": "Jagadanandakaraka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3741",
          "Name": "Janaki Ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Seemanthini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3742",
          "Name": "JanakiPathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KharaharaPriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3743",
          "Name": "Janani Janani Janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kadalur Venkatraman/Kadalur Subramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3744",
          "Name": "Janani ninnu vina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Subbaraya Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3745",
          "Name": "Janani Pahi sada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3746",
          "Name": "Jatadhara Sankara (5th Sapta Rathna Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3747",
          "Name": "Jaya Jaya Jaya Jaanaki kaantha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3748",
          "Name": "Jaya MahishAshwara mardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3749",
          "Name": "Jayathi Jayathi Bharatha Matha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi (Thisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Mayavaram Vishwanatha Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3750",
          "Name": "Kaanakankodi Vaendum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3751",
          "Name": "Kadanu variki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3752",
          "Name": "Kaithala Niraikani (Thirupugazh)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gambiranattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunagirinadar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3753",
          "Name": "Kakaichiraginilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3754",
          "Name": "Kaligiyuntae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3755",
          "Name": "Kalitheera Vandarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3756",
          "Name": "Kallarkum Katravarkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vallalar (Ramalinga Adigal)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3757",
          "Name": "Kamalaambikaayai (4th Avaranam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3758",
          "Name": "Kamalaambikaya (6th Avaranam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3759",
          "Name": "Kamalambha Bhajarae (2nd Avaranam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3760",
          "Name": "Kamalambha Samrakshathu (1st Avaranam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3761",
          "Name": "Kamalambikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Khanda Jhampai",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3762",
          "Name": "Kamalambikae (Dhyana krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3763",
          "Name": "Kana Vendamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3764",
          "Name": "Kanakaambari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kanakambari",
          "Thalam": "Thisra Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3765",
          "Name": "Kanakanaruchira",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3766",
          "Name": "Kanakasaila",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3767",
          "Name": "Kanjadalaya Thakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3768",
          "Name": "Kanthimathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3769",
          "Name": "Kanukontini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3770",
          "Name": "Kapaleeshwaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vaasanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr. S. Ghatam Karthick",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3771",
          "Name": "Kapali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3772",
          "Name": "Karpaga Manohara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3773",
          "Name": "Karpagamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3774",
          "Name": "Karthikeya Kaangeya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3775",
          "Name": "Karu Baru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3776",
          "Name": "Karuna Nidhiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3777",
          "Name": "Karunai Deivamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Madurai Srinivasan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3778",
          "Name": "Kaumaari Gauri vaeLLavaLi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3779",
          "Name": "Kaumari Gowri velavali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3780",
          "Name": "Kodubega Divya Mathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3781",
          "Name": "Koluvai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3782",
          "Name": "Koluvaiyunnaadae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3783",
          "Name": "Kondadu Panpadu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3784",
          "Name": "Koosina Kandeera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3785",
          "Name": "Kopametula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Chinnaiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3786",
          "Name": "Korisevimpararae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3787",
          "Name": "Kotina dhulu Dhanushkoti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3788",
          "Name": "Krishna nee begane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yamunakalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kanakadasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3789",
          "Name": "kruthi vaasasae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3790",
          "Name": "Ksheenamai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3791",
          "Name": "Ksheerabdi Kanyakaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3792",
          "Name": "Ksheerasagara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Dhruva Thalam",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3793",
          "Name": "Kunidado Krishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3794",
          "Name": "Kurai Ondrum Illai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Shivaranjani, Kapi, Sindhu Bhairavi)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Rajaji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3795",
          "Name": "Kuvalayadala nayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3796",
          "Name": "Lalithe Sri Pravruddhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3797",
          "Name": "Lambodhara Maha Ganesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3798",
          "Name": "Maa Ramanan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3799",
          "Name": "Maadhava Hridi khelini (3rd Sapta Rathna Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3800",
          "Name": "Maadhava Mamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3801",
          "Name": "Maakaelara vicharamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3802",
          "Name": "Maalmaruga Shanmukha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3803",
          "Name": "Maamava Maamava (Madhyamam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shri Ganapathi Sacchidananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3804",
          "Name": "Maamava Raghurama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3805",
          "Name": "Maamava Raghuveera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mahuri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3806",
          "Name": "Maamava Sada Janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3807",
          "Name": "Maamava Sada Varadae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3808",
          "Name": "Maamavatu Shri Saraswathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3809",
          "Name": "Maapalaa Velasika",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3810",
          "Name": "Maayamma Nannu Brovamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3811",
          "Name": "Madi Madi Madi Endhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3812",
          "Name": "Maha Ganapathaey Paalayashumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natanarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3813",
          "Name": "Mahaganapathim manasasswarami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Chatushra Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3814",
          "Name": "Mahaganapathim vande",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3815",
          "Name": "Mahalakshmi Jaganmatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3816",
          "Name": "Mahitha Pravuddha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3817",
          "Name": "Maitreem Bhajatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Chandrasekhara Saraswathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3818",
          "Name": "Mamahrudayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3819",
          "Name": "Manasa Etulo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3820",
          "Name": "Manasa Guruguha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3821",
          "Name": "Manasa Satatam Smaraneeyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Valachi, Durga, Sindhu Bhairavi, Darbari Kaanada, Desh)",
          "Thalam": "Eka",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3822",
          "Name": "Manavyalakinchara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3823",
          "Name": "Mangala Aarathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Mishra Nadai",
          "Praise of": "N/A",
          "Composer": "Bheemesha Krishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3824",
          "Name": "Maravakavae Oh Manasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3825",
          "Name": "Marivere",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3826",
          "Name": "Marugelara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayanthashree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3827",
          "Name": "Mathangi Srirajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3828",
          "Name": "Mayilaapuri Valar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr. S. Ghatam Karthick",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3829",
          "Name": "Meena Lochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3830",
          "Name": "Meenakshi Memudam Dehi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3831",
          "Name": "Meluko Dayanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3832",
          "Name": "Mohanakara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neethimathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Koteeswara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3833",
          "Name": "Muddugare Yashoda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3834",
          "Name": "Mukatake Mangalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3835",
          "Name": "Naa moralakimpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3836",
          "Name": "Naadaadina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3837",
          "Name": "Naanoru Vilayatu Bhommai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3838",
          "Name": "Nada loludai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyana Vasantham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3839",
          "Name": "Nagumomu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3840",
          "Name": "Nambi Kettavar Evaraiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3841",
          "Name": "Namo Namo Raghavaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3842",
          "Name": "Nandeesham Vande Sada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dr. Bala muralikrishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3843",
          "Name": "Nandha Gopa Balanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Lalgudi Gopala Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3844",
          "Name": "Nannu Brova neekinta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3845",
          "Name": "Nannu Brovu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3846",
          "Name": "Nannu Vidachi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3847",
          "Name": "Naradhaguruswami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3848",
          "Name": "Naradhamuni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3849",
          "Name": "Natajana Kalpavalli (9th avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3850",
          "Name": "Natanam Adinar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3851",
          "Name": "Ne pogada kunte",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3852",
          "Name": "Nee Allal Ini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G N Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3853",
          "Name": "Nee Chithamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3854",
          "Name": "Nee irangayenil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3855",
          "Name": "Nee Sari Saati",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3856",
          "Name": "Neela Lohita (5th avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Khanda Dhuruva",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3857",
          "Name": "Neelayathakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3858",
          "Name": "Neenyako Hangyako",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3859",
          "Name": "Neerada Sama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayanthashree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3860",
          "Name": "Neeraja Maanini (Nishadham)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shri Ganapathi Sacchidananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3861",
          "Name": "Neerajakshi Kaamaakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3862",
          "Name": "Nenendhu vethu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3863",
          "Name": "Nigama Nigamantava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3864",
          "Name": "Nila Nila Satru Nillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Tisra Gathi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3865",
          "Name": "Ninainthodi vandhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Thodi, Poorvikalyani, Bhairavi, Kalyani, Khambhoji, Mukhari, Varali)",
          "Thalam": "Saptha Thalam",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3866",
          "Name": "Ninne Bhajana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gambiranattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3867",
          "Name": "Ninnu vina namadendu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kaanada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3868",
          "Name": "Ninnu Vinaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Viloma Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3869",
          "Name": "Niravdhi Sukadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ravichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3870",
          "Name": "O Jagadambhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3871",
          "Name": "O Raajeevaksha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3872",
          "Name": "O Ranga Sayee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3873",
          "Name": "Odi Bharayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3874",
          "Name": "Om Shakthi Om Shakthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3875",
          "Name": "Paahi Paahi Jagan Mohana Krishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3876",
          "Name": "Paahi Raama Duta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shadvida Maargini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3877",
          "Name": "Paahimaam Ratnachala naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3878",
          "Name": "Paahimaam Sri Rajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3879",
          "Name": "Paalaya Sadaa maamayi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3880",
          "Name": "Paalisemma Mudu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3881",
          "Name": "Paapamharae (Panchamam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shri Ganapathi Sacchidananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3882",
          "Name": "Paarkadal Alai Melae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G Ramanatha Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3883",
          "Name": "Padmini Vallabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3884",
          "Name": "Pahi Janani Santatam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3885",
          "Name": "Palinchu Kamakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3886",
          "Name": "Palisemma Muddu Sharade",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3887",
          "Name": "Palukavemina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3888",
          "Name": "Panchabootha Kiranavalim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kiranavali",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3889",
          "Name": "Panchamaatanga mukha Ganapathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malahari",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3890",
          "Name": "Panchashat peeta Roopini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Devagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3891",
          "Name": "Pankaja Lochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3892",
          "Name": "Parakela Nannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3893",
          "Name": "Paraloka Sadhanamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3894",
          "Name": "Paramapurusham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lalitha Panchamam",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3895",
          "Name": "Paramukham Yaenayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3896",
          "Name": "Parashakthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3897",
          "Name": "Parathpara parameshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3898",
          "Name": "Paridhana Michite",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3899",
          "Name": "Paripaahimaam Sri Daasharathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3900",
          "Name": "Paripaalaya Sarasiruha lochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3901",
          "Name": "Paruvam Paarka gnyaayamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Anaiayya Brothers",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3902",
          "Name": "Parvathi Patim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3903",
          "Name": "Pathiki Harateere",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3904",
          "Name": "Pathiki Mangala Harateere",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3905",
          "Name": "Ra Ra Panisayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3906",
          "Name": "Ra Ra Raajeva Lochana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3907",
          "Name": "Raadhike Radhike",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhageshri",
          "Thalam": "Eka",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3908",
          "Name": "Raama Deivama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3909",
          "Name": "Raama katha sutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3910",
          "Name": "Raamaa dayajudavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3911",
          "Name": "Ragasudharasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3912",
          "Name": "Raghavanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3913",
          "Name": "Raghunayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3914",
          "Name": "Raghuvamsha Sudha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kathanakudhoohalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3915",
          "Name": "Rakshabettarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3916",
          "Name": "Rama Madhura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3917",
          "Name": "Rama Nama Bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3918",
          "Name": "Rama Rama Guna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3919",
          "Name": "Ramaa Nannu Brovara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3920",
          "Name": "Ramaa Ramana Raa Raa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3921",
          "Name": "Ramamantrava Japiso",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3922",
          "Name": "Ramanatham Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3923",
          "Name": "Ramanukku Mannan mudi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachala Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3924",
          "Name": "Randi Randi Venkatesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Eka (Khanda Nadai)",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3925",
          "Name": "Ranganayakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3926",
          "Name": "Rangapura Vihara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Brindavana Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3927",
          "Name": "Ranjani Mala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3928",
          "Name": "Re Re Swarodhitham (Rishbham)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Shri Ganapathi Sacchidananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3929",
          "Name": "Saarasa Netra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3930",
          "Name": "Saarasaaksha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3931",
          "Name": "Saaraya Saaraya (Shadjam swaradevata - Agni)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shri Ganapathi Sacchidananda",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3932",
          "Name": "Saatileni Guruguha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3933",
          "Name": "Sadaa Enna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vijaya Vittala Dasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3934",
          "Name": "Sadaanandamayi (6th avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Sankeerna Matyam",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3935",
          "Name": "Sadaanandamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3936",
          "Name": "Sadaapalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G N Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3937",
          "Name": "Sadananda Thaandavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Achyutha dasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3938",
          "Name": "Sadhguru Swamiku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3939",
          "Name": "Sadhinchanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3940",
          "Name": "Saguna Dhyanam Sukamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3941",
          "Name": "Sakala Loka (7th avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3942",
          "Name": "Saketha Nagaranaatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3943",
          "Name": "Saketha Nikethana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3944",
          "Name": "Samaja Vara Gamana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3945",
          "Name": "Samanamevaru Rama nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3946",
          "Name": "samayamidE nannu brOchuTaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BudhaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3947",
          "Name": "Samayamide ra ra na sami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3948",
          "Name": "Samukhana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kokilavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3949",
          "Name": "Sangeetha Gnaayanamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3950",
          "Name": "Sangeetha Samrajya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Bangalore Ramamurthy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3951",
          "Name": "Sangeetha Vandanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Various Thalam",
          "Praise of": "N/A",
          "Composer": "Neyveli Santhanagopalan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3952",
          "Name": "Santatam Aham (1st avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Deshakshi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3953",
          "Name": "Saraguna Palimpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3954",
          "Name": "Sarasa Saama Dhaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapinarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3955",
          "Name": "Sarasijanabha sodari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagagandhari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3956",
          "Name": "Sarasiruhaasanapriyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Puliyur Duraiswami Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3957",
          "Name": "Saraswathi Namoustuthe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "G N Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3958",
          "Name": "Saraswathi Vidhiyuvathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3959",
          "Name": "Sarievvaramma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3960",
          "Name": "Sarojadalanetri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3961",
          "Name": "Sarva jeeva (3rd avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3962",
          "Name": "sarva mangaLa rUpADyaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3963",
          "Name": "Sashivadana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chandrajyothi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3964",
          "Name": "Seethamma Maayamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3965",
          "Name": "Seshaachala Naayakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3966",
          "Name": "Sevika Vaendum Aiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthu Thandavar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3967",
          "Name": "Shaaradhae Vishaaradhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "T. V. Gopalakrishnan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3968",
          "Name": "Shambho Gangadhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Eesha Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3969",
          "Name": "Shambho Mahadeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3970",
          "Name": "Shankari neeve amba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Subbaraya Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3971",
          "Name": "Shankari Shankuru Chandramukhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3972",
          "Name": "Shankari Shree (8th avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3973",
          "Name": "Shanthi Nilava Vendum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tilang",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sedhumadhava Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3974",
          "Name": "Shara Shara Samarai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3975",
          "Name": "Sharade Karunanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Chandrasekhara Bharati",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3976",
          "Name": "Sharanam Bhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsavinodini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Teerthar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3977",
          "Name": "Sharanu Siddhivinaayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3978",
          "Name": "Shiva Shiva Shiva Bho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Mishra Jhumpai",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3979",
          "Name": "Shiva Shiva Shiva Enarada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3980",
          "Name": "Shivakaama Sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3981",
          "Name": "Shivakaama Sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3982",
          "Name": "Shree Raama jayamae jayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Naatai, Varali, Arabhi, Gowlai, Manirangu)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3983",
          "Name": "Shri Bhargavi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mangala Kaishiki",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3984",
          "Name": "Shri Chamundeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3985",
          "Name": "Shri Dakshinamoorthim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Phenadyuti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3986",
          "Name": "Shri Ganapathini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3987",
          "Name": "Shri Ganeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3988",
          "Name": "Shri Guruguha tarayaashumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3989",
          "Name": "Shri Guruguhasya Dasoham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3990",
          "Name": "Shri Jaalandhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gambiranattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3991",
          "Name": "Shri Kaamakoti Pitasthite",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3992",
          "Name": "Shri Kaamakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvarur Ramaswamy Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3993",
          "Name": "Shri Kamakshi Katakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiruvarur Ramaswamy Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3994",
          "Name": "Shri Kamalaambikae (Mangalam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Khanda Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3995",
          "Name": "Shri Kamalaambikayam (7th Avaranam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3996",
          "Name": "Shri Kamalambaya param (5th Avaranam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3997",
          "Name": "Shri Kamalambha (9th Avaranam)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3998",
          "Name": "Shri Kanthimatim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "3999",
          "Name": "Shri Krishnam Bhajamanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4000",
          "Name": "Shri Kumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4001",
          "Name": "Shri Lakshmi Varaham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4002",
          "Name": "Shri Maathrubhootham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannada",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4003",
          "Name": "Shri Maha Ganapathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "N S Ramachandran",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4004",
          "Name": "Shri Mahaganapathi Ravathu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4005",
          "Name": "Shri Mahaganapathim Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Maharaja Jeyachamaraja Wodeyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4006",
          "Name": "Shri Moolaadhara Chakra",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4007",
          "Name": "Shri Naarada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4008",
          "Name": "Shri Naathadhi Guruguho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4009",
          "Name": "Shri Pathaey",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4010",
          "Name": "Shri Raajagopalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4011",
          "Name": "Shri Raama Paadamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrutha Vaahini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4012",
          "Name": "Shri Raghukula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4013",
          "Name": "Shri Ramaa Saraswathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nasamani/Nasikaa Bhooshani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4014",
          "Name": "Shri Ranganathaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4015",
          "Name": "Shri Saraswathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4016",
          "Name": "Shri Shankara Guruvaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4017",
          "Name": "Shri Subramanyaya Namousthe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4018",
          "Name": "Shri Varalakshmi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4019",
          "Name": "Shri Venkatagireesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4020",
          "Name": "Shri Vigna Rajam Bhajae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gambiranattai",
          "Thalam": "Khanda Nadai",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4021",
          "Name": "Shri Vishwanatham Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Chaturdasa Ragamalika)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4022",
          "Name": "Shrimadadi Tyagaraja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalayani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4023",
          "Name": "Shriman narayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4024",
          "Name": "Shrinivasa tavacharanu chin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4025",
          "Name": "Shrinivasa Thiru Venkatamudayan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4026",
          "Name": "Siddhi Vinayakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4027",
          "Name": "Siddhim Dehime",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Siddhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr. Bala muralikrishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4028",
          "Name": "Smaranae Onde Salade",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4029",
          "Name": "Smarasada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4030",
          "Name": "Sogasugaa Mrudanga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4031",
          "Name": "Sri Narada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4032",
          "Name": "Srinivasa Karavalambam - Shwetha Sagara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Misra Nadai",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4033",
          "Name": "Sudhamadhurya Bhashini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sindhuramakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4034",
          "Name": "Sudhamayee Sudhanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amruthavarshini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4035",
          "Name": "Sujana Jeevama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4036",
          "Name": "Sukhi Evvaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4037",
          "Name": "Sundara Nandakumaara (7th Sapta Rathna Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4038",
          "Name": "Swagatam Krishna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4039",
          "Name": "Swamiki Sari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4040",
          "Name": "Swamikku Sari Evvarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Manambuchavadi Venkatasubbaiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4041",
          "Name": "Swamikku Sari Evvarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4042",
          "Name": "Swaminatha Paripaalayashumaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4043",
          "Name": "Taaraka Bindhige",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tilang",
          "Thalam": "Adi (Thisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4044",
          "Name": "Talli Ninnunera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4045",
          "Name": "Tamboori Meetidava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4046",
          "Name": "Tanayuni Brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4047",
          "Name": "Teliyaleru Rama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4048",
          "Name": "Thaayae Yashoda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4049",
          "Name": "Thathvamariya Tharama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4050",
          "Name": "Theerada Vilayatu Pillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4051",
          "Name": "Themmaangu - Sundaran chEr peNmayilaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi (Tisra Nadai)",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4052",
          "Name": "Thikku Teriyada Kaatil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Behag, Bowli, Kuntalavarali, Sahana, Kapi, Paras)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4053",
          "Name": "Thirupathi venkata ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4054",
          "Name": "Thoogirae Rayara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Jaganatha Vittala",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4055",
          "Name": "Thulasi Dalamulache",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4056",
          "Name": "Thwameva Gatimama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4057",
          "Name": "Thyagaraja Gurum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "M D Ramanathan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4058",
          "Name": "Tolijanma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4059",
          "Name": "Tyagaraja Mangalam-Kakarla Vamsha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Nedunuri Krishnamurthy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4060",
          "Name": "Umasutam Namaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Trishakthi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4061",
          "Name": "Unjal Aadinal Radhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mand",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Muralidhara Swamiji",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4062",
          "Name": "Unnadiyae Gathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G N Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4063",
          "Name": "Unnai Allal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4064",
          "Name": "Vaachaamagocharamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaikavashi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4065",
          "Name": "Vaananai mathisoodiya-Thevaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Thirunaavukarasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4066",
          "Name": "Vaanchhasi (Dhyana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4067",
          "Name": "Vaaranam Ayiram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Andal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4068",
          "Name": "Vaaridhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4069",
          "Name": "Vaarija Nayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4070",
          "Name": "Vaatapi Ganapathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4071",
          "Name": "Vaazhiya Senthamizh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subramania Bharathi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4072",
          "Name": "Vadana dhyuthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4073",
          "Name": "Vadavarayai Mathaaki-Silapadhikaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ilangoadigal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4074",
          "Name": "Vallabha naayakasya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4075",
          "Name": "Vallikanavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Etaiyya Ambasamudram Subbarayasaami",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4076",
          "Name": "Vandanamu Raghunandana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4077",
          "Name": "Vandeham Jagat",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Annamacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4078",
          "Name": "Vara Leela Gana Lola",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4079",
          "Name": "Vara naarada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayashree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4080",
          "Name": "Varadacharya Deva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Tisra Nadai",
          "Praise of": "N/A",
          "Composer": "Dr S Ramanathan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4081",
          "Name": "Varalakshmi neeye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SalagaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4082",
          "Name": "Varavallabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G N Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4083",
          "Name": "Veena pustaka dharini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vegavahini",
          "Thalam": "Mishra Jhumpai",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4084",
          "Name": "Venkatachalamilayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4085",
          "Name": "Venkatachalapathe ninnu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4086",
          "Name": "Venkataramananae Baro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4087",
          "Name": "Venu gaana loluniganave",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4088",
          "Name": "Vidajala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4089",
          "Name": "Vinayaka Ninnu Vina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramakrishna Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4090",
          "Name": "Vinayakuni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4091",
          "Name": "Vishveshwara Darshana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4092",
          "Name": "Vittala Salaho Swami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4093",
          "Name": "Yaakae Nirdaya + Ninnanda swami virutham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Shubhapantuvarali, Valachi, Bhimplas)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4094",
          "Name": "Yochana kamala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tyagaraja",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4095",
          "Name": "yOga nidraE yOga mudraE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4096",
          "Name": "YogaYogeshwari (4th avarana Krithi)",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukadu Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4097",
          "Name": "Aarumuga Vadivelavane (Kavadi Chinthu)",
          "Type": "Padam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi (Tisra and Chatusra nadai)",
          "Praise of": "N/A",
          "Composer": "Chennikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4098",
          "Name": "Adharkullae",
          "Type": "Padam",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4099",
          "Name": "Adinomu palamu",
          "Type": "Padam",
          "Ragam": "Begada",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Kshetrayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4100",
          "Name": "Alarsara (Jalajabandhu)",
          "Type": "Padam",
          "Ragam": "Surutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4101",
          "Name": "Baayarani",
          "Type": "Padam",
          "Ragam": "Hindustani Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tirupathi Narayanaswamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4102",
          "Name": "Bhaamaro",
          "Type": "Padam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kshetrayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4103",
          "Name": "Choodarae",
          "Type": "Padam",
          "Ragam": "Sahana",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kshetrayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4104",
          "Name": "Chumma Chumma (Padam)",
          "Type": "Padam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4105",
          "Name": "Ella Arumaigalum",
          "Type": "Padam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4106",
          "Name": "Ethanai Sonnalum",
          "Type": "Padam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subbarama Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4107",
          "Name": "Iddari Loni",
          "Type": "Padam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Moovaanalluur Sabhaapathi ayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4108",
          "Name": "Kaanthanodu Chellumelle (Padam)",
          "Type": "Padam",
          "Ragam": "Neelambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4109",
          "Name": "Maanameebhushanamu",
          "Type": "Padam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Govindasamayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4110",
          "Name": "Manamaebhushanamu",
          "Type": "Padam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Kshetrayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4111",
          "Name": "Netru andhi nerathilae",
          "Type": "Padam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Subbarama Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4112",
          "Name": "Nidirayil Sopanathil",
          "Type": "Padam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4113",
          "Name": "Ososi",
          "Type": "Padam",
          "Ragam": "Mukhari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Muddu Natesa",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4114",
          "Name": "Thanakku Thaanae (padam)",
          "Type": "Padam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4115",
          "Name": "Theruvil vaaraano",
          "Type": "Padam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthu Thandavar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4116",
          "Name": "Thiruvotriyur Thyagarajan",
          "Type": "Padam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4117",
          "Name": "Vaddante",
          "Type": "Padam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Kuppusami Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4118",
          "Name": "Velavarae",
          "Type": "Padam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4119",
          "Name": "Yaar Poi Solluvar",
          "Type": "Padam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Ghanam Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4120",
          "Name": "Ganapathae",
          "Type": "RTP",
          "Ragam": "Kalyani",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4121",
          "Name": "Ganapathae Mahamathae",
          "Type": "RTP",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Jathi Triputa thalam (Chatusra Nadai-2Kalai)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4122",
          "Name": "Maal Maruga",
          "Type": "RTP",
          "Ragam": "Bhairavi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4123",
          "Name": "Maalmaruga Guha",
          "Type": "RTP",
          "Ragam": "Bhairavi",
          "Thalam": "Pancha nadai",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4124",
          "Name": "Mohana Muralidhara Gopala",
          "Type": "RTP",
          "Ragam": "Mohanam",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4125",
          "Name": "Naataikurinji enbaar",
          "Type": "RTP",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi (2 Kalai)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4126",
          "Name": "Naataikurinji Enbar",
          "Type": "RTP",
          "Ragam": "Naatakurinji",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4127",
          "Name": "Pannirukaiyane",
          "Type": "RTP",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi (2 Kalai)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4128",
          "Name": "Subramanyam Bhajami",
          "Type": "RTP",
          "Ragam": "Shanmugapriya",
          "Thalam": "Tisra Jathi Triputa thalam (Chatusra Nadai-2Kalai)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4129",
          "Name": "Amba Kamakshi",
          "Type": "SwaraJathi",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4130",
          "Name": "Gopala Lola",
          "Type": "SwaraJathi",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4131",
          "Name": "Nee arulaayo",
          "Type": "SwaraJathi",
          "Ragam": "Bhairavi",
          "Thalam": "Adi (2Kalai)",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4132",
          "Name": "Rave Himagiri",
          "Type": "SwaraJathi",
          "Ragam": "Thodi",
          "Thalam": "Adi (2Kalai)",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4133",
          "Name": "Ataana",
          "Type": "Thillana",
          "Ragam": "Atana",
          "Thalam": "Adi (Tisra + Chatusra Nadai)",
          "Praise of": "N/A",
          "Composer": "Chitravina N. Ravikiran",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4134",
          "Name": "Behag",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4135",
          "Name": "Behag",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chitravina N. Ravikiran",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4136",
          "Name": "Bhimplas/Abheri",
          "Type": "Thillana",
          "Ragam": "Bhimplas/Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4137",
          "Name": "Bilahari",
          "Type": "Thillana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ariyakudi Ramanuja Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4138",
          "Name": "Brindavana Saranga",
          "Type": "Thillana",
          "Ragam": "Brindavana Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr. Bala muralikrishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4139",
          "Name": "Chenchurutti",
          "Type": "Thillana",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4140",
          "Name": "Desh",
          "Type": "Thillana",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4141",
          "Name": "Dhanashri",
          "Type": "Thillana",
          "Ragam": "Dhanashri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4142",
          "Name": "Hamsanandi",
          "Type": "Thillana",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G N Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4143",
          "Name": "Kaanada",
          "Type": "Thillana",
          "Ragam": "Kaanada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4144",
          "Name": "Khamaas",
          "Type": "Thillana",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4145",
          "Name": "Khamaas",
          "Type": "Thillana",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4146",
          "Name": "Khamaas",
          "Type": "Thillana",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4147",
          "Name": "Madhuvanthi",
          "Type": "Thillana",
          "Ragam": "Madhuvanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4148",
          "Name": "Mandhari",
          "Type": "Thillana",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4149",
          "Name": "MohanaKalyani",
          "Type": "Thillana",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4150",
          "Name": "Nalina Kaanthi",
          "Type": "Thillana",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4151",
          "Name": "Paras",
          "Type": "Thillana",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4152",
          "Name": "Poornachandrika",
          "Type": "Thillana",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4153",
          "Name": "Revathi",
          "Type": "Thillana",
          "Ragam": "Revathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4154",
          "Name": "Shankarabharanam",
          "Type": "Thillana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4155",
          "Name": "Shankarabharanam",
          "Type": "Thillana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Seshannah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4156",
          "Name": "Thillana - Darbari Kaanadaa",
          "Type": "Thillana",
          "Ragam": "Darbari Kaanadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4157",
          "Name": "Yadukula Kambhoji ",
          "Type": "Thillana",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4158",
          "Name": "Chalamela-Shankarabharanam",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4159",
          "Name": "Kamalaakshi-YadukulaKhambhoji",
          "Type": "Varnam-Tana",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4160",
          "Name": "Nera Nammithi-Kaanada",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4161",
          "Name": "Sarasijanabha-Khambhoji",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu/Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4162",
          "Name": "Vanajakshi-Kalyani",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Pallavi Gopalaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4163",
          "Name": "Viriboni-Bhairavi",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Pachaimiriam Adiyappa",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4164",
          "Name": "Mathe Malayadwaja-Khamas",
          "Type": "Varnam-Dharu",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4165",
          "Name": "Amma Varnam - Gambiranattai",
          "Type": "Varnam-Pada",
          "Ragam": "Gambiranattai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dr. Bala muralikrishna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4166",
          "Name": "AngayarKanni-Navarasa Varnam",
          "Type": "Varnam-Pada",
          "Ragam": "Ragamalika (Bilahari, Huseni, Valachi, Saranga, Sucharitra, Atana, Rasikapriya, Sahana, Nadanamakriya)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4167",
          "Name": "Chalamela-NattaKurinji",
          "Type": "Varnam-Pada",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mulai Veedu Rangaswamy Nattuvanar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4168",
          "Name": "Dani samajendra-Thodi",
          "Type": "Varnam-Pada",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4169",
          "Name": "Entha nee naedhalu-Khamas",
          "Type": "Varnam-Pada",
          "Ragam": "Khamas",
          "Thalam": "Thisra Ekam",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4170",
          "Name": "Innum En Manam-Charukesi",
          "Type": "Varnam-Pada",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4171",
          "Name": "Mohamana-Bhairavi",
          "Type": "Varnam-Pada",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4172",
          "Name": "Nee Indha Maayam-Dhanyasi",
          "Type": "Varnam-Pada",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4173",
          "Name": "Roopamu Joochi-Thodi",
          "Type": "Varnam-Pada",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4174",
          "Name": "Sami neeramma-Khamas/Sami Rammanave",
          "Type": "Varnam-Pada",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4175",
          "Name": "Sami Ninne-Ragamalika",
          "Type": "Varnam-Pada",
          "Ragam": "Ragamalika (Thodi, Shankarabharanam, Pantuvarali, Atana, Bhairavi, Kalyani, Vasantha, Mukhari)",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4176",
          "Name": "Swami Naan Undran-Naataikurinji",
          "Type": "Varnam-Pada",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4177",
          "Name": "Amboruha-Ranjani",
          "Type": "Varnam-Tana",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G N Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4178",
          "Name": "Annamae - Arabhi",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiger Varadachariar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4179",
          "Name": "Arunodayamae-Bhowli",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4180",
          "Name": "Chalamu-Valachi",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4181",
          "Name": "Enta Prematho-Bahudari",
          "Type": "Varnam-Tana",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4182",
          "Name": "Entho Premato-Surutti",
          "Type": "Varnam-Tana",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4183",
          "Name": "Era Napai-Thodi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4184",
          "Name": "Evvari Bodhana-Abhogi",
          "Type": "Varnam-Tana",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4185",
          "Name": "Intha Chalamu-Begada",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4186",
          "Name": "Intha Modi-Saranga",
          "Type": "Varnam-Tana",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4187",
          "Name": "Intha Tamasa-Kannada",
          "Type": "Varnam-Tana",
          "Ragam": "Kannada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4188",
          "Name": "Jalajakshi-Hamsadhwani",
          "Type": "Varnam-Tana",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Manambuchavadi Venkatasubbaiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4189",
          "Name": "Karunimpa-Sahana",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4190",
          "Name": "Neevae gati-Nalinakanthi",
          "Type": "Varnam-Tana",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4191",
          "Name": "Neevae Rakshaku-Kathanakuthuhalam",
          "Type": "Varnam-Tana",
          "Ragam": "Kathanakudhoohalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4192",
          "Name": "Ninnukori-Mohanam",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4193",
          "Name": "Ninnukori-Vasantha",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tachoor Singarachariar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4194",
          "Name": "Nivegani-Mandari",
          "Type": "Varnam-Tana",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4195",
          "Name": "Sami Daya Juda-KedaraGowlai",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4196",
          "Name": "Sami Ninne Kori-Shudda Thodi",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4197",
          "Name": "Sami Ninne-Pantuvarali",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Satkala Narasayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4198",
          "Name": "Sami Ninne-Shankarabharanam",
          "Type": "Varnam-Tana",
          "Ragam": "Dheera Sankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4199",
          "Name": "Sami Ninne-Shree",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4200",
          "Name": "Sarasuda-Saveri",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kottuvasal Venkata Ramaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4201",
          "Name": "Sumasaayaka-Karnataka Kapi",
          "Type": "Varnam-Tana",
          "Ragam": "Karnataka Kapi",
          "Thalam": "Thisra Ekam",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4202",
          "Name": "Tharunam yaen-Sama",
          "Type": "Varnam-Tana",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4203",
          "Name": "Tharuni ninnu-Khambhoji",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Violin Ponnuswamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4204",
          "Name": "Thirumal maruga-Andolika",
          "Type": "Varnam-Tana",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4205",
          "Name": "Valachi vacchi-Nava Ragamalika",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4206",
          "Name": "Vallabhai Nayaka-Mohana Kalyani",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi G Jayaraman",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4207",
          "Name": "Vanajakshiro-Kalyani",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram/Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4208",
          "Name": "Vanjaksha-Behag",
          "Type": "Varnam-Tana",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TRS",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "4209",
          "Name": "Undu Kuladeiva raaman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Lord Rama",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4210",
          "Name": "GangaiANi senchadaiyanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Ekam",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4211",
          "Name": "KadaikkaN nokki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Mylapore Kabaleeshwarar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4212",
          "Name": "Kalitheerumo karuNai varumo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4213",
          "Name": "Karthikeya Kaangeya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4214",
          "Name": "Kundram kudi konda velavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4215",
          "Name": "Taamasam yaen swami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4216",
          "Name": "Devi paadam panindhaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4217",
          "Name": "Pathitha paavana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Lord Rama",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4218",
          "Name": "Raajagopaala devaadhi devanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "On Rajagopalaswami",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4219",
          "Name": "Thiruvazhundhoor maadhavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Triputa",
          "Praise of": "On Thiruvazhundur Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4220",
          "Name": "Mariyaadhai thaanae ayyanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4221",
          "Name": "Unpaadhamae charanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "On Thirukudandhai Sarangapani",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4222",
          "Name": "Shiva ganga nagara nivasini Sri rajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4223",
          "Name": "Padamalarae gathiyena",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4224",
          "Name": "adi malar iNai illaamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4225",
          "Name": "kalladha vezhai allavo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4226",
          "Name": "Dinameedhae natrinamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4227",
          "Name": "Marundhalitharulvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4228",
          "Name": "Eesanae indha vezhaikku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4229",
          "Name": "Thaayae ezhaipaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4230",
          "Name": "Nee gathiyalladhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4231",
          "Name": "Sree Shanmukham sumukham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4232",
          "Name": "aLavillaiyae nilaayathaakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4233",
          "Name": "endha vidha munnarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4234",
          "Name": "Sollu paapaa sollu paapaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4235",
          "Name": "Singaaravelavan vandhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4236",
          "Name": "Ninai manamae skandhanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4237",
          "Name": "vaanor vanangum annaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Lakshmi-Saraswathi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4238",
          "Name": "vandhadhellam varattum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4239",
          "Name": "Nee arul puriya vaendum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4240",
          "Name": "paaraamukham yaenayya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4241",
          "Name": "Sreenivasa tava charana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4242",
          "Name": "Thathvamariya tharamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "On Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4243",
          "Name": "malariNai thuNaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Roopakam",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4244",
          "Name": "Inbam enbadhilayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4245",
          "Name": "Karpagamae kadaikaN",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "On Mylapore Karpagambal",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4246",
          "Name": "Saamaja varagamana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "On Lord Rama",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4247",
          "Name": "Raamam bhajatha manujaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "On Lord Rama",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4248",
          "Name": "Naam uravu koLLum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4249",
          "Name": "Raadhaa mukha kamala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindustani Kaapi",
          "Thalam": "Adi",
          "Praise of": "On Radha Devi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4250",
          "Name": "Undaendru urudhi koLvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4251",
          "Name": "Kamalap padha malariNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4252",
          "Name": "KaaNak kaN kODI",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "On Mylapore Kabaleeshwarar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4253",
          "Name": "Sikkal maviya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4254",
          "Name": "Kumaran thaaL paNindhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4255",
          "Name": "Unnai thudhikka arul thaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4256",
          "Name": "Dharmasamvardhanith thaayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "On Dharmasamvardhini",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4257",
          "Name": "Anbilayae nin pada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4258",
          "Name": "KadaikkaN paarvai adhu podhumae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4259",
          "Name": "ThiruvaLar mayilayin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "On Mylapore Karpagamabal",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4260",
          "Name": "parpala porpaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4261",
          "Name": "Sree raaman ravikula soman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4262",
          "Name": "kannmanamurugach seiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4263",
          "Name": "Piraviyadhanaar payanen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4264",
          "Name": "Sree Ganapathiyae thiruvarul",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "On Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4265",
          "Name": "Abhayaambika ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4266",
          "Name": "Chandra kalaavadhamsam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4267",
          "Name": "Thirukkumaranaai avadharithaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4268",
          "Name": "Padmanabhan maruga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4269",
          "Name": "Saa sabha nabhonibha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Sabha Varnanai",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4270",
          "Name": "Gaanarasamudan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4271",
          "Name": "Mahaprabho sree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4272",
          "Name": "Swami undan charanam charanam",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4273",
          "Name": "Karpagaambikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4274",
          "Name": "Chitham Maghizh vithidudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4275",
          "Name": "Paadha malarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4276",
          "Name": "Kaa vaa vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4277",
          "Name": "Unadu dhayai yenai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4278",
          "Name": "Neeyallavo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4279",
          "Name": "Dasharadhaathmajam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4280",
          "Name": "Iha param ennum iru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4281",
          "Name": "Aandavanae unnai nambinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4282",
          "Name": "Paraathpara Parameshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4283",
          "Name": "Kaameshwara Krupaakara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4284",
          "Name": "Chidambaram yena manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4285",
          "Name": "Sindhai theLindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4286",
          "Name": "Theril yaerinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4287",
          "Name": "Devi Sree Meenalochani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "On Meenakshi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4288",
          "Name": "Padmanabha maam paahi paahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4289",
          "Name": "Raamaa Idhu dharmamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4290",
          "Name": "Amba un mel bhaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4291",
          "Name": "Sree Maadhava Vasudeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4292",
          "Name": "Krishnanaama Bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4293",
          "Name": "Naama Bhajarae Raama Naama Bhajarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4294",
          "Name": "Jayavijayee Bhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4295",
          "Name": "Kabali, Karunai nilavu pozhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4296",
          "Name": "Gathiyudhavu maamadhu ninadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Kapi, Vasantha, Bilahari, Sowrashtram)",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4297",
          "Name": "Maara janaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (YadukulaKahmbhoji, Saveri, Begada, Saraswathi, GowriManohari)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4298",
          "Name": "Dharaathmajaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4299",
          "Name": "Eesanan paarkuvamai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "On Lord Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4300",
          "Name": "Manak kurangu adangavidil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4301",
          "Name": "Sree raama jaya mangalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4302",
          "Name": "KaruNai seivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "On Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "Tune based on Raghunayaka-Hamsadhwani - Tyagaraja",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4303",
          "Name": "Nijam unnai nambinaen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Tune based on Paridhana Michite-Bilahari",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4304",
          "Name": "Nin Charana malarae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Tune based on NeedhuCharanaMulae",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4305",
          "Name": "Haranae innum dayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KarnatakaBehag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Tune based on Hari-Naenendhu vedhakudhuraa",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4306",
          "Name": "Kaarirul oththa karutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Tune based on Thirupugazh-Devan vadithu sorindhadho",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4307",
          "Name": "AaraNamum aagamamum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4308",
          "Name": "Anbargalae aNNan thambigalae",
          "Type": "Vazhinadai Chindu",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4309",
          "Name": "Punniya ayodhi nagaril",
          "Type": "Nondi Chindu",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Sree Rama Charithram - Hanuman narrating to Bharathan",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai-Part 1",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1939",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4310",
          "Name": "Kosalai pudhalvanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Naatai, Kharaharapriya, Pantuvarali, Shuddha Saveri, Mukhari, Sahana, Vasantha, Thodi, Mohanam, Nadanamakriya, Khamas, Kalyani, AnandaBhairavi, Abhogi, Khambhoji, Simhendramdhyamam, Bhairavi, Arabhi, Kedaram, Navarasa Kannadaa, Manirangu, BrindavanaSaranga, Surutti, Madhyamavathi)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Sree Rama Charithra Geetham",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan's Sri Raama Charitra Keerthanam",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1998",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4311",
          "Name": "Amboruhapaadhamae",
          "Type": "Varnam-Tana",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4312",
          "Name": "kuvalayaakshi kushalam",
          "Type": "Varnam-Tana",
          "Ragam": "Kathanakudhoohalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4313",
          "Name": "Vara vallabha ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4314",
          "Name": "santhathamu ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4315",
          "Name": "saama gaana lolae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4316",
          "Name": "paramakrupaa saagari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4317",
          "Name": "Sree chakra raja nilayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShivaSakthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4318",
          "Name": "Indha paraaku neevae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4319",
          "Name": "kamal charanaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "AmruthaBehag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4320",
          "Name": "parithaapamulaeka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4321",
          "Name": "Ninnu pogada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4322",
          "Name": "vadharaerammaa nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4323",
          "Name": "Marivere gati naakevvaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maalavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4324",
          "Name": "manasaaraganae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4325",
          "Name": "KaaraNamaeme",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4326",
          "Name": "bhuvanathraya sammohana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4327",
          "Name": "madhuraapuri kalyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4328",
          "Name": "mamakulaeshwaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4329",
          "Name": "Entho moralida",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4330",
          "Name": "naamoralanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Narayani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4331",
          "Name": "samaana rahithae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SaarangaTharangini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4332",
          "Name": "Karuna jooda radha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4333",
          "Name": "bhaaramaa e baaluni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanaadam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4334",
          "Name": "varada nibhuNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4335",
          "Name": "Mohana krishna jagat",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kathanakudhoohalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4336",
          "Name": "Niravadhi sukha daayaki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4337",
          "Name": "nannubrova nee garudha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SenjuKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4338",
          "Name": "kavalai ellam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4339",
          "Name": "padamalariNaiyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4340",
          "Name": "Unnadiyae Gathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4341",
          "Name": "En manath thaamarai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4342",
          "Name": "kanavilum kamala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GNB",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of GNB (G N Balasubramaniam)",
          "Book Author": "S. Kalyaanarman/ V. Ramachandran",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4343",
          "Name": "Sami ninne Kori",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4344",
          "Name": "Kamalambha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4345",
          "Name": "Karunaanidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4346",
          "Name": "ambha ninnu nera nammithi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Kalyani, Gowlipantu, Naatakurinji, Chakravaham, Neelambari)",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4347",
          "Name": "koniyaada tharamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4348",
          "Name": "brochuda kevarunnaaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4349",
          "Name": "maruvaga dhaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4350",
          "Name": "Yaemani pogadudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4351",
          "Name": "raama raama ravikula somaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bangaala",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4352",
          "Name": "nera mencha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4353",
          "Name": "naamanavini vinavaiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4354",
          "Name": "yaeminaeramu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4355",
          "Name": "enthani naevaedu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4356",
          "Name": "Endhuku nirdhaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4357",
          "Name": "nannu karuNinchi brovu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4358",
          "Name": "Brova samayamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4359",
          "Name": "mari mari ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4360",
          "Name": "praNadhaarthi hara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4361",
          "Name": "ninnu nammina vaaDanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4362",
          "Name": "Dhurusugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4363",
          "Name": "Sree mahaa ganapathy",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4364",
          "Name": "yadukua thilaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4365",
          "Name": "Enthani ninunae",
          "Type": "Javali",
          "Ragam": "Unknown",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4366",
          "Name": "yevathe thaaLunuraa",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Eka",
          "Praise of": "N/A",
          "Composer": "Karur Devudu Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Garbapurivaasar Urupadigal",
          "Book Author": "T Vishwanathan",
          "Year of Publication": "1968",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4367",
          "Name": "Bhuddhi Devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malahari",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Ganesha",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4368",
          "Name": "Sree mangaLavANi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Roopakam",
          "Praise of": "On Saraswathi",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4369",
          "Name": "Maha bhaleshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "On Mahabaleshwar",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4370",
          "Name": "NaaraayaNaa naaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "On Vishnu-Narayana",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4371",
          "Name": "Guru naathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalatha",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Guru",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4372",
          "Name": "Sree Aanjaneya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "On Lord Hanuman",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Sthuthi Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4373",
          "Name": "Sampath pradae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4374",
          "Name": "Thappa manni sow",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maayapratheebam",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4375",
          "Name": "Sreemadh simhaasaneshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4376",
          "Name": "Sree vidya vedhya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4377",
          "Name": "Sree chakrapura vaasini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gamanashrama",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4378",
          "Name": "Sree Kanta dayithae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malavi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4379",
          "Name": "Devi Gowri ninna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4380",
          "Name": "Girija devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Veenaadhari",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4381",
          "Name": "Bhuvaneshwarya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4382",
          "Name": "Mahakaali mahharagjnye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4383",
          "Name": "Devi sree Mahalakshmi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariNaarayani",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4384",
          "Name": "Amba mahavaaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SaraswathiManohari",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4385",
          "Name": "manOnmaNi mandhahAsini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4386",
          "Name": "sahasra seersha samyukthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaLalitha",
          "Thalam": "Khanda Jathi Jhampa Thalam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4387",
          "Name": "sahasrakara manDithaayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4388",
          "Name": "koushumbha vasano",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karanataka Kapi",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4389",
          "Name": "Rathna kanjuka dhaariNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4390",
          "Name": "Ganesha Skanda janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagabhushani",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4391",
          "Name": "japaakusuma bhaasurae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4392",
          "Name": "Umaaninagae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4393",
          "Name": "kaathyaayani skanda janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4394",
          "Name": "Durgaa devi duritha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4395",
          "Name": "MandriNi maathanga thanayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4396",
          "Name": "Dhandini sree chandikaambae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SalagaBhairavi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4397",
          "Name": "jayaadevi jagan maathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowdaMalhaar",
          "Thalam": "Triputa",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4398",
          "Name": "karaanguli nagOthpanna naarayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Ata",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4399",
          "Name": "sachaamara ramaavaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4400",
          "Name": "saraseeruhaakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4401",
          "Name": "BhagaLaadevya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4402",
          "Name": "Bhaalae paripaali sow",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4403",
          "Name": "Chakresi devi sree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakrapradeepta",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4404",
          "Name": "vijayaambikae vimalaathmikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanagari",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4405",
          "Name": "panchaasya yaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4406",
          "Name": "haridhraa kumkuma priyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4407",
          "Name": "Mahabalaathri nilayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4408",
          "Name": "jaya mahishaasuramardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4409",
          "Name": "madhukadaipa samharthri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4410",
          "Name": "madhuraapura naayikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4411",
          "Name": "kaameshwari sree kaamakshi gowri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4412",
          "Name": "yOga nidraE yOga mudraE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4413",
          "Name": "Thaayae Bhavani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4414",
          "Name": "Chandikae sree chamundeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Narayani",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4415",
          "Name": "sathi devya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4416",
          "Name": "Chakra raaja rathaarudae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4417",
          "Name": "Shrushti sthithyantha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4418",
          "Name": "annapoornaeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4419",
          "Name": "jwallajihvae jwaalaamukhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4420",
          "Name": "kaaLaraadhri swaroopini amba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Urmika",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4421",
          "Name": "niraamayae niranjanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4422",
          "Name": "rathna bhushani rathnaambharae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4423",
          "Name": "parama mangaLathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijavanthi",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4424",
          "Name": "Sree Shubhaa devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4425",
          "Name": "sakala sampath pradae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Jhampai",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4426",
          "Name": "moola prakriti roopae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4427",
          "Name": "aaryaa devi amaresha nuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4428",
          "Name": "parama paavanae paarvathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MeghaRanjani",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4429",
          "Name": "sree parameshwari devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4430",
          "Name": "Bindu peeta kruthaa vaasae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4431",
          "Name": "chandra mandala madhyagae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4432",
          "Name": "Chidagni kunda sambhuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4433",
          "Name": "Vindhyaachala nivaasini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Triputa",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4434",
          "Name": "haragriva agastya poojithae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "UmaBharanam",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4435",
          "Name": "Soorya chandraagni lochanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4436",
          "Name": "Jaalandhara supeetasthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Valachi",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4437",
          "Name": "Sree shivae jaya vaibhavae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4438",
          "Name": "Devi Daakshaayani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4439",
          "Name": "Eeshwari raajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4440",
          "Name": "Nivaarana sampoojyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GuhaRanjani",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4441",
          "Name": "navaakshara manu sthuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaratnavilasam",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4442",
          "Name": "Nava laavanya roopaadyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4443",
          "Name": "Dwwadreem sadjwala daayuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4444",
          "Name": "kaamesha bhaddha maangalyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BudhaManohari",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4445",
          "Name": "Chandra rekha vibhushithae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4446",
          "Name": "charaachara jagatroopae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4447",
          "Name": "nithyaklinae nishkalangae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4448",
          "Name": "aparaajithae amareshanuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannada",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4449",
          "Name": "Uraga raaja mani valayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pasupathipriya",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4450",
          "Name": "Sree lalitha jagan maathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4451",
          "Name": "maha vishnu sodari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pasupathipriya",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4452",
          "Name": "Devi sree damshtraagaraaLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamirKalyani",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4453",
          "Name": "Vajreshi maathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4454",
          "Name": "vanhi vaasini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4455",
          "Name": "sarva mangaLa rUpADyaE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4456",
          "Name": "Sachidaananda vigrahae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devagandhari",
          "Thalam": "Khanda Chapu",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4457",
          "Name": "Ashtaadasha SupeeDasthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4458",
          "Name": "bhEruNdaambae bheDuvE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4459",
          "Name": "Bhairavi parameshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4460",
          "Name": "paraathparae bhaagarinuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4461",
          "Name": "ruNDamaala lasathkanDe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushavali",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4462",
          "Name": "bhaNDaasura vimardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4463",
          "Name": "purandara vinuthae puNDrekshu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4464",
          "Name": "Sugandha pushpa bhaaNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4465",
          "Name": "Devi shiva dhoothi paradhaevathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4466",
          "Name": "vedha maathae vedha vinuthae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4467",
          "Name": "shAnkari shAdodhari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4468",
          "Name": "Simhavaahanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhavahini",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4469",
          "Name": "chadhushashti upachaaraadyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Triputa",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4470",
          "Name": "yogini gaNasEvithE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4471",
          "Name": "vanadurgE vanaspathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vanaspathi",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4472",
          "Name": "BhadrakALi namOusthuthE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4473",
          "Name": "kadambha vanavaasini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Triputa",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4474",
          "Name": "chanDa munDa siraschEdhri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4475",
          "Name": "maharAgny mahAlakshmi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Triputa",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4476",
          "Name": "sudhAmayi sudhAnidhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amruthavarshini",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4477",
          "Name": "Sree chakra vara thaatangae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4478",
          "Name": "Sree shaila brahmaraambhikae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kamala Manohari",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4479",
          "Name": "Sree raajaraaja varadhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Roopakam",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4480",
          "Name": "Sreeamdh tripura sundari ambA",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4481",
          "Name": "Jaya mangalam nithya Shubha mangalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "On Chamundeshwari",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "Chamundambha Ashtothra Keerthanaigal - Shatha naama Keerthanams - Mangalam",
          "Language": "N/A",
          "Book Title": "Harikesanallur L. Muthiah Bhagavathar Sahithyangal - Part 2 (Chamundeshwari Ashtothra Keerthana Malai)",
          "Book Author": "M Vaidhyalingam",
          "Year of Publication": "1969",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4482",
          "Name": "Sami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4483",
          "Name": "Ra Ra Sami",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4484",
          "Name": "Ninne emtho",
          "Type": "Varnam-Tana",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4485",
          "Name": "Ninnukori",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4486",
          "Name": "Vanajaakshaa",
          "Type": "Varnam-Tana",
          "Ragam": "Varali",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4487",
          "Name": "Sami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Narayani",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4488",
          "Name": "Nera Nammithi-Kaanada",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4489",
          "Name": "Thaani kori kenu",
          "Type": "Varnam-Pada",
          "Ragam": "Vasantha",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4490",
          "Name": "Chindadeerachara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4491",
          "Name": "Parithaapamula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannada",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4492",
          "Name": "Sree raama nannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4493",
          "Name": "Karunatho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4494",
          "Name": "Raghunaathanannu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SuraRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4495",
          "Name": "Anudinamunu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4496",
          "Name": "Raamaa ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4497",
          "Name": "Nijamukharaama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4498",
          "Name": "Sadbhakthiyu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4499",
          "Name": "Parulaseva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4500",
          "Name": "Ninnu kolisi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4501",
          "Name": "Idu paraagu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4502",
          "Name": "Sathathamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4503",
          "Name": "Chinthakinji",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SaraswathiManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4504",
          "Name": "Samayamidhaeyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4505",
          "Name": "Saamaja varadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4506",
          "Name": "Paarthasaarathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4507",
          "Name": "Nee paadhamulae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4508",
          "Name": "Paripaalayamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4509",
          "Name": "Neekela naayaDa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4510",
          "Name": "Sree venkatesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4511",
          "Name": "Sree raghukula nidhim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4512",
          "Name": "Sadguru swami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4513",
          "Name": "Parama paavana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4514",
          "Name": "Saraguna paalimpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4515",
          "Name": "Nirupamaana",
          "Type": "Javali",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4516",
          "Name": "Marulukonnadira",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4517",
          "Name": "Veganeevuvaani",
          "Type": "Javali",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4518",
          "Name": "VathaninE",
          "Type": "Javali",
          "Ragam": "Kapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4519",
          "Name": "SaanarO",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4520",
          "Name": "SarasamulaadE",
          "Type": "Javali",
          "Ragam": "Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4521",
          "Name": "saaraghumera",
          "Type": "Javali",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4522",
          "Name": "Thillana-Thodi",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4523",
          "Name": "Thillana-Poornachandrika",
          "Type": "Thillana",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4524",
          "Name": "Thillana-YadukulaKhambhoji",
          "Type": "Thillana",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4525",
          "Name": "Thillana-Kapi",
          "Type": "Thillana",
          "Ragam": "Kapi",
          "Thalam": "Lakshmeesham",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "108 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4526",
          "Name": "Thillana-Sindhu Bhairavi",
          "Type": "Thillana",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "108 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4527",
          "Name": "Thillana-Naatakurinji",
          "Type": "Thillana",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "108 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4528",
          "Name": "Thillana-Hindolam",
          "Type": "Thillana",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "108 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4529",
          "Name": "Thillana-Pantuvarali",
          "Type": "Thillana",
          "Ragam": "Pantuvarali",
          "Thalam": "Ragavardhani",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "72 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4530",
          "Name": "Thillana-Kapi",
          "Type": "Thillana",
          "Ragam": "Kapi",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "72 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4531",
          "Name": "Thillana-Kaanadaa",
          "Type": "Thillana",
          "Ragam": "Kaanada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "72 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4532",
          "Name": "Thillana-Shankarabharanam",
          "Type": "Thillana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "72 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4533",
          "Name": "Thillana-Paras",
          "Type": "Thillana",
          "Ragam": "Paras",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "72 Thala Thillana",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4534",
          "Name": "Valliyin kalyanam",
          "Type": "KavadiChindu",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4535",
          "Name": "Saami neepai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Sarasangi, Saveri, Navaroj, Lalitha, Varali, Arabhi, Khamas, Bhupalam, Sree)",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Ramnad Poochi Srinivasa Iyengar" ,
          "Book Author": "Sri Salem D. Chellam Iyengar",
          "Year of Publication": "1982",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4536",
          "Name": "Paripaahimaam girijaapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Subbanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Four rare compositions of Veena Subbanna",
          "Book Author": "Titte Krishna Iyengar",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4537",
          "Name": "Swarajathi-Khambhoji",
          "Type": "SwaraJathi",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Subbanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Four rare compositions of Veena Subbanna",
          "Book Author": "Titte Krishna Iyengar",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4538",
          "Name": "Swarajathi-KarnatakaKapi",
          "Type": "SwaraJathi",
          "Ragam": "Karnataka Kapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Subbanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Four rare compositions of Veena Subbanna",
          "Book Author": "Titte Krishna Iyengar",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4539",
          "Name": "Paripaahi Sree chaamundeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Bhushavali, Pancharam, NadaBrahmam, ShuddhaSaveri, KarnatakaBehag, Kannada, Begada, Saveri, Shankarabharanam, Mohanam, AnandaBhairavi, Madhyamavathi)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veena Subbanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Four rare compositions of Veena Subbanna",
          "Book Author": "Titte Krishna Iyengar",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4540",
          "Name": "SaameethiveLa",
          "Type": "Varnam-Tana",
          "Ragam": "Kannada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4541",
          "Name": "thaamasinjaka",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4542",
          "Name": "Ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Ahiri",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tarangampadi Panchanadayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4543",
          "Name": "neevantidaivamu",
          "Type": "Varnam-Tana",
          "Ragam": "Neelambari",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tarangampadi Panchanadayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4544",
          "Name": "marasitLundEthi",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4545",
          "Name": "Daari theliyaka",
          "Type": "Varnam-Tana",
          "Ragam": "Darbar",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4546",
          "Name": "nenaruleni",
          "Type": "Varnam-Tana",
          "Ragam": "Balahamsa",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4547",
          "Name": "Vanajaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Varali",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4548",
          "Name": "Sami ninnekori",
          "Type": "Varnam-Tana",
          "Ragam": "Narayani",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4549",
          "Name": "Thaani kori kenu",
          "Type": "Varnam-Pada",
          "Ragam": "Vasantha",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4550",
          "Name": "Saamini rammanavae",
          "Type": "Varnam-Pada",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Tyagayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4551",
          "Name": "parabrahmamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kapinarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4552",
          "Name": "Nannu brova",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4553",
          "Name": "Idivaarulaetiki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4554",
          "Name": "Aasamayamuna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4555",
          "Name": "naamavini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Garbhapurivasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4556",
          "Name": "Yaemi neramu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Garudadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Garbhapurivasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4557",
          "Name": "Teliyaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Garbhapurivasar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4558",
          "Name": "samayamidhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4559",
          "Name": "ninnu kolisi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4560",
          "Name": "Raama ninne",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4561",
          "Name": "parulaseva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4562",
          "Name": "nijamukha raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4563",
          "Name": "Idu paraagu jaeya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer":"Ramnad Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4564",
          "Name": "yemiseyuthunade",
          "Type": "Javali",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pattabhiramiah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4565",
          "Name": "Thillana-Sindhu Bhairavi",
          "Type": "Thillana",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4566",
          "Name": "palukutase",
          "Type": "Anubandham",
          "Ragam": "PhalaManjari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4567",
          "Name": "naravara raghunandana",
          "Type": "Anubandham",
          "Ragam": "Manirangu",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare compositions of Patnam Subramanya Aiyar, Ramnad Srinivasa Iyengar, Garbhapurivasar, PanchanadaIyer, Pattabhiramiah, Tiruvotriyur Thyagayyar",
          "Book Author": "C Venkatrama Iyer, B. Krishnamurthi",
          "Year of Publication": "1958",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4568",
          "Name": "Anbirkum Undo",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "Thirukural Tana Varnam",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4569",
          "Name": "kaNNanin karuNaiyai",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4570",
          "Name": "neelakanta devan aruL",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "L R Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4571",
          "Name": "Paa Maa nan mazhai thannil",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4572",
          "Name": "vetrilai paakku unakkendru",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Srinivasachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4573",
          "Name": "Saarasaaksha krupaa",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "T V Lakshminarasimhan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4574",
          "Name": "murugaa muthamizhisai paada",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Fiddle Krishnaswamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4575",
          "Name": "chinmayaa nanda swaroopa",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjai V Venkataramaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4576",
          "Name": "Soghamaanaen thozhiyae",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4577",
          "Name": "kaninthOdi vandhaen",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nataraja sundaram Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4578",
          "Name": "Era Napai-Thodi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4579",
          "Name": "Sarigaa nidhani",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "On Manali Venkatakrishna Mudali",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "Swarakshara Varnam",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4580",
          "Name": "Irundhombhi illvazhavu",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4581",
          "Name": "madana roopaa ninnai naan",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4582",
          "Name": "Vara vaenum swaminaathaa",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4583",
          "Name": "Paadi maghizha innisai",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4584",
          "Name": "Vanajaakshi ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Tyagayyar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4585",
          "Name": "ezhu pirappum theeyavai",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4586",
          "Name": "Inidhu Inidhu Inidhu kaaN",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4587",
          "Name": "vel muruganae",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "M G Gowri Kumari",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4588",
          "Name": "sedi nee pOi arindhu",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nataraja sundaram Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4589",
          "Name": "maiyalaaginaaL",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kunnakudi Venkatarama iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4590",
          "Name": "Swami naatha ",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4591",
          "Name": "Swaami ninne nammina",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kuzhalisai K R Ganapathy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4592",
          "Name": "Chalamelara",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kunnakudi Venkatarama iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4593",
          "Name": "aravaazhi andhaNan",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4594",
          "Name": "pazhani velan",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4595",
          "Name": "Thiru murugan thEnisai",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4596",
          "Name": "thaamasinjaka",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4597",
          "Name": "nandri marappadhu",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4598",
          "Name": "kandhaa ninadharul",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4599",
          "Name": "chENudaiyor pugazh",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4600",
          "Name": "sagiyaa nenemi",
          "Type": "Varnam-Pada",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K V Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4601",
          "Name": "maayan gopaalan",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4602",
          "Name": "Sree raaja maathangi",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikeshanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4603",
          "Name": "serivarindhu seermai",
          "Type": "Varnam-Tana",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4604",
          "Name": "sinamadaindhu seerazhiyum",
          "Type": "Varnam-Tana",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4605",
          "Name": "senchol ulavum",
          "Type": "Varnam-Tana",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4606",
          "Name": "swamikke sari evvarae",
          "Type": "Varnam-Tana",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4607",
          "Name": "vaan nindru ulagam",
          "Type": "Varnam-Tana",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4608",
          "Name": "naaraNan paada malar",
          "Type": "Varnam-Tana",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4609",
          "Name": "thaaN malar paNindhu paadum",
          "Type": "Varnam-Tana",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4610",
          "Name": "pannaga shayana",
          "Type": "Varnam-Tana",
          "Ragam": "Revagupti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4611",
          "Name": "aruLilaarkku avvulagam",
          "Type": "Varnam-Tana",
          "Ragam": "Jayanthasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4612",
          "Name": "Dinamum unnai thudhi seiyum",
          "Type": "Varnam-Tana",
          "Ragam": "Jayanthasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4613",
          "Name": "Daya jae suDagu",
          "Type": "Varnam-Tana",
          "Ragam": "Jayanthasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4614",
          "Name": "maha ganapathaey namasthae",
          "Type": "Varnam-Tana",
          "Ragam": "Jayanthasree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "G N Dhandapani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4615",
          "Name": "sirappu eenum",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Seemanthini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4616",
          "Name": "endha vELaiyum kanDa vELaiyE",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Seemanthini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4617",
          "Name": "Deva Deva Maheshwara",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Seemanthini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4618",
          "Name": "naDuvindri nanporuL",
          "Type": "Varnam-Tana",
          "Ragam": "Nagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4619",
          "Name": "yekan aagi anekan aagi",
          "Type": "Varnam-Tana",
          "Ragam": "Nagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4620",
          "Name": "Sree Durga Lakshmi Saraswathi",
          "Type": "Varnam-Tana",
          "Ragam": "Nagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4621",
          "Name": "purangkoori poithuyir",
          "Type": "Varnam-Tana",
          "Ragam": "Ghanta",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4622",
          "Name": "senthaamaraiyil",
          "Type": "Varnam-Tana",
          "Ragam": "Ghanta",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4623",
          "Name": "nee vega gathiyani",
          "Type": "Varnam-Tana",
          "Ragam": "Ghanta",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4624",
          "Name": "Baala krishnam Bhajeham",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4625",
          "Name": "Bhaavayaami",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4626",
          "Name": "tharuNam thaayae",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4627",
          "Name": "Gowri parameshwari",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 1",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1973",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4628",
          "Name": "payanir sol paaraatu",
          "Type": "Varnam-Tana",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4629",
          "Name": "engum niraivaai",
          "Type": "Varnam-Tana",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4630",
          "Name": "saama gaana priyae",
          "Type": "Varnam-Tana",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4631",
          "Name": "thannuyir neepinum",
          "Type": "Varnam-Tana",
          "Ragam": "Natakapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4632",
          "Name": "nalla thamizh tharum iyalisai",
          "Type": "Varnam-Tana",
          "Ragam": "Natakapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4633",
          "Name": "naatakapriyae narayani",
          "Type": "Varnam-Tana",
          "Ragam": "Natakapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4634",
          "Name": "Iyathin neengi thelindhaarku",
          "Type": "Varnam-Tana",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4635",
          "Name": "thanga avarkkingu ini",
          "Type": "Varnam-Tana",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nataraja sundaram Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4636",
          "Name": "guNa nalam koNda enthan",
          "Type": "Varnam-Tana",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4637",
          "Name": "maadhavan avathaaram",
          "Type": "Varnam-Tana",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4638",
          "Name": "nerunal uLanoruvan",
          "Type": "Varnam-Tana",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4639",
          "Name": "veeNai innisai aanavanae",
          "Type": "Varnam-Tana",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4640",
          "Name": "moolaadhaara ganapathiyae",
          "Type": "Varnam-Tana",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4641",
          "Name": "manaithakka maaNbuDaiyal",
          "Type": "Varnam-Tana",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4642",
          "Name": "azhaguruvaagiya",
          "Type": "Varnam-Tana",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4643",
          "Name": "vallamai tharuvaai murugaa",
          "Type": "Varnam-Tana",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4644",
          "Name": "saahasam yaen",
          "Type": "Varnam-Tana",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4645",
          "Name": "azhukkaatrin",
          "Type": "Varnam-Tana",
          "Ragam": "VasanthaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4646",
          "Name": "neethavaai kaatharuL vaayae",
          "Type": "Varnam-Tana",
          "Ragam": "VasanthaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4647",
          "Name": "thaedi nee sendru solvaai",
          "Type": "Varnam-Tana",
          "Ragam": "VasanthaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4648",
          "Name": "nee pada pankaja mula",
          "Type": "Varnam-Tana",
          "Ragam": "VasanthaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karaikudi rajamani iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4649",
          "Name": "padaikudi koozh",
          "Type": "Varnam-Tana",
          "Ragam": "LalithaPanchamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4650",
          "Name": "paimpon maeniyil",
          "Type": "Varnam-Tana",
          "Ragam": "LalithaPanchamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4651",
          "Name": "maadhurya gaanaamrutha",
          "Type": "Varnam-Tana",
          "Ragam": "LalithaPanchamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4652",
          "Name": "kedu valyaan enbadhu",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4653",
          "Name": "ulagaLandha uthamanae",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4654",
          "Name": "neela megham pol maeni",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lalgudi Fiddle Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4655",
          "Name": "neeyae gathi yena nambinaen",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4656",
          "Name": "paaraai en vinai theeraai",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4657",
          "Name": "sharavana bhava shanmuga",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kanchipuram Fiddle Krishnaswamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4658",
          "Name": "maayamalawaGowlai vagaiyaai paadi",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Srinivasachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4659",
          "Name": "Sree maathru bhutheshwari",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4660",
          "Name": "sarasaangi nee pai",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Raghavendrachariar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4661",
          "Name": "Sree raaja rajeshwari shankari",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Unknown",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4662",
          "Name": "arivudaiyaar aavadharivar",
          "Type": "Varnam-Tana",
          "Ragam": "Malahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4663",
          "Name": "koil konDa en manak kovae",
          "Type": "Varnam-Tana",
          "Ragam": "Malahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4664",
          "Name": "shambu kumaaranae baalanae",
          "Type": "Varnam-Tana",
          "Ragam": "Malahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4665",
          "Name": "thurandhaarkum thuvaadhavarkum",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4666",
          "Name": "thendral vandhu endran",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4667",
          "Name": "pazhamudhir solai vaLar azhaganae",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nataraja sundaram Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4668",
          "Name": "maanae moham sahiyaenae",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N Koteeswara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4669",
          "Name": "tharuNam idhuvae shanmuga",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4670",
          "Name": "iravum pagalum",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Srinivasachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4671",
          "Name": "yedho kaaraNam ariyaena",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4672",
          "Name": "Sreedevi un kamalapaadham",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dandayutha Deekshithar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4673",
          "Name": "mahashae, vaamae shyamale",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4674",
          "Name": "Sarasuda ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kothavasal Venkatrama Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4675",
          "Name": "paaththUN",
          "Type": "Varnam-Tana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4676",
          "Name": "endrum innisai mazhaiyil",
          "Type": "Varnam-Tana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4677",
          "Name": "naadha thanuvara ganesha",
          "Type": "Varnam-Tana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4678",
          "Name": "Sree maathangi rajarajeshwari",
          "Type": "Varnam-Tana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4679",
          "Name": "vanajaakshiro vibhuni",
          "Type": "Varnam-Tana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thanjavur Adi Ponniah",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4680",
          "Name": "aganamarndhu eedhalin",
          "Type": "Varnam-Tana",
          "Ragam": "Manolayam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4681",
          "Name": "thaemadhura isai",
          "Type": "Varnam-Tana",
          "Ragam": "Manolayam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4682",
          "Name": "swamikkini sari evvare",
          "Type": "Varnam-Tana",
          "Ragam": "Manolayam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4683",
          "Name": "vaaimai yenappaduvadhu",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4684",
          "Name": "paNN sumandha maeniyanae",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4685",
          "Name": "thavamae seidhun thaal Panindhaen",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4686",
          "Name": "ika para sukha dhaayini",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4687",
          "Name": "sarasija netri",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karaikudi rajamani iyengar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 2",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4688",
          "Name": "irumai vagai therindhu",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4689",
          "Name": "kaalapaasath thaalae",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4690",
          "Name": "aanai maa muganae",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4691",
          "Name": "aadhura jana dhaarini",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4692",
          "Name": "sarasija naabha maam paahi",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Trivandram/Pallakad Parameshwara Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4693",
          "Name": "saadhu jana paripaalini",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4694",
          "Name": "palumaaru ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4695",
          "Name": "swami ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kothavasal Venkatrama Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4696",
          "Name": "nuNNiya nool pala",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4697",
          "Name": "melaith thavamillaa",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4698",
          "Name": "naamagalae veNthaamarai naayagiyae",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "On Saraswathi",
          "Composer": "Lalgudi Fiddle Neelamegham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4699",
          "Name": "gaNa naathaa",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4700",
          "Name": "sEkkizhaar thiruvadi thanaiyae",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "On Sekkizhaar (Compiler of Panirendu thirumurai)",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4701",
          "Name": "ninne kori yunnaanu",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4702",
          "Name": "kanakaangi pai nee",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thanjavur Adi Ponniah",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4703",
          "Name": "selimi kori vachchi",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4704",
          "Name": "evarunnaaruraa",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4705",
          "Name": "paramavyoma parama jyothi",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4706",
          "Name": "sinamennum sErndhAraik kolli",
          "Type": "Varnam-Tana",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4707",
          "Name": "vidai maghizhndheriya",
          "Type": "Varnam-Tana",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4708",
          "Name": "Ninaindhu ninaidhu paaduven",
          "Type": "Varnam-Tana",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4709",
          "Name": "maathava guNamaagi",
          "Type": "Varnam-Tana",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dandayutha Deekshithar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4710",
          "Name": "Swamikki sari evvare",
          "Type": "Varnam-Tana",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4711",
          "Name": "arankadai nindrarul",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4712",
          "Name": "anbuLLa durai enthan",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "on Raja sir Annamalai Chettiyar",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4713",
          "Name": "thanjam pugundhEn endru sollaDi",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4714",
          "Name": "swayam varEnyam",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4715",
          "Name": "aanandha nata maadinaar",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4716",
          "Name": "payan maram",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4717",
          "Name": "paadham paNindhEn endru",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4718",
          "Name": "Sree raghukula thilakaa",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4719",
          "Name": "ozhukkam udaimai kudimai",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4720",
          "Name": "aruL thara viraindhu",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4721",
          "Name": "Kamala manohari anbin thaayae",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4722",
          "Name": "neevamti saamini nEnemthu",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thanjavur Adi Chinniah",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4723",
          "Name": "Sree ramachandru naathu",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4724",
          "Name": "Swamikki sari evvare",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4725",
          "Name": "sevikku uNavilladha",
          "Type": "Varnam-Tana",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4726",
          "Name": "sondhamum bandhamum",
          "Type": "Varnam-Tana",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4727",
          "Name": "maa karuNai pozhi vaanmughilE",
          "Type": "Varnam-Tana",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4728",
          "Name": "megharanjani gaanaamrutha varshini",
          "Type": "Varnam-Tana",
          "Ragam": "MeghaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4729",
          "Name": "vasai enba vaiyaththaarkellam",
          "Type": "Varnam-Tana",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4730",
          "Name": "veNNira thaamarai meedhu",
          "Type": "Varnam-Tana",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "On Saraswathi",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4731",
          "Name": "Saama vedha rasikae",
          "Type": "Varnam-Tana",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4732",
          "Name": "kaalam karudhi",
          "Type": "Varnam-Tana",
          "Ragam": "Pahadi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4733",
          "Name": "kaama moha daasanaanEnE",
          "Type": "Varnam-Tana",
          "Ragam": "Pahadi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4734",
          "Name": "sindhai kavarndha senthil",
          "Type": "Varnam-Tana",
          "Ragam": "Pahadi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4735",
          "Name": "eeveLa nannu rakshimchutaku",
          "Type": "Varnam-Tana",
          "Ragam": "Pahadi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4736",
          "Name": "poruththal irappinai",
          "Type": "Varnam-Tana",
          "Ragam": "Pahadi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4737",
          "Name": "maamayil mel yeri varum",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4738",
          "Name": "sindhai thirundhi nanmai seravE",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4739",
          "Name": "maadharasi en ariyaa pizhai thanai",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvananthapuram Ksheeraabdi Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4740",
          "Name": "jala jaakshi ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4741",
          "Name": "sarasuda ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vummudi Chetti Venkatasamy Naidu",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4742",
          "Name": "dhayajE yutaku",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4743",
          "Name": "kadanarindhu kaalam",
          "Type": "Varnam-Tana",
          "Ragam": "Vaahini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4744",
          "Name": "sEvadi tharuvAi varuvAi",
          "Type": "Varnam-Tana",
          "Ragam": "Vaahini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4745",
          "Name": "paarinil nee kaameshwari",
          "Type": "Varnam-Tana",
          "Ragam": "Vaahini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvananthapuram Ksheeraabdi Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4746",
          "Name": "thaapa saanu gruheetha",
          "Type": "Varnam-Tana",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4747",
          "Name": "karima ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kothavasal Venkatrama Iyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 3",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1975",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4748",
          "Name": "eNenba yaenai yezhuthenba",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4749",
          "Name": "sadai virithaadiya",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4750",
          "Name": "uiyyum vagaiyae thandhenai",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4751",
          "Name": "bhayamellam neeki ennai",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dhandayuthapani Deekshitar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4752",
          "Name": "kaapaDutaku",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4753",
          "Name": "avaavinai aatra",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4754",
          "Name": "yedhu kobamaiyyA",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4755",
          "Name": "panneDu naaLaai unnai paaDi",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4756",
          "Name": "kayilayil inidhu vaazhum",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dhandayuthapani Deekshitar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4757",
          "Name": "aarendru arindhu vaadi sakhiyae",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "S Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4758",
          "Name": "saadhu jana priyae",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4759",
          "Name": "iDikku thuNai",
          "Type": "Varnam-Tana",
          "Ragam": "Veenaadhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4760",
          "Name": "thoNDanukku kurai yedhum",
          "Type": "Varnam-Tana",
          "Ragam": "Veenaadhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4761",
          "Name": "aadi naadhanae azhagiya velanae",
          "Type": "Varnam-Tana",
          "Ragam": "Veenaadhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4762",
          "Name": "veeNa dhanDa kamanDala dhaariNi",
          "Type": "Varnam-Tana",
          "Ragam": "Veenaadhari",
          "Thalam": "Adi",
          "Praise of": "On Saraswathi",
          "Composer": "Dhandayuthapani Deekshitar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4763",
          "Name": "varuvaanoDi vaNNa malar aaDi",
          "Type": "Varnam-Tana",
          "Ragam": "Veenaadhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "S Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4764",
          "Name": "sari evva runnaaruraa",
          "Type": "Varnam-Tana",
          "Ragam": "Veenaadhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4765",
          "Name": "therindha innathodu",
          "Type": "Varnam-Tana",
          "Ragam": "Suryakantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4766",
          "Name": "naaDuvorku aruLum naayakanae",
          "Type": "Varnam-Tana",
          "Ragam": "Suryakantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4767",
          "Name": "arivinuL ellam thalai enba",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4768",
          "Name": "koDupOrai nindhithal",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4769",
          "Name": "paraman karuNai ennai",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer/Veenai Narayanaswamy Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4770",
          "Name": "pongkaravu thavazh maarbhaa",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4771",
          "Name": "pizhai poruththu arul surandhu",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Delhi Fiddle Chidambaram Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4772",
          "Name": "sadaa un maNisilambhadi nambinean",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ksheerabdhi Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4773",
          "Name": "Sree maadhavi gowri un",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dhandayutha Deekshitar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4774",
          "Name": "yasya gaNaathipathae",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4775",
          "Name": "naada laya swaroopam",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4776",
          "Name": "aaryaambha shiva guru",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4777",
          "Name": "kallaa oruvan",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4778",
          "Name": "aaDiyum paaDiyum aaval",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4779",
          "Name": "netru iravu kaathirundhu",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Arunachalam Pillai/Tiger Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4780",
          "Name": "adhi roopamaana ivar yaar",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nataraja sundaram Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4781",
          "Name": "arumarai pugazhum",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ksheerabdhi Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4782",
          "Name": "vElan en nAdhan",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4783",
          "Name": "mohana roopan mahaanu bhaavan",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "On Muruga",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4784",
          "Name": "nin thava mahimai",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dhandayutha Deekshitar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4785",
          "Name": "vanajaakshiro vibhuni",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thanjavur Adi Ponniah",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4786",
          "Name": "ninnu kori unnaara",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tachchur Singarachariar",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4787",
          "Name": "shrutha praayamu",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4788",
          "Name": "suratharu phala saara",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4789",
          "Name": "eNNi yaar eNNam",
          "Type": "Varnam-Tana",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4790",
          "Name": "pazhani malai vaazh naadhanae",
          "Type": "Varnam-Tana",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4791",
          "Name": "karuNai thavazhum",
          "Type": "Varnam-Tana",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4792",
          "Name": "poruLaatchi potra",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/V S Gomathi SankaraIyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4793",
          "Name": "amudhinum inimaiyaai",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "V S Gomathi Sankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4794",
          "Name": "kodhaiyE vaaraai",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Fiddle Neela megham Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4795",
          "Name": "Sree madhavi gowri",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ksheerabdhi Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4796",
          "Name": "ennuLam kavarndha ezhil vadivElan",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Varagoor K Muthukumaraswamy",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4797",
          "Name": "madhuraapuri vaasini",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Keezhvelur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4798",
          "Name": "parameshwari nin madhi mugathu",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Dhandayutha Deekshitar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4799",
          "Name": "ninnu kori unnandhuku",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thanjavur Adi Ponniah",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4800",
          "Name": "yEpaapamu jesithinO",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4801",
          "Name": "vanitha ninnu kori",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chennai Ponnusamy",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4802",
          "Name": "sarasagu raku sarasa",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar / Veenai Kuppaiyer",
          "Group": "Both Swaram and Sahithyam are Palindrome",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4803",
          "Name": "sarva manthra swathantra",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vasudevanallur Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4804",
          "Name": "sundara deva vandanamuraa",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "H Yoganarasimham",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4805",
          "Name": "varadaayaki sree bruhannaayaki",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Hemavathi Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4806",
          "Name": "Indha viraga thaapam",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chidambaram Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4807",
          "Name": "vanamaala sree gopaalaa",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kunnakudi Venkatarama iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tana Varna Kadal - Vol 4",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1976",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4808",
          "Name": "Sree shanmukha janaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jathi Triputa thalam",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Mysore Sadashiva rao",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4809",
          "Name": "Sree Kaamakoti peetasthithe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Mysore Sadashiva rao",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4810",
          "Name": "Shankaracharya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Chapu",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Sanyasi Sivaraamaasrami swamigal",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4811",
          "Name": "Vijayathaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Mishra Chapu",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
          "Group": "Achaarya Pancharathnam",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4812",
          "Name": "aamnaayaraajya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
          "Group": "Achaarya Pancharathnam",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4813",
          "Name": "Jagadaachaarya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
          "Group": "Achaarya Pancharathnam",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4814",
          "Name": "aanandha vaahEna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
          "Group": "Achaarya Pancharathnam",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4815",
          "Name": "vandeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Roopakam",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
          "Group": "Achaarya Pancharathnam",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4816",
          "Name": "Sree Shankaracharyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "On Adi Shankaracharya",
          "Composer": "Tirunelveli Retd Chief Engineer Southern Railways V Ramaiah",
          "Group": "Achaarya Pancharathnam",
          "Language": "Sanskrit",
          "Book Title": "Guru Keerthana Ratnam",
          "Book Author": "Sankara Bhaktha Jana Sabhai",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4817",
          "Name": "swaami neepai",
          "Type": "Varnam-Tana",
          "Ragam": "Amruthavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4818",
          "Name": "Inthi chakka thanamu",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4819",
          "Name": "sarasijaakshi neepai",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4820",
          "Name": "Maaruthi theevra",
          "Type": "Varnam-Tana",
          "Ragam": "Gamakakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4821",
          "Name": "marubaari korva",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4822",
          "Name": "Viriboni ninnu jesi",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4823",
          "Name": "Maguvuneepai marulukoni",
          "Type": "Varnam-Tana",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4824",
          "Name": "sarasaakresha runDanee",
          "Type": "Varnam-Ata",
          "Ragam": "Neelambari",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4825",
          "Name": "Sree Raajaadhi Raja",
          "Type": "Varnam-Ata",
          "Ragam": "Naatai",
          "Thalam": "Ata",
          "Praise of": "On Ettayapuram Yuvaraja",
          "Composer": "Baluswami Dikshitar",
          "Group": "Baluswami Dikshitar Kanakaabhisheka Varnam",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4826",
          "Name": "Sree vishwanatham Bhajeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswami Dikshitar",
          "Group": "Chaturdasa Ragamalika",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4827",
          "Name": "Gana naayaka ninnunE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4828",
          "Name": "saadhanam begada",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4829",
          "Name": "neekae dayavachchunani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4830",
          "Name": "dasharatha thanaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4831",
          "Name": "manasuna raama naama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4832",
          "Name": "marivere gathi evvaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4833",
          "Name": "kanukoni sevinchchE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4834",
          "Name": "ninnu paasi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "NutanaGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4835",
          "Name": "neethaasoodani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rudrapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4836",
          "Name": "saraswathi ninnu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veenai Varadayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4837",
          "Name": "Siddhi vinaayakanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tilang",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "R V Krishnan (Son of Veenai Varadayya)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4838",
          "Name": "paarinaik kaathidum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "R V Krishnan (Son of Veenai Varadayya)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Veenai Varadayya Isai Urupadigal",
          "Book Author": "R V Krishnan (Veenai Varadayya's Son)",
          "Year of Publication": "2003",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4839",
          "Name": "Amba Prameshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhavapriya",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4840",
          "Name": "Ananda Devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amruthavarshini",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4841",
          "Name": "Ananda Ganapathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4842",
          "Name": "Arjuna Saarathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayanthasena",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4843",
          "Name": "Bala Gopala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4844",
          "Name": "Bhama Mani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4845",
          "Name": "Bhajadavalaambareem",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4846",
          "Name": "Bhajamohini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Brindavana Saranga",
          "Thalam": "Adi",
          "Praise of": "Shastha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4847",
          "Name": "Bhaja sathatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4848",
          "Name": "Bhama Shikhaamani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bindumalini",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4849",
          "Name": "Bhavadhaarini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Khanda Chapu",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4850",
          "Name": "Bhaavayae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yamuna Kalyani",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4851",
          "Name": "Bhujanaayakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4852",
          "Name": "Chandrasekharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4853",
          "Name": "Daamodhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KalyanaVasantham",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4854",
          "Name": "Daasaagreshaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SalagaBhairavi",
          "Thalam": "Adi",
          "Praise of": "Hanuman",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4855",
          "Name": "Devaadhidevam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Ekam (Tisra Gathi)",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4856",
          "Name": "Dinakarakula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surya",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4857",
          "Name": "Divaakaraanvaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tilang",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4858",
          "Name": "DurithanivaaraNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4859",
          "Name": "Ekadantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4860",
          "Name": "GambiraGajanaayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambhiraNaatai",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4861",
          "Name": "Gaana maalini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4862",
          "Name": "Gauri Kaantha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chitrambari",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4863",
          "Name": "Gokula Vihaari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4864",
          "Name": "Govinda Maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhimplas",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4865",
          "Name": "Guha Sharavanabhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "VandanaDhaarini",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4866",
          "Name": "Hara maanasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desaakshi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4867",
          "Name": "He Jagajjanani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Mishra Chapu",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4868",
          "Name": "Inakula Deepam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4869",
          "Name": "Jagajjanani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhageshwari",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4870",
          "Name": "Janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4871",
          "Name": "Janani Jayapara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4872",
          "Name": "Jaya Jaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4873",
          "Name": "Kaali kalpaalini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rageshwari",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4874",
          "Name": "Kamala Karuna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhimplas",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4875",
          "Name": "Kamala pushpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4876",
          "Name": "Kaanchi Kaamakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "IndhuPanchamam",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4877",
          "Name": "Kapisainya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Hanuman",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4878",
          "Name": "Karuna paripoorna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4879",
          "Name": "Karuna tarangini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SalagaBhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4880",
          "Name": "Kula deepam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4881",
          "Name": "Madana sukhumaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4882",
          "Name": "Madhurapuri adhi kaarini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4883",
          "Name": "Mama upakaari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KalaSaveri",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4884",
          "Name": "Maama Sree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4885",
          "Name": "Maanikya veenadhari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayanthasree",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4886",
          "Name": "Maarutha tanayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Hanuman",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4887",
          "Name": "Naamapraachara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "Naaradha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4888",
          "Name": "Nandhi vaahana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaranga",
          "Thalam": "Khanda Chapu",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4889",
          "Name": "Narthana Ganapathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4890",
          "Name": "Navapushpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4891",
          "Name": "Navaraathri Raani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShivaRanjani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4892",
          "Name": "Neela KanTam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaalikaa",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4893",
          "Name": "Neela Megha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Mishra Chapu",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4894",
          "Name": "NeerajadhaLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4895",
          "Name": "Para devathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Deshadi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4896",
          "Name": "Parama paavani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhaavani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4897",
          "Name": "Paramesha Ramani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4898",
          "Name": "Parameshi Paahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kosalam",
          "Thalam": "Khanda Chapu",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4899",
          "Name": "Parashiva kaamini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GopikaThilakam",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4900",
          "Name": "Paripoorna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4901",
          "Name": "Paartha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4902",
          "Name": "Paarvatheesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4903",
          "Name": "Pathithapaavana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4904",
          "Name": "Praanapathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaDhanyasi",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4905",
          "Name": "Raghavaroopam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Padmadeepam",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4906",
          "Name": "Raghukulathilakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revathi",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4907",
          "Name": "Raghuvamshadeepam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhimplas",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4908",
          "Name": "Raajamaathangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chandrakauns",
          "Thalam": "Mishra Chapu",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4909",
          "Name": "Raaja shikaamani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4910",
          "Name": "Raajendrakanyae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaSaranga",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4911",
          "Name": "Raamadaasam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Mishra Chapu",
          "Praise of": "Hanuman",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4912",
          "Name": "Raamadhootham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malhar",
          "Thalam": "Mishra Chapu",
          "Praise of": "Hanuman",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4913",
          "Name": "Ramaneeya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanadam",
          "Thalam": "Khanda Chapu",
          "Praise of": "Murugan",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4914",
          "Name": "Sada Shiva kaamini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4915",
          "Name": "Saamajagamani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4916",
          "Name": "Saamajagamani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Veenaavaadhini",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4917",
          "Name": "Saamba Sadashiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bahudari",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4918",
          "Name": "Shaambhavi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4919",
          "Name": "Shankaraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "Ayyappan",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4920",
          "Name": "Saarasa dhala nayani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4921",
          "Name": "Sarasija nethram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surya",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4922",
          "Name": "Shikhivaahanaroodam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanagari",
          "Thalam": "Khanda Chapu",
          "Praise of": "Murugan",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4923",
          "Name": "Shivaraajadaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShivaRanjani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4924",
          "Name": "SreeJayanthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4925",
          "Name": "Sree Gananaatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4926",
          "Name": "Sree Meenakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4927",
          "Name": "Sree Purasadani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4928",
          "Name": "Sree Raama Ramana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rathipathipriya",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4929",
          "Name": "Sukha Sowkhya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4930",
          "Name": "Sundara yaadhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BhojaMukhari",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4931",
          "Name": "Soorya Kulaambhudhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manoharam",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4932",
          "Name": "Swarnaa sabhaapathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4933",
          "Name": "Swarna Simhaasana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4934",
          "Name": "Swethasaroja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4935",
          "Name": "Uragashayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4936",
          "Name": "Varaguna Gambhira",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4937",
          "Name": "Venkatesha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4938",
          "Name": "Vidhirasanaasani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "Devi",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4939",
          "Name": "Yadhava vamsha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Madhurai GS Mani",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Rajapujita Ramyam - Stuti Gitams",
          "Book Author": "Dr Usha Prasad",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "4940",
          "Name": "Thudhi peru ganapathi iNaiyadi",
          "Type": "Arutpa",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4941",
          "Name": "Arpudham Arpudhame",
          "Type": "Arutpa",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4942",
          "Name": "Endhai en guruvae",
          "Type": "Arutpa",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4943",
          "Name": "Vaarum vaarum deiva vadivel",
          "Type": "Arutpa",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4944",
          "Name": "Otri ooranai patri",
          "Type": "Arutpa",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4945",
          "Name": "Vaanathin meedhu",
          "Type": "Arutpa",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4946",
          "Name": "Thaayagi thandhayumaai",
          "Type": "Arutpa",
          "Ragam": "Hamsadhwani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4947",
          "Name": "Innum dhayavu varavillaiya",
          "Type": "Arutpa",
          "Ragam": "Bahudari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4948",
          "Name": "paadarkiniya vaakaLikum",
          "Type": "Arutpa",
          "Ragam": "Pantuvarali",
          "Thalam": "Chatusra Jathi Eka Thalam (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4949",
          "Name": "Seivagai ariyaen",
          "Type": "Arutpa",
          "Ragam": "ShuddhaSaveri",
          "Thalam": "Khanda Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4950",
          "Name": "Naala marundhim marundhu",
          "Type": "Arutpa",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4951",
          "Name": "Thaayilaarena nenjakam",
          "Type": "Arutpa",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4952",
          "Name": "thaNmadhiyon muga peNmaNiyae",
          "Type": "Arutpa",
          "Ragam": "Khamas",
          "Thalam": "Khanda Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4953",
          "Name": "Anbenum pidiyul",
          "Type": "Arutpa",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4954",
          "Name": "kannae kannmaniyae",
          "Type": "Arutpa",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4955",
          "Name": "yetirendum en endraal",
          "Type": "Arutpa",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4956",
          "Name": "Idhu nalla tharuNam",
          "Type": "Arutpa",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4957",
          "Name": "varuvaar azhaithuvaadi",
          "Type": "Arutpa",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4958",
          "Name": "Vedha porulae charanam",
          "Type": "Arutpa",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4959",
          "Name": "thaen yena inikkum",
          "Type": "Arutpa",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4960",
          "Name": "Nambinae nindran thiruvadi malarai",
          "Type": "Arutpa",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4961",
          "Name": "Sirpodhuvum porpodhuvum",
          "Type": "Arutpa",
          "Ragam": "Arabhi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4962",
          "Name": "Orumaiyudan ninadhu thirumalaradi",
          "Type": "Arutpa",
          "Ragam": "Ragamalika",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4963",
          "Name": "neer undu pozhikindra",
          "Type": "Arutpa",
          "Ragam": "Ragamalika",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4964",
          "Name": "Pungavar pugazhumaa",
          "Type": "Arutpa",
          "Ragam": "Mangala ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vallalar/Music-TM Thyagarajan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thiruvarutpa Isai Maalai",
          "Book Author": "TM Thyagarajan",
          "Year of Publication": "0",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4965",
          "Name": "Ainkaranae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4966",
          "Name": "Sakala kala vaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "Saraswathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4967",
          "Name": "Sathiyamae vellum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4968",
          "Name": "Paranjyothi darisanamae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4969",
          "Name": "Unnadimai naanallavo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4970",
          "Name": "KaNNan kuzhalosai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4971",
          "Name": "Anbae arumarundhaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4972",
          "Name": "enna punniyam seidhaeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desiya Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4973",
          "Name": "Thanjam endru vandha ennai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4974",
          "Name": "Endrunnai kaaNbheno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4975",
          "Name": "Sree Venkateshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4976",
          "Name": "Endro nee uNarvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4977",
          "Name": "Ennuvadhellam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4978",
          "Name": "Adaikalamendru naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4979",
          "Name": "Muppuram yeri seidha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4980",
          "Name": "Pazhani malai vela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "MadhangaKaamini",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4981",
          "Name": "Maname unakkeno",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4982",
          "Name": "Indroru pudhu naaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "JayaNarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4983",
          "Name": "Thaam tharikita",
          "Type": "Thillana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4984",
          "Name": "Dheem Dheem Dheem",
          "Type": "Thillana",
          "Ragam": "UdhayaRaviChandrika",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4985",
          "Name": "Dheem Dheem tana thaam",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4986",
          "Name": "Dheem Thaam thanam",
          "Type": "Thillana",
          "Ragam": "Hamsadhwani",
          "Thalam": "Chatusra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4987",
          "Name": "Anbil agappadum deivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4988",
          "Name": "Shanthi illamal sugam undo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4989",
          "Name": "Aadum perumaanae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4990",
          "Name": "En murai kaetae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4991",
          "Name": "Muruga Muruga endral",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4992",
          "Name": "Iruvinai yedhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4993",
          "Name": "Undran kai aaLaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhushavali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4994",
          "Name": "Nenjamae nee anjaathae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4995",
          "Name": "Naanoru siru veenai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4996",
          "Name": "Paadaaravindham adaindhen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4997",
          "Name": "Kailayang giri thanil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4998",
          "Name": "Murugan vandhenai",
          "Type": "Padam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "4999",
          "Name": "Kaathirukindren",
          "Type": "Padam",
          "Ragam": "Pushpalathika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5000",
          "Name": "yeno avarku enmel",
          "Type": "Padam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5001",
          "Name": "Ariyaap paruva madhil",
          "Type": "Padam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5002",
          "Name": "Kanden kanavinilae",
          "Type": "Padam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5003",
          "Name": "en mugam paaradhu",
          "Type": "Padam",
          "Ragam": "Pantuvarali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5004",
          "Name": "kanni kazhiyadha kaalathil",
          "Type": "Javali",
          "Ragam": "Hindolam",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5005",
          "Name": "Inbath thirunaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5006",
          "Name": "Sorvadaiyaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saravilambhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5007",
          "Name": "enge thedukindraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5008",
          "Name": "yethanai per",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelamani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5009",
          "Name": "anbu seidhu vaazhvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShuddhaDhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan Keerthana Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1951",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5010",
          "Name": "Adivo alladivo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: AIR Mallik",
          "Group": "Album: Sree Venkateshwara Geethamalika",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5011",
          "Name": "Annidaa nerubari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Sripada Pinnakapani",
          "Group": "Album: Annamaiyya PadhaheLa",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5012",
          "Name": "Annitiki Nidhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Nookala Chinna Sathyanarayana",
          "Group": "Album: Annamaiyya Sreevaasaanubhupathi",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5013",
          "Name": "Anni vibhavamula",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: D Pashupathi",
          "Group": "Album: Annamaiyya Sankeerthana vaahini",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5014",
          "Name": "AngaNaku neeve",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Tisram",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: G Nageshwara Naidu",
          "Group": "Album: Annamaiyya Antharangamlo Almelumangaa",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5015",
          "Name": "Evvari Bhaagyam entunnadho",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
          "Group": "Album: Annamaiyya padasourabham",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5016",
          "Name": "Endhaaga nithra neekithe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Paarupalli Sri Ranganath",
          "Group": "Album: Annamaiyya Sankeerthana Vaibhavam",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5017",
          "Name": "Okapari Kokkapari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
          "Group": "Album: Sree Venkateshwara Pancharatnamaala - 2",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5018",
          "Name": "O bhavanaathmaja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: G Balakrishna Prasad",
          "Group": "Album: Annamaiyya VenkataadhriveNNila",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5019",
          "Name": "KaruNaanidhim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KannadaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Voleti Venkateshwaralu",
          "Group": "Album: Annamaiyya PadhaheLa",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5020",
          "Name": "Kadupendha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gundakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: M Balamurali krishna",
          "Group": "Album: Annamaiyya Sankeerthana Ravali",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5021",
          "Name": "Galaloni Sukhame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: G Nageshwara Naidu",
          "Group": "Album: Annamaiyya Bhakthi Lahari",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5022",
          "Name": "KoNdalo nelakonna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: MS Balasubramanya Sharma",
          "Group": "Album: Sree Venkateshwara Geethamalika",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5023",
          "Name": "Korudhu naamadhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
          "Group": "Album: Annamaiyya padasourabham",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5024",
          "Name": "Ksheerabdhi kanyakaku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Kadayanallur Venkatraman",
          "Group": "Album: Sree Venkateshwara Pancharatnamaala - 1",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5025",
          "Name": "Jo Achuthaanadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Kadayanallur Venkatraman",
          "Group": "Album: Sree Venkateshwara Pancharatnamaala - 1",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5026",
          "Name": "Tirumala Giri raaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: G Nageshwara Naidu",
          "Group": "Album: Annamaiyya Bhakthi Lahari",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5027",
          "Name": "Deva Devoththama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Sandhyavandhanam Sreenivasa Rao & Voleti Venkateshwaralu",
          "Group": "Album: Annamaiyya PadhaheLa",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5028",
          "Name": "Namo Namo Lakshmi Narasimha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: G Balakrishna Prasad",
          "Group": "Album: Annamaiyya Padapadmini",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5029",
          "Name": "Naarayanathe Namo namo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: M Balamurali krishna",
          "Group": "Album: HMV LP",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5030",
          "Name": "palukuthe nela thalli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Nedunuri Krishnamurthy",
          "Group": "Album: Annamaiyya padasourabham",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5031",
          "Name": "polayaluka nithralu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Paarupalli Sri Ranganath",
          "Group": "Album: Annamaiyya Sankeerthana Vaibhavam",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5032",
          "Name": "poDakaNti maiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: D Pashupathi",
          "Group": "Album: Annamaiyya VenkataadhriveNNila",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5033",
          "Name": "patalenu paanuppa parasare",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Paarupalli Sri Ranganath",
          "Group": "Album: Annamaiyya Sankeerthana Vaibhavam",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5034",
          "Name": "manujudai putti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/D Pashupathi",
          "Group": "Album: Sree Venkateshwara Pancharatnamaala - 1",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5035",
          "Name": "vaade venkataadhrimeetha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: Sripada Pinnakapani",
          "Group": "Album: Annamaiyya PadhaheLa",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5036",
          "Name": "vedakina nidhiyae vedhaarthamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Annamacharya/Music: G Balakrishna Prasad",
          "Group": "Album: Annamaiyya Padapadmini",
          "Language": "Telugu",
          "Book Title": "Annamayya Sankeerthana Swaravali",
          "Book Author": "Tirupathi Devasthanam",
          "Year of Publication": "1991",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5037",
          "Name": "Chandrasekara Vanakkam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Chandrasekharendra",
          "Composer": "Girija Srinivasan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5038",
          "Name": "MahaGanapathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5039",
          "Name": "Kunjaramukhane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Chatusra Jathi Matya thalam",
          "Praise of": "Ganesha",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5040",
          "Name": "neeye manamaghizhvodu karunai seivaaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5041",
          "Name": "paramaa vandhu arul seidhaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Revagupti",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5042",
          "Name": "kaaNa anantham kaN veNum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Swarnaangi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5043",
          "Name": "aruLpurindhiduvaaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5044",
          "Name": "Pahimaam Sri Rajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5045",
          "Name": "Sree Shankara Guruvaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Roopakam",
          "Praise of": "Adi Shankara",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5046",
          "Name": "Pankajaakshi pai",
          "Type": "Varnam-Pada",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5047",
          "Name": "vaeru thunai kaaNe nae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5048",
          "Name": "Gowri naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Simhaanandanam",
          "Praise of": "Shiva",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5049",
          "Name": "Aanandha natesa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5050",
          "Name": "Maal Maruganai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5051",
          "Name": "thudhi seidhidu maname",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5052",
          "Name": "natanam seiyum paadhanaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5053",
          "Name": "Neelamayil mel varuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5054",
          "Name": "aththi muganai",
          "Type": "Kummi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5055",
          "Name": "neeye pizhaikka seiyaavidil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5056",
          "Name": "Inimelaayinum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Chapu",
          "Praise of": "Devi-Parvathi",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5057",
          "Name": "Sharadaa deviye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "Sharadamba",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5058",
          "Name": "nee keladayaraadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Khanda Chapu",
          "Praise of": "Rama",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5059",
          "Name": "Thillana-Vasantha",
          "Type": "Thillana",
          "Ragam": "Vasantha",
          "Thalam": "Roopakam",
          "Praise of": "Tirunelveli Kanthimathi amman",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5060",
          "Name": "Tharunamidhu karunai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Roopakam",
          "Praise of": "Tirunelveli Kanthimathi amman",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5061",
          "Name": "Thillana-Kalyani",
          "Type": "Thillana",
          "Ragam": "Kalyani",
          "Thalam": "Simhaanandanam",
          "Praise of": "Shiva",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5062",
          "Name": "Sharanam Sharanam aiyane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "From MahaVaidhyanatha Sivan's Melaragamalika",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5063",
          "Name": "yezhai naan enseiven",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5064",
          "Name": "enna seivaen natesa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Triputa",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5065",
          "Name": "annaiye ennai aandarulvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5066",
          "Name": "en manam eppadi thirindhaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5067",
          "Name": "thirunatanam seiyum oruvanai dharisiththaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Triputa",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5068",
          "Name": "Shambho sabaapathae paahimaam",
          "Type": "Pallavi",
          "Ragam": "Arabhi",
          "Thalam": "Gopuchayathi",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5069",
          "Name": "Shambho shiva saambha paahimaam maampahi",
          "Type": "Pallavi",
          "Ragam": "Shankarabharanam",
          "Thalam": "GajaJhampai",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5070",
          "Name": "hara hara shankara shiva paahimaam karunaanandha",
          "Type": "Pallavi",
          "Ragam": "Bhairavi",
          "Thalam": "Vilohitham",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5071",
          "Name": "Shambho nataraja paahimaam",
          "Type": "Pallavi",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mridangayathi",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5072",
          "Name": "Shambho umaapathe paahimaam",
          "Type": "Pallavi",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Lakshmeesham",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5073",
          "Name": "Shambho thaandavesa paahimaam",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "SamaDamarugam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5074",
          "Name": "Shankara Shiva Shankara",
          "Type": "Pallavi",
          "Ragam": "Bilahari",
          "Thalam": "Pradhaabhasekaram",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5075",
          "Name": "Aananda natana prakaasham",
          "Type": "Pallavi",
          "Ragam": "Kedaram",
          "Thalam": "Dhathaathreyam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5076",
          "Name": "Gowri naayaka Kanaga sabhaa naayaka",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Simhaanandanam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5077",
          "Name": "Shankara Shiva Shankara",
          "Type": "Pallavi",
          "Ragam": "Saveri",
          "Thalam": "Tisra Laghu, 2 Tisra Nadai, Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan/Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5078",
          "Name": "Muthukumara aiyyane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5079",
          "Name": "Ekkaalathilum maravene",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Ramaswamy Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5080",
          "Name": "Maha Vaidhyanatha Shivan's MelaRagamalika",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Maha Vaidyanatha Sivan & Ramaswamy Sivan Paadalgal",
          "Book Author": "Maha Vaidyanathan Sivan, Ramaswamy Sivan",
          "Year of Publication": "2001",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5081",
          "Name": "Venkata saila vihaara",
          "Type": "Pallavi",
          "Ragam": "Kharaharapriya",
          "Thalam": "Tisra Jathi Dhuruva Thalam (Mani)",
          "Praise of": "Vishnu",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5082",
          "Name": "Kanchi kaamaakshi",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Chatusra Jathi Dhuruva Thalam (Sreekara)",
          "Praise of": "Kanchipuram Kamakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5083",
          "Name": "Maamaramum Nizhalum",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Dhuruva Thalam (Pramaana)",
          "Praise of": "N/A",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5084",
          "Name": "Shivakaama sundari",
          "Type": "Pallavi",
          "Ragam": "Vachaspathi",
          "Thalam": "Mishra Jathi Dhuruva Thalam (PoorNa)",
          "Praise of": "Devi-Parvathi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5085",
          "Name": "Then parangiri vaazh murugaa",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Sankeerna Jathi Dhuruva Thalam (Bhuvana)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5086",
          "Name": "Maadhava Sreedhara",
          "Type": "Pallavi",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Tisra Jathi Matya Thalam (Saara)",
          "Praise of": "Vishnu-Krishna",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5087",
          "Name": "Mandha haasa vadhana",
          "Type": "Pallavi",
          "Ragam": "Mohanam",
          "Thalam": "Chatusra Jathi Matya thalam (Sama)",
          "Praise of": "Vishnu-Krishna",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5088",
          "Name": "Sree raghuvara",
          "Type": "Pallavi",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Matya Thalam (Udhaya)",
          "Praise of": "Rama",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5089",
          "Name": "Unadhu paadham thunaiye",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Mishra Jathi Matya Thalam (udheerNa)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5090",
          "Name": "Kanjadalaayathaakshi",
          "Type": "Pallavi",
          "Ragam": "Khambhoji",
          "Thalam": "Sankeerna Jathi Matya Thalam (Raava)",
          "Praise of": "Kanchipuram Kamakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5091",
          "Name": "Bhajare raghuveeram",
          "Type": "Pallavi",
          "Ragam": "Chakravaham",
          "Thalam": "Tisra Jathi Roopaka Thalam (Chakra)",
          "Praise of": "Rama",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5092",
          "Name": "Mahima theliya tharamaa",
          "Type": "Pallavi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chatusra Jathi Roopaka Thalam (Paththi)",
          "Praise of": "N/A",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5093",
          "Name": "Maa Madhurai meenakshi",
          "Type": "Pallavi",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Roopaka Thalam (Raaja)",
          "Praise of": "Madurai Meenakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5094",
          "Name": "Sankaadhi sannutha",
          "Type": "Pallavi",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Jathi Roopaka Thalam (Kula)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5095",
          "Name": "Niranjaname",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Sankeerna Jathi Roopaka Thalam (Bindu)",
          "Praise of": "N/A",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5096",
          "Name": "Eesanai thudhi maname",
          "Type": "Pallavi",
          "Ragam": "Shanmugapriya",
          "Thalam": "Tisra Jathi Jhampa Thalam (Kadamba)",
          "Praise of": "Shiva",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5097",
          "Name": "Hare Raama Govinda Muraare",
          "Type": "Pallavi",
          "Ragam": "KedaraGowlai",
          "Thalam": "Chatusra Jathi Jhampa Thalam (Madhura)",
          "Praise of": "Vishnu",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5098",
          "Name": "Sharavana Bhava guhane",
          "Type": "Pallavi",
          "Ragam": "Pantuvarali",
          "Thalam": "Khanda Jathi Jhampa Thalam (ChaNa)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5099",
          "Name": "Aananda kadalaadum aiyyane",
          "Type": "Pallavi",
          "Ragam": "Bilahari",
          "Thalam": "Mishra Jathi Jhampa Thalam (Soora)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5100",
          "Name": "Neerajaasanasathi",
          "Type": "Pallavi",
          "Ragam": "Madhyamavathi",
          "Thalam": "Sankeerna Jathi Jhampa Thalam (Kara)",
          "Praise of": "N/A",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5101",
          "Name": "Devaadi Devane",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Tisra Jathi Triputa Thalam (Sanga)",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5102",
          "Name": "Undarisanam Kidaikkumo",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Chatusra Jathi Triputa Thalam (Adi)",
          "Praise of": "Natarajar",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5103",
          "Name": "Sree Raama Jayaraama",
          "Type": "Pallavi",
          "Ragam": "Poorvikalyani",
          "Thalam": "Khanda Jathi Triputa Thalam (Dushkara)",
          "Praise of": "Rama",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5104",
          "Name": "Vadivelan adiyaark anukoolan",
          "Type": "Pallavi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jathi Triputa Thalam (Leela)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5105",
          "Name": "Maampazhani malai mahaapathiye",
          "Type": "Pallavi",
          "Ragam": "Kharaharapriya",
          "Thalam": "Sankeerna Jathi Triputa Thalam (Bhoga)",
          "Praise of": "Pazhani Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5106",
          "Name": "Meenakshi",
          "Type": "Pallavi",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
          "Praise of": "Madurai Meenakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5107",
          "Name": "Sharavana Bhava guhane",
          "Type": "Pallavi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chatusra Jathi Ata Thalam (Lekha)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5108",
          "Name": "Neerajakshi Kaamakshi",
          "Type": "Pallavi",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Ata Thalam (VidhaLa)",
          "Praise of": "Kanchipuram Kamakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5109",
          "Name": "Aravinda DhaLaayathaakshi",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Jathi Ata Thalam (Loya)",
          "Praise of": "Madurai Meenakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5110",
          "Name": "Raamam Bhajeham Sadaa",
          "Type": "Pallavi",
          "Ragam": "Saveri",
          "Thalam": "Sankeerna Jathi Ata Thalam (Dheera)",
          "Praise of": "Rama",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5111",
          "Name": "Sangeethaachaarya",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Tisra Jathi Eka Thalam (Soodha)",
          "Praise of": "Tyagaraja Swamy",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5112",
          "Name": "Maamyilerum murugaa",
          "Type": "Pallavi",
          "Ragam": "Hindolam",
          "Thalam": "Chatusra Jathi Eka Thalam (Maana)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5113",
          "Name": "Maampaalaya",
          "Type": "Pallavi",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Jathi Eka Thalam (Ratha)",
          "Praise of": "Ganesha",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5114",
          "Name": "Kaamakoti peeta vaasini",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Jathi Eka Thalam (Raaga)",
          "Praise of": "Kanchipuram Kamakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5115",
          "Name": "Purandara vitalam Bhaja",
          "Type": "Pallavi",
          "Ragam": "Khambhoji",
          "Thalam": "Sankeerna Jathi Eka Thalam (vasu)",
          "Praise of": "Vishnu",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "35 Suladi Sapta Thala Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5116",
          "Name": "Thelupavo",
          "Type": "Pallavi",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Jathi Jhampa Thalam (Tisra Nadai)",
          "Praise of": "N/A",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "Nadai Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5117",
          "Name": "Paraashakthi janani",
          "Type": "Pallavi",
          "Ragam": "Saveri",
          "Thalam": "Chatusra Jathi Triputa Thalam (Khanda Nadai)",
          "Praise of": "Devi-Parvathi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "Nadai Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5118",
          "Name": "Vadivelan",
          "Type": "Pallavi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Jathi Jhampa Thalam (Mishra Nadai)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "Nadai Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5119",
          "Name": "Velavane ninadhu padhame",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Jathi Jhampa Thalam (Sankeerna Nadai)",
          "Praise of": "Murugan",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "Nadai Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5120",
          "Name": "Maa Madhurai meenakshi",
          "Type": "Pallavi",
          "Ragam": "Bhairavi",
          "Thalam": "Panchanadai",
          "Praise of": "Madurai Meenakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "Nadai Pallavis",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5121",
          "Name": "Kaamakoti peeta vihaarini",
          "Type": "Pallavi",
          "Ragam": "Kalyani",
          "Thalam": "Sarabhanandana",
          "Praise of": "Kanchipuram Kamakshi",
          "Composer": "Tinniyam Venkatrama iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Pallavi Ratna Mala",
          "Book Author": "Tinniyam Venkatrama Iyer",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5122",
          "Name": "Swaravali Varisais (Count - 12)",
          "Type": "Practice",
          "Ragam": "Various",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5123",
          "Name": "Irattai varisai/Jantai varisai (Count - 15)",
          "Type": "Practice",
          "Ragam": "Various",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5124",
          "Name": "Mel Sthayi Varisais (Count - 9)",
          "Type": "Practice",
          "Ragam": "Various",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5125",
          "Name": "Dhaatu Varisais (Count - 5)",
          "Type": "Practice",
          "Ragam": "Various",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5126",
          "Name": "Alankarams (Count - 7)",
          "Type": "Practice",
          "Ragam": "Various",
          "Thalam": "Various",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5127",
          "Name": "VaaraNa mukha",
          "Type": "Sanchari Geetham",
          "Ragam": "Arabhi",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Ganesha",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5128",
          "Name": "Padham tharuvaai",
          "Type": "Sanchari Geetham",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Triplicane Parthasarathy perumal",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5129",
          "Name": "yezhu malai mel",
          "Type": "Sanchari Geetham",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Tirupathi Perumal",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5130",
          "Name": "Janaki devi",
          "Type": "Sanchari Geetham",
          "Ragam": "Sree",
          "Thalam": "Jhampai",
          "Praise of": "Sita Devi",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5131",
          "Name": "thuyiluvadheno",
          "Type": "Sanchari Geetham",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "Srirangam Perumal",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5132",
          "Name": "KaruNai kadalamudhe",
          "Type": "Sanchari Geetham",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "Saraswathi",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5133",
          "Name": "kaatharul vaaye ganapathye",
          "Type": "Sanchari Geetham",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5134",
          "Name": "Vadivela mayil meedhe",
          "Type": "Sanchari Geetham",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5135",
          "Name": "Sivaperumaane",
          "Type": "Sanchari Geetham",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5136",
          "Name": "Thaamarai malar",
          "Type": "Sanchari Geetham",
          "Ragam": "Mohanam",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "Vishnu",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5137",
          "Name": "Naagareega uNarvu",
          "Type": "Sanchari Geetham",
          "Ragam": "Bhupalam",
          "Thalam": "Jhampai",
          "Praise of": "Saraswathi",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5138",
          "Name": "Gananaatha nin",
          "Type": "Sanchari Geetham",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "Ganesha",
          "Composer": "Fiddle MS Subramanya Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5139",
          "Name": "Arul purivaai thaaye",
          "Type": "Sanchari Geetham",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "Saraswathi",
          "Composer": "Fiddle MS Subramanya Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5140",
          "Name": "aanai mugane malai maamagal",
          "Type": "Sanchari Geetham",
          "Ragam": "Kamala Manohari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Ganesha",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5141",
          "Name": "kavuNiyar thavame",
          "Type": "Sanchari Geetham",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "Tirugnanasampandhar",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5142",
          "Name": "Sivaneriye melendru",
          "Type": "Sanchari Geetham",
          "Ragam": "Nagaswarali",
          "Thalam": "Roopakam",
          "Praise of": "Tirunavukarasar",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5143",
          "Name": "Paravi kozhu",
          "Type": "Sanchari Geetham",
          "Ragam": "Jayantasena",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Sundarar",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5144",
          "Name": "VaanaLaaviya",
          "Type": "Sanchari Geetham",
          "Ragam": "Chakravaham",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "Manikkavasagar",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swamintha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5145",
          "Name": "PaiyaravaNai metruyilum",
          "Type": "Sanchari Geetham",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu",
          "Composer": "TV Lakshminarasimhan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5146",
          "Name": "kola mayilin melamarum",
          "Type": "Sanchari Geetham",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Murugan",
          "Composer": "TV Lakshminarasimhan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5147",
          "Name": "Viththaikiraiye un",
          "Type": "Sanchari Geetham",
          "Ragam": "Gowlai",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "Saraswathi",
          "Composer": "TV Lakshminarasimhan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5148",
          "Name": "Gurunaatha surabodhaa",
          "Type": "Sanchari Geetham",
          "Ragam": "Bhupalam",
          "Thalam": "Roopakam",
          "Praise of": "Ganesha",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5149",
          "Name": "annaiyum pithaavum",
          "Type": "Sanchari Geetham",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Parents (Father & Mother)",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5150",
          "Name": "aalayam thozhuvadhu saalavum",
          "Type": "Sanchari Geetham",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "Saguna Dhyanam (Idol Worship)",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5151",
          "Name": "thaai mozhiyil",
          "Type": "Sanchari Geetham",
          "Ragam": "Dhanyasi",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "Nanneri Vazhkai (Code of Conduct)",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5152",
          "Name": "PooraNiye kalaa valliye",
          "Type": "Sanchari Geetham",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "Saraswathi",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5153",
          "Name": "eNNaamal yethanaiyo",
          "Type": "Sanchari Geetham",
          "Ragam": "Poorvikalyani",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "Annamalai Chettiyar",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5154",
          "Name": "Murugane arutsiva pudhalvane",
          "Type": "Sanchari Geetham",
          "Ragam": "Bhairavi",
          "Thalam": "Tisra Jathi Triputa",
          "Praise of": "Murugan",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5155",
          "Name": "Deivamadhai igazhaadhe",
          "Type": "Sanchari Geetham",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Nanneri Vazhkai (Code of Conduct)",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5156",
          "Name": "Ambalathil aaDum",
          "Type": "Sanchari Geetham",
          "Ragam": "Mandari",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Sambashiva Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5157",
          "Name": "Ganapathiye",
          "Type": "Sanchari Geetham",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "Ganesha",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5158",
          "Name": "Varuga Muruga Valli nayaka",
          "Type": "Sanchari Geetham",
          "Ragam": "Bhupalam",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5159",
          "Name": "KalaimagaLe amudhanaiyaai",
          "Type": "Sanchari Geetham",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "Saraswathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5160",
          "Name": "iLamaane himagirivaazh",
          "Type": "Sanchari Geetham",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5161",
          "Name": "Jyothiyin niraive",
          "Type": "Sanchari Geetham",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5162",
          "Name": "aruL niraive aanandhame",
          "Type": "Sanchari Geetham",
          "Ragam": "Darbar",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5163",
          "Name": "saminaatha aruLvaaye",
          "Type": "Sanchari Geetham",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5164",
          "Name": "ThirumagalE",
          "Type": "Sanchari Geetham",
          "Ragam": "Sree",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "Devi-Lakshmi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5165",
          "Name": "Mazhai mugilae amudhanaiyaai",
          "Type": "Sanchari Geetham",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5166",
          "Name": "karuNaiye vadivudaiya",
          "Type": "Sanchari Geetham",
          "Ragam": "Bhairavi",
          "Thalam": "Jhampai",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5167",
          "Name": "aruLvaaye isai arivu",
          "Type": "Sanchari Geetham",
          "Ragam": "Naatai",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "Vishnu-Matysa avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-1",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5168",
          "Name": "unadhu padamalar",
          "Type": "Sanchari Geetham",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "Vishnu-Koorma avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-2",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5169",
          "Name": "ThirumagaLurai maNi maarbhaa",
          "Type": "Sanchari Geetham",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu-Varaha avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-3",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5170",
          "Name": "VaramaruLum kove",
          "Type": "Sanchari Geetham",
          "Ragam": "Bhairavi",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "Vishnu-Narasimha avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-4",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5171",
          "Name": "kaashipa muni",
          "Type": "Sanchari Geetham",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu-Vamana avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-5",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5172",
          "Name": "Maamazhu yendhum kaiyaa",
          "Type": "Sanchari Geetham",
          "Ragam": "Narayana Gowlai",
          "Thalam": "Adi",
          "Praise of": "Vishnu-Parashurama avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-6",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5173",
          "Name": "Vaazhga dasaratha raama",
          "Type": "Sanchari Geetham",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Vishnu-Rama avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-7",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5174",
          "Name": "Thaayu rohini",
          "Type": "Sanchari Geetham",
          "Ragam": "Naatakurinji",
          "Thalam": "Mishra Chapu",
          "Praise of": "Vishnu-Balaramar avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-8",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5175",
          "Name": "Gopaalaa Devaki baalaa",
          "Type": "Sanchari Geetham",
          "Ragam": "Reethigowlai",
          "Thalam": "Chatusra Jathi Dhuruva Thalam",
          "Praise of": "Vishnu-Krishnar avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-9",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5176",
          "Name": "Vaazhvuru kali yuzhiyil",
          "Type": "Sanchari Geetham",
          "Ragam": "Bilahari",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "Vishnu-Kalki/Gargiya avatharam",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: VS Gomathi Shankara Iyer",
          "Group": "Dasavathara Geetham-10",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5177",
          "Name": "vaaraNa mukhane",
          "Type": "Lakshana Geetham",
          "Ragam": "Malahari",
          "Thalam": "Roopakam",
          "Praise of": "Ganesha",
          "Composer": "TV Lakshminarasimhan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5178",
          "Name": "Natana maadiya paadam",
          "Type": "Lakshana Geetham",
          "Ragam": "Arabhi",
          "Thalam": "Triputa",
          "Praise of": "Shiva",
          "Composer": "TV Lakshminarasimhan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5179",
          "Name": "Jathiswaram-Naatai",
          "Type": "Jathiswaram",
          "Ragam": "Naatai",
          "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
          "Praise of": "N/A",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5180",
          "Name": "Jathiswaram-KedaraGowlai",
          "Type": "Jathiswaram",
          "Ragam": "KedaraGowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5181",
          "Name": "Jathiswaram-Dhanyasi",
          "Type": "Jathiswaram",
          "Ragam": "Dhanyasi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5182",
          "Name": "Jathiswaram-Sree",
          "Type": "Jathiswaram",
          "Ragam": "Sree",
          "Thalam": "Chatusra Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5183",
          "Name": "Pannaga shayanane",
          "Type": "Swarajathi",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "TV Lakshminarasimhan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5184",
          "Name": "Pirai aNi muDi",
          "Type": "Prabandham",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
          "Group": "Pradoshakala Shambhu Natanam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5185",
          "Name": "AaDiya mudhalva ambikai",
          "Type": "Prabandham",
          "Ragam": "Gowlai",
          "Thalam": "Chapu",
          "Praise of": "Shiva",
          "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
          "Group": "Pradoshakala Shambhu Natanam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5186",
          "Name": "Koothaaduvaai komaane",
          "Type": "Prabandham",
          "Ragam": "Arabhi",
          "Thalam": "Ekam",
          "Praise of": "Shiva",
          "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
          "Group": "Pradoshakala Shambhu Natanam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5187",
          "Name": "aaDum amabala vaaNaa thamizh isai",
          "Type": "Prabandham",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
          "Group": "Pradoshakala Shambhu Natanam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5188",
          "Name": "aaDum veLLi ambalavaa",
          "Type": "Prabandham",
          "Ragam": "Sree",
          "Thalam": "Jhampai",
          "Praise of": "Shiva",
          "Composer": "Mu Arunachalam Pillai & VS Gomathi Shankara Iyer",
          "Group": "Pradoshakala Shambhu Natanam",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5189",
          "Name": "anname aravaabharanaNai",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5190",
          "Name": "sariyo nee seiyum",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5191",
          "Name": "manam irangaadhadheno",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5192",
          "Name": "karuNai kaDale kalyani",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Parvathi - Tiruvotriyur ambal",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5193",
          "Name": "alai kaDal",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5194",
          "Name": "aalilaimel kaNvaLartha",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5195",
          "Name": "kaadal noikku",
          "Type": "Varnam-Tana",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5196",
          "Name": "Devaadi devaniru",
          "Type": "Varnam-Tana",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5197",
          "Name": "netriravu kaathirundhu",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5198",
          "Name": "aaraavamudhe",
          "Type": "Varnam-Tana",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5199",
          "Name": "neeye mudhalvan yaane adimai",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Lyrics: Mu. Arunachalam Pillai/Music: Tiger K Varadachariar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5200",
          "Name": "vaa vaa mahaa ganapathy",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5201",
          "Name": "murugaa unmel moham",
          "Type": "Varnam-Tana",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Arunachala Annaavi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5202",
          "Name": "Madhurapuri vaasini",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Madurai Meenakshi",
          "Composer": "KeezhvELUr Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5203",
          "Name": "anbuLLa durai enthan",
          "Type": "Varnam-Tana",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "Tiruvannamalai Shivan",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5204",
          "Name": "ambalathaadum en aiyan",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5205",
          "Name": "amudhinum inimaiyaa",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Thamizh mozhi",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5206",
          "Name": "thendral vandhu endran",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5207",
          "Name": "kola mayil paagan",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5208",
          "Name": "kaNNanin karuNaiyai",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5209",
          "Name": "pagalelaa nin padam",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5210",
          "Name": "ellam aran seyal endrennu nenjame",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5211",
          "Name": "Bhudhaththaalaagiya",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5212",
          "Name": "madhana roopaa ninnai naan",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5213",
          "Name": "Sadhguru paadhaththai",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5214",
          "Name": "ambaa ninnai vendiye",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5215",
          "Name": "vaadhugal purivadhum",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5216",
          "Name": "kaNNan thaniye vandhu",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5217",
          "Name": "soodhinai ariyaa naan",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5218",
          "Name": "naatin kalai ellaam",
          "Type": "Varnam-Tana",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "VS Gomathi Shankara Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5219",
          "Name": "maathae en kaadalar",
          "Type": "Varnam-Tana",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pa Vajravelu Mudaliar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5220",
          "Name": "maal ariyaa naan mukhanum",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Pa Vajravelu Mudaliar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5221",
          "Name": "veezhimizhalai",
          "Type": "Varnam-Tana",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5222",
          "Name": "maa madurai meen vizhiyaaL",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Madurai Meenakshi",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5223",
          "Name": "vaanor paNi ponnambala vaaNane",
          "Type": "Varnam-Tana",
          "Ragam": "HariKhambhoji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5224",
          "Name": "vaNDaar poonkuzhal",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Thirupampuram Shivan",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: TN Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5225",
          "Name": "Undran paadam paNindhen",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Vishnu",
          "Composer": "Fiddle MS Subramanya Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5226",
          "Name": "Sree Ramachandrane",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Rama",
          "Composer": "Fiddle MS Subramanya Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5227",
          "Name": "naadhane parane",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Fiddle MS Subramanya Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5228",
          "Name": "kaayaa malar meniyane kaaviri soozh",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Srirangam Ranganathar",
          "Composer": "Lyrics: Mu Arunachalam Pillai/Music: Fiddle MS Subramanya Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5229",
          "Name": "maaye manadhuvandharuN",
          "Type": "Varnam-Tana",
          "Ragam": "Abhogi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Devi-Parvathi",
          "Composer": "Mayuram Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5230",
          "Name": "thaanaagi nindra deva",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Mayuram Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Tamizh Isai Padalgal - Vol 2 (Tamizh Geetha Varnangal)",
          "Book Author": "V S Gomathi Sankara Iyer",
          "Year of Publication": "1953",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5231",
          "Name": "Kanakangi",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5232",
          "Name": "vanajakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Lalitha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5233",
          "Name": "Panthamela naa saami",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5234",
          "Name": "Nee Vanti",
          "Type": "Varnam-Tana",
          "Ragam": "Kamala Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnaih Pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5235",
          "Name": "Saami kaane",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5236",
          "Name": "Kanakangi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5237",
          "Name": "Naameetha",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5238",
          "Name": "soothathi",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5239",
          "Name": "Valachi vachchi",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5240",
          "Name": "saami naapai",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5241",
          "Name": "salamujese",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5242",
          "Name": "Chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5243",
          "Name": "Sarasija naabha",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5244",
          "Name": "Vanajaaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu Pillai (Tanjore Quaret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5245",
          "Name": "samukamu",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Vadivelu Pillai (Tanjore Quaret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5246",
          "Name": "Intha chalamu",
          "Type": "Varnam-Tana",
          "Ragam": "Surutti",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5247",
          "Name": "KaruNaa",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5248",
          "Name": "Manasu",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5249",
          "Name": "saaranganayanaro",
          "Type": "Varnam-Tana",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5250",
          "Name": "Saara saakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5251",
          "Name": "Maayaatheetha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Thanjavur Quatret)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5252",
          "Name": "Sree guruguha moorthy",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pinnashadjam/Dhenuka",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5253",
          "Name": "Sree Karambu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5254",
          "Name": "Saarassakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShailaDesaakshi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5255",
          "Name": "Parama paavani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5256",
          "Name": "Needhu paadhame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5257",
          "Name": "Sree Raajarajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5258",
          "Name": "Saatileni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5259",
          "Name": "Saaregu nee paadamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5260",
          "Name": "Dheena rakshaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Roopakam",
          "Praise of": "Srirangam Ranganathar",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5261",
          "Name": "Ranganaathude",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Roopakam",
          "Praise of": "Srirangam Ranganathar",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5262",
          "Name": "SabhalachiththuDa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "Tanjore Bruhadeeshwarar",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5263",
          "Name": "Bruhadeeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "Tanjore Bruhadeeshwarar",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5264",
          "Name": "ninnupaasi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Tanjore Bruhadeeshwarar",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5265",
          "Name": "kaamitha phala naayaki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5266",
          "Name": "Parama Kalyani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5267",
          "Name": "Aadi parameshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5268",
          "Name": "Bhaktha paalana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5269",
          "Name": "Sree mahaadevuni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5270",
          "Name": "Ninnu saaregu nammithi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5271",
          "Name": "Sree mahaadeva manohari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5272",
          "Name": "Bruhadamba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "Composed when one of the brothers was sick",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5273",
          "Name": "ambaayanine",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5274",
          "Name": "Needhu paadhame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5275",
          "Name": "Ambaa neelambari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5276",
          "Name": "Nee sari saati",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5277",
          "Name": "MangaLa pradhampulichu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "Madurai Meenakshi",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5278",
          "Name": "Krupa jooda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Kanchipuram Kamakshi",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5279",
          "Name": "Kaliyuga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Mishra Chapu",
          "Praise of": "Nagapatnam Nilaayathaakshi",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5280",
          "Name": "ambaa sowrambhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "Chamundeshwari",
          "Composer": "Tanjore Quartet (Ponniah, Chinayya, Sivanandam, Vadivelu)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5281",
          "Name": "KaruNa jeyave",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5282",
          "Name": "kandukaanagaramuna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanallur Meenakshi Sundaram Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5283",
          "Name": "tharuNam eedhamma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5284",
          "Name": "enna punniyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Shivanam Yogi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5285",
          "Name": "Perungkovil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5286",
          "Name": "ippaDi en manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Thiruveezhimalai Swaminatha Kavirayar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5287",
          "Name": "Jagadeeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvaalur Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5288",
          "Name": "Ekkaalathilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvaalur Swaminatha Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5289",
          "Name": "Idhu nalla samayam amma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Thiruvaalur Swaminatha Pillai",
          "Group": "Chaturbhaashaa Maalikai",
          "Language": "Tamil, Kannadam, Hindi, Sanskrit",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5290",
          "Name": "Gowri nayakaa",
          "Type": "Thillana",
          "Ragam": "Kaanada",
          "Thalam": "Simhaanandanam",
          "Praise of": "N/A",
          "Composer": "Maha Vaidhyanatha Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5291",
          "Name": "Thillana-Kapi",
          "Type": "Thillana",
          "Ragam": "Kaapi",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Chinnaih Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5292",
          "Name": "Thillana-Bilahari",
          "Type": "Thillana",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5293",
          "Name": "Thillana-Atana",
          "Type": "Thillana",
          "Ragam": "Atana",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5294",
          "Name": "Thillana-Behag",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Thanjai Peruvudaiyaan Perisai",
          "Book Author": "K. Ponniah Pillai",
          "Year of Publication": "1940",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5295",
          "Name": "Nee Indha maayam",
          "Type": "Varnam-Pada",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5296",
          "Name": "Swaami nee manamirangi",
          "Type": "Varnam-Tana",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5297",
          "Name": "Gajavadana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5298",
          "Name": "Sri Ganesha Sharanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tilang",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5299",
          "Name": "Kai koDuththaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5300",
          "Name": "aruL puri ainkarane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5301",
          "Name": "sadaa shiva kumaaraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5302",
          "Name": "saraswathi dayainidhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "Saraswathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5303",
          "Name": "vaaNi aruL purivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "Saraswathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5304",
          "Name": "ulaga vaazhvilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5305",
          "Name": "thaNigai vaLar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Jhampai",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5306",
          "Name": "SharavaNa bhava guhane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5307",
          "Name": "Maadhayai nidhiyenum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5308",
          "Name": "Maal Marugaa Shanmugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5309",
          "Name": "vandharuL vaai Murugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5310",
          "Name": "Sharavana Bhava enum thirumandhiram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5311",
          "Name": "Mahalakshmi jagan maathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Triputa",
          "Praise of": "Lakshmi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5312",
          "Name": "Nee irangaayenil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "Lakshmi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5313",
          "Name": "Swaamikku sari evvare",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Triputa",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5314",
          "Name": "Sundara roopa goopaalanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5315",
          "Name": "Aayiram manmadhar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5316",
          "Name": "Kaadhali raadhayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5317",
          "Name": "dayavillaiyaa dhayaaLo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5318",
          "Name": "Iniyoru gaNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5319",
          "Name": "Ninaindhurugum aDiyaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5320",
          "Name": "Gopaalane Deivamaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5321",
          "Name": "Gopaala sajjana baalaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5322",
          "Name": "swaami siridhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5323",
          "Name": "mohana muraLidharaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5324",
          "Name": "kaNNanai paNi maname",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5325",
          "Name": "enadhu uLLame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5326",
          "Name": "muraLee dhara manamOhana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5327",
          "Name": "SharaNaam bhujam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5328",
          "Name": "thuNai purindharuL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5329",
          "Name": "naaraayaNa naLinaayatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5330",
          "Name": "Ksheerasaagara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Triputa",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5331",
          "Name": "Unnai ninaindhu ninaindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5332",
          "Name": "uNdendru nambu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5333",
          "Name": "kanja malaraDi thanjamaDaindhen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5334",
          "Name": "aththaruNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5335",
          "Name": "sakala lokha naayakane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5336",
          "Name": "Vaa Vaa Madhivadhanane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5337",
          "Name": "peraanandham kaaN",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5338",
          "Name": "kamala malaridhazh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Triputa",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5339",
          "Name": "gathineeye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "Srirangam Ranganathar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5340",
          "Name": "Ranganai thudhipporku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "Srirangam Ranganathar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5341",
          "Name": "Ennai sodhanai seiyaadhae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "Srirangam Ranganathar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5342",
          "Name": "Jaya vittala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "Pandurangan-Rakumai",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5343",
          "Name": "Indra jaalaviththaikkaaran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "Vishnu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5344",
          "Name": "Neeye paaramukhamaayin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanaadham",
          "Thalam": "Adi",
          "Praise of": "Pandurangan-Rakumai",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5345",
          "Name": "karuNaanidhiye kaDaikkaN",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "Pandurangan-Rakumai",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5346",
          "Name": "Bhuvana ",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Pandurangan-Rakumai",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5347",
          "Name": "unai allaal oru thurumbu asaiyumo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saramathi",
          "Thalam": "Adi",
          "Praise of": "Pandurangan-Rakumai",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5348",
          "Name": "Raamanai bhajiththaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5349",
          "Name": "Mahimai ariya tharamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5350",
          "Name": "Bhajanai seivom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5351",
          "Name": "Chaamundeshwari sankari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Chamundeshwari",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5352",
          "Name": "ambaa manamkanindhu unadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5353",
          "Name": "Sree Jagadhambikaiye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Roopakam",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5354",
          "Name": "Parashakthi janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5355",
          "Name": "Maathaa thuNai purivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5356",
          "Name": "amale amarargaL paNiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5357",
          "Name": "thaaye neeye thanjam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5358",
          "Name": "thaaye bhairaviye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5359",
          "Name": "Shankari dhayakari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5360",
          "Name": "paradevathe padam nambinen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Deshadi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5361",
          "Name": "unaidinam paNindhadhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5362",
          "Name": "Mahishaasura mardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5363",
          "Name": "Aiyaa nin adaikalame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5364",
          "Name": "Un perumaiyai yevar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5365",
          "Name": "annaamalai naadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Triputa",
          "Praise of": "Thiruvannaamalai Annaamalaiyaan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5366",
          "Name": "O shiva permaalE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5367",
          "Name": "nin aruL iyambalaamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5368",
          "Name": "pathipadam paNivadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5369",
          "Name": "thaayumaanavane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5370",
          "Name": "sivaperumaan krupai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5371",
          "Name": "naadhan thaaL thuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hameer Kalyani",
          "Thalam": "Tisram",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5372",
          "Name": "Paarvathi naayakane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5373",
          "Name": "unai allaal gathi yaar ulagilae",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5374",
          "Name": "Piravaa varam thaarum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5375",
          "Name": "Deva devanai thruvaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5376",
          "Name": "para deivam unai andRi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5377",
          "Name": "Sundareshwarane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5378",
          "Name": "NeelakaNTaa nithya nirmala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi (Khanda Nadai)",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5379",
          "Name": "kaal maariyaadum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Deshadi",
          "Praise of": "Madurai Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5380",
          "Name": "umaiyaar paagane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chalanaatai",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5381",
          "Name": "sakala bhuvana nayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5382",
          "Name": "aananda natamidum paadhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5383",
          "Name": "Gnaanasabayil Thillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Triputa",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5384",
          "Name": "Aadum Deivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5385",
          "Name": "Chidambara naathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5386",
          "Name": "Kaana vendaamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Tisra Jathi Eka Thalam",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5387",
          "Name": "Kanakasabhayai kanda piragu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5388",
          "Name": "Adbhutha leelaigaLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "Chidambara Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5389",
          "Name": "aanandhame paramaanandhame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5390",
          "Name": "Pathi avane ulagam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5391",
          "Name": "Kailaasapathiye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5392",
          "Name": "Maa RamaNan umaa ramaNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu & Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5393",
          "Name": "saarasa poompaadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5394",
          "Name": "aarana porulE abhayamaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "DevaGandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5395",
          "Name": "engum nirandhirukkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5396",
          "Name": "kaapaali karunanidhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5397",
          "Name": "pollaa puliyinum pollaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5398",
          "Name": "jyothi mayamaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5399",
          "Name": "tiruvaDi paNiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5400",
          "Name": "kunchithapaadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5401",
          "Name": "deivamthanai bhajippeer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5402",
          "Name": "ellam avan seyal endrenni",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5403",
          "Name": "kadalil aminzhndhiduvom",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5404",
          "Name": "Paradevathaiye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "Thulasi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5405",
          "Name": "paaril nal vazhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Deepam",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5406",
          "Name": "naadha pranava vadivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "NaadaBrahmam",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5407",
          "Name": "ulaga vaazhvil oru maghizhvundo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Mana Upadesam",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5408",
          "Name": "kaatruLLa podhe thootrikkoL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5409",
          "Name": "illaadhadhi virumbhi keDugiraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5410",
          "Name": "veenE azhiyaadhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5411",
          "Name": "kaamaththai jeikaadhavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5412",
          "Name": "thamizh maNam kamazha vEnDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "Thamizh mozhi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5413",
          "Name": "sreedhara venkatesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Chapu",
          "Praise of": "Thiruvisainalloor Sridhara AiyaavaaL",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5414",
          "Name": "vaanaveLiyilE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "Radio",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5415",
          "Name": "paamaalaikku iNaiunDO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "Bharathiyar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5416",
          "Name": "thirukural yenum pudhaiyal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "Thirukural",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5417",
          "Name": "aruL surakkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5418",
          "Name": "thaan manam aruL surandhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Sundararai thaDuthu aaTkoLLa vandha Sivan thirukolam",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5419",
          "Name": "kaNDaal kaNDadhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "Paravai naachiyar Deiveega vanappu",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5420",
          "Name": "vaazhaki yenum vaNDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "Human Life",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5421",
          "Name": "Shaanthamilaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "Those without mental peace",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5422",
          "Name": "Bhuddhar piraane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "Bhuddha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5423",
          "Name": "paarulagil uzhandru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Bhuddha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5424",
          "Name": "kaNDEn vaanavar kaaNaa kaaTchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Bhuddha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5425",
          "Name": "murugaa muzhu madhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5426",
          "Name": "maayaavadhaara madhusudhana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Chapu",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5427",
          "Name": "natana sabhaapathiye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "Natarajar",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5428",
          "Name": "Thillana-Shanmugapriya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5429",
          "Name": "Thillana-Hindolam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Papanasam Sivan Keerthana Maalai",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5430",
          "Name": "palumaaru ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5431",
          "Name": "entha kintha modi",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5432",
          "Name": "palumaaru ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5433",
          "Name": "Era naapai",
          "Type": "Varnam-Tana",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5434",
          "Name": "Evvari Bodhana",
          "Type": "Varnam-Tana",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5435",
          "Name": "pagavaari bhodha",
          "Type": "Varnam-Tana",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5436",
          "Name": "Era napai",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5437",
          "Name": "sarassninnechaala",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5438",
          "Name": "Valachi vachchi",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "Navaragamalika",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5439",
          "Name": "saami nee vegame",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5440",
          "Name": "Manasetiki",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5441",
          "Name": "ikanelanevaani",
          "Type": "Varnam-Tana",
          "Ragam": "Poornachandrika",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5442",
          "Name": "padarethi meragaa",
          "Type": "Varnam-Tana",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5443",
          "Name": "yevaremi bhodhana",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5444",
          "Name": "imkaachala melanaiyya",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5445",
          "Name": "sami ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5446",
          "Name": "Nera Nammithi-Kaanada",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Ramanathapuram Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5447",
          "Name": "Sree Gana Lola shrutha jana",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika (Sree, Mohanam, Thodi, Kalyani, Keeravani, Nayaki, Sahana, Vasantha, Surutti)",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "CS Krishnaswami Ayyar",
          "Group": "Nithya Ratna Malika",
          "Language": "N/A",
          "Book Title": "Sri Venkateshwara Taana Varnangal",
          "Book Author": "C S Krishnaswamy Iyer/Sri Srivāñciyam Rāmacandrayyar",
          "Year of Publication": "1917",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5448",
          "Name": "paavana guru bhavana puraadeeshamaashrye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5449",
          "Name": "Gajaanana paahi sadaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Roopakam",
          "Praise of": "Ganesha",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5450",
          "Name": "vaarijadhaLalochani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5451",
          "Name": "Shrutha jana ranjani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5452",
          "Name": "Sree VenkataShailapathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Roopakam",
          "Praise of": "Vishnu",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5453",
          "Name": "Maaruthe namousthuthe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "Hanuman",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5454",
          "Name": "Maamava sadaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5455",
          "Name": "tripura sundari naapai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5456",
          "Name": "Kailaasa pathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shubhapantuvarali",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5457",
          "Name": "Sree Vishwanatham Shruta",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Jhampai",
          "Praise of": "Shiva",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5458",
          "Name": "Saama gaana vinodhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Chapu",
          "Praise of": "Devi-Parvathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5459",
          "Name": "Entha bhaagya vanthulo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5460",
          "Name": "Krupajuda idhe samayamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5461",
          "Name": "Vandheham bhagavantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5462",
          "Name": "Sree Kanchi nagara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Kanchipuram Kamakshi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5463",
          "Name": "Sarvaalaya nilaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Roopakam",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5464",
          "Name": "paalinchutaku samayamidhenu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5465",
          "Name": "Poornathrayee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5466",
          "Name": "naameetha neekiganu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5467",
          "Name": "pankeruha naapa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5468",
          "Name": "nannu brova nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5469",
          "Name": "palukavemammaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5470",
          "Name": "Kohlaapura nilaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5471",
          "Name": "nannu brova nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5472",
          "Name": "Vanaja nayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5473",
          "Name": "Bhujakaadhipa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5474",
          "Name": "Shivaanandha lahari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5475",
          "Name": "Bhajanaanandaamrutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5476",
          "Name": "Evvariki thelusu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5477",
          "Name": "vinapaDu sunnathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5478",
          "Name": "Akilandeshwari jaya jaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5479",
          "Name": "Ra Ra Muralidhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vijayanagari",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5480",
          "Name": "Vinevaa revarammaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5481",
          "Name": "Maragadha mani neela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5482",
          "Name": "Nannu brova vEmi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5483",
          "Name": "karuNanu vinavEmi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5484",
          "Name": "Thaamasa mikanaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5485",
          "Name": "Jaya Jaya Sreekari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5486",
          "Name": "radhaa dhara makarandha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5487",
          "Name": "Saagara kumaari nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5488",
          "Name": "Paarthasaarathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5489",
          "Name": "Ranga naayaki maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5490",
          "Name": "Vihaara muthaa mama hrudaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5491",
          "Name": "Nanda Nandanaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desh",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5492",
          "Name": "ramaa nannu brovaraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5493",
          "Name": "Sadaa Brahmaanandame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5494",
          "Name": "aataleeraa sannayaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5495",
          "Name": "Vyaagrapureesha jaya jaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5496",
          "Name": "aananda mukhaanunda raadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5497",
          "Name": "Sharaja bhava maamava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5498",
          "Name": "neeketaku nammaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5499",
          "Name": "Shankara guruvaramaashraye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5500",
          "Name": "Sree Shaaradaambike",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5501",
          "Name": "Bhakthula sangame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5502",
          "Name": "nannuLaalimbha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5503",
          "Name": "Bhaaskara maNDala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5504",
          "Name": "Chinthayeham Sree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5505",
          "Name": "Shaarade namousthuthe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5506",
          "Name": "Dharmasamvardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5507",
          "Name": "Shivasudhaayathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5508",
          "Name": "Aananda natana vilola",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5509",
          "Name": "Madhuraapuri nilaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5510",
          "Name": "Ganapathiye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5511",
          "Name": "Deviyai kaNDen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5512",
          "Name": "malaipondra nam paapam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5513",
          "Name": "ThiruthaNi vaazhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5514",
          "Name": "Karpagavalli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5515",
          "Name": "iniyaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5516",
          "Name": "paapathil patrudhalaamo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5517",
          "Name": "unnaDimalarinai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5518",
          "Name": "paaDupaaDurasane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5519",
          "Name": "eppaDi aanandham koLvaarO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5520",
          "Name": "Sangeethaa nandha rasike",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5521",
          "Name": "Ennil kanindhavun",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5522",
          "Name": "Sivanai anudhinam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5523",
          "Name": "Un Krupaiyaale",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5524",
          "Name": "Imaya malai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5525",
          "Name": "Ambaa Saraswathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "Saraswathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5526",
          "Name": "ennavendrazhaikil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5527",
          "Name": "ennakku munnaruL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5528",
          "Name": "nee viLaiyaaDina",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5529",
          "Name": "IthDhiname",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Lalithambal",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5530",
          "Name": "akkarai thaaNDuvadheppadiyO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5531",
          "Name": "Ennakku enna manakkavalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5532",
          "Name": "annaiye ninaithOngum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5533",
          "Name": "vaaNi aruL purivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Roopakam",
          "Praise of": "Saraswathi",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5534",
          "Name": "unnai maravaadhirukka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5535",
          "Name": "vaai irundhum un thaaraga naamaththai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5536",
          "Name": "Un perumaiyai endrum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5537",
          "Name": "paDathil un uruvathil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5538",
          "Name": "uyir irukkaiyil unnai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5539",
          "Name": "Bhakthi andre allaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5540",
          "Name": "Ponmalai engeyo eththanai dooramO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5541",
          "Name": "Chandiranai ponDra vadana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5542",
          "Name": "en meedhum kanindha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5543",
          "Name": "naan enna puNNiyam seidhEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5544",
          "Name": "Abhayam aruL ennaku ambikaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5545",
          "Name": "DakshiNaamoorthaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5546",
          "Name": "Sree Visaalaakshiye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5547",
          "Name": "SanjalamEn en nenjagamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5548",
          "Name": "Ambikaiyin anbe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5549",
          "Name": "unnarulE en pon poruLelaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5550",
          "Name": "lalithE yenum un naama prayaagaiyil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5551",
          "Name": "kaNNanai kaNDaayO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5552",
          "Name": "Un anbarkkendrum aanandhame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5553",
          "Name": "Chenthaamarai poovE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "UdhayaRaviChandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5554",
          "Name": "unnaDiyE en",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5555",
          "Name": "sonnadhEdhO nijamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5556",
          "Name": "innam orudharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5557",
          "Name": "Indha varam yenakkE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5558",
          "Name": "Idhu nam kaNNanadhu thiruppadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Punnagavarali, Shogini, Behag, Chenchurutti)",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5559",
          "Name": "Pazhaniyil maghindhezhun",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Malayamarutham, Saranga, Sahana, MayamalawaGowlai, Neelambari)",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5560",
          "Name": "aaDuyirE maghizhndhaaDuyirE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (AnandaBhairavi, Bilahari, Dhanyasi, Khamas, Saranga, Kedaram, Shogini, SindhuBhairavi, Surutti)",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Sri Lalitha Dasar Keerthanaigal",
          "Book Author": "Sri Lalitha Dasar T G Krishna Aiyar",
          "Year of Publication": "1984",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5561",
          "Name": "Ganapathy",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "Prahlada Charithra Keerthanaigal",
          "Language": "N/A",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5562",
          "Name": "IdhikO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Mishram",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5563",
          "Name": "veDala namma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "DevaGandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5564",
          "Name": "vachchenithikO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5565",
          "Name": "PrahlaadhuDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5566",
          "Name": "sanuthenchenidhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Mishram",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5567",
          "Name": "aruthenjchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5568",
          "Name": "Intha kObha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5569",
          "Name": "Harimeethi vairamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ghanta",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5570",
          "Name": "Asurulu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5571",
          "Name": "Hariyenthu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Mishram",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5572",
          "Name": "Vachchene",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5573",
          "Name": "chanDaamarkuDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5574",
          "Name": "IdhukO asura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5575",
          "Name": "Haribhajane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maanji",
          "Thalam": "Mishram",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5576",
          "Name": "SharaNu dhayaambhudhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5577",
          "Name": "Chinni BaaluDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Karnataka Devagandhari",
          "Thalam": "Khanda Laghu",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5578",
          "Name": "yemaninE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5579",
          "Name": "Endhuku palumaaru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5580",
          "Name": "Bhaamu jogivachche jooda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5581",
          "Name": "aruthenjchenidhukO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5582",
          "Name": "Hari Bhakthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5583",
          "Name": "GajendrutithukO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5584",
          "Name": "kaavichallaDamu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5585",
          "Name": "Erapaalaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5586",
          "Name": "Bhumi devi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5587",
          "Name": "yeraa ori paalaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5588",
          "Name": "Deva Deva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5589",
          "Name": "saamujesenidhukO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5590",
          "Name": "Rakkasuni KaDupu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Mishram",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5591",
          "Name": "ala amarulu veDaliri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5592",
          "Name": "veDala namma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishram",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5593",
          "Name": "Sree Narahari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Melatthu Venkatramana Shastry/EditedNotation-B Krishnamurthi",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Prahlada Charithra Keerthanas",
          "Book Author": "B Krishnamurthi",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5594",
          "Name": "Gajavadhana bheDuvE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5595",
          "Name": "yenu dhanyaLo lakumi emtha maanyaLo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5596",
          "Name": "ninna nODi thandhya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5597",
          "Name": "guDi guDi yanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5598",
          "Name": "ludhara vairaagyavidhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5599",
          "Name": "kaNdEnaa govindhanaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5600",
          "Name": "Paraaku maaDathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5601",
          "Name": "koDu koDu koDu KoDu Rangaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5602",
          "Name": "neene anaadha bandhu kaaruNya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5603",
          "Name": "Raama Raama Raama Raama Raama ennirO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5604",
          "Name": "smarane ondhe saaladhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5605",
          "Name": "thaaLa bhEgu thakka mELa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5606",
          "Name": "Raama naama paayasakke",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5607",
          "Name": "Jo Jo Jo Jo Jo saadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5608",
          "Name": "nanda thanaya govindathana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhimplas",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5609",
          "Name": "raama mantrava japiso",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5610",
          "Name": "thaamboolava koLLavO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5611",
          "Name": "yaare ranganaa yaare krishnanaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5612",
          "Name": "BhamTa naadhenu naanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5613",
          "Name": "paalisemma muddu sharadhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5614",
          "Name": "venkatachala nilayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5615",
          "Name": "bhaarathi deviye nene",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5616",
          "Name": "sreenivasa ennabhittu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5617",
          "Name": "andhakana dhootharike",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhageshri",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5618",
          "Name": "sree kaantha ena ghishtu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5619",
          "Name": "eethanuva nambalu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5620",
          "Name": "siva darshana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5621",
          "Name": "bhaaro brahmaadhivandhya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5622",
          "Name": "bhaagyadha lakshmee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5623",
          "Name": "Bhanda dhella bharali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5624",
          "Name": "Devaki nandana mukunda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5625",
          "Name": "dharma shravaNa vidhethakE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5626",
          "Name": "yaadhava neebhaa yadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5627",
          "Name": "manava nilisuvadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5628",
          "Name": "praaNanaatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5629",
          "Name": "alli nOTalu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5630",
          "Name": "mooruthiyanu nillisO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5631",
          "Name": "Krishna moorthy kaNNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5632",
          "Name": "aaTahO dhalle",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5633",
          "Name": "Govindana Dhyaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5634",
          "Name": "Deva bandha namma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5635",
          "Name": "ikO namma swaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5636",
          "Name": "ranga koLalanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5637",
          "Name": "laali sidhaLu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5638",
          "Name": "sakala graha bhala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5639",
          "Name": "yaaramaga namma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5640",
          "Name": "yaarige yaarumDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5641",
          "Name": "ennakate haaisuvadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5642",
          "Name": "Dhooru maaduvare rangaiyana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5643",
          "Name": "sundara mooruthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5644",
          "Name": "neenyaako ninna hangyaako",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5645",
          "Name": "jaya jaya jaya jaanaki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5646",
          "Name": "Sharanam BhevaaNi poraya kalyaaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNaatai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5647",
          "Name": "kaNNaare kaNDe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5648",
          "Name": "aadhadhella oLithe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5649",
          "Name": "yaake ninaghishtu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5650",
          "Name": "dhoobhaarathiya nODuva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5651",
          "Name": "hariya nenayadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maaruva",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5652",
          "Name": "anjiki gnyaadhakaiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5653",
          "Name": "Dhayamaato dhayamaato rangaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5654",
          "Name": "ee pariya sowbhagu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5655",
          "Name": "Chikkavane ivanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanakalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5656",
          "Name": "kathaa shravaNamaato",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5657",
          "Name": "harismaraNe maato",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hameer Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5658",
          "Name": "kaNDu kaNDu nee enna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5659",
          "Name": "bheeteninna paadhava bhimka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5660",
          "Name": "mella mellane bandene",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5661",
          "Name": "naanEke badavanu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5662",
          "Name": "aparaadhi naanalla",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pilu",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5663",
          "Name": "mangaLam jaya mangaLam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Purandaradasar",
          "Group": "Mangalam",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5664",
          "Name": "karuNiga LoLagENe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Jagannaatha Vittalar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5665",
          "Name": "yelemanave nee mareyadhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Jaya Vittalar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5666",
          "Name": "haNave ninnaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Vaadharaaja swamy",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5667",
          "Name": "neenubhet sayamaaDe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Keshava Daasar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5668",
          "Name": "karuNa viyaakE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Jaya Vittalar",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Sri Purandara mani maalai",
          "Book Author": "Gana Kala Bhooshani Lalithaangi (MLV's Mother)",
          "Year of Publication": "1941",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5669",
          "Name": "Nandan charithram aanandam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Nool Perumai",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5670",
          "Name": "ThirunaaLai pOvaan charithram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5671",
          "Name": "neesanaai pirandhaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5672",
          "Name": "pazhana marungkaNaiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "aadhanoor seri varnanai",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5673",
          "Name": "pazhana marungkaNaiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "aadhanoor seri varnanai",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5674",
          "Name": "aadhanooril oru vedhiyar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5675",
          "Name": "vaarirukkudhu thOl irukkudhu",
          "Type": "Dialogue",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Tisra Laghu",
          "Praise of": "Nandanar selling strings and animal hides for instruments",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5676",
          "Name": "vaarirukkudhu thOl irukkudhu",
          "Type": "Dialogue",
          "Ragam": "Huseni",
          "Thalam": "Tisra Laghu",
          "Praise of": "Nandanar selling strings and animal hides for instruments",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5677",
          "Name": "Nandanar: Sivane Deivam",
          "Type": "Dialogue",
          "Ragam": "Shuddha Saveri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5678",
          "Name": "Pulayargal: seriyE sorgam yeriyE kailaasam",
          "Type": "Dialogue",
          "Ragam": "Shuddha Saveri",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5679",
          "Name": "Sivalokha naadhanai kaNDU",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5680",
          "Name": "Sendhaamarai malar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Tisra Laghu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5681",
          "Name": "arahara Shiva Shiva",
          "Type": "N/A",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5682",
          "Name": "singaaramaana paLLu paaDinaar",
          "Type": "N/A",
          "Ragam": "Poorvikalyani",
          "Thalam": "Tisra Laghu",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5683",
          "Name": "thalam vandhu veedhi valam vandhu",
          "Type": "N/A",
          "Ragam": "Kedaram",
          "Thalam": "Khanda Laghu",
          "Praise of": "Nandanar after reaching Thirupunkoor",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5684",
          "Name": "Sivalokha naadan thiru sannidhaanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Khanda Laghu",
          "Praise of": "Nandanar saying when he sees that Nandi is obstructing the view of Shivan",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5685",
          "Name": "vazhi maraithu irukudhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying when he sees that Nandi is obstructing the view of Shivan",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5686",
          "Name": "vazhi maraithu irukudhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Chapu",
          "Praise of": "Nandanar saying when he sees that Nandi is obstructing the view of Shivan",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5687",
          "Name": "satrE vilagi irum piLLaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "Thirupunkoor Shivan asking Nandi to move aside",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5688",
          "Name": "Satru vilagi irum pillai",
          "Type": "N/A",
          "Ragam": "Hamsadhwani",
          "Thalam": "Tisra Laghu",
          "Praise of": "Thirupunkoor Shivan telling Nandi",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5689",
          "Name": "oru naaLum varaadha bhakthan",
          "Type": "N/A",
          "Ragam": "N/A",
          "Thalam": "Khanda Laghu",
          "Praise of": "Thirupunkoor Shivan telling Nandi",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5690",
          "Name": "matradhellaam porupEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5691",
          "Name": "kudhithaar ekkaliththaar",
          "Type": "N/A",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "No Thalam",
          "Praise of": "Nandanar's state when he got the darshan of Thirupunkoor Shivan",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5692",
          "Name": "thadaagam ondru undaakinaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Vinayagar making a lake for Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5693",
          "Name": "naaLai pOgaamal iruppEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maanji",
          "Thalam": "Chapu",
          "Praise of": "Nandanar says",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5694",
          "Name": "naaLai pOgaamal iruppEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "Nandanar says",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5695",
          "Name": "kaaNaamal irukkalaagaadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "N/A",
          "Thalam": "Mishram",
          "Praise of": "Nandanar telling Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5696",
          "Name": "ellOrum vaarungaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "Nandanar calling his people (Pulayargal)",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5697",
          "Name": "Nandanar: Sivane Deivam",
          "Type": "Dialogue",
          "Ragam": "Chenchurutti",
          "Thalam": "No Thalam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5698",
          "Name": "Nandanar: Thillai ambalam endru solla sonnaar",
          "Type": "Dialogue",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "Argument between Nandanar and Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5699",
          "Name": "Pulayargal: kaLLa pEchendru meLLasonnaar",
          "Type": "Dialogue",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "Argument between Nandanar and Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5700",
          "Name": "vaarungaL vaarungaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Jhampai",
          "Praise of": "Nandanar telling Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5701",
          "Name": "Pulayargal: paarpaar deivamadhu palikkaadhu paraiyarai",
          "Type": "Dialogue",
          "Ragam": "Themmangu",
          "Thalam": "Adi",
          "Praise of": "Argument between Nandanar and Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5702",
          "Name": "Nandanar: Paarpaar paraiyar endru paaraadhu paridhipOl",
          "Type": "Dialogue",
          "Ragam": "Themmangu",
          "Thalam": "Adi",
          "Praise of": "Argument between Nandanar and Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5703",
          "Name": "Periya Kizhavan: Gnyaayam thaane neer sollum",
          "Type": "Dialogue",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Periya Kizhavan telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5704",
          "Name": "Periya Kizhavan: Gnyaayam thaane neer sollum",
          "Type": "Dialogue",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "Periya Kizhavan telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5705",
          "Name": "paarpaar jaathi undE",
          "Type": "Dialogue",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "Periya Kizhavan telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5706",
          "Name": "Chidambara darisanam kaaNaa vidil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "Nandanar telling Periya Kizhavan",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5707",
          "Name": "meesai naraithu pOche kizhavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Ekam",
          "Praise of": "Nandanar telling Periya Kizhavan",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5708",
          "Name": "periya kizhavan varugiraan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "Arrival of Periya Kizhavan",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5709",
          "Name": "veeran iruLan kaaTTEri",
          "Type": "N/A",
          "Ragam": "N/A",
          "Thalam": "Ekam",
          "Praise of": "Gods worshipped by Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5710",
          "Name": "veeran iruLan kaaTTEri",
          "Type": "N/A",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Ekam",
          "Praise of": "Gods worshipped by Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5711",
          "Name": "veeran iruLan kaaTTEri",
          "Type": "N/A",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "Gods worshipped by Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5712",
          "Name": "Pulayargal: ellai piDaariyE",
          "Type": "N/A",
          "Ragam": "Neelambari",
          "Thalam": "No Thalam",
          "Praise of": "Argument between Nandanar and Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5713",
          "Name": "Nandanar: Sollinum aDangaadha",
          "Type": "N/A",
          "Ragam": "Neelambari",
          "Thalam": "No Thalam",
          "Praise of": "Argument between Nandanar and Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5714",
          "Name": "thirunaaLai pOvaar indha sErikkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "Periya Kizhavan telling Pulayargal the greatness of Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5715",
          "Name": "thathipuli pOlE thaaNDi gudhippaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Tisra Laghu",
          "Praise of": "Nandanar asking Pulayargal to chant Shiva naamaa",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5716",
          "Name": "ara hara shiva shiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Ekam",
          "Praise of": "Pulayargal singing glory of Shiva Naama at Nandanar's Insistence",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5717",
          "Name": "ara hara shiva shiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Tisra Laghu",
          "Praise of": "Pulayargal singing glory of Shiva Naama at Nandanar's Insistence",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5718",
          "Name": "paarthu pizhaiyungaL neengaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Adi",
          "Praise of": "Nandanar telling Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5719",
          "Name": "namakkini yama bhayam yEdhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "Nandanar telling Pulayargal",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5720",
          "Name": "thillai veLiyilE kalandhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying with tears on eyes",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5721",
          "Name": "thillai thalam endru solla thoDanginaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying with tears on eyes",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5722",
          "Name": "AiyE oru sEdhi kELum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5723",
          "Name": "sEdhi solla vandhOm nandanaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijavanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5724",
          "Name": "sEdhi solla vandhOm nandanaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5725",
          "Name": "nandanaarum vandhaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "Nandanar came to see his Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5726",
          "Name": "Nandanar: thillai ambala thalam ondru irukku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "Nandanar telling his Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5727",
          "Name": "Nandanar: aaDiya paadhathai kaaNaare",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "Nandanar telling his Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5728",
          "Name": "Nandanar: uththaaram thaarum aiyyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Ekam",
          "Praise of": "Nandanar telling his Brahmin Landlord that he wishes to go to Chidambaram",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5729",
          "Name": "Brahmin Landlord: paraiyaa nee chidambaram endru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5730",
          "Name": "Brahmin Landlord: sEri mutrum shiva bhakthi paNNum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Tisra Laghu",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5731",
          "Name": "Brahmin Landlord: sEri mutrum shiva bhakthi paNNum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Tisra Laghu",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5732",
          "Name": "Brahmin Landlord: nandaa oru sEdhi solrEn kEL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5733",
          "Name": "Brahmin Landlord: Chidambaram pOgaadhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5734",
          "Name": "Nandanar: Bhakthi paNNi koNDirunthaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "Nandanar tells Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5735",
          "Name": "Nandanar: Bhakthi paNNi koNDirunthaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "Nandanar tells Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5736",
          "Name": "Nandanar: Thillai chidambarathai oru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "Nandanar tells Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5737",
          "Name": "Brahmin Landlord: Chidambaram darisanamaa nee adhai sindhikalaamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5738",
          "Name": "Nandanar: pitham theLiya marundhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "Nandanar tells Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5739",
          "Name": "Nandanar: Chidambaram pOgaamal iruppEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "Nandanar tells Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5740",
          "Name": "Nandanar: aasai nEsaraagum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maanji",
          "Thalam": "Chapu",
          "Praise of": "Nandanar tells Brahmin Landlord",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5741",
          "Name": "Brahmin landlord: maaDu thinnum pulaiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5742",
          "Name": "Nandanar: Gopura darisaname enthan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "Nandanar singing when having the darshan of Chidambara gopuram",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5743",
          "Name": "Nandanar: maangkuyil kooviya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Ekam",
          "Praise of": "Nandanar describing Chidambaram",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5744",
          "Name": "Brahmin landlord: Nandaa unakku indha madhi thandhadhu yaaradaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5745",
          "Name": "Brahmin landlord: nandanaare undhan perumai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5746",
          "Name": "Brahmin landlord: yezhai paarpaan seidhiDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5747",
          "Name": "Brahmin landlord: nandaa nee shiva bhakthan unnai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Chapu",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5748",
          "Name": "Brahmin landlord: yaaruku thaan theriyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5749",
          "Name": "Brahmin landlord: yaarukku ponnambalam krupai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5750",
          "Name": "Nandanar: AiyyE meththakaDinam umakkaDimai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord asking Nandanar for Guru Upadesham, Nandanar replying to it",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5751",
          "Name": "Brahmin Landlord: chidambaram pOi nee vaarumaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "Brahmin Landlord telling Nandanar",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5752",
          "Name": "Nandanar: Chidambara darisanam kidaikkumO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5753",
          "Name": "Brahmin Landlord: KiDaikkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5754",
          "Name": "GhAnTa maNi aaDudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Chapu",
          "Praise of": "Bell SoundChidambaram",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5755",
          "Name": "Nandanar: mukthi aLikkum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Chapu",
          "Praise of": "\"Nandanar saying \"\"Chidambaram vandhu darisiyaadha piravi vEEN\"\"\"N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5756",
          "Name": "Nandanar: kaaNaadha kaN enna kaNNO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Chapu",
          "Praise of": "Nandanar sayingecstasy",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5757",
          "Name": "Nandanar: VarugalaamO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maanji",
          "Thalam": "Chapu",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5758",
          "Name": "Nandanar: periyOr irukkumiDam thillai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5759",
          "Name": "Nandanar: kanaka sabEsan sEvaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Roopakam",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5760",
          "Name": "Nandanar: varaamal iruppaarO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5761",
          "Name": "Nandanar: innum varakaaNEnE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5762",
          "Name": "Nandanar: idhu nalla samayam aiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5763",
          "Name": "Nandanar: virudhaa janmam aachE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5764",
          "Name": "Nandanar: sannidhi varalaamO saami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Ekam",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5765",
          "Name": "Nandanar: kanavO ninaivO kaNDadhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Chapu",
          "Praise of": "Nandanar talks about his dream",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5766",
          "Name": "Nandanar: ambalavaaNanai thenpuliyooraanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Mishra Chapu",
          "Praise of": "Nandanar saying",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5767",
          "Name": "kaLai yeDAmal jalam viDAmal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5768",
          "Name": "thirunaaLaipOvaarukku jaya mangaLam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "Mangalam",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5769",
          "Name": "aadhi madhyaanandanukku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Chapu",
          "Praise of": "Mangalam",
          "Composer": "Gopalakrishna Bharathi",
          "Group": "Nandanar Charithram",
          "Language": "Tamil",
          "Book Title": "Gopalakrishna Bharathis Nandanar Charithram",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1971",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5770",
          "Name": "Vanaspathi-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Vanaspathi",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5771",
          "Name": "Maanavati-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Manavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5772",
          "Name": "Bhairavi-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Bhairavi",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5773",
          "Name": "Kharaharapriya-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Kharaharapriya",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5774",
          "Name": "Karnataka Kapi-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Karnataka Kapi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5775",
          "Name": "Khaambhoji-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Khambhoji",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5776",
          "Name": "Chenchurutti-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Chenchurutti",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5777",
          "Name": "Khamas-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Khamas",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5778",
          "Name": "Shankarabharanam-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Shankarabharanam",
          "Thalam": "Sankeerna Jathi Triputa Thalam (Bhoga)",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5779",
          "Name": "Neelambari-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Neelambari",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5780",
          "Name": "Behag-Jathiswaram",
          "Type": "Jathiswaram",
          "Ragam": "Behag",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5781",
          "Name": "Gaanaloludaina",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Dhuruva Thalam (Pramaana)",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5782",
          "Name": "Neerajaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5783",
          "Name": "maayamElaraa",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5784",
          "Name": "sarasijaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Kedaram",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5785",
          "Name": "ninne nammiyunnadhiraa",
          "Type": "Varnam-Tana",
          "Ragam": "DevaGandhari",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5786",
          "Name": "kaama shathakOTi sundara",
          "Type": "Varnam-Tana",
          "Ragam": "Jhaalavarali",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5787",
          "Name": "saami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Poorvikalyani",
          "Thalam": "Khanda Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5788",
          "Name": "kaamakOTi sundaraangkuta",
          "Type": "Varnam-Tana",
          "Ragam": "Behag (Ragamalika Chittaiswarams)",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5789",
          "Name": "mahishaasura samhaariNi",
          "Type": "Varnam-Tana",
          "Ragam": "Begada (Ragamalika Chittaiswarams)",
          "Thalam": "Sankeerna Jathi Matya Thalam (Raava)",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5790",
          "Name": "SreepathE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ganamoorthi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5791",
          "Name": "Raamaabhiraama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5792",
          "Name": "Sadaa Shiva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natakapriya",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5793",
          "Name": "Gangaadhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5794",
          "Name": "Raama ninnu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5795",
          "Name": "thaamasambu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5796",
          "Name": "ubhaya kaaveri ranga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gamanashrama",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5797",
          "Name": "Ninnu joochi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5798",
          "Name": "dharamukhaadhuraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5799",
          "Name": "Indha sOdhana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rishabhapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5800",
          "Name": "yemani thelupudhuraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Telugu",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5801",
          "Name": "Thillana-Thodi",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5802",
          "Name": "Thillana-Thodi",
          "Type": "Thillana",
          "Ragam": "Thodi",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5803",
          "Name": "Thillana-Paras",
          "Type": "Thillana",
          "Ragam": "Paras",
          "Thalam": "Madhyadi",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5804",
          "Name": "Thillana-Bhairavi",
          "Type": "Thillana",
          "Ragam": "Bhairavi",
          "Thalam": "Tisra Jathi Roopaka Thalam (Chakra)",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5805",
          "Name": "Thillana-Kaanadaa",
          "Type": "Thillana",
          "Ragam": "Kaanada",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5806",
          "Name": "Thillana-Darbari Kaanadaa",
          "Type": "Thillana",
          "Ragam": "Darbari Kaanadaa",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5807",
          "Name": "Thillana-Khamas",
          "Type": "Thillana",
          "Ragam": "Khamas",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5808",
          "Name": "Thillana-Chenchurutti",
          "Type": "Thillana",
          "Ragam": "Chenchurutti",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5809",
          "Name": "Thillana-Hindustani Kapi",
          "Type": "Thillana",
          "Ragam": "Hindustani Kapi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5810",
          "Name": "Thillana-Shankarabharanam",
          "Type": "Thillana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5811",
          "Name": "Thillana-Kedaram",
          "Type": "Thillana",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5812",
          "Name": "Thillana-Begada",
          "Type": "Thillana",
          "Ragam": "Begada",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5813",
          "Name": "Thillana-Kannadaa",
          "Type": "Thillana",
          "Ragam": "Kannadaa",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5814",
          "Name": "Thillana-Behag",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5815",
          "Name": "Thillana-Behag",
          "Type": "Thillana",
          "Ragam": "Behag",
          "Thalam": "Deshadi",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5816",
          "Name": "Thillana-Kalyani",
          "Type": "Thillana",
          "Ragam": "Kalyani",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5817",
          "Name": "Thillana-Purvi",
          "Type": "Thillana",
          "Ragam": "Purvi",
          "Thalam": "ChowthaaL",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5818",
          "Name": "yenendhu pogaLalli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5819",
          "Name": "kanavaatha ninna sannithikE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Jhampai",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5820",
          "Name": "SiriyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Chatusra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5821",
          "Name": "kaapaaDapEkuthaaye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5822",
          "Name": "shaarade varadhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "Sharadamba",
          "Composer": "Veena Seshanna",
          "Group": "N/A",
          "Language": "Kannada",
          "Book Title": "Compositions of Vainika Sikhamani Veena Seshanna",
          "Book Author": "Veena Seshanna",
          "Year of Publication": "1965",
          "Book Print Language": "Kannadaa"
      },
      {
          "Serial #": "5823",
          "Name": "gaNapathy kavuthuvam-Kari mukha",
          "Type": "kavuthuvam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5824",
          "Name": "Thodaya Mangalam-nathaamara raaja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Vasantha, Kalyani, Madhyamavathi)",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5825",
          "Name": "Sallaama dharu - karpoora dhavaLaanga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5826",
          "Name": "jakkiNi dharu prabandham-indhu kalaadhara",
          "Type": "Prabandham",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5827",
          "Name": "Ukthaa lakshaNa padham-thyaageshaa chinthithO",
          "Type": "Padam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5828",
          "Name": "Bhakthi padham-SharaNu dharunEndhu",
          "Type": "Padam",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5829",
          "Name": "dheera padham-saarasa dhayaakara",
          "Type": "Padam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5830",
          "Name": "viraha padham-saarasaakshisE",
          "Type": "Padam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5831",
          "Name": "tribhaashaa padham-vaarum dhayatO",
          "Type": "Padam",
          "Ragam": "Khamas",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5832",
          "Name": "kaNDithaa lakshaNa padham-Shiva Shiva",
          "Type": "Padam",
          "Ragam": "Kaapi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5833",
          "Name": "vairaagya padham-dhayaa thujE jaalyaanE",
          "Type": "Padam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5834",
          "Name": "neethi padham-Bhaavaye panchanadeesham",
          "Type": "Padam",
          "Ragam": "Dhanyasi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5835",
          "Name": "sapta saagara sooLaadhi prabhanda leelaadharu-Sree thyaagEsha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (NarayanaGowlai, KannadaGowlai, MalawaGowlai, ReethiGowlai, PoorviGowlai, ChaayaaGowlai, KedaraGowlai)",
          "Thalam": "Thalamalika (C.Dhuruva, C. Matya, C. Roopaka, C.Jhampai, C.Triputa, C.Ata, C.Ekam)",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5836",
          "Name": "chaturdasha bhuvana anuraaga raagamaalikaa dharu-satata gowri",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Gowri, Kalyani, Saranga, Naatai, Bhairavi, Varali, LalithaPanchamam, Ghanta, Lalitha, Madhyamavathi)",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5837",
          "Name": "thyaagesha mangalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "PanchaNadai",
          "Praise of": "N/A",
          "Composer": "King Shahaji of Thanjavur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "Rare Music and Dance compositions of King Shahaji of Thanjavur",
          "Book Author": "Madhavi Rajagopalan",
          "Year of Publication": "2014",
          "Book Print Language": "English"
      },
      {
          "Serial #": "5838",
          "Name": "GaNanaatha",
          "Type": "Varnam-Tana",
          "Ragam": "DevaGandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5839",
          "Name": "sendhil vaazh",
          "Type": "Varnam-Tana",
          "Ragam": "Hameer Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5840",
          "Name": "dharmamaa",
          "Type": "Varnam-Tana",
          "Ragam": "Varamu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5841",
          "Name": "annaiyE",
          "Type": "Varnam-Tana",
          "Ragam": "Dwijavanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5842",
          "Name": "innamum",
          "Type": "Varnam-Tana",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5843",
          "Name": "angayarkaNNi",
          "Type": "Varnam-Pada",
          "Ragam": "Mishra SivaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5844",
          "Name": "innum",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5845",
          "Name": "Vanajaaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5846",
          "Name": "vigneshwaram",
          "Type": "Pallavi",
          "Ragam": "Hameer Kalyani",
          "Thalam": "Adi (Khanda Nadai)",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5847",
          "Name": "subramanyam",
          "Type": "Pallavi",
          "Ragam": "Khambhoji",
          "Thalam": "Chatusra Jathi Roopaka Thalam (Paththi)",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5848",
          "Name": "saravaNanai",
          "Type": "Pallavi",
          "Ragam": "Thodi",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5849",
          "Name": "Sri Raajagopaala",
          "Type": "Pallavi",
          "Ragam": "Saveri",
          "Thalam": "Tisra Jathi Triputa Thalam (Tisra Nadai)",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5850",
          "Name": "velavaa",
          "Type": "Pallavi",
          "Ragam": "Reethigowlai",
          "Thalam": "Chatusra Jathi Roopaka Thalam (Mishra Nadai)",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5851",
          "Name": "namaami",
          "Type": "Pallavi",
          "Ragam": "Hamsadhwani",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5852",
          "Name": "SaravaNa",
          "Type": "Pallavi",
          "Ragam": "Kaanada",
          "Thalam": "Mishra Jathi Triputa Thalam (Tisra Nadai)",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5853",
          "Name": "vaibhavE",
          "Type": "Pallavi",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5854",
          "Name": "Shambho",
          "Type": "Pallavi",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Triputa",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5855",
          "Name": "maa mano",
          "Type": "Pallavi",
          "Ragam": "Surutti",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "TR Subramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "5856",
          "Name": "thiruvutrilaghu ganga varaiyil",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "Ganesha",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5857",
          "Name": "seervaLar pasunthogai",
          "Type": "Kavadi Chindu",
          "Ragam": "Chakravaham",
          "Thalam": "Khanda Laghu",
          "Praise of": "Murugan",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5858",
          "Name": "theLLu thamizhukudhavu",
          "Type": "Kavadi Chindu",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Nagar vaLam",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5859",
          "Name": "chennai kuLa nagar",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Tisra Laghu",
          "Praise of": "Kovil kuLam",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5860",
          "Name": "ponnulavu chenni kuLa",
          "Type": "Kavadi Chindu",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "malai vaLam",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5861",
          "Name": "puLLi kalaabha mayil",
          "Type": "Kavadi Chindu",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "vaavi vaLam",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5862",
          "Name": "maragatha vikachitha",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5863",
          "Name": "paadhi raathiri vELaiyil (NatRaai irangal)",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5864",
          "Name": "aarumuga vaDivElavane (thalaiviyin ooDal)",
          "Type": "Kavadi Chindu",
          "Ragam": "Natabhairavi",
          "Thalam": "Tisra Laghu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5865",
          "Name": "kaNNaayiram paDaitha",
          "Type": "Kavadi Chindu",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5866",
          "Name": "bhoomi mechchidum",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Tisra Laghu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5867",
          "Name": "chandhavarai vandha guha naathaa",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Khanda Laghu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5868",
          "Name": "vanna thinai maavai ",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Tisra Laghu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5869",
          "Name": "chendhil maanagaram thanil",
          "Type": "Kavadi Chindu",
          "Ragam": "Chenchurutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5870",
          "Name": "paaLaivaai kamugil",
          "Type": "Kavadi Chindu",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5871",
          "Name": "ennaDi naan petra mangai",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Tisra Laghu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5872",
          "Name": "manju nigar kundhaLa minnE",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5873",
          "Name": "chendhoor vaLar muruga naathaa",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Chatusra Ekam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5874",
          "Name": "bhavaNak kiriyathanuL",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5875",
          "Name": "chedhil maa nagar vaazh",
          "Type": "Kavadi Chindu",
          "Ragam": "HariKhambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5876",
          "Name": "kannal soozh pazhanampuDai",
          "Type": "Kavadi Chindu",
          "Ragam": "Chenchurutti",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Chinnikulam Annamalai Reddiyar",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5877",
          "Name": "villinai oththa puruvam",
          "Type": "Kavadi Chindu",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Tisra Laghu",
          "Praise of": "N/A",
          "Composer": "Mahakavi Subramanya Bharathi",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Kavadi Chindu",
          "Book Author": "Dr S Ramanathan",
          "Year of Publication": "1966",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5878",
          "Name": "BhajarE gopaalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5879",
          "Name": "smara vaaram vaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5880",
          "Name": "broohi mukundheti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5881",
          "Name": "Maansa sancharare",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5882",
          "Name": "Gaayathri vanamaali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mishra Kaapi",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5883",
          "Name": "kreeTathi vanamaali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5884",
          "Name": "Bhajare yadhu naadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pilu",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5885",
          "Name": "bhajarE raghu veeram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "Rama",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5886",
          "Name": "prathi vaaram vaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "Rama",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5887",
          "Name": "Chetha Sree Raamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5888",
          "Name": "Pibare raama rasam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "YamunaKalyani",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5889",
          "Name": "Kelathi mama hrudayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "Rama",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5890",
          "Name": "kElathi piNDaaNdE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5891",
          "Name": "sthirathaa nahi nahirE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5892",
          "Name": "nahirE nahi rangaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5893",
          "Name": "chindhaa naasthi kila",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navaroj",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5894",
          "Name": "brahmai vaahamkila",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5895",
          "Name": "sarvam brahmamayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5896",
          "Name": "thathvajeevathvam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5897",
          "Name": "poorNa pOdhOham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5898",
          "Name": "ananda poorNa pOdhOham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5899",
          "Name": "ananda poorNa pOdhOham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5900",
          "Name": "thunga tarange gange",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sadashiva Brahmendral",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Sadashiva Brahmendrar Keerthanaigal",
          "Book Author": "Semmangudi Srinivasa Aiyar",
          "Year of Publication": "1979",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5901",
          "Name": "gOvardhana giridhara gOvindA",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5902",
          "Name": "maa mahaabhaaratha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5903",
          "Name": "jaya jaya swaamin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5904",
          "Name": "Sree naaraayaNa vaahana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5905",
          "Name": "jaya jaya raamanaatha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5906",
          "Name": "matsya koorma varaaha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5907",
          "Name": "raama krishna gOvindhethi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5908",
          "Name": "sharaNam bhava karuNaa mayi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5909",
          "Name": "naaraayaNa namO maadhavaaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5910",
          "Name": "mangaLaaya maamava dEva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5911",
          "Name": "avadhaaraya maameeshwaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5912",
          "Name": "Sree pathimiha nandha gOpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5913",
          "Name": "MangaLaani thanOthu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5914",
          "Name": "maadhava maadhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5915",
          "Name": "yEhi mudham dhEhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5916",
          "Name": "krishNam kalaya sakhi sundaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5917",
          "Name": "kalaya yashOdE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5918",
          "Name": "dhAmOdhara thaavaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5919",
          "Name": "harimeeDe Sree harimeeDe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5920",
          "Name": "gOvinda ghataya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5921",
          "Name": "aalOkayE sree bAlakrishNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5922",
          "Name": "kalayatha gOpikaaruNyarasapoora",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5923",
          "Name": "pashyatha pashyatha bhagavantham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5924",
          "Name": "parama karuNayaa maam paalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5925",
          "Name": "paahi paahi jagan mohana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5926",
          "Name": "kalayatha vanabhuvi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5927",
          "Name": "bandhanaan mOsaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5928",
          "Name": "aayaahi vrajayuvathee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5929",
          "Name": "Sree krishna thaavakamahimaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5930",
          "Name": "indra yaaga samaaramba",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5931",
          "Name": "paramapurusham anuyaama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5932",
          "Name": "jaya jaya gokula baala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5933",
          "Name": "shankE shankaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5934",
          "Name": "brundaavana madhoonaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5935",
          "Name": "vallavaanganaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5936",
          "Name": "sharaNam bhava krishNa samsarathaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5937",
          "Name": "pooraya mama kaamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5938",
          "Name": "bhooyO bhooyO vaasEnjjalinaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5939",
          "Name": "vanabhuvi gOvindamiha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5940",
          "Name": "kalayE dEvamiha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5941",
          "Name": "baalagOpaala krishNa paahi paahi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5942",
          "Name": "maadhava maamava dEvaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5943",
          "Name": "gOvindamiha gOpikaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhageshri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5944",
          "Name": "dhruvamanDala paNDitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5945",
          "Name": "kalayatha suravanithaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5946",
          "Name": "bhaavayE sakkhi bhaavayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5947",
          "Name": "sree nilayam sakkhi sree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5948",
          "Name": "mrugayatha raadhaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5949",
          "Name": "sahachari samarasamiha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5950",
          "Name": "kadhaya kadhaya maadhavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5951",
          "Name": "paahi paahi maam parama krupaaLO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5952",
          "Name": "vikshEham kadhaa gOpaala moorthim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5953",
          "Name": "naadha naadha maa kalaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5954",
          "Name": "aalOkayE rukmiNi kalyaaNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5955",
          "Name": "Jaya mangalam nithya Shubha mangalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Narayana Theerthar/Music: Seetha Rajan",
          "Group": "Krishna Leela Tarangini",
          "Language": "Sanskrit",
          "Book Title": "Krishna Leela Tarangini",
          "Book Author": "Seetha Rajan",
          "Year of Publication": "1996",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5956",
          "Name": "nee indha maayam seidhaal",
          "Type": "Varnam-Pada",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5957",
          "Name": "swaami nee manamirangi",
          "Type": "Varnam-Pada",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5958",
          "Name": "swaami unnai sadaa",
          "Type": "Varnam-Pada",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5959",
          "Name": "sadaa shiva kumaaraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5960",
          "Name": "moolaadhaara moorthi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5961",
          "Name": "gajavadanaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5962",
          "Name": "sree ganesha sharanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Tilang",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5963",
          "Name": "kai koDuthaanaiyyaa gathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5964",
          "Name": "aruL puri ainkarane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5965",
          "Name": "tharuNamidhaiyyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ShivaRanjani",
          "Thalam": "Roopakam",
          "Praise of": "Ganesha",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5966",
          "Name": "thaNigai vaLar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Khanda Chapu",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5967",
          "Name": "jaya jaya guhaa sharanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5968",
          "Name": "muruhara muzhumadhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5969",
          "Name": "ulaga vaazhvilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5970",
          "Name": "pazhaniyappa nin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5971",
          "Name": "Maal Marugaa Shanmugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5972",
          "Name": "vandharuLvaai murugaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5973",
          "Name": "sharavaNa bhava guhanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5974",
          "Name": "SharavaNa bhava ennum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5975",
          "Name": "maadhayai nidhiyenum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5976",
          "Name": "chitham irangaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Mishra Chapu",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5977",
          "Name": "Sree valli devasenapathe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5978",
          "Name": "kandhaa vandharuL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5979",
          "Name": "kadhir kaama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Tisra Triputa",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5980",
          "Name": "nekkurugi unnai paNiyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5981",
          "Name": "sOdhanai sumaikkivvELai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5982",
          "Name": "murganai bhaji manamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jonpuri",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5983",
          "Name": "sendhil vaLar sEvalanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5984",
          "Name": "konjam dhayai puriya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5985",
          "Name": "maalai sooTuvEn vElavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika (Keeravani, Shankarabharanam, Varali, Bilahari, KedaraGowlai)",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5986",
          "Name": "aDithaalum unai viTTu aDimai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5987",
          "Name": "shivakaama sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5988",
          "Name": "karuNaa nidhiyE thaayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Tisra Triputa",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5989",
          "Name": "paadha malar thuNaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5990",
          "Name": "aananda bhairavi annai un thiruvaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5991",
          "Name": "bhuvanEshwari paadam ninaindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Roopakam",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5992",
          "Name": "bhuvaneshwari pugazh unadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Deshadi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5993",
          "Name": "mangaLa naayaki maathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5994",
          "Name": "uLLam irnaguvadhen un kaDanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5995",
          "Name": "Devi neeyE thuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5996",
          "Name": "paraashakthi janani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5997",
          "Name": "dharmaambhikE thanjam neeyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Deshadi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5998",
          "Name": "thiruvaruL thara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "5999",
          "Name": "amba unaiyE nambinEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Deshadi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6000",
          "Name": "ambaa manamkanindhu unadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6001",
          "Name": "unai dhinam paNindhadum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6002",
          "Name": "amale amarargaL paNiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6003",
          "Name": "Maathaa thuNai purivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6004",
          "Name": "thaaye neeye thanjam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6005",
          "Name": "shankari dhayaa kari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6006",
          "Name": "Chaamundeshwari sankari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6007",
          "Name": "thaayE bhairaviyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6008",
          "Name": "sree jagadambhikaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Roopakam",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6009",
          "Name": "paradevathe padam nambinen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Deshadi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6010",
          "Name": "mahishaasura mardhini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6011",
          "Name": "thiruvaruL thandharuL vaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6012",
          "Name": "un perumaiyai yevar arivaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6013",
          "Name": "aaDum deivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6014",
          "Name": "sundarEshwaranE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6015",
          "Name": "mayilaapuriyil vandha mahaadEvan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6016",
          "Name": "nambikeTTavar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6017",
          "Name": "sarveshaa aDimaiyai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6018",
          "Name": "umaiyOr paaghanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6019",
          "Name": "pollaa puliyinum pollaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Madhyadi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6020",
          "Name": "nataraajO vijayathE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Khanda Jathi Ata Thalam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Sanskrit",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6021",
          "Name": "nataraajan un thirunaTam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6022",
          "Name": "aiyaa nin aDaikalamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6023",
          "Name": "nin aruL iyambalaagumO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6024",
          "Name": "sakala bhuvana naayakaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6025",
          "Name": "iDadhu padam thUki aaDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6026",
          "Name": "shivaaya namavendrE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6027",
          "Name": "gnaana sabhaiyil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6028",
          "Name": "kunchithapaadham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6029",
          "Name": "shiva perumaan krupai vEnDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6030",
          "Name": "Paarvathi naayakane",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6031",
          "Name": "unai allaal gathiyaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6032",
          "Name": "Piravaa varam thaarum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Lathangi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6033",
          "Name": "Deva devanai thruvaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6034",
          "Name": "paradeivam unaiandRi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6035",
          "Name": "thaayumaanavanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6036",
          "Name": "naadhan thaaL thuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hameer Kalyani",
          "Thalam": "Tisra Ekam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6037",
          "Name": "shree mahaadeva naama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6038",
          "Name": "kaaNa vEndDaamO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6039",
          "Name": "aaNDavanai nambu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6040",
          "Name": "adhbhutha leelaigaLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6041",
          "Name": "sundarEshwaraaneeyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6042",
          "Name": "kaal maariyaadum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Deshadi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6043",
          "Name": "karuNai puri kapaali",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6044",
          "Name": "padhari manamayarndhu varundhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6045",
          "Name": "chidambara naathaa thiruvaruL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6046",
          "Name": "garbha vaasam ariyaadha kaDavulE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6047",
          "Name": "pichchaikku vandheerO",
          "Type": "Padam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6048",
          "Name": "natana sabhaapathiye",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6049",
          "Name": "aananda natamidum paadhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6050",
          "Name": "aanandhame paramaanandhame",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6051",
          "Name": "gowri manOharaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6052",
          "Name": "thiruvaDi paNiyum anbar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6053",
          "Name": "pathi avanE ulagam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6054",
          "Name": "kanaka sabhayai kaNDa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindustani Kapi",
          "Thalam": "Roopakam",
          "Praise of": "Shiva",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6055",
          "Name": "Thillana-Shanmugapriya",
          "Type": "Thillana",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6056",
          "Name": "mangaLam aruL sree gajamukhanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sree",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "Mangalam-Mylapore",
          "Language": "Tamil",
          "Book Title": "Papanasam Sivan Keerthana Malai-Part2",
          "Book Author": "Papanasam Sivan",
          "Year of Publication": "1965",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6057",
          "Name": "aabheera palla",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6058",
          "Name": "aaDaadhu asangaadhu vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6059",
          "Name": "adhaTTi uruTTi vazhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6060",
          "Name": "adhbhuthanaam baalakanaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6061",
          "Name": "aaDi asaimdhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6062",
          "Name": "aaDi asaimdhu varugindraan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6063",
          "Name": "aDi muDi kaaNaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6064",
          "Name": "aaDinamaaTTukkum nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6065",
          "Name": "aaDinaan maadhavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6066",
          "Name": "aaDinaan narthanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6067",
          "Name": "aaDinaan viLayaaDinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6068",
          "Name": "aadhiyoru kaalamadhil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Khanda Ekam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6069",
          "Name": "aaDum varai avar aaDattum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6070",
          "Name": "aagama raajagopaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6071",
          "Name": "agaNitha mahimaadbhutha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "SaptaRatna Krithis-2",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6072",
          "Name": "aiyan allavO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6073",
          "Name": "aiyan karuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6074",
          "Name": "aiyan oorvalam vandhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6075",
          "Name": "aakka poruthavarkku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6076",
          "Name": "alai paayudhe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6077",
          "Name": "alaivaaipazham udhirum sOlai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6078",
          "Name": "alankaara gOpikai thaaLam pODa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6079",
          "Name": "aaLaavadhennaaLo",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "SaptaRatna Krithis-6",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6080",
          "Name": "allikENikkarai thEDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6081",
          "Name": "aLLudhE manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6082",
          "Name": "amudhanukku amudhooTTum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hameer Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6083",
          "Name": "aanamaTTum sonnEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6084",
          "Name": "aananda narthana ganapathim",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6085",
          "Name": "andhinEram nadhikkaraiyOram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6086",
          "Name": "anjanaanandham bhodhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Anjaneya SaptaRathna Krithis-4",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6087",
          "Name": "annaikkezhudhinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6088",
          "Name": "annaiyarhaL vanam naaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6089",
          "Name": "annamE idhu enna maayam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6090",
          "Name": "anRE naan sonnadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6091",
          "Name": "antharam maNandha malar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6092",
          "Name": "anuraagaja aalaapa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Khanda Jathi Matya Thalam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6093",
          "Name": "aparam na jaanaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6094",
          "Name": "aridhu aridhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6095",
          "Name": "arivEn arindhEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6096",
          "Name": "arooDam ondru sollaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6097",
          "Name": "aruLaLan thiramellaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6098",
          "Name": "asaindhaDum mayil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6099",
          "Name": "aasayinai paaraDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6100",
          "Name": "athi nirupama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6101",
          "Name": "avaraaha varuvaarO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6102",
          "Name": "avathaaram aanaan raaghavan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6103",
          "Name": "avathaaram shree krishNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6104",
          "Name": "aayiram aayiram paayiram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6105",
          "Name": "aayiram sollaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6106",
          "Name": "aayiramthaan sollaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6107",
          "Name": "azhagaai dwaarakayai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6108",
          "Name": "aazhaazhi neela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6109",
          "Name": "baala sarasa muraLi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "SaptaRatna Krithis-4",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6110",
          "Name": "bhajanaamruta paramaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "SaptaRatna Krithis-1",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6111",
          "Name": "bhajasva shree tripurasundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-2",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6112",
          "Name": "bhaktha bhaagadhEya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Anjaneya SaptaRathna Krithis-7",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6113",
          "Name": "bhakthar perumaiyE perumai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6114",
          "Name": "bhakthi paNNadhirukkalaamO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6115",
          "Name": "Bhakthi yOga sangeetha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6116",
          "Name": "bheema nadhikkarayil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6117",
          "Name": "bhuvanamOha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6118",
          "Name": "brundaavana nilayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6119",
          "Name": "brundaavanam enbadhivayyO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6120",
          "Name": "chaamarOpachaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6121",
          "Name": "chamathu kuTTiyaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6122",
          "Name": "chindhithavar nenjil iruppadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6123",
          "Name": "chOra chaathurya leela",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6124",
          "Name": "dhaamOdhara vaibhavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6125",
          "Name": "dhanta thaavanam seiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6126",
          "Name": "darisanam thandhE aruLinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6127",
          "Name": "daasharathE dayaanidhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6128",
          "Name": "dEvi vanam vandhaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6129",
          "Name": "dhyaanamE parama paavanamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RasaManjari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6130",
          "Name": "dwaarakai enbadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6131",
          "Name": "yedhaDi yedhaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6132",
          "Name": "edhir koNDazhaithu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6133",
          "Name": "yekadantha vinaayakam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6134",
          "Name": "yellai illaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6135",
          "Name": "ellaam gurunaathan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6136",
          "Name": "yEn ingE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6137",
          "Name": "yenakkum aruLineerO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6138",
          "Name": "yenda samarththai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6139",
          "Name": "yendha vidhamaahilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6140",
          "Name": "yendha vidhathaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6141",
          "Name": "yendhapaDi aanaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6142",
          "Name": "yengE yengEyaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6143",
          "Name": "yengum illaadha piLLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6144",
          "Name": "yengum illaadhoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6145",
          "Name": "yengum iyaRkkai maNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika ",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6146",
          "Name": "yenna paaraamukham amma",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Tisra triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6147",
          "Name": "yenna puNNiyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6148",
          "Name": "ennadhaan inbam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "DevaGandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6149",
          "Name": "ennadhaan kobam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6150",
          "Name": "ennadhaan sollaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6151",
          "Name": "ennadhaan sonnaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6152",
          "Name": "ennadhaan sonnEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6153",
          "Name": "ennathai sollaDi pODi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6154",
          "Name": "ennaththai sonnaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6155",
          "Name": "eNNippaaraDi kaNNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6156",
          "Name": "yenO indha nishivELai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6157",
          "Name": "eppaDiththaan en uLLam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6158",
          "Name": "eTTa irundhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6159",
          "Name": "eththaikaNDu nee meenaakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6160",
          "Name": "eththanaikETTaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6161",
          "Name": "gajamukha anujam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6162",
          "Name": "gamana sundara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6163",
          "Name": "gambheera naDaiyODu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6164",
          "Name": "gaanam krishNa gaanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6165",
          "Name": "gaNanaayakan thuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6166",
          "Name": "gaNayaami satatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6167",
          "Name": "gathi neerE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6168",
          "Name": "geetha rasikE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6169",
          "Name": "gOkula maamaNiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6170",
          "Name": "gOkulatheruvinilE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6171",
          "Name": "gOpa kumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6172",
          "Name": "gOvinda gOvinda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6173",
          "Name": "gowri naatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vakulabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6174",
          "Name": "guru paadaravinda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6175",
          "Name": "haladharaanujam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-Phala Sthuthi",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6176",
          "Name": "idhu oru thiramaamO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6177",
          "Name": "idhudhaan theriyumO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6178",
          "Name": "idhuvE ennakku laksham kOTi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6179",
          "Name": "idhuvE phalan aamODi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6180",
          "Name": "iLa mukham kaaTi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6181",
          "Name": "illai illai illaiyammaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6182",
          "Name": "indha vazhi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6183",
          "Name": "innamudhu anna un",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6184",
          "Name": "innum enna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6185",
          "Name": "innum mayakkudhu swaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6186",
          "Name": "innum ninaiththaalum kaNNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6187",
          "Name": "innum varkkaaNenE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6188",
          "Name": "ippaDiyum oru piLLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6189",
          "Name": "ippaDiyum oru deivamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6190",
          "Name": "iththanai parivu ennaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6191",
          "Name": "iththanaiyum pOi urakkam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6192",
          "Name": "ivandhaan ivandhaanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6193",
          "Name": "jagamengum pughazhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6194",
          "Name": "jaganmOhana sundaraanga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6195",
          "Name": "jagathu raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6196",
          "Name": "janani tripura Sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6197",
          "Name": "jaTaadhara shankara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "SaptaRatna Krithis-5",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6198",
          "Name": "kaDaindhaalE thayir",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6199",
          "Name": "kaadhalaahi vasamizhandhEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6200",
          "Name": "kadhirEgum madhiyaagum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ahiri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6201",
          "Name": "kai varisaikaaTTaadheer",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6202",
          "Name": "kaalinga narthana naTaangam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6203",
          "Name": "kaLLamE ariyaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6204",
          "Name": "kalyaaNa raama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanaadham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6205",
          "Name": "kaamini kELi lOla",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6206",
          "Name": "kaaNaadha adhishaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6207",
          "Name": "kanaka sabhaa naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6208",
          "Name": "kaaNakkiDaithadhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6209",
          "Name": "kaNDum kaaNaadhadhu pOl",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6210",
          "Name": "kaaNeerE kanjamalar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6211",
          "Name": "kaNgaLum pOdhaadhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6212",
          "Name": "kanjikku eeDaagumaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6213",
          "Name": "kaNkaNDa deivamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6214",
          "Name": "kaNNaa yezhundhirum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6215",
          "Name": "kaNNaithirandhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6216",
          "Name": "kaNNallavO swaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6217",
          "Name": "kaNNan piRandhadai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6218",
          "Name": "kaNNan varuhinRa nEram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kavadi Sindhu",
          "Thalam": "Tisra Gathi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6219",
          "Name": "kaNNanO kaNNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6220",
          "Name": "kaNNaazhi alai parava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Khanda Ekam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6221",
          "Name": "kathaya kathaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6222",
          "Name": "kaayaambhoo vaNNarE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6223",
          "Name": "kiDaikkumO kiDaikkaadhO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6224",
          "Name": "koDukka kaNNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6225",
          "Name": "koDuththu vaiththa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6226",
          "Name": "koLLaadha aasai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6227",
          "Name": "konjum mazhalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6228",
          "Name": "kOTi janmaani",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6229",
          "Name": "kshaNamEva gaNya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6230",
          "Name": "kshaNathinil aahaadhO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6231",
          "Name": "kungiliyam vaangaliyO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6232",
          "Name": "kunRin meliTTa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6233",
          "Name": "kuzhalE nee enna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6234",
          "Name": "kuzhaloodhi manamellaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6235",
          "Name": "laali raajagopaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6236",
          "Name": "lOla gOpabaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6237",
          "Name": "madhananga mOhana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6238",
          "Name": "maadhava Hrdi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "SaptaRatna Krithis-3",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6239",
          "Name": "maadhavanai munn niruththi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Khanda Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6240",
          "Name": "Madhura Madhura",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6241",
          "Name": "mahaashaya hrudaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6242",
          "Name": "malaiyai thookinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6243",
          "Name": "manadharindhO allaadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6244",
          "Name": "manadhukkiniya gOpaala charithram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6245",
          "Name": "manamE arivODum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6246",
          "Name": "mandhaakini karai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6247",
          "Name": "maanE avarthaanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6248",
          "Name": "maNi noopura dhaari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6249",
          "Name": "manjanamaaDa nee vaaraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6250",
          "Name": "manRuLaaDi mahizhndE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6251",
          "Name": "manRuLaaDum paraman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6252",
          "Name": "marakatha maNimaya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6253",
          "Name": "mathuraapuri nagaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6254",
          "Name": "mithilaadhipa suthaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6255",
          "Name": "mumadha vEzha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6256",
          "Name": "mun seidha thavapayanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6257",
          "Name": "mundhi varum isaiyil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6258",
          "Name": "muththukrishNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6259",
          "Name": "naadha muraLi gaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hameer Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6260",
          "Name": "naDa naDa naDa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6261",
          "Name": "naDayai paaraDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6262",
          "Name": "Thillana-SindhuBhairavi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6263",
          "Name": "naaga naTanamaaDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6264",
          "Name": "nalladhalla endRu sollaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6265",
          "Name": "nallathanamaai solli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6266",
          "Name": "naama sukha suthaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SiddhasEna",
          "Thalam": "Ekam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6267",
          "Name": "naan enna thavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6268",
          "Name": "nanda gOpaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6269",
          "Name": "nanda sukumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbaar",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6270",
          "Name": "nanRaaha iru thaayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6271",
          "Name": "nathajana kalpavallee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-9",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6272",
          "Name": "naTavara tharuNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KannadaGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6273",
          "Name": "nee seidha upakaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "UmaBharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6274",
          "Name": "needhaan mechchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6275",
          "Name": "neela lOhitha ramaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Balahamsa",
          "Thalam": "Khanda Dhuruvam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-5",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6276",
          "Name": "neela malar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6277",
          "Name": "neela vaanam thanil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6278",
          "Name": "neela vaNNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6279",
          "Name": "neelavaanam pOllilangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maanji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6280",
          "Name": "neeradha sama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayanthashree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6281",
          "Name": "neeyE kaNkoNDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6282",
          "Name": "nEram konjamum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6283",
          "Name": "nillaamal pOhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "UdayaRavichandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6284",
          "Name": "ninRa nilaikaNDu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6285",
          "Name": "ninRaan thanai pOi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6286",
          "Name": "ninRandha mayilondRu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Khanda Ekam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6287",
          "Name": "nindRingunnaruL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6288",
          "Name": "niravadhikka bhuvana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6289",
          "Name": "Om namO bhagavathE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6290",
          "Name": "ooraan oruvan kuzhlODum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6291",
          "Name": "oyyaarakkaNNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maalavam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6292",
          "Name": "oyyaaramaaha oyyaaramaaha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6293",
          "Name": "pachchai iLam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6294",
          "Name": "paada sEvana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Deeparam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6295",
          "Name": "padmaavathi ramaNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6296",
          "Name": "padmini vallabha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6297",
          "Name": "paal vaDiyum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6298",
          "Name": "paanDuranga viTTala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "ChayaTarangini",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6299",
          "Name": "paravaasudEvam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6300",
          "Name": "paarthasaarathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6301",
          "Name": "paarvai ondRE pOdhumE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6302",
          "Name": "pathitha paavanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6303",
          "Name": "paTTaabhishekam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6304",
          "Name": "pavana kumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Anjaneya SaptaRathna Krithis-1",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6305",
          "Name": "pazhamO pazhamO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6306",
          "Name": "pesaadhE pOngaLaaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6307",
          "Name": "pesakELeerO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6308",
          "Name": "pODi pODi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6309",
          "Name": "pOdhum pOdhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6310",
          "Name": "pOhaviTTu puram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Tisra Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6311",
          "Name": "pollaadha piLLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6312",
          "Name": "pooNool kalyaaNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6313",
          "Name": "poruLaai iruppEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6314",
          "Name": "pOTTu vaiyEDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6315",
          "Name": "praNavaakaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6316",
          "Name": "praNavOpadesham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kavadi Sindhu",
          "Thalam": "Tisra Gathi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6317",
          "Name": "prasanna gOpaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Mishra Ata",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6318",
          "Name": "prEmaswaroopa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6319",
          "Name": "pullaai piravi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6320",
          "Name": "puthra kaamayaagam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6321",
          "Name": "raadhE krishNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6322",
          "Name": "raadhikaa praaNa naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6323",
          "Name": "raaga rasaananda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HamsaKeeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6324",
          "Name": "raghukulOththama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6325",
          "Name": "raaja raaja gOpaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maanji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6326",
          "Name": "raaja raaja gOpaala",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6327",
          "Name": "Raaja Raajeshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6328",
          "Name": "raajaadhi raaja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6329",
          "Name": "raajeeva nayana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbaar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6330",
          "Name": "raaman perumai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6331",
          "Name": "ramaNeeya yamunaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6332",
          "Name": "raamaayaNamE dhanam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6333",
          "Name": "ranganaatham anisham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6334",
          "Name": "raasa kEli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6335",
          "Name": "raasa maNDalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6336",
          "Name": "raasalOkha vaibhOga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6337",
          "Name": "rasika mahOththama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6338",
          "Name": "rukmiNi kalyaaNa mangaLam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6339",
          "Name": "sadaanandamayee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "sankeerana Matyam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-6",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6340",
          "Name": "sakala lokha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-7",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6341",
          "Name": "saamam mudhal vEdhangaLai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kurunji",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6342",
          "Name": "santhatham aham sEvE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Desaakshi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-1",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6343",
          "Name": "sarasa guNa ratna",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6344",
          "Name": "sarasijabhava",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6345",
          "Name": "sarva jeeva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-3",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6346",
          "Name": "sathvaguNa virachithaanga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RasaManjari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Anjaneya SaptaRathna Krithis-5",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6347",
          "Name": "sathyam param",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6348",
          "Name": "sEnaapathE namOusthuthE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6349",
          "Name": "Shankari Shree",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-8",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6350",
          "Name": "sharaNam gOpa baalaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6351",
          "Name": "sharaNam yaami",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6352",
          "Name": "shathakOTi manmathaakaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6353",
          "Name": "sEdhi ellaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6354",
          "Name": "seidha thavam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6355",
          "Name": "sendRa samaththu pOl",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6356",
          "Name": "sendRu vaa nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6357",
          "Name": "shenShiva jaTaadhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6358",
          "Name": "solla kiDaiththadhE bhaagyam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6359",
          "Name": "solla varam tharuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6360",
          "Name": "sollavallEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6361",
          "Name": "solli kaLLippOm",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6362",
          "Name": "solli koDuththadhaarO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6363",
          "Name": "solli muDiyumaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6364",
          "Name": "sollinilE varum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6365",
          "Name": "sollithaan tharavENumO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6366",
          "Name": "sollitherivadillayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6367",
          "Name": "sonnadhaikkEL ayyanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6368",
          "Name": "sonnadhaikkEL kaNNaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6369",
          "Name": "sonnaal mangaLam tharumE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6370",
          "Name": "sonnaalozhiya manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6371",
          "Name": "Shree Chakra maathangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6372",
          "Name": "shree ganeshwara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-Ganesha Kruthi",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6373",
          "Name": "shree gOvinda naama",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6374",
          "Name": "shree raaghava dhootham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Anjaneya SaptaRathna Krithis-2",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6375",
          "Name": "shree raama jayamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6376",
          "Name": "shree shiva naayikE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "LalithaGandharvam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6377",
          "Name": "shree Vigna rajam bhajE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNaatai",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6378",
          "Name": "Shreenivaasa chinthayeham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6379",
          "Name": "shuzhandRu suzhandRaaDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6380",
          "Name": "sogasukaara krishNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6381",
          "Name": "sundara nandakumaara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "SaptaRatna Krithis-7",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6382",
          "Name": "sundara naTaraajam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6383",
          "Name": "sundareshwaraya jayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6384",
          "Name": "swaagatham Krishnaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6385",
          "Name": "swaagatham kushalam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6386",
          "Name": "swaami showriraaja",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6387",
          "Name": "swaami umakku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6388",
          "Name": "Thillana-puRaneermai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "puraneermai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6389",
          "Name": "Thillana-Surutti",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6390",
          "Name": "thanakku niharillaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6391",
          "Name": "thaNDavam aaDuhiraanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6392",
          "Name": "thannanthaniyaaha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6393",
          "Name": "tharuNa maamuhil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6394",
          "Name": "thavamondRum ariyaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6395",
          "Name": "thaayE un dhaamOdhara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6396",
          "Name": "thayE yashodaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6397",
          "Name": "thEDariya selvamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6398",
          "Name": "thEDi aruLa vandhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6399",
          "Name": "thEDikkaNDEnE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6400",
          "Name": "thEjOmaya raadhikaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6401",
          "Name": "thEril vandhaanO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6402",
          "Name": "therODum veedhiyilE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6403",
          "Name": "thiruvaDi malarO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6404",
          "Name": "thONuvadillaiyaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6405",
          "Name": "too too too too",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6406",
          "Name": "thuDukkuthanam ellaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6407",
          "Name": "thuLLi viLaiyaaDinaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6408",
          "Name": "thyaagaraaja paramEsha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6409",
          "Name": "udhaja gOpa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "UmaBharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6410",
          "Name": "umaa maheshwaraathmajam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "UmaBharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6411",
          "Name": "unnilum enakkoru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6412",
          "Name": "uruhaadha manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6413",
          "Name": "uyndhadhu uyndhaden",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6414",
          "Name": "vaachakamum maRandhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6415",
          "Name": "vaaDi thOzhi annamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Khanda Ata",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6416",
          "Name": "vaahiNi naayaka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Anjaneya SaptaRathna Krithis-6",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6417",
          "Name": "vaiyam aLandhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadanamakriya",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6418",
          "Name": "vallaree samaanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6419",
          "Name": "vanamaali swaagatham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6420",
          "Name": "vaanchhasi yadi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-Dhyana Kruthi",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6421",
          "Name": "vandhadhuvum pOnadhuvum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6422",
          "Name": "vandhapOdhum varaadhapOdhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6423",
          "Name": "vandhaar kuru puNya bhoomi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6424",
          "Name": "vandhaarODi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6425",
          "Name": "vandhavarai vaarum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6426",
          "Name": "vandhE aruLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6427",
          "Name": "vandhE kavilOkha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Reethigowlai",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6428",
          "Name": "vandhE nanda soonum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KannaDamaaruvam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6429",
          "Name": "vandhE pirandhaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6430",
          "Name": "vandhE pirandhaan kaNNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6431",
          "Name": "vandhE vaalmiki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6432",
          "Name": "vandhu kETpaar illaiyO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6433",
          "Name": "vandhu nindRaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6434",
          "Name": "vaangum enakku irukkai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6435",
          "Name": "vaNNam thigazh",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6436",
          "Name": "varamondRu thandharuLvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6437",
          "Name": "vaari vihaari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kuntala Varali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6438",
          "Name": "varisai koNarndhu vandaar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6439",
          "Name": "varNikka thiranillayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naayaki",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6440",
          "Name": "vassudEvaaya namO namasthE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6441",
          "Name": "veekshitOham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Anjaneya SaptaRathna Krithis-3",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6442",
          "Name": "veLLi nilavinilE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6443",
          "Name": "vENu gaana ramaNa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6444",
          "Name": "vEREdhum vahai illayO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6445",
          "Name": "viDindhadhu pODi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6446",
          "Name": "vijayasE vijayasakhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6447",
          "Name": "vijayathE gOvinda",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6448",
          "Name": "virudhondRu aahudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6449",
          "Name": "vishamakaara kaNNan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Chatusra Ekam",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6450",
          "Name": "vishathi vishathi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6451",
          "Name": "viTa samavara",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6452",
          "Name": "viththaaram pEsi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6453",
          "Name": "yamunaa thaTa vilaasa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "kunthaLashreekaNTi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6454",
          "Name": "yaamuneeya theera",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6455",
          "Name": "yarenna sonnaalum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6456",
          "Name": "yaarum iyalaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6457",
          "Name": "yOga yOgEshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Oothukaadu Venkata Kavi",
          "Group": "Kamakshi Navaavarnam-4",
          "Language": "N/A",
          "Book Title": "#N/A",
          "Book Author": "#N/A",
          "Year of Publication": "#N/A",
          "Book Print Language": "#N/A"
      },
      {
          "Serial #": "6458",
          "Name": "animaa mayil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6459",
          "Name": "kalaimagaLe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6460",
          "Name": "saarathiyaai varuvaayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "VandhanaDhaarini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6461",
          "Name": "karaiyEra vENDaamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RasikaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6462",
          "Name": "vEleDuththa kaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6463",
          "Name": "iniyEdhu kavalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6464",
          "Name": "arangaaa aravaNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6465",
          "Name": "aaruyirgeLukkellaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6466",
          "Name": "sathya sEvaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BhogaVasantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6467",
          "Name": "pazham nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6468",
          "Name": "suDar vaDi vElaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6469",
          "Name": "kaalakaalan kayilai naadhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6470",
          "Name": "kaDaikaNNilaagilum paaraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6471",
          "Name": "konji konji vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6472",
          "Name": "yaaro avan yaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6473",
          "Name": "vElanai ninaithirundhaal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6474",
          "Name": "kanindhuLamurugi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6475",
          "Name": "indha varamaruLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6476",
          "Name": "endraniDar kaNDapinnum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6477",
          "Name": "yenindha viLaiyaaTTaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6478",
          "Name": "Undran thanipperumai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Rukmaambhari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6479",
          "Name": "aaruyir annai nin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6480",
          "Name": "vElaippiDitha un",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6481",
          "Name": "ellaam umadhu seyal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6482",
          "Name": "gurupara guhanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6483",
          "Name": "kaNNaa karuneela mugil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6484",
          "Name": "unnai marandhu naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6485",
          "Name": "yendrO ariyEnE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6486",
          "Name": "un thiru naamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6487",
          "Name": "yEzhai mEl iththanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6488",
          "Name": "karuthilennaaLum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6489",
          "Name": "sahiyE ini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6490",
          "Name": "veNNei uNNumen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6491",
          "Name": "urangiDuvaai endre",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6492",
          "Name": "virivaanagam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Amrithavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6493",
          "Name": "yEnO yenai",
          "Type": "Javali",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6494",
          "Name": "enna dhaan nee",
          "Type": "Padam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6495",
          "Name": "eppaDi sahippEnaDi",
          "Type": "Padam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6496",
          "Name": "indha vidham eppaDi nee",
          "Type": "Padam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6497",
          "Name": "idhu thaanO",
          "Type": "Padam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: N Sivaramakrishna Iyer",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6498",
          "Name": "sakala kalaa valli",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6499",
          "Name": "BaalasubramaNyanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6500",
          "Name": "anjaadhE nenjE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6501",
          "Name": "naam bhajanai seiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Khanda Triputa",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6502",
          "Name": "Anbe sivam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nalinakanthi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan/Music: Chitoor Subramanya Pillai",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Malai",
          "Book Author": "N Sivaramakrishnayyar, Chitoor Subramanya Pillai",
          "Year of Publication": "1949",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6503",
          "Name": "gaNanaathanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6504",
          "Name": "pazham nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6505",
          "Name": "kundRuruva vElvaangi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6506",
          "Name": "kaala kaalan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6507",
          "Name": "murugaa murugaa endraal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saveri",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6508",
          "Name": "konji konji vaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6509",
          "Name": "veNNei uNNumen",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6510",
          "Name": "thaayE tripura sundari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Khanda Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6511",
          "Name": "yeththanai dharam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6512",
          "Name": "vEleDuththa kaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6513",
          "Name": "aaruyirgeLukkellaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6514",
          "Name": "kazhanigaL soozh thiru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6515",
          "Name": "sathya sEvaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "BhogaVasantham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6516",
          "Name": "thaayinai kaanaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6517",
          "Name": "indha varma aruLa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6518",
          "Name": "engE theDuginraai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6519",
          "Name": "karuththil ennaaLum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6520",
          "Name": "unaiyanDri vEroru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saraswathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6521",
          "Name": "pazhani malai thanil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6522",
          "Name": "gurupara guhanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6523",
          "Name": "yEdhEdhO yeNNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6524",
          "Name": "appaa unnai maravEnE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6525",
          "Name": "enmanam pOlE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Darbaar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6526",
          "Name": "saarathiyaai varuvaayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "VandhanaDhaarini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6527",
          "Name": "iniyEdhu kavalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNaatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6528",
          "Name": "murugaa yenadhaaruyirE",
          "Type": "Varnam-Tana",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6529",
          "Name": "Thillana-UdayaRaviChandrika",
          "Type": "Thillana",
          "Ragam": "UdayaRavichandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6530",
          "Name": "indha veeDundran",
          "Type": "Kriti/Keerthanam",
          "Ragam": "DevaGandhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6531",
          "Name": "kalaiyinbamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6532",
          "Name": "pizhai ninaindhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Neelambari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6533",
          "Name": "shanthamillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6534",
          "Name": "aaDum perumaanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6535",
          "Name": "kai koDuppaayE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6536",
          "Name": "manamirangaadhadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Punnagavarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6537",
          "Name": "endrO nee uNarvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6538",
          "Name": "kailayangiri thanil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Matyam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6539",
          "Name": "namakkoru kurai yEdhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6540",
          "Name": "enna puNNiyam seidhEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "DesiyaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6541",
          "Name": "appanirukkumpodhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6542",
          "Name": "endrenai naaDiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vilaasini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6543",
          "Name": "saamagaanapriyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6544",
          "Name": "yaaro avan yaaro",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6545",
          "Name": "paarellaam aruLaalE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6546",
          "Name": "arangaaa aravaNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6547",
          "Name": "kaaNbhadhendrO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pilu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6548",
          "Name": "sahiyE ini",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6549",
          "Name": "urangiDuvaai endre",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6550",
          "Name": "kaNNan kuzhalOsai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SindhuBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6551",
          "Name": "kaNDEn kanavinilE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6552",
          "Name": "thanjamendru vandha yenai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Isai Mani Manjari",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1970",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6553",
          "Name": "ainkaranE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6554",
          "Name": "animaa mayil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6555",
          "Name": "harihara sudhanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6556",
          "Name": "kaDaikkaNNilaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6557",
          "Name": "sathiyamE vellum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6558",
          "Name": "vENu gaana amudhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6559",
          "Name": "sirithu purameriththa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6560",
          "Name": "indroru pudhunaaL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jayanarayani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6561",
          "Name": "endrO ariyEnE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6562",
          "Name": "Ekaambara naathaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6563",
          "Name": "muruganaDi manamuruga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6564",
          "Name": "vElavaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6565",
          "Name": "karuNaiyin avathaaram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mukhari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6566",
          "Name": "karaiyEra vENDaamaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RasikaRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6567",
          "Name": "veera vaazhvE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6568",
          "Name": "raajaraajEshwari",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6569",
          "Name": "yenakkoru vazhiyumE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6570",
          "Name": "muruganaDi sErndha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sama",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6571",
          "Name": "pazhani nindra paraman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6572",
          "Name": "nenjamE nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6573",
          "Name": "suDar vaDi vElaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6574",
          "Name": "evvuyirukkum anbu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RagaVinodini",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6575",
          "Name": "sEvaDi malarvaaDa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "RaviChandrika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6576",
          "Name": "vetriaruLum vElaip patRi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6577",
          "Name": "muruganendru varuvaanO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6578",
          "Name": "sendhil vaLar guhanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6579",
          "Name": "dEva dEva mahaadEva",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pushpalathika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6580",
          "Name": "eNNiDumbhOdhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Manirangu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6581",
          "Name": "marutheeswaranE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6582",
          "Name": "aanDavanE sharaNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6583",
          "Name": "vEl muruganai ninaivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6584",
          "Name": "ennenna viLaiyaaDal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "N/A",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6585",
          "Name": "picchai yeDukka vandha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6586",
          "Name": "azhagudeivamaaga",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kavadi Sindhu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6587",
          "Name": "aranumaagi hariyumaagi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kavadi Sindhu",
          "Thalam": "Mishra Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6588",
          "Name": "sirumalarE unai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malayamarutham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6589",
          "Name": "paadhaaravindham",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6590",
          "Name": "sumaiperidhendrE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6591",
          "Name": "thillayil aaDum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6592",
          "Name": "aDaikalamenDru",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6593",
          "Name": "sendhil vaDivElanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kannadaa",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6594",
          "Name": "Sree Venkateshwaraa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6595",
          "Name": "endrunaikaaNbhEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6596",
          "Name": "koNDal maNivaNNaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6597",
          "Name": "kaliyugavaradhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Brindavana Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Murugan Arul Mani Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1972",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6598",
          "Name": "ainkaranillamal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "Ganesha",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6599",
          "Name": "aadi mandiram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6600",
          "Name": "maamadhurai meenakshi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Chapu",
          "Praise of": "Madurai Meenakshi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6601",
          "Name": "unaiyanDRi thuNaikaaNEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sarasangi",
          "Thalam": "Khanda Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6602",
          "Name": "endru varumO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6603",
          "Name": "puNNiyam orukODi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6604",
          "Name": "thanjam yena vandhenai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6605",
          "Name": "sakala kalaa vaaNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Roopakam",
          "Praise of": "Saraswathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6606",
          "Name": "sodhanai seidhadhu pOdhum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6607",
          "Name": "mangaLa vinaayakanE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Chapu",
          "Praise of": "Ganesha",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6608",
          "Name": "aadhi shankarar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Chapu",
          "Praise of": "Adi Sankara",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6609",
          "Name": "vazhikaaTuvaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Navarasa Kannadaa",
          "Thalam": "Adi",
          "Praise of": "VaLLalaar",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6610",
          "Name": "hari inbaa naamam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6611",
          "Name": "yezhaimEl ithanai kObam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6612",
          "Name": "muraLi dhara gOpaalaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Maand",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6613",
          "Name": "paramEshwari ambaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Jhampai",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6614",
          "Name": "pichchai yeDukka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6615",
          "Name": "annapooraNi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poornachandrika",
          "Thalam": "Adi",
          "Praise of": "Kanchi Kamakshi Annapoorani",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6616",
          "Name": "yengum niraivaana",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Mishra Chapu",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6617",
          "Name": "sollith theriya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pantuvarali",
          "Thalam": "Chapu",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6618",
          "Name": "thiruvaDi thozhugindREn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6619",
          "Name": "oppumai illaadha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6620",
          "Name": "yenindha viLaiyaaTTaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6621",
          "Name": "vElum mayilum thuNaiyE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Charukesi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6622",
          "Name": "sindhanai seimanamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6623",
          "Name": "naTanamaaDinaar naadhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Varali",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6624",
          "Name": "karpagavalli ambaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Mylapore Karpagambal",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6625",
          "Name": "BaalasubramaNyanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6626",
          "Name": "naama bhajani sei manamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "HariKhambhoji",
          "Thalam": "Khanda Triputa",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6627",
          "Name": "jaya sree raamakrishNa dEvaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "Ramakrishna Paramahamsa",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6628",
          "Name": "annai shaaradhaa maNi dEvi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "Saradambaal",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6629",
          "Name": "yethanai naaL yenguvadhO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6630",
          "Name": "nandha gOpaalanai",
          "Type": "Varnam-Pada",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Krishna",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6631",
          "Name": "innamum umadhu nenjam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6632",
          "Name": "kaathirukkindREn",
          "Type": "Padam",
          "Ragam": "Pushpalathika",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6633",
          "Name": "yenO avarkkenmEl",
          "Type": "Padam",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Jhampai",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6634",
          "Name": "ariyaap paruvamadhil",
          "Type": "Padam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6635",
          "Name": "endru nin thErvarum",
          "Type": "Padam",
          "Ragam": "Vilaasini",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6636",
          "Name": "veeN pazhi sumakka vENDaam",
          "Type": "Javali",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6637",
          "Name": "kanni kazhiyaadha kaalathil",
          "Type": "Javali",
          "Ragam": "Hindolam",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6638",
          "Name": "yEnO yenai marandhEnO",
          "Type": "Javali",
          "Ragam": "Abheri",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6639",
          "Name": "sharavaNabhava mandhiram",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Navamani Isai Malai",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1980",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6640",
          "Name": "kalaimagaLe",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "Saraswathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6641",
          "Name": "murugan thuNai varuvaan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6642",
          "Name": "unnaDimai naanallavO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6643",
          "Name": "tharuNamidhu thaanaiyya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shankarabharanam",
          "Thalam": "Jhampai",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6644",
          "Name": "vElavanai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ramapriya",
          "Thalam": "Jhampai",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6645",
          "Name": "indha Or udhaviyaagilum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6646",
          "Name": "endraniDar kaNDapinnum",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kalyani",
          "Thalam": "Khanda Chapu",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6647",
          "Name": "sharaNam sharaNam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6648",
          "Name": "kaalanai veezhthiya",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6649",
          "Name": "ini indha vaazhvil",
          "Type": "Kriti/Keerthanam",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6650",
          "Name": "yEn padaithai yenakE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nadabrahmam",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6651",
          "Name": "anjEl endrE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6652",
          "Name": "idhuvO nin karuNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Malahari",
          "Thalam": "Chapu",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6653",
          "Name": "kanavilum naan maravEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6654",
          "Name": "vaazhvinil thunbam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6655",
          "Name": "eNNuvadhellaam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6656",
          "Name": "manamE unakkEnO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Nayaki",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6657",
          "Name": "innamum avar manam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6658",
          "Name": "ulagariyaa endru uvagai paDaadhE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6659",
          "Name": "naanoru siru veeNai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6660",
          "Name": "manadhu kalangaadhE nee",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6661",
          "Name": "muruganE yenai aaNDaruL",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Madhyamavathi",
          "Thalam": "Roopakam",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6662",
          "Name": "ariyaamai iruL pOkki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhowli",
          "Thalam": "Khanda Chapu",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6663",
          "Name": "kOzhikoDi uyartha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Chenchurutti (KavadiSindhu)",
          "Thalam": "Mishra Chapu",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6664",
          "Name": "engu naan selvEn aiyyaa",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dwijaavanthi",
          "Thalam": "Khanda Chapu",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6665",
          "Name": "enna sugam kaNDaai manamE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Vasantha",
          "Thalam": "Chatusra jathi Matya Thalam",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6666",
          "Name": "un naamam un pEchchu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6667",
          "Name": "naaTkaLai yavamaai naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6668",
          "Name": "ellamumadhu seyal endraal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6669",
          "Name": "sakala kalaa naayaki",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Sowrashtram",
          "Thalam": "Adi",
          "Praise of": "Saraswathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6670",
          "Name": "vElaippiDitha un kaalai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kaapi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6671",
          "Name": "hari naamam bhajanai seivaai",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Surutti",
          "Thalam": "Chapu",
          "Praise of": "Vishnu",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6672",
          "Name": "aDaikkalam aiyaa naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GambiraNaatai",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6673",
          "Name": "saravaNa poigaiyilE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6674",
          "Name": "yeththanai naaL thaan yEnguvadhO",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6675",
          "Name": "yaarO avar yaarO endhan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Hamsanandi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6676",
          "Name": "yaariDam murai solluvEn",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6677",
          "Name": "un thiru naamam endranukku",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6678",
          "Name": "ini enna kurai yenakkE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dhenuka",
          "Thalam": "Adi",
          "Praise of": "Vishnu",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6679",
          "Name": "eppaDi sahippEnaDi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6680",
          "Name": "jagaththinai paDaitha",
          "Type": "Kriti/Keerthanam",
          "Ragam": "GowriManohari",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6681",
          "Name": "enna pizhai enniDam",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6682",
          "Name": "aiyan thiruvaDi kaatchi",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6683",
          "Name": "vaLLi malai kaaTinilE",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Pilu",
          "Thalam": "Tisra Nadai",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6684",
          "Name": "unnai marandhu naan",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6685",
          "Name": "aaruyir annai nin",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6686",
          "Name": "kuzhandhai paruvamudhal",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6687",
          "Name": "oozhil andru paraman",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Kavadi Sindhu",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6688",
          "Name": "pichchai yeDukka",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Ragamalika",
          "Thalam": "Mishra Chapu",
          "Praise of": "Shiva",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6689",
          "Name": "jyothiswaroopini uyar",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Jyothiswaroopini",
          "Thalam": "Khanda Ekam",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6690",
          "Name": "param poruLe unadhu",
          "Type": "Kriti/Keerthanam",
          "Ragam": "Dharmavathi",
          "Thalam": "Adi",
          "Praise of": "ParaBrahmam",
          "Composer": "Periyasamy Turan",
          "Group": "N/A",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6691",
          "Name": "thEnendru mozhi pEsum",
          "Type": "Poem",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "Murugan",
          "Composer": "Periyasamy Turan",
          "Group": "Pazhani Murugan thudhi",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6692",
          "Name": "azhagu vaDivaai vandha",
          "Type": "Poem",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "Devi-Parvathi",
          "Composer": "Periyasamy Turan",
          "Group": "Thiruverkaadu Devi Karumaari amman thudhi",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6693",
          "Name": "karpagamE karpagamaai",
          "Type": "Poem",
          "Ragam": "N/A",
          "Thalam": "N/A",
          "Praise of": "Mylapore Karpagambal",
          "Composer": "Periyasamy Turan",
          "Group": "Mylai Karpagambal thudhi",
          "Language": "Tamil",
          "Book Title": "Periyasamy Turan - Keerthanai Amudam",
          "Book Author": "Periyasamy Turan",
          "Year of Publication": "1974",
          "Book Print Language": "Tamizh"
      },
      {
          "Serial #": "6694",
          "Name": "Evvari Bodhana",
          "Type": "Varnam-Tana",
          "Ragam": "Abhogi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6695",
          "Name": "rAmuni kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Abhogi",
          "Thalam": "Khanda Jathi Triputa",
          "Praise of": "N/A",
          "Composer": "Srinivasa Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6696",
          "Name": "vallamai tharuvAi",
          "Type": "Varnam-Tana",
          "Ragam": "Ahiri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Muthukumaraswamy, Varahur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6697",
          "Name": "ninnE kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Ahiri",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tarangampadi Panchanada Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6698",
          "Name": "saami nee pai",
          "Type": "Varnam-Tana",
          "Ragam": "Amruthavarshini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6699",
          "Name": "kOri sEvimparE",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6700",
          "Name": "ninnu nammi naa",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6701",
          "Name": "pantha mEla naa saami",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponnaiyya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6702",
          "Name": "paavana suguna",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6703",
          "Name": "raa raa sami",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6704",
          "Name": "saare gunidu",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6705",
          "Name": "saami nee pai",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6706",
          "Name": "sami nee rammaa",
          "Type": "Varnam-Tana",
          "Ragam": "Ananda Bhairavi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6707",
          "Name": "mahishaa sura",
          "Type": "Varnam-Tana",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6708",
          "Name": "nee daya raadhaa",
          "Type": "Varnam-Tana",
          "Ragam": "Andolika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6709",
          "Name": "annamE",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tiger Varadacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6710",
          "Name": "paapa jaati maaruni",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Venai Krishnamachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6711",
          "Name": "sarasija mukhi",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotri Veenai Kuppaiyer / Pallavi Dorasamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6712",
          "Name": "swaami unnai sadaa",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Papanasam Sivan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6713",
          "Name": "O maani baari",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6714",
          "Name": "ninne enthO",
          "Type": "Varnam-Tana",
          "Ragam": "Arabhi",
          "Thalam": "Tisra Jathi Dhruva Thalam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6715",
          "Name": "taama sincha kathaLa",
          "Type": "Varnam-Tana",
          "Ragam": "Asaveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "PS Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6716",
          "Name": "nela tharO ee marulu",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6717",
          "Name": "karuNa karuDa",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Tisra Jathi Ata Thalam (Gupta)",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6718",
          "Name": "saami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6719",
          "Name": "manasu thelisi yuNTi",
          "Type": "Varnam-Tana",
          "Ragam": "Atana",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6720",
          "Name": "nenarulE",
          "Type": "Varnam-Tana",
          "Ragam": "Balahamsa",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6721",
          "Name": "sree raajaadhi raaja",
          "Type": "Varnam-Tana",
          "Ragam": "Balahamsa",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6722",
          "Name": "ajanani nannu",
          "Type": "Varnam-Tana",
          "Ragam": "Bhowli",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6723",
          "Name": "dayaa nidhE",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6724",
          "Name": "Intha chalamu",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6725",
          "Name": "mazhalai chollaal",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Srinivasachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6726",
          "Name": "neerajaaNa",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "PS Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6727",
          "Name": "marachiTLuNDE",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "PS Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6728",
          "Name": "Saraswathi",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6729",
          "Name": "ummaiyE endRum ninaindhu",
          "Type": "Varnam-Tana",
          "Ragam": "Begada",
          "Thalam": "Tisra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6730",
          "Name": "vanajaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Behag",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6731",
          "Name": "ennuLLam kavarndha",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Muthukumarasamy, Varahur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6732",
          "Name": "ninnu kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer/CS Ponniah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6733",
          "Name": "sarasa gura",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Walajapet Venkatramana Bhagavathar/Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6734",
          "Name": "sree maadhavi gowri",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ksheerabdi Shastry anandhai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6735",
          "Name": "vanitha ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponnusamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6736",
          "Name": "yEmi sEthunE",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer/CS Ponniah",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6737",
          "Name": "Viriboni",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Pachhaimiriam Adiyappa",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6738",
          "Name": "nee saaTi dora",
          "Type": "Varnam-Tana",
          "Ragam": "Bhairavi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6739",
          "Name": "saami nannelu kOra",
          "Type": "Varnam-Tana",
          "Ragam": "Bhupalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6740",
          "Name": "intha chauka",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6741",
          "Name": "yaadhu seivEn",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "CS Nataraja Sundaram Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6742",
          "Name": "nenarunchi",
          "Type": "Varnam-Tana",
          "Ragam": "Bilahari",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Sonti Venkatasubbayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6743",
          "Name": "Jalajakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6744",
          "Name": "maadha rasi en ariyaa pizhai",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ksheerabdi Shastry anandhai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6745",
          "Name": "saami nannelu kOra",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6746",
          "Name": "sara sUDa",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ummadichetti Venkatasamy Naidu",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6747",
          "Name": "ingkala mEla",
          "Type": "Varnam-Tana",
          "Ragam": "Chakravaham",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6748",
          "Name": "shree raajaadhi raaja mahaaraaja",
          "Type": "Varnam-Tana",
          "Ragam": "Chalanaatai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Balaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6749",
          "Name": "Eemaguva bOdhi",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Sadashiva Rao",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6750",
          "Name": "vana jaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6751",
          "Name": "vara vENum",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N Koteeswara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6752",
          "Name": "nena runchi",
          "Type": "Varnam-Tana",
          "Ragam": "Dhanyasi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6753",
          "Name": "chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6754",
          "Name": "mudhar paar moovaril",
          "Type": "Varnam-Tana",
          "Ragam": "Darbar",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Srinivasachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6755",
          "Name": "intha mODi",
          "Type": "Varnam-Tana",
          "Ragam": "Darbar",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6756",
          "Name": "palu maaru ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Devamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6757",
          "Name": "ninne entho vEDi",
          "Type": "Varnam-Tana",
          "Ragam": "Devamanohari",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6758",
          "Name": "shree meenakshi maam paahi",
          "Type": "Varnam-Tana",
          "Ragam": "Gamakakriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedantha Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6759",
          "Name": "ninnu kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Gamakakriya",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Sonti Venkatasubbayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6760",
          "Name": "chelimi kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6761",
          "Name": "gaNanaathaa nin ponnaDi",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mayuram Vishwanatha Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6762",
          "Name": "kanakaangi pai",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6763",
          "Name": "ninnE kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6764",
          "Name": "rammani pilachE",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6765",
          "Name": "aapalumaaru",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6766",
          "Name": "paadham paNindhE",
          "Type": "Varnam-Tana",
          "Ragam": "Gowlipanthu",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Muthukumaraswamy, Varagur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6767",
          "Name": "mayilE poom pozhi",
          "Type": "Varnam-Tana",
          "Ragam": "Guhamanohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "NS Ramachandran",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6768",
          "Name": "Jalajakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Manambuchaavadi Venkatasubbaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6769",
          "Name": "paga vaari bO",
          "Type": "Varnam-Tana",
          "Ragam": "Hamsadhwani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6770",
          "Name": "Sri Subramanya",
          "Type": "Varnam-Tana",
          "Ragam": "Hamsadhwani",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6771",
          "Name": "gnaana maargam",
          "Type": "Varnam-Tana",
          "Ragam": "Hamsanadam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vedanayakam Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6772",
          "Name": "jaalamEla",
          "Type": "Varnam-Tana",
          "Ragam": "Hemavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6773",
          "Name": "chalamelara",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6774",
          "Name": "Maal Marugan",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TM Thyagarajan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6775",
          "Name": "Maal Marugan",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tanjore Shankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6776",
          "Name": "sakala kalaa vaaNi",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubrmanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6777",
          "Name": "swaaminaathaa",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Muthukumaraswamy, Varagur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6778",
          "Name": "saami ninne kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6779",
          "Name": "rammana",
          "Type": "Varnam-Tana",
          "Ragam": "Hindolam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6780",
          "Name": "valachi vachchi",
          "Type": "Varnam-Tana",
          "Ragam": "HindolaVasantham",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6781",
          "Name": "eemaayalaaTi",
          "Type": "Varnam-Tana",
          "Ragam": "Huseni",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6782",
          "Name": "sarasija nEtRi",
          "Type": "Varnam-Tana",
          "Ragam": "Jaganmohini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Karaikudi Rajamani Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6783",
          "Name": "shringaara janaranjani",
          "Type": "Varnam-Tana",
          "Ragam": "Janaranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6784",
          "Name": "arullilaarku avvulagam",
          "Type": "Varnam-Tana",
          "Ragam": "Jayanthashree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvalluvar/VS GomathyShankara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6785",
          "Name": "mahaa gaNapathe namasthE",
          "Type": "Varnam-Tana",
          "Ragam": "Jayanthashree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai GN Dandapani",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6786",
          "Name": "sharaNagatha vatsalE",
          "Type": "Varnam-Tana",
          "Ragam": "Kathanakuthuhalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6787",
          "Name": "kuvalayaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Kathanakuthuhalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanian",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6788",
          "Name": "nivera",
          "Type": "Varnam-Tana",
          "Ragam": "Kathanakuthuhalam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6789",
          "Name": "chinnaanu",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvitriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6790",
          "Name": "chirugu bOni",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6791",
          "Name": "dharmasamvardhani",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6792",
          "Name": "kamalaambha ninnu",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6793",
          "Name": "saamiyai azhaithu vaaDi",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6794",
          "Name": "sarOjaaksha ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6795",
          "Name": "vanajaakshirO",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar/Mullaiveetu Rangaswamy Nattuvanar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6796",
          "Name": "vanajakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Pallavi Gopala Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6797",
          "Name": "nivE gathi yani",
          "Type": "Varnam-Tana",
          "Ragam": "Kalyani",
          "Thalam": "Tisra Jathi Matya Thalam (Saara)",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6798",
          "Name": "garima ninnE",
          "Type": "Varnam-Tana",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kottavasal Venkatrama Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6799",
          "Name": "neevamTi saami",
          "Type": "Varnam-Tana",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Chinayya Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6800",
          "Name": "Sree kamalambhaa",
          "Type": "Varnam-Tana",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6801",
          "Name": "shree raama chandra",
          "Type": "Varnam-Tana",
          "Ragam": "KamalaManohari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6802",
          "Name": "samini",
          "Type": "Varnam-Pada",
          "Ragam": "Khamas",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6803",
          "Name": "entha ninnE",
          "Type": "Varnam-Pada",
          "Ragam": "Khamas",
          "Thalam": "Tisra jathi Eka Thalam",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6804",
          "Name": "pankajaakshi pai",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Maha Vaidhyanatha Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6805",
          "Name": "saamiki nEnE ramanTi",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6806",
          "Name": "saarasaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6807",
          "Name": "tharuNi ninnu",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Fiddle Ponnusamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6808",
          "Name": "intha jaalamu",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Pallavi GopalaIyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6809",
          "Name": "sarasija naabha",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal/Vadivelu",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6810",
          "Name": "kamalaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Khambhoji",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Kunnakudi Krishna Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6811",
          "Name": "Ninnekori",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6812",
          "Name": "sarasuda",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Krishnamachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6813",
          "Name": "nera nammithi",
          "Type": "Varnam-Tana",
          "Ragam": "Kaanada",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6814",
          "Name": "alukanela",
          "Type": "Varnam-Tana",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6815",
          "Name": "anthamizh vaDa mozhi",
          "Type": "Varnam-Tana",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Krishnamachari",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6816",
          "Name": "saamidhi",
          "Type": "Varnam-Tana",
          "Ragam": "Kannadaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6817",
          "Name": "sadaya valachi",
          "Type": "Varnam-Tana",
          "Ragam": "Kaapi",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6818",
          "Name": "Kaamaakshi nee paadha",
          "Type": "Varnam-Tana",
          "Ragam": "KarnatakaKapi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6819",
          "Name": "Sree kaanchi",
          "Type": "Varnam-Tana",
          "Ragam": "Kasiramakriya/Pantuvarali",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Sonti Venkatasubayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6820",
          "Name": "nee sari",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Govindasamy ayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6821",
          "Name": "saami daya jooda",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6822",
          "Name": "saami naa pai",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6823",
          "Name": "viriboni",
          "Type": "Varnam-Tana",
          "Ragam": "KedaraGowlai",
          "Thalam": "Jhampai",
          "Praise of": "N/A",
          "Composer": "Dharmapuri Subbarayar/Rudrapatnam Venkataramayya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6824",
          "Name": "sarOjaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Kedaram",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6825",
          "Name": "sami ni rammanavE",
          "Type": "Varnam-Tana",
          "Ragam": "Kedaram",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6826",
          "Name": "muddugumma",
          "Type": "Varnam-Tana",
          "Ragam": "Kharaharapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Krishnamachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6827",
          "Name": "hE kaaLi neevE gati",
          "Type": "Varnam-Tana",
          "Ragam": "Kharaharapriya",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Anantakrishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6828",
          "Name": "chala mela",
          "Type": "Varnam-Tana",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6829",
          "Name": "Sree krishna naatha",
          "Type": "Varnam-Tana",
          "Ragam": "Keeravani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6830",
          "Name": "guNa nalam koNda enthan",
          "Type": "Varnam-Tana",
          "Ragam": "Kokilapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Muthukumaraswamy, Varagur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6831",
          "Name": "Ninnu kori",
          "Type": "Varnam-Tana",
          "Ragam": "Lalithaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6832",
          "Name": "vanajaakshirO",
          "Type": "Varnam-Tana",
          "Ragam": "Lalithaa",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6833",
          "Name": "paim pon mEni",
          "Type": "Varnam-Tana",
          "Ragam": "Lalithapanchamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Muthukumarasamy, varagur",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6834",
          "Name": "saraguNa nanEla",
          "Type": "Varnam-Tana",
          "Ragam": "Madhyamavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6835",
          "Name": "intha viraga taapam",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6836",
          "Name": "saami ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Malayamarutham",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6837",
          "Name": "vanajaaksha nammi naanu",
          "Type": "Varnam-Tana",
          "Ragam": "Mandari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Mysore Vasudevacharya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6838",
          "Name": "chaala valachi",
          "Type": "Varnam-Tana",
          "Ragam": "Manirangu",
          "Thalam": "Tisra Ekam",
          "Praise of": "N/A",
          "Composer": "Pallavi Seshaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6839",
          "Name": "maayaamaaLava gowlai",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Srinivasachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6840",
          "Name": "ninnE chaala nammithi",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6841",
          "Name": "ninne kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6842",
          "Name": "sarasaangi neepai",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Raghavendrachariaar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6843",
          "Name": "sarasija naabha",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6844",
          "Name": "saravaNabhava shaNmugaa",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Fiddle Krishnaswamy Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6845",
          "Name": "sree raajaraajeshwari",
          "Type": "Varnam-Tana",
          "Ragam": "Mayamalawa Gowlai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6846",
          "Name": "ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pallavi Gopalaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6847",
          "Name": "ninnu kori",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6848",
          "Name": "sarighaadhaani",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Govindasaami",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6849",
          "Name": "paga joopEvEla",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6850",
          "Name": "sarasijaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Mohanam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6851",
          "Name": "era naapai",
          "Type": "Varnam-Tana",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6852",
          "Name": "saami nannelu kOra",
          "Type": "Varnam-Tana",
          "Ragam": "Mukhari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6853",
          "Name": "aamadiraakshi neepai",
          "Type": "Varnam-Tana",
          "Ragam": "Mukhari",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6854",
          "Name": "maguva neepai",
          "Type": "Varnam-Tana",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6855",
          "Name": "saakEtha nagara",
          "Type": "Varnam-Tana",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Srinivasa Aiyyaa",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6856",
          "Name": "saami nee pai",
          "Type": "Varnam-Tana",
          "Ragam": "Nagaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6857",
          "Name": "aamaguva",
          "Type": "Varnam-Tana",
          "Ragam": "NarayanaGowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6858",
          "Name": "saami ninne kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Narayani",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6859",
          "Name": "manjuLa vENi",
          "Type": "Varnam-Tana",
          "Ragam": "Natabhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Krishnamachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6860",
          "Name": "palumaaru",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6861",
          "Name": "sarasija naabha",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Palakadu Parameshwara Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6862",
          "Name": "maruvai yunnadhi",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6863",
          "Name": "samukamukanu",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Vadivel Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6864",
          "Name": "shree naatha",
          "Type": "Varnam-Tana",
          "Ragam": "Naatai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6865",
          "Name": "chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kuppuswamy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6866",
          "Name": "sarasaa ninnE",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6867",
          "Name": "inthapara",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thayagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6868",
          "Name": "intha",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "koovanasamy Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6869",
          "Name": "saami neeve",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6870",
          "Name": "saami gaanEni",
          "Type": "Varnam-Tana",
          "Ragam": "Naatakurinji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6871",
          "Name": "sarasijaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Nayaki",
          "Thalam": "Khanda Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6872",
          "Name": "neevanTi daivamu",
          "Type": "Varnam-Tana",
          "Ragam": "Neelambari",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tarangampadi Panchanadayyar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6873",
          "Name": "eevEla nannu",
          "Type": "Varnam-Tana",
          "Ragam": "Paadi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pathamadai Krishnaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6874",
          "Name": "sami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Shatkaala Narasaiyya",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6875",
          "Name": "saami neethOdi dEvEna",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6876",
          "Name": "chalamu",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6877",
          "Name": "Cheliya",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6878",
          "Name": "madhava",
          "Type": "Varnam-Tana",
          "Ragam": "Pantuvarali",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Sri Adappayyaa",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6879",
          "Name": "taapa saanugruheeta",
          "Type": "Varnam-Tana",
          "Ragam": "Paras",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pallavi Subbiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6880",
          "Name": "egaanEree",
          "Type": "Varnam-Tana",
          "Ragam": "Poornachandrika",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6881",
          "Name": "shree raaja",
          "Type": "Varnam-Tana",
          "Ragam": "Poornachandrika",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6882",
          "Name": "elananne",
          "Type": "Varnam-Tana",
          "Ragam": "Poornachandrika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6883",
          "Name": "pankajaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6884",
          "Name": "SaamikientanO",
          "Type": "Varnam-Tana",
          "Ragam": "Poorvikalyani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6885",
          "Name": "sarivaari",
          "Type": "Varnam-Tana",
          "Ragam": "Poorvikalyani",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6886",
          "Name": "saarasaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Poorvikalyani",
          "Thalam": "Mishra Jathi Roopaka Thalam (Kula)",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6887",
          "Name": "valachi yunna",
          "Type": "Varnam-Tana",
          "Ragam": "Pushpalathika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vainika Sri Kalahasthi Venkataswami",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6888",
          "Name": "raagamaalikaa varnamu",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "P Sambamoorthy",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6889",
          "Name": "intha kOpa",
          "Type": "Varnam-Tana",
          "Ragam": "GhanaRagamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6890",
          "Name": "sami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6891",
          "Name": "chera raava",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "Nakshathra Ragamalika",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6892",
          "Name": "valachi vachchi",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "Navaragamalika",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6893",
          "Name": "saami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Ragamalika",
          "Thalam": "Triputa",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6894",
          "Name": "saakEtha nagara",
          "Type": "Varnam-Tana",
          "Ragam": "Ragaswarali",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6895",
          "Name": "ambOruhaa",
          "Type": "Varnam-Tana",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "GN Balasubramanyam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6896",
          "Name": "neeve nunnu",
          "Type": "Varnam-Tana",
          "Ragam": "Ranjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6897",
          "Name": "saami vanajaaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Reethigowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6898",
          "Name": "vanajaakshaa ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Reethigowlai",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6899",
          "Name": "karuNimpa",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6900",
          "Name": "evarEmi bOdha",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6901",
          "Name": "vaarijaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Sahana",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Subbarama Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6902",
          "Name": "Sami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6903",
          "Name": "Chalamela",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal/Vadivelu",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6904",
          "Name": "niluparaani mOha",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6905",
          "Name": "raarapu",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6906",
          "Name": "Elana",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6907",
          "Name": "gajaananaaya",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6908",
          "Name": "Vanaja",
          "Type": "Varnam-Tana",
          "Ragam": "Shankarabharanam",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6909",
          "Name": "intha mODi",
          "Type": "Varnam-Tana",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer/Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6910",
          "Name": "ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Saranga",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6911",
          "Name": "saaranga",
          "Type": "Varnam-Tana",
          "Ragam": "Saranga",
          "Thalam": "Roopakam",
          "Praise of": "N/A",
          "Composer": "Vadivelu",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6912",
          "Name": "naamana vini vinu",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Shyama Shastry",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6913",
          "Name": "valachi vachchi",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtram",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6914",
          "Name": "sadaa un maNi chilamba",
          "Type": "Varnam-Tana",
          "Ragam": "Sowrashtri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ksheerabdi Shastry Anandai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6915",
          "Name": "iravum pagalum",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Nerur Srinivasachariar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6916",
          "Name": "maanE mOgam sakhiyE",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N Koteeswara Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6917",
          "Name": "Sarasuda",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Kottavasal Venkatrama Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6918",
          "Name": "kalki ninnE",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Thiruvotriyur Thyagaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6919",
          "Name": "mana sEti",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6920",
          "Name": "neevE dikkani",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Tirunelveli Vengu Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6921",
          "Name": "vanajakshi ninnE",
          "Type": "Varnam-Tana",
          "Ragam": "Saveri",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Swathi Thirunal/Vadivelu",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6922",
          "Name": "pankajaakshi pai nenarunchi",
          "Type": "Varnam-Tana",
          "Ragam": "Shanmugapriya",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Veenai Krishnamachary",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6923",
          "Name": "pada pangkEjaata",
          "Type": "Varnam-Tana",
          "Ragam": "Simhendramadhyamam",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6924",
          "Name": "endu kinda mODi jEsE",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6925",
          "Name": "saami ninnE",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Garbhaurivasa",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6926",
          "Name": "iTu jaagu",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6927",
          "Name": "vaarijaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6928",
          "Name": "saami nee pada",
          "Type": "Varnam-Tana",
          "Ragam": "Sree",
          "Thalam": "Mishra Jathi Triputa Thalam",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6929",
          "Name": "saami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "SriRanjani",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6930",
          "Name": "Sakhiye",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6931",
          "Name": "Sree raaja maathangi",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Dhanyasi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Harikesanallur Muthiah Bhagavathar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6932",
          "Name": "mamataarE",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddha Saveri",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6933",
          "Name": "dEva dEva mahEshwara",
          "Type": "Varnam-Tana",
          "Ragam": "Shuddhaseemanthini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6934",
          "Name": "saami ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "ShuddhaThodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6935",
          "Name": "gaNanaayakaa",
          "Type": "Varnam-Tana",
          "Ragam": "Sunadavinodini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "NS Ramachandran",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6936",
          "Name": "enthO prEmathO",
          "Type": "Varnam-Tana",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6937",
          "Name": "saamini",
          "Type": "Varnam-Tana",
          "Ragam": "Surutti",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pallavi Seshaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6938",
          "Name": "intha chalamu",
          "Type": "Varnam-Tana",
          "Ragam": "Surutti",
          "Thalam": "Chatusra Jathi Ata Thalam",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6939",
          "Name": "chandra vadana",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "AZHAGIRISAMI BHAGAVATAR",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6940",
          "Name": "daani saaTipOTi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6941",
          "Name": "Era napai",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6942",
          "Name": "mOhalAhiri",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Sivanandam",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6943",
          "Name": "Roopamu juchi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthuswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6944",
          "Name": "saami ninne kori",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "N/A",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6945",
          "Name": "saara saaksha krupa",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "TV Lakshminarasimhan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6946",
          "Name": "sari gaani",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "SVARASTANA VARNAM (IN PRAISE OF MANALI CHINNAYYA MUDALIYAR)",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6947",
          "Name": "Soga manE en",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "K Ponniah Pillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6948",
          "Name": "kanakaangi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Pallavi Gopala Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6949",
          "Name": "vanajaakshi",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6950",
          "Name": "veera vasantatya",
          "Type": "Varnam-Tana",
          "Ragam": "Thodi",
          "Thalam": "Chatusra Jathi Jhampa Thalam (Madhura)",
          "Praise of": "N/A",
          "Composer": "Ramaswamy Dikshitar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6951",
          "Name": "sarasudu neeve yani",
          "Type": "Varnam-Tana",
          "Ragam": "Vachaspathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Pandanainallur mInAkshi sundarampillai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6952",
          "Name": "paarinil nee kaameshwari",
          "Type": "Varnam-Tana",
          "Ragam": "Vaahini",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ksheerabdi Shastry Anandai",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6953",
          "Name": "aarendru arindhu",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "S Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6954",
          "Name": "thanjam neeye dayai puri",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi (Tisra Gathi)",
          "Praise of": "N/A",
          "Composer": "TM Thyagarajan",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6955",
          "Name": "tharuNi ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "NS Ramachandran",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6956",
          "Name": "vana maalaa sree gOpaalaa",
          "Type": "Varnam-Tana",
          "Ragam": "Valachi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Venkatrama Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6957",
          "Name": "viragamu vasamalla",
          "Type": "Varnam-Tana",
          "Ragam": "Vamsavathi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Muthukumara Pulavar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6958",
          "Name": "vanajaaksha",
          "Type": "Varnam-Tana",
          "Ragam": "Varali",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6959",
          "Name": "ninnu kori",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Tachchur Singarachariar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6960",
          "Name": "vanajaakshirO",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Ponniah (Tanjore Quartet)",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6961",
          "Name": "nee paadapankajamu",
          "Type": "Varnam-Tana",
          "Ragam": "VasanthaBhairavi",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Rajamani Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6962",
          "Name": "daani kOri",
          "Type": "Varnam-Tana",
          "Ragam": "Vasantha",
          "Thalam": "Mishra Jathi Jhampa Thalam",
          "Praise of": "N/A",
          "Composer": "Poochi Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6963",
          "Name": "varuvaanODi",
          "Type": "Varnam-Tana",
          "Ragam": "Vinadari",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "S Srinivasa Iyengar",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6964",
          "Name": "sami ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Adi",
          "Praise of": "N/A",
          "Composer": "Vadivelu",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6965",
          "Name": "kamalaakshi ninne",
          "Type": "Varnam-Tana",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Veenai Kuppaiyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      },
      {
          "Serial #": "6966",
          "Name": "pada rEti mEra",
          "Type": "Varnam-Tana",
          "Ragam": "Yadukula Kambhoji",
          "Thalam": "Ata",
          "Praise of": "N/A",
          "Composer": "Patnam Subramanya Iyer",
          "Group": "N/A",
          "Language": "N/A",
          "Book Title": "VARNAMS - A COMPILATION",
          "Book Author": "Premeela Gurumurthy",
          "Year of Publication": "2021",
          "Book Print Language": "English"
      }
  
  ]
  const columns = [
  
    {  
     
    accessor: 'Name'  , Label: ' Name'
    },
   
    {  
   
    accessor: 'Ragam'  , Label: 'Ragam'
    },
    {
    
        accessor: 'Type'  ,Label: 'Type'
        },
        {  
        accessor: 'Composer'  , Label: 'Composer'
            },
    {  
     
      accessor: 'Thalam'  , Label: 'Thalam'
      },
        
      { 
       
        accessor: 'Group' , Label: 'Group'   
        },
        {
          
          accessor: 'Language'  , Label: 'Language' 
          },
          {
        
            accessor: 'Book Title' ,  Label: 'Book Title' 
            },
            // {
        
            //     accessor: 'Praise of'  ,Label: 'Praise of'
            //     },
            {
             
              accessor: 'Book Author'  ,  Label: 'Book Author' 
              },

              {
                
                accessor: 'Year of Publication'  ,Label: 'Year of Publication'  
                },

                {
                 
                  accessor: 'Book Print Language' , Label: 'Book Print Language'  
                  },

  ]  

  return (
      <Table data={data} columns={columns}/>
  )

}

export default DataTableComponent