import React from 'react'
import { useNavigate } from 'react-router-dom'

const AboutUs = () => {
    const navigate = useNavigate();
    return (
        <div className="container mt-5 wrapper1">
        <h2 className="mb-3">About Us</h2>
       
        <div class="row col-sm-12 interchange">
            <h5>Bhargavi Chandrasekaran</h5> <br /> <br />
                <div class="col-sm-6 text-center">
                    <p>Bhargavi Chandrasekaran is in 7th grade attending middle school in Katy, TX.
She has been taking Carnatic music vocal lessons since age 5 from Vidushi
Smt. Rajarajeshwary Bhat and Vidushi Smt. Kruthi Bhat. Bhargavi gave her
solo debut concert in 2019 at the age of 9. She had participated and won
various prizes in music competitions conducted by the following
organizations - Tambura first prize-Sub-Junior Overseas Category at
Guruguhaamruta competition-2020, Cleveland Tyagaraja Aradhana, Vidwan
VVS Murari sir's VVS Foundation-SaMaa Arts, The Artiste, SAPTAMI-Dallas,
MYICMF-Malaysia International Music & Dance Festival organized by Sugam

Culture & Heritage Foundation partnering with Carnatica Global, IMPL-
International Music Premier League and the Ministry of Education Malaysia.

She debuted in Chennai's December Music Season in the year 2022 and
performed concerts at many prestigious venues. Additionally, she has
performed concerts at various venues in Tirunelveli, Dallas-Houston area
and many online platforms.</p>
                </div>

                <div class="col-sm-6">
               <img src="/images/Bhargavi-Photo.jpg" title="Bhargavi ChandraSekharan" alt="Bhargavi ChandraSekharan" width="450" height="400" />
                </div>


                <div class="col-md-5 col-sm-6">
                    
                  </div>
            </div>
           <br /> <br /> <br />
           <div class="wrapper1">
            <h5>Pavithra Chandrasekaharan</h5> <br /> <br />
            <div class="row col-sm-12 interchange">
            <div class="col-sm-5 ">
                <img src="/images/Pavithra-Photo.jpg" title="Cleveland Thyagaraja Festival" alt="Cleveland Thyagaraja Festival" width="450" height="400" />
                </div>
            <div class="col-sm-6 text-center">
                <p>Pavithra Chandrasekaran is a junior attending high school in Katy, TX. She
has been taking Carnatic music vocal lessons since age 7 from Vidushi Smt.
Rajarajeshwary Bhat and Vidushi Smt. Kruthi Bhat. She was initiated into
Bharathanatyam at the age of 3 and in the year 2009 by Smt Kripa Baskaran
of Natyarpana Dance Company - Wisconsin. After moving to Houston at age
7 and in the year 2013, she continued to learn Bharathanatyam from Smt.
Sandhya Raghuraman until 2015. Since 2015, she is receiving advanced
training in Bharathanatyam from Smt. Dr. Rathna Kumar of Anjali
Performing Arts. Pavithra gave her solo vocal debut concert (also known as)
Gruha Sangeetham in 2018 at the age of 12. She had participated and won
various prizes in music competitions conducted by the following
organizations - Cleveland Tyagaraja Aradhana, The Artiste, SAPTAMI-Dallas,
MYICMF-Malaysia International Music & Dance Festival organized by Sugam

Culture & Heritage Foundation partnering with Carnatica Global, IMPL-
International Music Premier League and the Ministry of Education Malaysia.

She has performed concerts at various venues in Tirunelveli, Dallas-Houston
area, and many online platforms.</p>
                  </div>
               


               
            </div>
            </div>
        </div>
  

          
    )}


        
    

export default AboutUs
